import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { withRouter } from '../../../services/security/withRouter';
import CompanyService from '../../../services/CompanyService';
import Skeleton from '@mui/material/Skeleton';
import CustomSnackbar from '../../shared/CustomSnackbar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TargetAudience from './steps/TargetAudience';
import BrandDetails from './steps/BrandDetails';
import ConfirmDialog from '../../shared/ConfirmDialog';
import RightsService from "../../../services/security/RightsService";

class Brand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brand: { industry: '' },
            loadingBrand: true,
            snackbarOpen: false,
            snackbarSeverity: 'success',
            snackbarMessage: '',
            expandedVoices: {},
            drawerOpen: false,
            targetAudience: {
                location: [],
                ageRange: '',
                interests: [],
                voices: [],
                industry: null,
            },
            activeBrand: null,
            errors: {},
            loadingSave: false,
            isEdit: false,
            deleteDialogOpen: false,
            removeAudienceId: null,
            deleteBrandDialogOpen: false,
        };
    }

    componentDidMount() {
        const { id } = this.props.params;
        this.getBrand(id);
    }

    getBrand(brandId) {
        CompanyService.getModelBrand(brandId).then(brand => {
            this.setState({
                brand,
                loadingBrand: false,
            });
        }).catch(error => {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingBrand: false,
            });
        });
    }

    handleSeeAllBrandsClick = () => {
        this.props.navigate('/brands');
    }

    handleEditBrandClick = () => {
        const { id } = this.props.params;
        this.props.navigate(`/create-brand?brandId=${id}&step=1&editBrand=true`);
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }

    toggleVoiceStory = (voiceId) => {
        this.setState(prevState => ({
            expandedVoices: {
                ...prevState.expandedVoices,
                [voiceId]: !prevState.expandedVoices[voiceId]
            }
        }));
    }

    handleOpenDrawer = (audience = {}) => {
        if (Object.keys(audience).length > 0) {
            this.setState({ isEdit: true });
        } else {
            this.setState({ isEdit: false });
        }
        this.setState({
            drawerOpen: true,
            targetAudience: {
                location: audience.location || [],
                ageRange: audience.ageRange || '',
                interests: audience.interests || [],
                id: audience.id,
                voices: audience.voices || [],
                industry: audience.industry || '',
            },
        });
    }

    handleDeleteDialogOpen = (audienceId) => {
        this.setState({ deleteDialogOpen: true, removeAudienceId: audienceId });
    };

    handleDeleteDialogClose = () => {
        this.setState({ deleteDialogOpen: false, removeAudienceId: null });
    };

    handleDeleteDialogConfirm = () => {
        const removeId = this.state.removeAudienceId;
        CompanyService.deleteAudience(removeId).then(() => {
            this.setState(prevState => ({
                snackbarSeverity: 'success',
                snackbarMessage: 'Target audience was deleted successfully.',
                snackbarOpen: true,
                brand: {
                    ...prevState.brand,
                    audiences: prevState.brand.audiences.filter(audience => audience.id !== removeId),
                }
            }));
        }).catch(error => {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: 'An error occurred while deleting the target audience. Please try again.',
                snackbarOpen: true,
            });
        });

        this.handleDeleteDialogClose();
    };

    handleCloseDrawer = () => {
        this.setState({ drawerOpen: false });
    }

    handleAudienceInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            targetAudience: {
                ...prevState.audience,
                [name]: value,
            }
        }));
    }

    handleVoiceInputChange = (index, updatedVoice) => {
        const voices = [...this.state.targetAudience.voices];
        voices[index] = {
            ...voices[index],
            ...updatedVoice
        };

        this.setState(prevState => ({
            targetAudience: {
                ...prevState.targetAudience,
                voices,
            }
        }));
    }

    handleUpdate = (updatedState) => {
        this.setState((prevState) => ({
            ...prevState,
            ...updatedState,
        }));
    };

    addVoice = () => {
        this.setState(prevState => ({
            targetAudience: {
                ...prevState.targetAudience,
                voices: [...prevState.targetAudience.voices, { personality: '', toneOfVoice: '', story: '' }],
            }
        }));
    }

    removeVoice = (index) => {
        const voices = [...this.state.targetAudience.voices];
        voices.splice(index, 1);
        this.setState(prevState => ({
            targetAudience: {
                ...prevState.targetAudience,
                voices,
            }
        }));
    }

    isTargetAudienceValid = () => {
        const { location, ageRange, interests, voices } = this.state.targetAudience;
        return location.length > 0 && ageRange !== '' && interests.length > 0 && voices.length < 3;
    }

    validateInputs = () => {
        const { location, ageRange, interests, voices } = this.state.targetAudience;
        const errors = {};

        if (location.length === 0) {
            errors.location = 'Please specify a location.';
        }
        if (ageRange === '') {
            errors.ageRange = 'Please specify an age range.';
        }
        if (interests.length === 0) {
            errors.interests = 'Please add at least one interest.';
        }
        if (!this.state.targetAudience.industry) {
            errors.audienceIndustry = 'Please select the industry of your target audience.';
        } else if (this.state.targetAudience.industry === 'Other' && !this.state.targetAudience.otherIndustry) {
            errors.audienceOtherIndustry = 'Please specify the "Other" industry for your target audience.';
        }

        if (voices.length === 0) {
            errors.voices = 'At least one voice is required';
        } else {
            voices.forEach((voice, index) => {
                if (!voice.personality) {
                    errors[`voice_personality_${index}`] = 'A personality is required for each brand voice.';
                }
                if (!voice.toneOfVoice) {
                    errors[`voice_toneOfVoice_${index}`] = 'A tone of voice is required for each brand voice.';
                }
                if (!voice.story) {
                    errors[`voice_story_${index}`] = 'A story is required for each brand voice.';
                }
            });
        }

        this.setState({ errors });
        return Object.keys(errors).length === 0;
    }

    handleSubmit = async () => {
        if (!this.validateInputs()) {
            return;
        }

        this.setState({ loadingSave: true });
        try {
            const industryValue = this.state.targetAudience.industry === 'Other'
                ? this.state.targetAudience.otherIndustry
                : this.state.targetAudience.industry;

            const response = await CompanyService.createAudienceFully(this.state.brand.id, {
                ageRange: this.state.targetAudience.ageRange,
                location: this.state.targetAudience.location,
                interests: this.state.targetAudience.interests,
                industry: industryValue,
                id: this.state.targetAudience.id,
                voices: this.state.targetAudience.voices,
            });

            this.setState(prevState => {
                const existingAudienceIndex = prevState.brand.audiences.findIndex(audience => audience.id === response.id);

                let updatedAudiences;
                if (existingAudienceIndex !== -1) {
                    updatedAudiences = [
                        ...prevState.brand.audiences.slice(0, existingAudienceIndex),
                        response,
                        ...prevState.brand.audiences.slice(existingAudienceIndex + 1)
                    ];
                } else {
                    updatedAudiences = [...prevState.brand.audiences, response];
                }

                return {
                    brand: {
                        ...prevState.brand,
                        audiences: updatedAudiences
                    },
                    loadingSave: false,
                    drawerOpen: false,
                };
            });

        } catch (error) {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingSave: false,
            });
        }
    }

    hasBrandEditRight = () => {
        return RightsService.hasRight('BRANDS_EDIT');
    };

    handleDeleteBrandDialogOpen = () => {
        this.setState({ deleteBrandDialogOpen: true });
    };

    handleDeleteBrandDialogClose = () => {
        this.setState({ deleteBrandDialogOpen: false });
    };

    handleDeleteBrandDialogConfirm = () => {
        const { id } = this.props.params;
        CompanyService.deleteBrand(id).then(() => {
            this.setState({
                snackbarSeverity: 'success',
                snackbarMessage: 'Brand deleted successfully.',
                snackbarOpen: true,
            });
            this.props.navigate('/brands');
        }).catch(error => {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
            });
        });
        this.handleDeleteBrandDialogClose();
    };

    render() {
        const { brand, loadingBrand, snackbarOpen, snackbarSeverity, snackbarMessage, expandedVoices, drawerOpen, targetAudience, errors } = this.state;

        return (
            <>
                {loadingBrand ? (
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <Skeleton variant="rectangular" height={400} />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <Card sx={{ padding: 4, position: 'relative', marginBottom: 4 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2 }}>
                                    {this.hasBrandEditRight() && (
                                        <Button
                                            sx={{ marginRight: 2 }}
                                            onClick={this.handleDeleteBrandDialogOpen}
                                            variant="contained"
                                            color="error"
                                        >
                                            Delete
                                        </Button>
                                    )}
                                    {this.hasBrandEditRight() && (
                                        <Button
                                            sx={{ marginRight: 2 }}
                                            onClick={this.handleEditBrandClick}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Modify Brand Details
                                        </Button>
                                    )}
                                    <Button
                                        onClick={this.handleSeeAllBrandsClick}
                                        variant="contained"
                                        color="primary"
                                    >
                                        View All Brands
                                    </Button>
                                </Box>
                                <CardContent>
                                    {brand && (
                                        <>
                                            <Typography variant="h3" gutterBottom>
                                                {brand.name}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                                {brand.industry}
                                            </Typography>
                                            <Divider sx={{ marginY: 2 }} />
                                            {brand?.audiences?.map((audience, index) => (
                                                <Accordion key={audience.id} defaultExpanded={index === 0}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`panel-${audience.id}-content`}
                                                        id={`panel-${audience.id}-header`}
                                                    >
                                                        <Box>
                                                            <Typography variant="h6">{audience.name}</Typography>
                                                            <Typography variant="subtitle1" color="primary"> Industry: {audience.industry} </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {audience.interests.join(', ')}
                                                            </Typography>
                                                        </Box>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {audience.voices.map(voice => (
                                                            <Box key={voice.id} sx={{ marginBottom: 2 }}>
                                                                <Typography variant="subtitle1" color="primary">
                                                                    Personality: {voice.personality}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    Tone: {voice.toneOfVoice}
                                                                </Typography>
                                                                <Typography variant="body2" paragraph>
                                                                    {expandedVoices[voice.id] || voice.story.length <= 100 ? voice.story : `${voice.story.substring(0, 100)}...`}
                                                                </Typography>
                                                                {voice.story.length > 100 && (
                                                                    <Button size="small" onClick={() => this.toggleVoiceStory(voice.id)}>
                                                                        {expandedVoices[voice.id] ? 'See Less' : 'See More'}
                                                                    </Button>
                                                                )}
                                                                <Divider sx={{ marginY: 2 }} />
                                                            </Box>
                                                        ))}
                                                        {this.hasBrandEditRight() && (
                                                            <Button size="small" onClick={() => this.handleOpenDrawer(audience)}>
                                                                Modify Target Audience Details
                                                            </Button>
                                                        )}
                                                        { this.hasBrandEditRight() && <Button
                                                            size="small"
                                                            color="error"
                                                            onClick={() => this.handleDeleteDialogOpen(audience.id)}
                                                            disabled={this.state.brand.audiences.length === 1}
                                                        >
                                                            Delete Audience
                                                        </Button>}
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                            {this.hasBrandEditRight() && (
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ marginTop: 2 }}
                                                    onClick={() => this.handleOpenDrawer()}
                                                    disabled={brand?.audiences?.length >= 3}
                                                >
                                                    Create New Audience
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                )}
                <CustomSnackbar
                    snackbarOpen={snackbarOpen}
                    handleSnackbarClose={this.handleSnackbarClose}
                    severity={snackbarSeverity}
                    snackbarMessage={snackbarMessage}
                />
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={this.handleCloseDrawer}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    BackdropProps={{
                        onClick: (event) => event.stopPropagation(),
                    }}
                    disableEscapeKeyDown
                >
                    <Box sx={{ width: this.props.isMobile ? 380 : 600, padding: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                            <Typography variant="h6">{this.state.isEdit ? 'Edit Audience' : 'Add a New Target Audience'}</Typography>
                            <IconButton onClick={this.handleCloseDrawer}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <TargetAudience
                            targetAudience={targetAudience}
                            errors={errors}
                            onUpdate={this.handleUpdate}
                            industry={brand.industry == "Other" ? brand.otherIndustry : brand.industry}
                            inSidebar={true}
                        />
                        {targetAudience.voices.map((voice, index) => (
                            <Box key={index} sx={{ marginBottom: 2 }}>
                                <BrandDetails
                                    voice={voice}
                                    errors={errors}
                                    onUpdate={this.handleVoiceInputChange}
                                    industry={brand.industry == "Other" ? brand.otherIndustry : brand.industry}
                                    audienceIndustry={targetAudience.industry == "Other" ? targetAudience.otherIndustry : targetAudience.industry}
                                    ageRange={targetAudience.ageRange}
                                    location={targetAudience.location}
                                    brandName={brand.name}
                                    index={index}
                                    inSidebar={true}
                                    allVoices={targetAudience.voices}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 1 }}>
                                    <Button size="small" color="danger" onClick={() => this.removeVoice(index)}>
                                        Delete Brand Voice
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                        {errors.voices && <Typography color="error">{errors.voices}.</Typography>}
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{ marginTop: 2 }}
                            onClick={this.addVoice}
                            disabled={!this.isTargetAudienceValid()}
                        >
                            Add New Brand Voice
                        </Button>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                            <Button onClick={this.handleCloseDrawer} color="danger" sx={{ marginRight: 1 }}>
                                Discard Changes
                            </Button>
                            <Button onClick={this.handleSubmit} color="primary">
                                Save Changes
                            </Button>
                        </Box>
                    </Box>
                </Drawer>
                <ConfirmDialog
                    dialogOpen={this.state.deleteDialogOpen}
                    handleClose={this.handleDeleteDialogClose}
                    handleConfirm={this.handleDeleteDialogConfirm}
                    dialogTitle={"Confirm Deletion"}
                    dialogMessage={"Are you certain you want to permanently delete this target audience?"}
                />
                <ConfirmDialog
                    dialogOpen={this.state.deleteBrandDialogOpen}
                    handleClose={this.handleDeleteBrandDialogClose}
                    handleConfirm={this.handleDeleteBrandDialogConfirm}
                    dialogTitle={"Confirm Deletion"}
                    dialogMessage={"Are you certain you want to permanently delete this brand?"}
                />
            </>
        );
    }
}

export default withRouter(Brand);