import React, { Component } from 'react';
import RoleService from '../../services/RoleService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import RightsService from "../../services/security/RightsService";

class Roles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            loading: true,
            snackbarOpen: false,
            snackbarSeverity: 'success',
            snackbarMessage: '',
        };
    }

    componentDidMount() {
        if (!RightsService.hasRight('USERS_EDIT')) {
            setTimeout(() => {
                this.props.navigate('/dashboard');
            }, 0);
            return;
        }

        RoleService.getAll().then(roles => {
            this.setState({ roles: roles, loading: false });
        }).catch(error => {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: 'Failed to retrieve the roles',
                snackbarOpen: true,
                loading: false
            });

            console.error(error);
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    render() {
        const { loading, roles, snackbarOpen, snackbarSeverity, snackbarMessage } = this.state;

        return (
            <Box sx={{ padding: 3 }}>
                {loading ? (
                    Array.from(new Array(3)).map((_, index) => (
                        <Card key={index} sx={{ marginBottom: 3 }}>
                            <CardContent>
                                <Skeleton variant="text" width="40%" />
                                <Skeleton variant="rectangular" height={118} />
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    roles.map((role) => (
                        <Card key={role.name} sx={{ marginBottom: 3 }}>
                            <CardContent>
                                <Typography component="h4" variant="h6" color="text.primary" gutterBottom>
                                    {role.name}
                                </Typography>
                                
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Category</TableCell>
                                                <TableCell align="right">Rights</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(role.rights).map((category) => (
                                                <TableRow key={category}>
                                                    <TableCell component="th" scope="row">
                                                        {category}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {role.rights[category].join(', ')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    ))
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                >
                    <Alert onClose={this.handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        );
    }
}

export default Roles;