import React, { Component } from 'react';
import AuthService from '../../services/security/AuthService';
import { withRouter } from '../../services/security/withRouter';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import getTheme from "../getTheme";
import { FormHelperText } from '@mui/material';

const theme = createTheme(getTheme());

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      rememberMe: false,
      errorResponse: {},
      resetPasswordMessage: '',
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const resetPasswordMessage = urlParams.get('message');
    this.setState({ resetPasswordMessage });
  }

  handleLogin = async () => {
    const { email, password, rememberMe } = this.state;
    this.setState({ resetPasswordMessage: '' });

    if (!email) {
      this.setState({ emailError: 'Please enter your email address.' });
      return;
    }
    if (!password) {
      this.setState({ passwordError: 'Please enter your password.' });
      return;
    }

    const response = await AuthService.login(email, password, rememberMe);
    if (response.error) {
      this.setState({ errorResponse: response });
    } else {
      this.props.onLogin(response.token, response.refreshToken);
      this.props.navigate('/dashboard');
    }
  };

  handleChangeCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Log In
              </Typography>
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  this.handleLogin();
                }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({ email: e.target.value, emailError: '' })
                  }
                  error={Boolean(this.state.emailError)}
                  helperText={this.state.emailError}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={(e) =>
                    this.setState({ password: e.target.value, passwordError: '' })
                  }
                  error={Boolean(this.state.passwordError)}
                  helperText={this.state.passwordError}
                />
                <FormControlLabel
                  control={<Checkbox name="rememberMe" color="primary" onChange={this.handleChangeCheckbox} checked={this.state.rememberMe} />}
                  label="Keep me signed in"
                />
                {this.state.errorResponse && <FormHelperText error>{this.state.errorResponse.message}</FormHelperText>}
                {this.state.resetPasswordMessage && <FormHelperText style={{ color: 'green' }}>{this.state.resetPasswordMessage.replace(/"/g, '')}</FormHelperText>}

                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  type='submit'
                  >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs >
                    <Link href="/forgot-password" variant="body2">
                      Forgot your password?
                    </Link>
                  </Grid>
                  <Grid item sx={{ ml: 2 }}>
                    <Link href="/register" variant="body2">
                      {"New to the platform? Create an account."}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default withRouter(Login);
