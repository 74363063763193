import { alpha } from '@mui/material/styles';

const primaryColor = {
  50: '#edeefd',
  100: '#c8cafd',
  200: '#a2a6f5',
  300: '#8085f0',
  400: '#686dec',
  500: '#595ce2',  // base shade
  600: '#4c50c8',
  700: '#4245a8',
  800: '#373987',
  900: '#2a2b63',
  A100: '#c3c7ff',
  A200: '#a0a5ff',
  A400: '#7d83ff',
  A700: '#5b61ff',
};

const secondaryColor = {
  50: '#fff4e8',
  100: '#ffe2c2',
  200: '#ffc899',
  300: '#ffae6f',
  400: '#ff9750',
  500: '#ff7f33',
  600: '#e6702d',
  700: '#c45f27',
  800: '#a35020',
  900: '#7b3b17',
  A100: '#ffd6b0',
  A200: '#ffbc80',
  A400: '#ffa250',
  A700: '#ff8d33',
};

const customColors = {
  success: '#66bb6a',
  warning: '#ffa726',
  info: '#5ab0e8',
  danger: '#e64a45',
  lightGray: '#f5f5f5',
};

const getTheme = () => ({
  palette: {
    mode: 'light',
    primary: {
      main: primaryColor[500],
      dark: primaryColor[700],
      light: primaryColor[300],
      contrastText: '#ffffff',
    },
    secondary: {
      main: secondaryColor[500],
      dark: secondaryColor[700],
      light: secondaryColor[300],
      contrastText: '#ffffff',
    },
    error: {
      main: '#e64a45',
    },
    warning: {
      main: customColors.warning,
    },
    info: {
      main: customColors.info,
    },
    danger: {
      main: customColors.danger,
    },
    success: {
      main: customColors.success,
    },
    background: {
      default: customColors.lightGray,
      paper: '#ffffff',
    },
    text: {
      primary: '#2e2e2e',
      secondary: '#595959',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h1: {
      fontSize: '2.75rem',
      fontWeight: 700,
      lineHeight: 1.2,
      color: '#2e2e2e',
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 600,
      lineHeight: 1.3,
      color: '#2e2e2e',
    },
    h3: {
      fontSize: '1.9rem',
      fontWeight: 500,
      lineHeight: 1.4,
      color: '#2e2e2e',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.4,
      color: '#2e2e2e',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.4,
      color: '#2e2e2e',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
      color: '#595959',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
    caption: {
      fontSize: '0.75rem',
      color: '#888888',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          boxShadow: 'none',
          borderRadius: '8px',
          padding: '8px 16px',
          textTransform: 'none',
          transition: 'all 0.2s ease-in-out',
          ...(ownerState.size === 'small' && {
            minHeight: '32px',
          }),
          ...(ownerState.size === 'medium' && {
            minHeight: '40px',
          }),
          ...(ownerState.variant === 'contained' && {
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: `0 4px 10px ${alpha(theme.palette.primary.dark, 0.2)}`,
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            borderColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              borderColor: theme.palette.primary.dark,
            },
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '8px',
          },
          '& .MuiInputLabel-root': {
            color: alpha('#000', 0.6),
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: primaryColor[800],
          color: '#ffffff',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 3px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '16px',
          padding: '20px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          padding: '24px',
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.15)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: primaryColor[700],
          color: '#ffffff',
          fontSize: '0.875rem',
          borderRadius: '6px',
        },
      },
    },
  },
});

export default getTheme;