import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

const ButtonGroup = ({ loading, loadingIdeas, loadingRephrase, handleGenerate, handleRephrase, handleGenerateIdeas, saveContent, inEdit, loadingSave }) => (
    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
        <Button
            variant="contained"
            color="primary"
            onClick={handleGenerate}
            startIcon={inEdit ? <ReplayOutlinedIcon /> :  <AutoAwesomeIcon />}
            disabled={loadingRephrase || loading || loadingIdeas}
        >
            {loading ? <CircularProgress size={24} color="inherit" /> : inEdit ? 'Re-Generate' : 'Generate'}
        </Button>
        {!inEdit && <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateIdeas}
            startIcon={<AutoAwesomeIcon />}
            disabled={loadingRephrase || loading || loadingIdeas}
        >
            {loadingIdeas ? <CircularProgress size={24} color="inherit" /> : 'Ideas'}
        </Button>}
        {inEdit && <Button
            variant="contained"
            color="primary"
            onClick={handleRephrase}
            startIcon={<AutoAwesomeIcon />}
            disabled={loadingRephrase || loading || loadingIdeas}
        >
            {loadingRephrase ? <CircularProgress size={24} color="inherit" /> : 'Rephrase'}
        </Button>}
        <Button
            variant="contained"
            color="primary"
            onClick={saveContent}
            startIcon={<SaveAltOutlinedIcon/>}
            disabled={loadingRephrase || loading || loadingIdeas}
            sx={{ bgcolor: 'secondary' }}
        >
            {loadingSave ? <CircularProgress size={24} color="inherit" /> : 'Save'}

        </Button>
    </Box>
);

export default ButtonGroup;