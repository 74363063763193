import React, { Component } from 'react';
import { Editor, EditorState, RichUtils, Modifier, ContentState } from 'draft-js';
import { Box, IconButton, Tooltip } from '@mui/material';
import Toolbar from './Toolbar';
import 'draft-js/dist/Draft.css';
import styleMap from '../../../services/util/styleMap';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ButtonGroup from './ButtonGroup';

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorContent: EditorState.createEmpty(),
        };
    }

    componentDidMount() {
        if (this.props.initialContent) {
            this.setState({ editorContent: this.props.initialContent });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.initialContent !== prevProps.initialContent) {
            this.setState({ editorContent: this.props.initialContent });
        }
    }

    handleEditorChange = (editorContent) => {
        this.setState({ editorContent });
        if (this.props.onChange) {
            this.props.onChange(editorContent);
        }
    };

    toggleInlineStyle = (event, style) => {
        event.preventDefault();
        this.setState({
            editorContent: RichUtils.toggleInlineStyle(this.state.editorContent, style),
        });
    };

    toggleBlockType = (event, blockType) => {
        event.preventDefault();
        this.setState({
            editorContent: RichUtils.toggleBlockType(this.state.editorContent, blockType),
        });
    };

    setAlignment = (event, alignment) => {
        event.preventDefault();
        const { editorContent } = this.state;
        const selection = editorContent.getSelection();
        const contentState = editorContent.getCurrentContent();

        const newContentState = Modifier.setBlockData(
            contentState,
            selection,
            { alignment }
        );

        this.setState({
            editorContent: EditorState.push(editorContent, newContentState, 'change-block-data'),
        });
    };

    setFontSize = (fontSize) => {
        const { editorContent } = this.state;
        const selection = editorContent.getSelection();
        const contentState = editorContent.getCurrentContent();

        const newContentState = Modifier.applyInlineStyle(
            contentState,
            selection,
            `FONTSIZE_${fontSize}`
        );

        this.setState({
            editorContent: EditorState.push(editorContent, newContentState, 'change-inline-style'),
        });
    };

    setColor = (color) => {
        const { editorContent } = this.state;
        const selection = editorContent.getSelection();

        const newContentState = Modifier.applyInlineStyle(
            editorContent.getCurrentContent(),
            selection,
            `COLOR_${color}`
        );

        this.setState({
            editorContent: EditorState.push(editorContent, newContentState, 'change-inline-style'),
        });
    };

    copyContentToClipboard = () => {
        const contentState = this.state.editorContent.getCurrentContent();
        const plainText = contentState.getPlainText();
        navigator.clipboard.writeText(plainText).then(() => {
            console.log('Content copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy content: ', err);
        });
    };

    render() {
        const { editorContent } = this.state;

        return (
            <Box>
                <ButtonGroup
                    loading={this.props.loading}
                    loadingIdeas={this.props.loadingIdeas}
                    loadingSave={this.props.loadingSave}
                    handleGenerate={this.props.handleGenerate}
                    handleRephrase={this.props.handleRephrase}
                    handleGenerateIdeas={this.props.handleGenerateIdeas}
                    saveContent={this.props.saveContent}
                    inEdit={this.props.inEdit}
                    loadingRephrase={this.props.loadingRephrase}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Toolbar
                        toggleInlineStyle={this.toggleInlineStyle}
                        toggleBlockType={this.toggleBlockType}
                        setAlignment={this.setAlignment}
                        setFontSize={this.setFontSize}
                        setColor={this.setColor}
                    />

                    <Tooltip title="Copy to clipboard">
                        <IconButton
                            color="primary"
                            onClick={this.copyContentToClipboard}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ border: '1px solid #ccc', borderRadius: 1, minHeight: this.props.inEdit ? '100px' : '50vh', padding: 2 }}>
                    <Editor
                        editorState={editorContent}
                        onChange={this.handleEditorChange}
                        placeholder="Start typing..."
                        customStyleMap={styleMap}
                    />
                </Box>
            </Box>
        );
    }
}

export default TextEditor;