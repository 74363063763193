import React, { Component } from 'react';
import { withRouter } from '../../services/security/withRouter';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PasswordResetService from '../../services/security/PasswordResetService';

const defaultTheme = createTheme();

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            matchPassword: '',
            errors: {},
            errorResponse: {},
            token: '',
            isButtonDisabled: false, // New state variable
        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        this.setState({ token });
    }

    validateForm = () => {
        const { password, matchPassword } = this.state;
        let errors = {};

        if (!password) {
            errors.password = 'Please enter a password.';
        }

        if (!matchPassword) {
            errors.matchPassword = 'Please re-enter your password to confirm.';
        } else if (password !== matchPassword) {
            errors.matchPassword = 'The passwords you entered do not match. Please try again.';
        }

        this.setState({ errors });

        return Object.keys(errors).length === 0;
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validateForm()) {
            this.setState({ errorResponse: '', isButtonDisabled: true }); // Disable the button

            this.handleResetPassword();

            // Re-enable the button after 30 seconds
            setTimeout(() => {
                this.setState({ isButtonDisabled: false });
            }, 60000);
        }
    };

    handleResetPassword = async () => {
        const response = await PasswordResetService.resetPassword(this.state.token, this.state.password);
        if (response.error) {
            this.setState({ errorResponse: response });
        } else {
            this.props.navigate('/login?message="Your password has been reset successfully. You can now log in with your new password."');
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    render() {
        const { errors, isButtonDisabled } = this.state;

        return (
            <div>
                <ThemeProvider theme={defaultTheme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign up
                            </Typography>
                            <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            error={!!errors.password}
                                            helperText={errors.password}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="matchPassword"
                                            label="Confirm Your Password"
                                            type="password"
                                            id="matchPassword"
                                            autoComplete="new-password"
                                            value={this.state.matchPassword}
                                            onChange={this.handleChange}
                                            error={!!errors.matchPassword}
                                            helperText={errors.matchPassword}
                                        />
                                    </Grid>
                                </Grid>
                                {this.state.errorResponse && <FormHelperText error>{this.state.errorResponse.message}</FormHelperText>}

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={isButtonDisabled} // Disable the button based on state
                                >
                                    Reset Password
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            Sign in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </div>
        );
    }
}

export default withRouter(ResetPassword);