import React, { Component, createRef } from 'react';
import { Fab, Paper, TextField, IconButton, Box, Typography, Avatar, Divider, CircularProgress } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import MinimizeOutlinedIcon from '@mui/icons-material/MinimizeOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ChatService from '../../services/chat/ChatService';
import withMediaQuery from "../../services/withMediaQuery";
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';

class ChatWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isFullscreen: false,
            messages: [],
            input: '',
            loading: false,
        };
        this.messagesEndRef = createRef();
    }

    toggleChat = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    toggleFullscreen = () => {
        this.setState((prevState) => ({ isFullscreen: !prevState.isFullscreen }));
    };

    sendMessage = async () => {
        const { input, messages } = this.state;
        if (input.trim()) {
            const userMessage = { sender: 'user', text: input };
            const aiPlaceholder = { sender: 'ai', text: '', isLoading: true };
            this.setState({ messages: [...messages, userMessage, aiPlaceholder], input: '' }, this.scrollToBottom);

            try {
                const response = await ChatService.sendRequest({ message: input, brandId: this.props.selectedBrand ? this.props.selectedBrand.id : null });
                this.setState((prevState) => {
                    const updatedMessages = prevState.messages.slice(0, -1).concat({ sender: 'ai', text: response.response });
                    return { messages: updatedMessages, loading: false };
                }, this.scrollToBottom);
            } catch (error) {
                const errorMessage = error.code === 1016
                    ? 'Error: Message limit reached. Please wait one minute before trying again.'
                    : 'Error: Unable to fetch a reply.';

                this.setState((prevState) => {
                    const updatedMessages = prevState.messages.slice(0, -1).concat({ sender: 'ai', text: errorMessage });
                    return { messages: updatedMessages, loading: false };
                }, this.scrollToBottom);
            }
        }
    };

    handleInputChange = (e) => {
        this.setState({ input: e.target.value });
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter' && !this.state.loading) {
            e.preventDefault(); // Prevents newline insertion
            this.sendMessage();
        }
    };

    scrollToBottom = () => {
        this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    parseMessage = (message, isLoading) => {
        if (typeof message !== 'string') {
            return null;
        }

        const lines = message.split('\n'); // Split the message into lines

        return lines.map((line, index) => {
            line = line.replace(/【\d{1,2}:\d{2}†source】/g, '');
            if (line.startsWith('### ')) {
                return (
                    <Typography
                        key={index}
                        variant="h6"
                        sx={{ fontWeight: 'bold', marginBottom: 1 }}
                    >
                        {line.replace('### ', '')}
                    </Typography>
                );
            }

            const parts = line.split(/(\*\*.*?\*\*)/);
            return (
                <Typography key={index} component="div">
                    {parts.map((part, idx) =>
                        part.startsWith('**') && part.endsWith('**') ? (
                            <span key={idx} style={{ fontWeight: 'bold' }}>
                            {part.slice(2, -2)} {/* Remove ** markers */}
                        </span>
                        ) : (
                            <span key={idx}>{part}</span>
                        )
                    )}
                    {isLoading && index === lines.length - 1 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress size={20} />
                        </Box>
                    )}
                </Typography>
            );
        });
    };

    render() {
        const { isOpen, isFullscreen, messages, input, loading } = this.state;
        const { isMobile } = this.props;

        return (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: isMobile ? 80 : 16,
                    zIndex: 2000,
                }}
            >
                <Fab color="primary" onClick={this.toggleChat}>
                    <ChatIcon />
                </Fab>

                {isOpen && (
                    <Paper
                        elevation={8}
                        sx={{
                            position: 'fixed',
                            bottom: isFullscreen ? 0 : 80,
                            right: isFullscreen ? 0 : 16,
                            width: isFullscreen ? '100%' : (isMobile ? 300 : 400),
                            height: isFullscreen ? '100%' : 600,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: isFullscreen ? 0 : 4,
                            overflow: 'hidden',
                            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                            zIndex: 3000, // Higher zIndex for Paper
                        }}
                    >

                        <Box
                            sx={{
                                backgroundColor: 'primary.main',
                                color: '#fff',
                                padding: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                                Chat with AI
                                <AutoAwesomeSharpIcon sx={{ ml: 1 }} />
                            </Typography>                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <IconButton
                                    size="small"
                                    sx={{ color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={this.toggleFullscreen}
                                >
                                    {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                </IconButton>
                                <IconButton
                                    size="small"
                                    sx={{ color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={this.toggleChat}
                                >
                                    <MinimizeOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                flex: 1,
                                padding: 2,
                                overflowY: 'auto',
                                backgroundColor: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {messages.map((msg, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                                        marginBottom: 1,
                                    }}
                                >
                                    {msg.sender === 'ai' && (
                                        <Avatar
                                            sx={{
                                                bgcolor: 'primary.light',
                                                marginRight: 1,
                                                height: 30,
                                                width: 30,
                                            }}
                                        >
                                            AI
                                        </Avatar>
                                    )}
                                    <Typography
                                        sx={{
                                            maxWidth: '70%',
                                            padding: 1,
                                            borderRadius: 2,
                                            backgroundColor: msg.sender === 'user' ? 'primary.light' : 'primary.dark',
                                            color: '#fff',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        {this.parseMessage(msg.text, msg.isLoading)}
                                    </Typography>
                                </Box>
                            ))}
                            <div ref={this.messagesEndRef} />
                        </Box>

                        {/* Divider */}
                        <Divider />

                        {/* Chat Input */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: 1,
                                backgroundColor: '#fff',
                            }}
                        >
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={input}
                                onChange={this.handleInputChange}
                                onKeyPress={this.handleKeyPress}
                                placeholder="Type a message..."
                                sx={{
                                    marginRight: 1,
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: 2,
                                }}
                            />

                            <IconButton color="primary" onClick={this.sendMessage} disabled={loading}>
                                <SendIcon />
                            </IconButton>
                        </Box>
                    </Paper>
                )}
            </Box>
        );
    }
}

export default withMediaQuery(ChatWidget);