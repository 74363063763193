import ApiCalls from "../util/ApiCalls";

class StripeSubscriptionService {
    async getUrl(productName, billingPeriod) {
      return ApiCalls.defaultPost("/subscriptions/stripe-session", {}, {title: productName, period: billingPeriod});
    }

    async getSeatsUrl(quantity) {
        return ApiCalls.defaultPost("/stripe/buy-seats", {}, {quantity});
    }

    async removeSeats(quantity) {
        return ApiCalls.defaultPost("/stripe/remove-seats", {}, {quantity});
    }

    async getProducts() {
      return ApiCalls.defaultGet("/stripe/get-products", {});
    }

    async getSubscription() {
      return ApiCalls.defaultGet("/stripe/get-subscription", {});
    }

    async getInvoices() {
        return ApiCalls.defaultGet("/stripe/get-invoices", {});
    }

    async getCurrentUsage() {
        return ApiCalls.defaultGet("/user-subscriptions/current-usage", {});
    }

    async upgradeSubscription(productName, billingPeriod) {
        return ApiCalls.defaultPost("/stripe/upgrade-subscription", {}, {title: productName, period: billingPeriod});
    }

    async getUpgradeEstimates(productName, billingPeriod) {
        return ApiCalls.defaultPost("/stripe/upgrade-estimates", {}, {title: productName, period: billingPeriod});
    }
}
  
  export default new StripeSubscriptionService();