class RightsService {
    static getRights() {
        const rights = localStorage.getItem('rights');
        return rights ? JSON.parse(rights) : [];
    }

    static hasRight(rightName) {
        const rights = this.getRights();
        return rights.includes(rightName);
    }
}

export default RightsService;