import React, { Component, createRef } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    Link,
    ThemeProvider,
    CssBaseline
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import guides from '../../services/guide/guides';
import { createTheme } from "@mui/material/styles";
import getTheme from "../getTheme";

const theme = createTheme({
    ...getTheme(),
    typography: {
        fontFamily: 'Arial, sans-serif', // Change the font to a standard one
    },
});

class GuideComponent extends Component {
    constructor(props) {
        super(props);
        const initialSection = guides.length > 0 ? guides[0] : null;
        const initialSubsection = initialSection && initialSection.subsections.length > 0 ? initialSection.subsections[0].id : null;
        this.state = {
            activeSection: JSON.parse(localStorage.getItem('activeSection')) || initialSection,
            activeSubsection: localStorage.getItem('activeSubsection') || initialSubsection
        };
        this.sectionRefs = {};
        this.initializeSectionRefs(this.state.activeSection);
    }

    initializeSectionRefs = (section) => {
        this.sectionRefs = {};
        if (section) {
            section.subsections.forEach((sub) => {
                this.sectionRefs[sub.id] = createRef();
            });
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScrollSpy);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollSpy);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeSection !== this.state.activeSection) {
            this.initializeSectionRefs(this.state.activeSection);
        }
        localStorage.setItem('activeSection', JSON.stringify(this.state.activeSection));
        localStorage.setItem('activeSubsection', this.state.activeSubsection);
    }

    handleScrollSpy = () => {
        const { activeSection } = this.state;
        if (activeSection) {
            let activeSubsection = null;
            activeSection.subsections.forEach((sub) => {
                const ref = this.sectionRefs[sub.id].current;
                if (ref && ref.getBoundingClientRect().top < window.innerHeight / 2) {
                    activeSubsection = sub.id;
                }
            });
            this.setState({ activeSubsection });
        }
    };

    handleScroll = (id) => {
        if (this.sectionRefs[id] && this.sectionRefs[id].current) {
            const offset = 100; // Adjust this value as needed
            const elementPosition = this.sectionRefs[id].current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });

            this.setState({ activeSubsection: id });
        }
    };

    setActiveSection = (section) => {
        const firstSubsection = section.subsections.length > 0 ? section.subsections[0].id : null;
        this.initializeSectionRefs(section);
        this.setState({ activeSection: section, activeSubsection: firstSubsection });
        window.scrollTo(0, 0);
    };

    render() {
        const { activeSection, activeSubsection } = this.state;

        if (!activeSection) {
            return <Typography variant="h6">No guides available</Typography>;
        }

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    {/* Navigation Bar */}
                    <Box
                        component="nav"
                        sx={{
                            width: '100%',
                            padding: '1rem',
                            bgcolor: 'primary.main',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            position: 'fixed',
                            top: 0,
                            zIndex: 1000,
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/logo.png" alt="logo" style={{ width: '28px', marginRight: '8px' }} />
                            <Typography variant="h5" sx={{ color: 'white' }}>Brand Bounce Docs</Typography>
                        </Box>
                        <Link
                            href="/dashboard"
                            underline="hover"
                            sx={{ color: 'white', fontSize: '1rem' }}
                        >
                            Back to App
                        </Link>
                    </Box>

                    <Box sx={{ display: 'flex', flexGrow: 1, marginTop: '64px' }}>
                        <Box
                            sx={{
                                width: '250px',
                                borderRight: 1,
                                borderColor: 'divider',
                                padding: 2,
                                bgcolor: '#f5f5f5',
                                color: 'black',
                                position: 'sticky',
                                top: '64px',
                                height: 'calc(100vh - 64px)',
                            }}
                        >
                            {guides.map((section) => (
                                <Accordion
                                    key={section.section}
                                    expanded={activeSection.section === section.section}
                                    onChange={() => this.setActiveSection(section)}
                                    disableGutters
                                    sx={{
                                        boxShadow: 'none',
                                        '&:before': { display: 'none' },
                                        marginBottom: 2,
                                        bgcolor: '#f5f5f5'
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
                                        sx={{
                                            padding: 0,
                                            minHeight: 'auto',
                                            '& .MuiAccordionSummary-content': { margin: 0 }
                                        }}
                                    >
                                        <Typography sx={{
                                            fontWeight: activeSection.section === section.section ? 'bold' : 'normal',
                                            color: 'black'
                                        }}>
                                            {section.section}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: 0 }}>
                                        <List>
                                            {section.subsections.map((sub) => (
                                                <ListItem
                                                    key={sub.id}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        color: activeSubsection === sub.id ? 'primary.light' : 'black',
                                                        '&:hover': { color: 'primary.dark'},
                                                        padding: 0,
                                                        mb: 2,
                                                    }}
                                                    onClick={() => this.handleScroll(sub.id)}
                                                >
                                                    {sub.title}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>

                        {/* Main Content Area */}
                        <Box sx={{ flex: 1, padding: 3, overflowY: 'auto', bgcolor: 'background.paper' }}>
                            {activeSection.subsections.map((sub) => (
                                <Box
                                    key={sub.id}
                                    ref={this.sectionRefs[sub.id]}
                                    id={sub.id}
                                    sx={{ marginBottom: 6 }} // Add padding between subsections
                                >
                                    <Typography variant="h4" sx={{ marginBottom: 2, color: 'primary.main' }}>
                                        {sub.title}
                                    </Typography>
                                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                        {sub.description}
                                    </Typography>
                                    {sub.steps &&
                                        <>
                                            <Typography variant="h5"
                                                        sx={{ marginBottom: 1, color: 'primary.dark' }}>Steps</Typography>
                                            <ol>
                                                {sub.steps.map((step, index) => (
                                                    <li key={index}>
                                                        <Typography variant="body1" sx={{ marginBottom: 1 }}>
                                                            <strong>{step.title}:</strong> {step.content}
                                                        </Typography>
                                                        {step.images && step.images.map((image, imgIndex) => (
                                                            <img
                                                                key={imgIndex}
                                                                src={image}
                                                                alt={`${imgIndex + 1}`}
                                                                style={{
                                                                    width: '100%',
                                                                    marginBottom: '1rem',
                                                                    borderRadius: '5px',
                                                                }}
                                                            />
                                                        ))}
                                                    </li>
                                                ))}
                                            </ol>
                                        </>
                                    }
                                    {sub.tips && (
                                        <>
                                            <Typography variant="h5" sx={{
                                                marginTop: 2,
                                                marginBottom: 1,
                                                color: 'primary.dark'
                                            }}>Tips</Typography>
                                            <ul>
                                                {sub.tips.map((tip, index) => (
                                                    <li key={index}>
                                                        <Typography variant="body1"
                                                                    sx={{ marginBottom: 1 }}>{tip}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                    {sub.faqs && (
                                        <>
                                            <Typography variant="h5" sx={{
                                                marginTop: 2,
                                                marginBottom: 1,
                                                color: 'primary.dark'
                                            }}>FAQs</Typography>
                                            <ul>
                                                {sub.faqs.map((faq, index) => (
                                                    <li key={index} style={{ marginBottom: '1rem' }}>
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                            {faq.question}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {faq.answer}
                                                        </Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}

                                    {sub.appLinks && (
                                        <>
                                            <Typography variant="h5"
                                                        sx={{ marginTop: 2, marginBottom: 1, color: 'primary.dark' }}>App
                                                Links</Typography>
                                            <ul>
                                                {sub.appLinks.map((link, index) => (
                                                    <li key={index}>
                                                        <Link href={link.url} underline="hover"
                                                              sx={{ color: 'primary.main' }}>
                                                            {link.label}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}

                                    {sub.images && sub.images.length > 0 && (
                                        <>
                                            <Typography variant="h5" sx={{
                                                marginTop: 2,
                                                marginBottom: 1,
                                                color: 'primary.dark'
                                            }}>Images</Typography>
                                            <Box>
                                                {sub.images.map((image, index) => (
                                                    <img
                                                        key={index}
                                                        src={image}
                                                        alt={`Screenshot ${index + 1}`}
                                                        style={{
                                                            width: '100%',
                                                            marginBottom: '1rem',
                                                            borderRadius: '5px',
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}

export default GuideComponent;