import React, { Component } from 'react';
import { Container, Box, Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Button, RadioGroup, FormControlLabel, Radio, FormHelperText, CircularProgress } from '@mui/material';
import AiService from '../../../../services/AiService';
import toneOfVoiceOptions from '../../../../services/util/toneOfVoiceOptions';
import personalityOptions from '../../../../services/util/personalityOptions';

class BrandDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingRecommendations: false,
            snackbarSeverity: '',
            snackbarMessage: '',
            snackbarOpen: false,
            errors: {},
            aiButtonDisabled: false,
            timer: 0,
        };
    }

    componentDidMount() {
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        if (this.props.inSidebar) {
            this.props.onUpdate(this.props.index, {
                ...this.props.voice,
                [name]: value,
            });
        } else {
            this.props.onUpdate({
                voice: {
                    ...this.props.voice,
                    [name]: value,
                },
            });
        }
    };

    handleAISuggestion = () => {
        this.setState({
            loadingRecommendations: true,
            aiButtonDisabled: true,
            timer: 15,
        });
        this.startTimer();

        const aiDto = {
            locations: this.props.location,
            industry: this.props.industry,
            audienceIndustry: this.props.audienceIndustry,
            ageRange: this.props.ageRange,
            toneOfVoices: toneOfVoiceOptions.map(option => option.value),
            personalities: personalityOptions.map(option => option.value),
            brandName: this.props.brandName,
            story: this.props.voice.story,
        };

        if (this.props.allVoices && this.props.allVoices.length > 0) {
            aiDto.personalityToToneOfVoice = this.props.allVoices.reduce((acc, voice) => {
                if (voice.personality) {
                    acc[voice.personality] = voice.toneOfVoice;
                }
                return acc;
            }, {});
        }

        AiService.getVoice(aiDto).then(response => {
            this.setState({
                loadingRecommendations: false,
            });

            if (this.props.inSidebar) {
                this.props.onUpdate(this.props.index, {
                    ...this.props.voice,
                    personality: response.personality,
                    toneOfVoice: response.voice,
                    story: response.description,
                });
            } else {
                this.props.onUpdate({
                    voice: {
                        ...this.props.voice,
                        personality: response.personality,
                        toneOfVoice: response.voice,
                        story: response.description,
                    },
                });
            }
        }).catch(error => {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingRecommendations: false,
            });

            this.startTimer();
        });
    };

    startTimer = () => {
        const countdown = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.timer <= 1) {
                    clearInterval(countdown);
                    return { aiButtonDisabled: false, timer: 0 };
                }
                return { timer: prevState.timer - 1 };
            });
        }, 1000);
    };

    render() {
        const { errors } = this.props;
        const { aiButtonDisabled, timer, loadingRecommendations } = this.state;

        return (
            <Container
                id="create-brand"
                sx={{
                    pt: { xs: 4, sm: 12 },
                    pb: { xs: 8, sm: 16 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 1, sm: 1 },
                }}
            >
                <Box
                    sx={{
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >

                    <Typography component="h2" variant="h4" color="text.primary">
                        {this.props.inSidebar ? "Brand Voice " + (this.props.index + 1) : "Describe Your Brand Identity"}
                    </Typography>

                    <br />
                </Box>
                <Box component="form" noValidate sx={{ width: '100%' }} className='brandDetailsContainer'>
                    <Grid container spacing={3} alignItems="center">

                        {/* Brand Personality */}
                        <Grid item xs={12} sm={this.props.inSidebar ? 12 : 6}>
                            <FormControl fullWidth error={this.props.inSidebar ? !!errors[`voice_personality_${this.props.index}`] : !!errors.personality}>                                <InputLabel>Brand Personality</InputLabel>
                                <Select
                                    name="personality"
                                    value={this.props.voice.personality}
                                    onChange={this.handleChange}
                                    label="Brand Personality"
                                >
                                    {personalityOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                                {!this.props.inSidebar && errors.personality && <FormHelperText>{errors.personality}</FormHelperText>}
                                {this.props.inSidebar && errors[`voice_personality_${this.props.index}`] && <FormHelperText>{errors[`voice_personality_${this.props.index}`]}</FormHelperText>}
                                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                    Choose the personality traits that align best with your brand's identity.
                                </Typography>
                            </FormControl>
                        </Grid>

                        {/* Tone of Voice */}
                        <Grid item xs={12}>
                            <Typography variant="h6" color="text.primary">
                                Tone of Voice
                            </Typography>
                            <FormControl
                                component="fieldset"
                                error={this.props.inSidebar ? !!errors[`voice_toneOfVoice_${this.props.index}`] : !!errors.toneOfVoice}
                            >
                                <RadioGroup
                                    name="toneOfVoice"
                                    value={this.props.voice.toneOfVoice}
                                    onChange={this.handleChange}
                                    row
                                >
                                    {toneOfVoiceOptions.map(option => (
                                        <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                                    ))}
                                </RadioGroup>
                                {!this.props.inSidebar && errors.toneOfVoice && <FormHelperText>{errors.toneOfVoice}</FormHelperText>}
                                {this.props.inSidebar && errors[`voice_toneOfVoice_${this.props.index}`] && <FormHelperText>{errors[`voice_toneOfVoice_${this.props.index}`]}</FormHelperText>}
                            </FormControl>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                Select the tone of voice that reflects your brand's communication style.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Briefly describe your brand, and press the button below to let AI craft a personalized suggestion."
                                name="story"
                                value={this.props.voice.story}
                                onChange={this.handleChange}
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 800 }}
                                error={this.props.inSidebar ? !!errors[`voice_story_${this.props.index}`] : !!errors.story}
                                helperText={this.props.inSidebar ? errors[`voice_story_${this.props.index}`] : errors.story}
                            />
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                Example: Brand Bounce empowers individuals to master their marketing with our intuitive SaaS platform. We provide tools and education to build effective marketing strategies, reducing reliance on expensive agencies.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={this.props.inSidebar ? 12 : 6}>
                            <Button variant="outlined" onClick={this.handleAISuggestion} disabled={aiButtonDisabled}>
                                {aiButtonDisabled ? `Need inspiration? Let AI suggest! ${timer}s` : 'Need inspiration? Let AI suggest!'}
                                {loadingRecommendations && <CircularProgress size={24} sx={{ ml: 2 }} />}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    }
}

export default BrandDetails;