import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TemplateService from '../../../services/TemplateService';
import { withRouter } from '../../../services/security/withRouter';
import {renderIcon} from "../../../services/util/iconHelper";

class ChooseContentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            contentTypes: [],
            loadingContentTypes: true,
            platforms: [],
            loadingPlatforms: false,
            templates: [],
            loadingTemplates: false
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            if (this.props.contentType) {
                this.setState({ step: 1, loadingPlatforms: true });
                this.fetchPlatforms(this.props.contentType.id);
            } else {
                this.setState({ step: 0, loadingContentTypes: true });
                this.fetchContentTypes();
            }
        }
    }

    fetchContentTypes = async () => {
        try {
            const contentTypes = await TemplateService.getContentTypes();
            this.setState({ contentTypes, loadingContentTypes: false }, () => {
                if (contentTypes.length === 1) {
                    this.handleContentTypeClick(contentTypes[0]);
                }
            });
        } catch (error) {
            console.error('Failed to fetch content types:', error);
            this.setState({ loadingContentTypes: false });
        }
    };

    fetchPlatforms = async (contentTypeId) => {
        this.setState({ loadingPlatforms: true });
        try {
            const platforms = await TemplateService.getPlatformsForContentType(contentTypeId);
            this.setState({ platforms, loadingPlatforms: false }, () => {
                if (platforms.length === 1) {
                    this.handlePlatformClick(platforms[0]);
                }
            });
        } catch (error) {
            console.error('Failed to fetch platforms:', error);
            this.setState({ loadingPlatforms: false });
        }
    };

    fetchTemplates = async (platformId) => {
        this.setState({ loadingTemplates: true });
        try {
            const templates = await TemplateService.getTemplatesForPlatform(platformId);
            this.setState({ templates, loadingTemplates: false }, () => {
                if (templates.length === 1) {
                    this.handleTemplateClick(templates[0]);
                }
            });
        } catch (error) {
            console.error('Failed to fetch templates:', error);
            this.setState({ loadingTemplates: false });
        }
    };

    handleNext = () => {
        this.setState((prevState) => ({
            step: prevState.step + 1
        }));
    };

    handleBack = () => {
        this.setState((prevState) => ({
            step: prevState.step - 1
        }));
    };

    handleContentTypeClick = (contentType) => {
        this.fetchPlatforms(contentType.id);
        this.handleNext();
    };

    handlePlatformClick = (platform) => {
        this.fetchTemplates(platform.id);
        this.handleNext();
    };

    handleTemplateClick = (template) => {
        const path = `/template/${template.id}`;
        const url = this.props.campaignId ? `${path}?campaignId=${this.props.campaignId}` : path;
        this.props.navigate(url);
        this.props.handleClose();
    };

    renderCard = (item, handleClick) => (
        <Grid item xs={12} sm={6} md={3} key={item.id}>
            <Button
                variant="contained"
                onClick={() => handleClick(item)}
                sx={{
                    padding: '16px',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'primary.main',
                    width: '100%',
                    height: '100%', // Ensure the button takes the full height of the grid item
                    aspectRatio: '1', // Make the button square
                    gap: 2,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'background.paper',
                    '&:hover': {
                        backgroundColor: 'primary.light',
                        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
                        transform: 'scale(1.05)',
                        color: 'white',
                        '& .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    }
                }}
            >
                <span>{renderIcon(item.name, {fontSize: 40})}</span>
                <Typography variant="subtitle1" fontWeight="bold">
                    {item.name}
                </Typography>
            </Button>
        </Grid>
    );

    renderStepContent = (step) => {
        const { contentTypes, loadingContentTypes, platforms, loadingPlatforms, templates, loadingTemplates } = this.state;

        const stepConfig = [
            { data: contentTypes, loading: loadingContentTypes, title: 'Select a Content Type', onClick: this.handleContentTypeClick },
            { data: platforms, loading: loadingPlatforms, title: 'Select a Platform', onClick: this.handlePlatformClick },
            { data: templates, loading: loadingTemplates, title: 'Select a Template', onClick: this.handleTemplateClick }
        ][step];

        return (
            <Fade in={true}>
                <Box>
                    <DialogTitle textAlign="center">{stepConfig.title}</DialogTitle>
                    <DialogContent>
                        {stepConfig.loading ? (
                            <Skeleton variant="rectangular" height={150} sx={{ borderRadius: '12px', marginBottom: '16px' }} />
                        ) : (
                            <Grid container spacing={3} sx={{padding: '10px'}}>
                                {stepConfig.data.map((item) =>
                                    this.renderCard(item, stepConfig.onClick)
                                )}
                            </Grid>
                        )}
                    </DialogContent>
                </Box>
            </Fade>
        );
    };

    render() {
        const { open } = this.props;
        const { step } = this.state;

        return (
            <Dialog open={open} onClose={this.props.handleClose} maxWidth="md" fullWidth>
                <Stepper activeStep={step} alternativeLabel>
                    {['Content Type', 'Platform', 'Template'].map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {this.renderStepContent(step)}
                <DialogActions>
                    {step > 0 && (
                        <Button onClick={this.handleBack} variant="outlined">
                            Previous
                        </Button>
                    )}
                    <Button onClick={this.props.handleClose} variant="contained" color="danger">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withRouter(ChooseContentModal);