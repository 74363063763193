const guides = [
  {
    section: "Account",
    subsections: [
      {
        id: "1",
        title: "Create an Account",
        description: "Learn how to create a new account.",
        steps: [
          { title: "Go to the Register Page", content: "Navigate to the register page from the homepage."},
          { title: "Fill in the Required Information", content: "Enter your name, email, and password in the sign-up form." },
          { title: "Verify the Terms and Conditions", content: "Verify the terms and conditions by pressing on the 'Terms and Conditions' text." },
          { title: "Optional* Check the 'Keep me updated' checkbox", content: "Check the 'Keep me updated' checkbox to receive exclusive offers and exciting news." },
          { title: "Submit the Form", content: "Click the 'Sign Up' button to create your account." },
          { title: "Choose the subscription", content: "Choose the subscription you want to use and press on 'Subscribe Now'. From this page you are able to toggle the yearly prices for a discount." },
          { title: "Fill in payment information", content: "In this step you are redirected to Stripe, one of the most secure payment processors available. Fill in the information requested." },
          { title: "Finish", content: "Congratulations, you have finished the registration process. You are now being redirected to create your first Brand." },
        ],
        tips: ["Use a strong password to secure your account."],
        faqs: [
          { question: "What if I forget my password?", answer: "You can reset your password by clicking on the 'Forgot Password' link on the sign-in page." },
        ],
      },
      {
        id: "2",
        title: "Configure Your First Brand",
        description: "Learn how to configure your first brand.",
        steps: [
          { title: "Start the process", content: "After registering you are now redirected to an incremental process with the purpose of creating your first brand." },
          { title: "Intro", content: "Press on the 'Continue' button."},
          { title: "Create Your Brand", content: "Input your business name, brand name, and the industry in which your brand operates and then press on the 'Continue' button." },
          { title: "Target Audience", content: "Input the main locations and the industry your customers are in. For Interests and Age Range you can press on the 'Need ideas? Let AI help!' button to receive recommendations. Press the 'Continue' button." },
          { title: "Brand Voice", content: "Choose a brand personality, a tone of voice and describe your brand. You can let AI choose for you the most appropriate values for the industry you selected by pressing on the 'Need inspiration? Let AI suggest!' button. Press on 'Complete Setup'" },
        ],
        tips: ["Ensure your brand information is accurate for better results."],
        faqs: [
          { question: "Can I edit my brand information later?", answer: "Yes, you can edit your brand information anytime from the brand settings page." },
        ],
      },
      {
        id: "3",
        title: "Edit Your Account Information",
        description: "Learn how to edit your account information.",
        steps: [
          { title: "Open the User Context Menu", content: "Open the user context menu by pressing on your avatar in the navigation bar." },
          { title: "Select account", content: "Select 'Account' from the context menu." },
          { title: "Edit the information", content: "Edit the information by editing the input fields displayed in the 'Edit Info' tab." },
          { title: "Save", content: "Save the changes by pressing on the 'Save' button." },
        ],
        tips: ["Keep your account information up-to-date."],
        faqs: [
          { question: "How do I change my email address?", answer: "You can change your email address in the account settings page." },
        ],
      },
      {
        id: "4",
        title: "Change Your Password",
        description: "Learn how to change your account password.",
        steps: [
          { title: "Open the User Context Menu", content: "Open the user context menu by pressing on your avatar in the navigation bar." },
          { title: "Select account", content: "Select 'Account' from the context menu." },
          { title: "Edit the password", content: "Edit the password by filling the input fields displayed in the 'Change Password' tab." },
          { title: "Save", content: "Save the changes by pressing on the 'Save' button." },
        ],
        tips: ["Use a strong and unique password for better security."],
        faqs: [
          { question: "What if I forget my current password?", answer: "You can reset your password by clicking on the 'Forgot Password' link on the sign-in page." },
        ],
      },
      {
        id: "5",
        title: "See Subscription Information",
        description: "Learn how to view your subscription information.",
        steps: [
          { title: "Open the User Context Menu", content: "Open the user context menu by pressing on your avatar in the navigation bar." },
          { title: "Select account", content: "Select 'Account' from the context menu." },
          { title: "Select 'Plan'", content: "Select the 'Plan' tab from the account page." },
          { title: "View", content: "In the 'Plan' tab you can see default information about your subscription and the current usage." },
        ],
        tips: ["Review your subscription details regularly."],
        faqs: [
          { question: "How do I upgrade my subscription?", answer: "You can upgrade your subscription from the 'Plan' tab in the accounts page." },
        ],
      },
      {
        id: "6",
        title: "Edit Subscription Information",
        description: "Learn how to edit your subscription information.",
        steps: [
          { title: "Open the User Context Menu", content: "Open the user context menu by pressing on your avatar in the navigation bar." },
          { title: "Select account", content: "Select 'Account' from the context menu." },
          { title: "Select 'Plan'", content: "Select the 'Plan' tab from the account page." },
          { title: "Click 'Manage Your Subscription'", content: "Click on the 'Manage Your Subscription' button." },
          { title: "Edit subscription information", content: "You are now redirected to Stripe. Here, you can edit your subscription information." },
        ],
        tips: ["Ensure your billing information is accurate to avoid payment issues."],
        faqs: [
          { question: "Can I change my payment method?", answer: "Yes, you can change your payment method here." },
        ],
      },
      {
        id: "7",
        title: "Choose Another Subscription",
        description: "Learn how to modify your subscription plan.",
        steps: [
          { title: "Open the User Context Menu", content: "Open the user context menu by pressing on your avatar in the navigation bar." },
          { title: "Select account", content: "Select 'Account' from the context menu." },
          { title: "Select 'Plan'", content: "Select the 'Plan' tab from the account page." },
          { title: "Click 'Change Plan'", content: "Click on the 'Change Plan' button." },
          { title: "Edit subscription information'", content: "You are now redirected to the 'Change Subscription' page. In here you can choose another subscription." },
        ],
        tips: ["Choose a plan that best fits your needs."],
        faqs: [
          { question: "Can I downgrade my subscription?", answer: "Yes, you can downgrade your subscription here if your current usage allows it." },
        ],
      },
      {
        id: "8",
        title: "Cancel Your Subscription",
        description: "Learn how to cancel your subscription.",
        steps: [
          { title: "Open the User Context Menu", content: "Open the user context menu by pressing on your avatar in the navigation bar." },
          { title: "Select account", content: "Select 'Account' from the context menu." },
          { title: "Select 'Plan'", content: "Select the 'Plan' tab from the account page." },
          { title: "Click 'Manage Your Subscription'", content: "Click on the 'Manage Your Subscription' button." },
          { title: "Edit subscription information'", content: "You are now redirected to Stripe. In here you can cancel your subscription." },
        ],
        tips: ["Review the cancellation policy before proceeding."],
        faqs: [
          { question: "Will I get a refund if I cancel my subscription?", answer: "Refund policies vary, please review our cancellation policy." },
        ],
      },
      {
        id: "9",
        title: "View Your Invoices",
        description: "Learn how to view your invoices.",
        steps: [
          { title: "Open the User Context Menu", content: "Open the user context menu by pressing on your avatar in the navigation bar." },
          { title: "Select account", content: "Select 'Account' from the context menu." },
          { title: "Select 'Invoices'", content: "Select the 'Invoices' tab from the account page." },
          { title: "View'", content: "Your invoices are displayed in the 'Invoices' tab. To save an invoice press on the download button located right to the invoice." },
        ],
        tips: ["Keep a record of your invoices for future reference."],
        faqs: [
          { question: "How can I get a copy of my invoice?", answer: "You can download a copy of your invoice from here." },
        ],
      },
    ],
  },
  {
    section: "Dashboard",
    subsections: [
      {
        id: "10",
        title: "Favorite Content",
        description: "Learn how to add content to your favorites for quick access.",
        steps: [
          { title: "Navigate to any template", content: "Press on the 'Create Content' button in the dashboard or in the navbar. Choose the content type, then choose the platform, and finally choose the template." },
          { title: "Click on the Star Icon", content: "Click the star icon next to the content you want to add to favorites." },
        ],
        tips: ["Use the favorites feature to quickly access frequently used content."],
      },
      {
        id: "11",
        title: "Recently Created Content",
        description: "View your recently created content.",
        faqs: [
          { question: "How do I find my recent content?", answer: "Your recent content is listed under the 'Recently Created Content' section in the dashboard." },
        ],
      },
      {
        id: "12",
        title: "Recently Created Campaigns",
        description: "View and manage your recently created campaigns.",
        faqs: [
          { question: "How do I find my recently created campaigns?", answer: "Your recently created campaigns are listed under the 'Recently Created Campaigns' section in the dashboard." },
        ],
      },
    ],
  },
  {
    section: "Content",
    subsections: [
      {
        id: "13",
        title: "Navigate to the Template",
        description: "Learn how to navigate to the template.",
        steps: [
          { title: "Navigate to any template", content: "Press on the 'Create Content' button in the dashboard or in the navbar." },
          { title: "Choose the content type", content: "Choose the content type by pressing on one of the cards." },
          { title: "Choose the platform", content: "After choosing the content type you will be redirected to the platforms. Select one of the cards." },
          { title: "Choose the template", content: "After choosing the platform you will be redirected to the templates. Select one of the cards." },
        ],
      },
      {
        id: "14",
        title: "Generate Content",
        description: "Learn how to generate content.",
        steps: [
          { title: "Select the template", content: "Once you have selected a template you will be redirected to the creation screen." },
          { title: "Customize Content", content: "Using the input boxes on the left side you can customize the content. All of those inputs are optional, except 'Audience' and 'Voice'. Those are defined in your Brand settings and autocompleted."},
          { title: "Choose a language", content: "In the inputs section you have the option to choose a language. The default is English." },
          { title: "Press the generate button", content: "After adding all the information needed, press on the 'Generate' button above the text field." },

        ],
        tips: ["Use the content generator to save time.", "Use the latest trends checkbox to generate insightful content.", "Use the chatbot to improve your content."],
      },
      {
        id: "15",
        title: "Save Content",
        description: "Learn how to save your content.",
        steps: [
          { title: "Save Content", content: "Click the 'Save' button above the text field to save your content."},
          { title: "Confirm Save", content: "Ensure the content is saved by checking you are redirected to the content screen." },
        ],
        tips: ["Save your work frequently to avoid losing changes."],
      },
      {
        id: "16",
        title: "Edit Content",
        description: "Learn how to edit your content.",
        steps: [
          { title: "Edit the inputs", content: "Edit the inputs in the 'Inputs' tab, below the text editor." },
          { title: "Re-Generate ", content: "Re-Generate the content by pressing on the 'Re-Generate' button above the text editor." },
          { title: "Directly edit the content", content: "Directly edit the content by writing in the text editor." },
          { title: "Rephrase", content: "Rephrase the content by pressing on the 'Rephrase' button above the text editor." },
          { title: "Save the edit", content: "Save the content by pressing on the 'Save' button above the text editor." },
        ],
        tips: ["Review your content thoroughly before saving changes."],
      },
      {
        id: "17",
        title: "Assign Content",
        description: "Learn how to assign content.",
        steps: [
          { title: "Open the  'Select Assignee' select", content: "Click on the 'Select Assignee' select above the text editor." },
          { title: "Select Assignee", content: "Choose the team member from the dropdown list" },
        ],
        tips: ["Assign content to team members based on their expertise."],
      },
      {
        id: "18",
        title: "Change the Status of the Content",
        description: "Learn how to change the status of your content.",
        steps: [
          { title: "Change to 'Scheduled'", content: "Press on the 'Schedule' button above the text editor." },
          { title: "Change to 'Posted'", content: "Press on the 'Post' button above the text editor." },
          { title: "Change to 'Archived'", content: "Press on the 'Archive' button above the text editor." },
        ],
        tips: ["Use status updates to track the progress of your content."],
      },
      {
        id: "19",
        title: "Leave Comments Under the Content",
        description: "Learn how to leave comments under the content.",
        steps: [
          { title: "Open the Comments Tab", content: "Click on the 'Comments' tab below the text editor." },
          { title: "Leave Comments", content: "Use the comment section below the text editor to leave feedback or notes under the content." },
          { title: "Review Comments", content: "Review comments left by other team members for collaboration."},
        ],
        tips: ["Use comments for collaborative feedback and suggestions."],
      },
    ],
  },
  {
    section: "Brand",
    subsections: [
      {
        id: "20",
        title: "Create a Brand",
        description: "Learn how to create a new brand.",
        steps: [
          { title: "Go to the Brand Page", content: "Navigate to the brand page from the side menu." },
          { title: "Click on 'View All Brands'", content: "Click the 'View All Brands' button to see the brand list." },
          { title: "Click on 'Create a Brand", content: "Click the 'Create a Brand' button to start the brand creation." },
            { title: "Intro", content: "Press on the 'Continue' button." },
            { title: "Create Your Brand", content: "Input your business name, brand name and the industry in which your brand operates in and then press on the 'Continue' button." },
            { title: "Target Audience", content: "Input the main locations and the industry your customers are in. For Interests and Age Range you can press on the 'Need ideas? Let AI help!' button to receive recommendations. Press on the continue button." },
            { title: "Brand Voice", content: "Choose a brand personality, a tone of voice and describe your brand. You can let AI choose for you the most appropriate values for the industry you selected by pressing on the 'Need inspiration? Let AI suggest!' button. Press on 'Complete Setup'" },
        ],
        tips: ["Ensure your brand information is accurate for better results."],
        faqs: [
          { question: "Can I create multiple brands?", answer: "Yes, you can create multiple brands from the brands page, depending on your subscription." },
        ]
      },
      {
        id: "21",
        title: "Edit a Brand",
        description: "Learn how to edit an existing brand.",
        steps: [
           { title: "Go to the Brand Page", content: "Navigate to the brand page from the side menu." },
          { title: "Click on 'Modify Brand Details'", content: "Click on the 'Modify Brand Details' button to start the process." },
          { title: "Edit Brand Information", content: "Update brand name and industry as needed." },
        ],
      },
      {
        id: "22",
        title: "Add Target Audiences and Voices to the Brand",
        description: "Learn how to add target audiences and voices to your brand.",
        steps: [
            { title: "Go to the Brand Page", content: "Navigate to the brand page from the side menu." },
          { title: "Click on 'Create New Audience'", content: "Click on the 'Create New Audience' button to open the creation sidebar." },
          { title: "Input the information", content: "Enter the target audience details and then click on 'Add New Brand Voice'." },
            { title: "Add Brand Voice", content: "Enter the brand voice details and then click on 'Save Changes'." },
        ],
        tips: ["Define clear target audiences and voices for better brand alignment.", "Use AI suggestions for target audience and brand voice inspiration."],
        faqs: [
          { question: "Can I add multiple target audiences?", answer: "Yes, you can add up to 3 target audiences to a brand." },
          { question: "Can I add multiple voices?", answer: "Yes, you can add up to 3 voices to a target audience." },
        ],
      },
      {
        id: "23",
        title: "Select a Brand",
        description: "Learn how to select a brand.",
        steps: [
          { title: "Click on the select in the navbar", content: "Click on the select in the navbar to change your active brand." },
          { title: "Select the Brand", content: "Click on the brand you want to select." },
        ],
        tips: ["Select the appropriate brand for the task at hand.", "The selected brand will be used for everything you do in the platform."],
        faqs: [
          { question: "Can I switch between brands?", answer: "Yes, you can switch between brands from the navigation bar select or from the brands page." },
        ],
      },
      {
        id: "24",
        title: "View All Brands",
        description: "Learn how to view all your brands.",
        steps: [
            { title: "Go to the Brand Page", content: "Navigate to the brand page from the side menu. Your selected brand will open." },
            { title: "Click on 'View All Brands'", content: "Click the 'View All Brands' button to see the brand list." },
        ],
        tips: ["Regularly review your brands to ensure they are up-to-date."],
        faqs: [
          { question: "How can I see all my brands?", answer: "All your brands are listed on the brands page." },
        ],
      },
    ],
  },
    {
        section: "Campaigns",
        subsections: [
            {
                id: "25",
                title: "View All Campaigns",
                description: "Learn how to view all your campaigns.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "View Campaign List", content: "All your campaigns are listed on this page." },
                ],
                tips: ["Regularly review your campaigns to update their progress."],
                faqs: [
                    { question: "How can I see all my campaigns?", answer: "All your campaigns are listed on the campaigns page." },
                ],
            },
            {
                id: "26",
                title: "Create a Marketing Campaign",
                description: "Learn how to create a new marketing campaign.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Click on 'Create'", content: "Click the 'Create' button to start the campaign creation process." },
                    { title: "Select 'Paid Advertising Content'", content: "Select 'Paid Advertising Content' from the 'Select Content Type' select." },
                    { title: "Fill in Campaign Details", content: "Enter the campaign name, objective and other required details." },
                    { title: "Save Campaign", content: "Click the 'Save' button to create your marketing campaign." },
                ],
                tips: ["Define clear objectives for your marketing campaign."],
                faqs: [
                    { question: "Can I create multiple marketing campaigns?", answer: "Yes, you can create multiple marketing campaigns from the campaigns page." },
                ],
            },
            {
                id: "27",
                title: "Create an Organic Content Campaign",
                description: "Learn how to create a new organic content campaign.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Click on 'Create Campaign'", content: "Click the 'Create Campaign' button to start the campaign creation process." },
                    { title: "Select 'Social Media Content'", content: "Select 'Social Media Content' from the 'Select Content Type' select." },
                    { title: "Fill in Campaign Details", content: "Enter the campaign name, objective, and other required details." },
                    { title: "Save Campaign", content: "Click the 'Save' button to create your organic content campaign." },
                ],
                tips: ["Focus on creating engaging content for your organic content campaign."],
            },
            {
                id: "28",
                title: "Create an E-mail Campaign",
                description: "Learn how to create a new e-mail campaign.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Click on 'Create Campaign'", content: "Click the 'Create Campaign' button to start the campaign creation process." },
                    { title: "Select 'E-mail Content'", content: "Select 'E-mail Content' from the 'Select Content Type' select." },
                    { title: "Fill in Campaign Details", content: "Enter the campaign name, objective, and other required details." },
                    { title: "Save Campaign", content: "Click the 'Save' button to create your e-mail campaign." },
                ],
                tips: ["Craft compelling email content to engage your audience."],
            },
            {
                id: "29",
                title: "Generate the Posting Plan",
                description: "Learn how to generate a posting plan for your campaign.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Select a Campaign", content: "Click on the campaign for which you want to generate a posting plan." },
                    { title: "Click on 'Generate Posting Plan'", content: "Click the 'Generate Posting Plan' button to create a posting schedule." },
                    { title: "Verify that your posting plan was generated", content: "Check the 'Calendar' tab in the campaign page to see if the plan was generated." },
                ],
                tips: ["Use the posting plan to organize your posting times."],
            },
            {
                id: "30",
                title: "See the Campaign Insights",
                description: "Learn how to view insights for your campaign.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Select a Campaign", content: "Click on the campaign for which you want to view insights." },
                    { title: "Click on 'Insights'", content: "Click the 'Insights' tab to view campaign recommendations and insights." },
                ],
                tips: ["Before you start working on a campaign review the Insights that were generated for you."],
            },
            {
                id: "31",
                title: "Create Content for the Campaign",
                description: "Learn how to create content for your campaign.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Select a Campaign", content: "Click on the campaign for which you want to create content." },
                    { title: "Click on 'Create Campaign Content'", content: "Click the 'Create Campaign Content' button to start creating content for the campaign." },
                    { title: "Right-click a posting plan item", content: "Right-click a posting plan item to create content for that specific date, template and platform." },
                    { title: "Generate and save the content", content: "Generate the content and save it." },
                    { title: "Navigate from the content page to the campaign page", content: "Navigate from the content page to the campaign page by pressing on the title of the campaign located at the top of the content page." },
                    { title: "Navigate from the campaign page to the content page", content: "Navigate from the campaign page to the content page by right clicking on the calendar, on an already generated content piece." },
                ],
                tips: ["Create content that aligns with your campaign objectives."],
            },
            {
                id: "32",
                title: "Create Content Based on the Posting Plan",
                description: "Learn how to create content based on the posting plan.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Select a Campaign", content: "Click on the campaign for which you want to create content." },
                    { title: "View Calendar", content: "Press on the 'Calendar' Tab." },
                    { title: "Right-click a posting plan item", content: "Right-click a posting plan item to create content for that specific date, template and platform." },
                ],
                tips: ["Follow the posting plan to maintain a consistent content schedule, recommended by the AI."],
            },
            {
                id: "33",
                title: "Move Content in the Campaign Calendar",
                description: "Learn how to move content in the campaign calendar.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Select a Campaign", content: "Click on the campaign for which you want to move content." },
                    { title: "Open the Calendar", content: "Press on the 'Calendar' Tab." },
                    { title: "Drag and Drop Content", content: "Drag and drop content to the desired date in the calendar." },
                ],
                tips: ["Use the campaign calendar to organize your content schedule."],
            },
            {
                id: "34",
                title: "Edit Campaign Overview",
                description: "Learn how to edit the overview of your campaign.",
                steps: [
                    { title: "Go to the Campaigns Page", content: "Navigate to the campaigns page from the side menu." },
                    { title: "Select a Campaign", content: "Click on the campaign you want to edit." },
                    { title: "Click on 'Edit'", content: "Click the 'Edit' tab to update the campaign details." },
                    { title: "Update Campaign Details", content: "Edit the campaign name, objective, and other details as needed." },
                    { title: "Save Changes", content: "Click the 'Update Campaign' button to save the updated campaign overview." },
                ],
                tips: ["Ensure your campaign overview is up-to-date for better management."],
            },
        ],
    },
    {
        section: "Calendar",
        subsections: [
            {
                id: "35",
                title: "View Content",
                description: "Learn how to view content in the calendar.",
                steps: [
                    { title: "Go to the Calendar Planner", content: "Navigate to the Calendar Planner from the side menu." },
                    { title: "View Content", content: "All your scheduled content is displayed on the calendar." },
                ],
                tips: ["Regularly check the calendar to stay updated on your content schedule."],
                faqs: [
                    { question: "How can I see all my scheduled content?", answer: "All your scheduled content is displayed on the calendar page." },
                ],
            },
            {
                id: "36",
                title: "Move Content",
                description: "Learn how to move content in the calendar.",
                steps: [
                    { title: "Go to the Calendar Planner", content: "Navigate to the Calendar Planner from the side menu." },
                    { title: "Drag and Drop Content", content: "Drag and drop content to the desired date in the calendar." },
                ],
                tips: ["Use the drag-and-drop feature to easily reschedule your content."],
            },
            {
                id: "37",
                title: "Change Calendar View",
                description: "Learn how to change the calendar view.",
                steps: [
                    { title: "Go to the Calendar Planner", content: "Navigate to the Calendar Planner from the side menu." },
                    { title: "Select View Option", content: "Choose between daily, weekly, or monthly view options." },
                ],
                tips: ["Switch between different views to get a better overview of your content schedule."],
            },
            {
                id: "38",
                title: "Navigate to Content",
                description: "Learn how to navigate to content from the calendar.",
                steps: [
                    { title: "Go to the Calendar Page", content: "Navigate to the calendar page from the side menu." },
                    { title: "Right-click on content", content: "Right-click on any content item in the calendar to view its details." },
                ],
                tips: ["Use the calendar to quickly access and manage your content."],
            },
        ],
    },
    {
        section: "Images",
        subsections: [
            {
                id: "39",
                title: "View Generated Image List",
                description: "Learn how to view the list of generated images.",
                steps: [
                    { title: "Go to the Images Page", content: "Navigate to the images page from the side menu." },
                    { title: "View Image List", content: "All your generated images are listed on this page." },
                ],
                faqs: [
                    { question: "How can I see all my generated images?", answer: "All your generated images are listed on the images page." },
                ],
            },
            {
                id: "40",
                title: "Generate Image",
                description: "Learn how to generate a new image.",
                steps: [
                    { title: "Go to the Images Page", content: "Navigate to the images page from the side menu." },
                    { title: "Click on 'Add New Image'", content: "Click the 'Add New Image' button to start the image generation process." },
                    { title: "Input Image Details", content: "Enter the prompt that will be used for the image generation." },
                    { title: "Click 'Generate Image'", content: "Click the 'Generate Image' button to create the image." },
                ],
                tips: ["Provide detailed information for better image generation results."],
                faqs: [
                    { question: "Can I generate multiple images?", answer: "Yes, you can generate multiple images from the images page." },
                ],
            },
            {
                id: "41",
                title: "Re-generate Image",
                description: "Learn how to re-generate an existing image.",
                steps: [
                    { title: "Go to the Images Page", content: "Navigate to the images page from the side menu." },
                    { title: "Select Image", content: "Select the image you want to re-generate from the list." },
                    { title: "Update Image Details", content: "Update the details as needed for the image re-generation." },
                    { title: "Click 'Update Image'", content: "Click the 'Update Image' button to create the updated image." },
                ],
                tips: ["Update the image details to improve the re-generated image."],
                faqs: [
                    { question: "Can I re-generate an image multiple times?", answer: "Yes, you can re-generate an image as many times as needed, but every generation is added to the subscription usage." },
                ],
            },
        ],
    },
    {
        section: "Journeys",
        subsections: [
            {
                id: "42",
                title: "View Journeys",
                description: "Learn how to view all your journeys.",
                steps: [
                    { title: "Go to the Journeys Page", content: "Navigate to the journeys page from the side menu." },
                    { title: "View Journeys", content: "All your journeys are listed on this page." },
                ],
                tips: ["Regularly review your journeys to track their progress."],
                faqs: [
                    { question: "How can I see all my journeys?", answer: "All your journeys are listed on the journeys page." },
                ],
            },
            {
                id: "43",
                title: "Create a Journey",
                description: "Learn how to create a new journey.",
                steps: [
                    { title: "Go to the Journeys Page", content: "Navigate to the journeys page from the side menu." },
                    { title: "Click on 'Create'", content: "Click the 'Create' button to start the journey creation process." },
                    { title: "Select the Journey Goal", content: "Select the journey goal from the available options: Generate sales, Generate leads, Convert leads, Increase brand awareness, Boost traffic, Improve customer retention, Generate buzz and virality. Press on the continue button." },
                    { title: "Select the Platforms", content: "Select the platforms from the available options." },
                    { title: "Check the Boxes", content: "Check the checkboxes you wish to use in your journey and press on the 'Continue' button." },
                    { title: "Press 'Start Journey'", content: "Press on the 'Start Journey' button." },
                ],
                tips: ["Provide detailed information for better journey creation results."],
                faqs: [
                    { question: "Can I create multiple journeys?", answer: "Yes, you can create multiple journeys from the journeys page." },
                ],
            },
            {
                id: "44",
                title: "Fulfill an Action Item",
                description: "Learn how to fulfill an action item in a journey.",
                steps: [
                    { title: "Go to the Journeys Page", content: "Navigate to the journeys page from the side menu." },
                    { title: "Select Journey", content: "Select the journey containing the action item you want to fulfill." },
                    { title: "Press on the action item title", content: "Press on the action item title. You will be redirected to the template plage for that specific action item." },
                    { title: "Generate the content for that action item", content: "Generate the content in the template page and save it." },
                    { title: "Navigate back to the journey", content: "Navigate back to the journey by clicking the journey title at the top of the content page." },
                    { title: "Navigate back to the content", content: "Return to the content by clicking the action item's title."},
                    { title: "Complete an Action Item", content: "Complete an Action Item by clicking on the radiobutton next to the action item." },
                ],
            },
            {
                id: "45",
                title: "Mark an Action Item as Completed",
                description: "Learn how to mark an action item as completed.",
                steps: [
                    { title: "Go to the Journeys Page", content: "Navigate to the journeys page from the side menu." },
                    { title: "Select Journey", content: "Select the journey containing the action item you want to mark as completed." },
                    { title: "Complete an Action Item", content: "Complete an Action Item by clicking on the radiobutton next to the action item." },
                ],
                tips: ["Regularly mark completed action items to keep the journey updated."],
            },
            {
                id: "46",
                title: "View Archived Action Items",
                description: "Learn how to view archived action items.",
                steps: [
                    { title: "Go to the Journeys Page", content: "Navigate to the journeys page from the side menu." },
                    { title: "Click on 'History'", content: "Click the 'History' tab to view archived action items." },
                ],
                tips: ["Review archived action items to track past activities."],
                faqs: [
                    { question: "How can I see all my archived action items?", answer: "All your archived action items are listed under the 'History' tab on the journeys page." },
                    { question: "When do my action items go into archive?", answer: "Your action items are archived after new ones are marked as completed." },
                ],
            },
        ],
    },
    {
        section: "Users",
        subsections: [
            {
                id: "47",
                title: "Create Users",
                description: "Learn how to create new users.",
                steps: [
                    { title: "Go to the Users Page", content: "Navigate to the users page from the side menu." },
                    { title: "Click on 'Add User'", content: "Click the 'Add User' button to start the user creation process. A modal for creation will appear." },
                    { title: "Input User Details", content: "Enter the required details for the new user." },
                    { title: "Click 'Create User'", content: "Click the 'Create User' button to create the user." }
                ],
                faqs: [
                    { question: "Can I create multiple users?", answer: "Yes, you can create multiple users from the users page." },
                ]
            },
            {
                id: "48",
                title: "Change User Role",
                description: "Learn how to change the role of a user.",
                steps: [
                    { title: "Go to the Users Page", content: "Navigate to the users page from the side menu." },
                    { title: "Select User", content: "Select the user whose role you want to change." },
                    { title: "Click on the 'Role' select", content: "Click the 'Role' select next to the user's name." },
                    { title: "Select New Role", content: "Choose the new role from the dropdown list." }
                ],
                tips: ["Ensure the new role aligns with the user's responsibilities."]
            },
            {
                id: "49",
                title: "Delete Users",
                description: "Learn how to delete users.",
                steps: [
                    { title: "Go to the Users Page", content: "Navigate to the users page from the side menu." },
                    { title: "Select Users", content: "Select the users you want to delete by checking the checkbox before each row." },
                    { title: "Click on 'Delete'", content: "Click the 'Delete' button next to the user's name." },
                    { title: "Confirm Deletion", content: "Confirm the deletion in the pop-up dialog." }
                ],
                tips: ["Ensure you have the necessary permissions to delete a user."],
                faqs: [
                    { question: "Can I recover a deleted user?", answer: "No, once a user is deleted, it cannot be recovered." },
                ]
            },
            {
                id: "50",
                title: "Buy User Seats",
                description: "Learn how to add more user seats to your subscription.",
                steps: [
                    { title: "Go to the Users Page", content: "Navigate to the users page from the side menu." },
                    { title: "Click on 'Buy Seats'", content: "Click the 'Buy Seats' button to open the seat purchase modal." },
                    { title: "Enter Quantity", content: "Input the number of user seats you want to add in the modal." },
                    { title: "Confirm Purchase", content: "Click 'Confirm' to complete the purchase. The seats will now be available." }
                ],
                faqs: [
                    { question: "Can I add user seats at any time?", answer: "Yes, you can add user seats as needed from the users page." },
                    { question: "How does buying seats affect my subscription?", answer: "Each added seat will increase your subscription cost accordingly." }
                ]
            },
            {
                id: "51",
                title: "Remove User Seats",
                description: "Learn how to remove user seats from your subscription.",
                steps: [
                    { title: "Go to the Users Page", content: "Navigate to the users page from the side menu." },
                    { title: "Click on 'Remove Seats'", content: "Click the 'Remove Seats' button to open the seat removal modal." },
                    { title: "Enter Quantity", content: "Input the number of user seats you want to remove in the modal." },
                    { title: "Confirm Removal", content: "Click 'Confirm' to complete the removal. The seats will be deducted from your subscription." }
                ],
                tips: ["Ensure there are no active users occupying the seats you wish to remove."],
                faqs: [
                    { question: "Can I remove seats if all are in use?", answer: "No, you must first delete users to free up seats." },
                    { question: "How does removing seats affect my subscription?", answer: "Removing seats will decrease your subscription cost accordingly." }
                ]
            }
        ]
    },
    {
        section: "Speeches",
        subsections: [
            {
                id: "52",
                title: "View Generated Speech List",
                description: "Learn how to view the list of generated speeches.",
                steps: [
                    { title: "Go to the Speeches Page", content: "Navigate to the speeches page from the side menu." },
                    { title: "View Speech List", content: "All your generated speeches are listed on this page." },
                ],
                faqs: [
                    { question: "How can I see all my generated speeches?", answer: "All your generated speeches are listed on the speeches page." },
                ],
            },
            {
                id: "53",
                title: "Generate Speech",
                description: "Learn how to generate a new speech.",
                steps: [
                    { title: "Go to the Speeches Page", content: "Navigate to the speeches page from the side menu." },
                    { title: "Click on 'Add New Speech'", content: "Click the 'Add New Speech' button to start the speech generation process." },
                    { title: "Input Speech Prompt", content: "Enter the prompt that will be used for the speech generation." },
                    { title: "Click 'Generate Speech'", content: "Click the 'Generate Speech' button to create the speech." },
                ],
                tips: ["Provide detailed information for better speech generation results."],
                faqs: [
                    { question: "Can I generate multiple speeches?", answer: "Yes, you can generate multiple speeches from the speeches page." },
                ],
            },
        ],
    },
];

export default guides;