import ApiCalls from "../util/ApiCalls";

class JourneyService {
    async create(journeyDto) {
      return ApiCalls.defaultPost("/journey/create", {}, journeyDto);
    }

    async getForBrand(queryParams) {
      return ApiCalls.defaultGet("/journey", queryParams);
    }

    async getOne(journeyId) {
      return ApiCalls.defaultGet("/journey/" + journeyId, {});
    }

    async completeJourneyActionItem(actionItemId) {
        return ApiCalls.defaultPost("/journey/complete/" + actionItemId, {});
    }

    async delete(journeyId) {
        return ApiCalls.defaultDelete("/journey/" + journeyId, {});
    }
}
  
export default new JourneyService();