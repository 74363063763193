import React, { Component } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CustomSnackbar from '../shared/CustomSnackbar';
import { withRouter } from '../../services/security/withRouter';
import ContentService from '../../services/ContentService';
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

class ContentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            snackbarOpen: false,
            snackbarSeverity: 'success',
            snackbarMessage: '',
            contents: [],
            loadingContents: true,
            page: 0,
            pageSize: 10,
            sort: 'createdAt',
            order: 'desc',
            filters: {},
            totalNumberOfItems: 0
        };
    }

    componentDidMount() {
        this.fetchContentsForBrand();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedBrand } = this.props;

        if (selectedBrand && selectedBrand.id !== prevProps.selectedBrand.id) {
            this.fetchContentsForBrand();
        }
    }

    fetchContentsForBrand = async () => {
        const { selectedBrand } = this.props;
        const { page, pageSize, sort, order, filters } = this.state;
        if (!selectedBrand || !selectedBrand.id) return;

        const queryParams = {
            page,
            size: pageSize,
            sort,
            order,
            ...filters
        };

        try {
            const response = await ContentService.getForBrand(selectedBrand.id, queryParams);
            this.setState({ contents: response.content, totalNumberOfItems: response.totalNumberOfItems, loadingContents: false });
        } catch (error) {
            console.error('Failed to fetch contents:', error);
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingContents: false
            });
        }
    };

    handleSnackbarClose = (event, reason) => {
        this.setState({ snackbarOpen: false });
    };

    handlePaginationModelChange = (paginationModel) => {
        const { page, pageSize } = paginationModel;
        this.setState({ page, pageSize }, this.fetchContentsForBrand);
    };

    handleSortModelChange = (sortModel) => {
        if (sortModel.length > 0) {
            const { field, sort } = sortModel[0];
            this.setState({ sort: field, order: sort }, this.fetchContentsForBrand);
        }
    };

    handleFilterChange = (filterModel) => {
        const filters = filterModel.items.reduce((acc, filter) => {
            if (filter.value) {
                acc[filter.field] = filter.value;
            }
            return acc;
        }, {});

        this.setState({ filters }, this.fetchContentsForBrand);
    };

    handleViewClick = (id) => {
        this.props.navigate(`/content/${id}`);
    };

    render() {
        const { contents, loadingContents, page, pageSize, totalNumberOfItems } = this.state;
        const { selectedBrand, isMobile } = this.props;

        if (!selectedBrand || selectedBrand.id === 0) {
            return (
                <Box sx={{ padding: 4 }}>
                    <Typography variant="body1" color="textSecondary">
                        To continue, please select a brand from the navigation bar.
                    </Typography>
                </Box>
            );
        }

        const columns = [
            {
                field: 'templateName',
                headerName: 'Template',
                editable: false,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
            },
            {
                field: 'platformName',
                headerName: 'Platform',
                editable: false,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
            },
            {
                field: 'postDate',
                headerName: 'Post Date',
                editable: false,
                filterable: false,
                valueGetter: (param) => {
                    if (!param) return '';
                    const date = new Date(param);
                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
                flex: 1.5,
            },
            {
                field: 'status',
                headerName: 'Status',
                editable: false,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                editable: false,
                filterable: false,
                disableColumnMenu: true,
                sortable: false,
                flex: 0.8,
                renderCell: (params) => (
                    <Tooltip title="View">
                        <IconButton
                            color="primary"
                            onClick={() => this.handleViewClick(params.id)}
                        >
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                ),
            },
        ];

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography variant="h4" gutterBottom>
                                            Contents
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {loadingContents ? (
                                    <Skeleton variant="rectangular" width="100%" height={400} />
                                ) : (
                                    contents.length > 0 ? (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                overflowX: 'auto',
                                                '@media (max-width: 600px)': {
                                                    padding: 1,
                                                }
                                            }}
                                        >
                                        <DataGrid
                                            key={this.props.sidebarOpen}
                                            rows={contents}
                                            columns={columns}
                                            pagination
                                            paginationMode="server"
                                            sortingMode="server"
                                            filterMode="server"
                                            page={page}
                                            pageSize={pageSize}
                                            rowCount={totalNumberOfItems}
                                            onPaginationModelChange={this.handlePaginationModelChange}
                                            onSortModelChange={this.handleSortModelChange}
                                            onFilterModelChange={this.handleFilterChange}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiDataGrid-root': {
                                                    overflowX: 'auto',
                                                },
                                                minWidth: 600,
                                                marginTop: '10px',
                                            }}
                                            pageSizeOptions={[10, 5, 3]}
                                            disableRowSelectionOnClick
                                        />
                                        </Box>
                                    ) : (
                                        <Typography variant="body1" color="textSecondary">
                                            No content has been created yet	.
                                        </Typography>
                                    ))}
                            </CardContent>
                    </Grid>
                </Grid>

                <CustomSnackbar
                    snackbarOpen={this.state.snackbarOpen}
                    handleSnackbarClose={this.handleSnackbarClose}
                    severity={this.state.snackbarSeverity}
                    snackbarMessage={this.state.snackbarMessage}
                />
            </>
        );
    }
}

export default withRouter(ContentList);