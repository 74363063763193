import React, {Component} from 'react';
import {Box, Typography, TextField, MenuItem, Button, Grid, CircularProgress, Card} from '@mui/material';
import {withRouter} from '../../services/security/withRouter';
import moment from 'moment';
import ReportsService from '../../services/reports/ReportsService';
import {PieChart, Pie, Cell, Tooltip, Legend} from 'recharts';

class Reports extends Component {
    constructor(props) {
        super(props);
        const today = moment().endOf('day').format('YYYY-MM-DD');
        const firstDayOfMonth = moment().startOf('month').startOf('day').format('YYYY-MM-DD');

        this.state = {
            reportType: 'contentStatusOverview',
            startDate: firstDayOfMonth,
            endDate: today,
            loading: false,
            reportData: null,
        };
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    isFormValid = () => {
        const {reportType, startDate, endDate} = this.state;
        return reportType && startDate && endDate && moment(startDate).isSameOrBefore(moment(endDate));
    };

    generateReport = async () => {
        const {reportType, startDate, endDate} = this.state;
        const {selectedBrand} = this.props;

        if (!selectedBrand || selectedBrand.id === 0) {
            return;
        }

        this.setState({loading: true});

        const reportRequestDto = {
            startDate: moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            endDate: moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            reportType,
            brandId: selectedBrand.id,
        };

        try {
            const response = await ReportsService.generate(reportRequestDto);
            this.setState({reportData: response.data, loading: false});
        } catch (error) {
            console.error('Error generating report:', error);
            this.setState({loading: false});
        }
    };

    render() {
        const {reportType, startDate, endDate, loading, reportData} = this.state;
        const {selectedBrand} = this.props;

        if (!selectedBrand || selectedBrand.id === 0) {
            return (
                <Box sx={{padding: 4}}>
                    <Typography variant="h6">
                        Select a brand to generate reports.
                    </Typography>
                </Box>
            );
        }

        const COLORS = [
            '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
            '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384', '#36A2EB',
            '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
            '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ];

        return (
            <Box sx={{padding: 2}}>
                <Typography variant="h4" gutterBottom>
                    Generate Report
                </Typography>
                <Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={3}>
                            <TextField
                                select
                                label="Report Type"
                                name="reportType"
                                value={reportType}
                                onChange={this.handleChange}
                                fullWidth
                            >
                                <MenuItem value="contentStatusOverview">Content Status Overview</MenuItem>
                                <MenuItem value="contentPlatformOverview">Content Platform Overview</MenuItem>
                                <MenuItem value="contentCampaignOverview">Content Campaign Overview</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Start Date"
                                type="date"
                                name="startDate"
                                value={startDate}
                                onChange={this.handleChange}
                                InputLabelProps={{shrink: true}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="End Date"
                                type="date"
                                name="endDate"
                                value={endDate}
                                onChange={this.handleChange}
                                InputLabelProps={{shrink: true}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!this.isFormValid() || loading}
                                onClick={this.generateReport}
                            >
                                {loading ? <CircularProgress size={24}/> : 'Generate Report'}
                            </Button>
                        </Grid>
                    </Grid>
                    {moment(startDate).isAfter(moment(endDate)) && (
                        <Typography variant="body2" color="error">
                            The end date cannot be earlier than the start date.
                        </Typography>
                    )}
                    {!reportData && (
                        <Typography variant="body1" color="textSecondary">
                            Please select the report type and date range to generate the report.
                        </Typography>
                    )}
                    {reportData && Object.keys(reportData).length === 0 && (
                        <Typography variant="body1" color="textSecondary">
                            No data available for the selected criteria.
                        </Typography>
                    )}
                    {reportData && Object.keys(reportData).length > 0 && (
                        <Box sx={{marginTop: 4, display: 'flex', justifyContent: 'center'}}>
                            <PieChart width={800} height={500}>
                                <Pie
                                    data={Object.entries(reportData).map(([name, value]) => ({name, value}))}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={({name, percent}) => `${name}: ${(percent * 100).toFixed(2)}%`}
                                    outerRadius={200}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {Object.entries(reportData).map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                    ))}
                                </Pie>
                                <Tooltip/>
                                <Legend/>
                            </PieChart>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }
}

export default withRouter(Reports);