import React, { Component } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";

class PrivacyPolicy extends Component {
    render() {
        return (
            <Box sx={{ padding: "2rem", maxWidth: "800px", margin: "0 auto" }}>
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Effective Date:</strong> 20 December 2024
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce ("we," "our," or "us") is committed to protecting your privacy.
                    This Privacy Policy outlines how we collect, use, store, and share your
                    personal data when you use our services (“Services”), including our website,
                    applications, and integrations. By accessing or using our Services, you agree
                    to this Privacy Policy.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Introduction
                </Typography>
                <Typography variant="body1" paragraph>
                    Our Services are designed to help businesses create, manage, and optimize
                    marketing campaigns. This Privacy Policy applies to all users worldwide. For
                    questions or concerns about this policy, please contact us at&nbsp;
                    <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link>.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Data Collection
                </Typography>
                <Typography variant="h6" gutterBottom>
                    2.1 Personal Data
                </Typography>
                <Typography variant="body1" paragraph>
                    We collect:
                </Typography>
                <List>
                    <ListItem>Account Information: Name, email address, company name, billing address, and payment details.</ListItem>
                    <ListItem>Subscription Information: Plan type, usage data, and preferences.</ListItem>
                </List>
                <Typography variant="h6" gutterBottom>
                    2.2 Non-Personal Data
                </Typography>
                <Typography variant="body1" paragraph>
                    Device information, browser type, and access logs.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    2.3 How Data Is Collected
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>User-Provided Data:</strong> Data you enter when signing up or
                    interacting with our Services.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Automated Data Collection:</strong> Data collected via mandatory
                    cookies to ensure functionality.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    3. Purpose of Data Usage
                </Typography>
                <Typography variant="body1" paragraph>
                    We use your data for:
                </Typography>
                <List>
                    <ListItem>Account Management: To create and maintain your account.</ListItem>
                    <ListItem>Service Delivery: To provide AI-driven content creation, email notifications, and payment processing.</ListItem>
                    <ListItem>Customer Support: To respond to inquiries and provide technical assistance.</ListItem>
                    <ListItem>Legal Compliance: To comply with applicable laws and regulations.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    4. Data Sharing and Disclosure
                </Typography>
                <Typography variant="body1" paragraph>
                    We do not sell your personal data. However, we may share data with trusted
                    third-party partners to provide and improve our Services.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    4.1 Third-Party Integrations
                </Typography>
                <List>
                    <ListItem>
                        <strong>OpenAI:</strong> For AI-driven content creation. See&nbsp;
                        <Link href="https://openai.com/privacy" target="_blank" rel="noopener">
                            OpenAI Privacy Policy
                        </Link>.
                    </ListItem>
                    <ListItem>
                        <strong>Stripe:</strong> For payment processing. See&nbsp;
                        <Link href="https://stripe.com/privacy" target="_blank" rel="noopener">
                            Stripe Privacy Policy
                        </Link>.
                    </ListItem>
                    <ListItem>
                        <strong>Google:</strong> For email notifications. See&nbsp;
                        <Link href="https://policies.google.com/privacy" target="_blank" rel="noopener">
                            Google Privacy Policy
                        </Link>.
                    </ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    5. Data Security
                </Typography>
                <Typography variant="body1" paragraph>
                    We take measures to protect your data, including encryption, access controls,
                    and regular audits.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Data Retention
                </Typography>
                <Typography variant="body1" paragraph>
                    We retain personal data only as long as necessary to fulfill the purposes
                    outlined in this policy.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. User Rights
                </Typography>
                <Typography variant="body1" paragraph>
                    Depending on your location, you may have rights to access, correct, delete,
                    or restrict your data. Contact us at&nbsp;
                    <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link> for
                    assistance.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                    We use only mandatory cookies necessary for the functionality of our Services.
                    These cookies:
                </Typography>
                <List>
                    <ListItem>Ensure security.</ListItem>
                    <ListItem>Maintain session integrity.</ListItem>
                    <ListItem>Do not track user behavior.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    9. International Data Transfers
                </Typography>
                <Typography variant="body1" paragraph>
                    Data may be transferred to and stored in countries outside your jurisdiction,
                    with adequate protection in compliance with GDPR and other laws.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    10. Children’s Privacy
                </Typography>
                <Typography variant="body1" paragraph>
                    Our Services are not intended for users under 18 years of age. If you believe
                    we have collected such data, contact us to delete it.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    11. Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this Privacy Policy. Significant changes will be communicated to
                    users via email or through our platform.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    12. Contact Information
                </Typography>
                <Typography variant="body1" paragraph>
                    For questions or concerns, contact us at:
                </Typography>
                <List>
                    <ListItem>Email: office@brand-bounce.com</ListItem>
                    <ListItem>Address: Șos. Pantelimon 311, Bucharest, Romania</ListItem>
                </List>
            </Box>
        );
    }
}

export default PrivacyPolicy;