import React, { Component } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import MaintenanceIcon from '@mui/icons-material/Build';
import {withRouter} from "../services/security/withRouter";

class Maintenance extends Component {
    handleRefresh = () => {
        this.props.navigate('/dashboard');
    };

    render() {
        return (
            <Container maxWidth="sm">
                <Box sx={{ textAlign: 'center', mt: 5 }}>
                    <MaintenanceIcon sx={{ fontSize: 80, color: 'gray', mb: 2 }} />
                    <Typography variant="h3" gutterBottom>
                        Maintenance Mode
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Our site is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon />}
                        onClick={this.handleRefresh}
                        sx={{ mt: 3 }}
                    >
                        Refresh
                    </Button>
                </Box>
            </Container>
        );
    }
}

export default withRouter(Maintenance);