import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

class JourneyRecommendations extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <Container
                sx={{
                    pt: { xs: 4, sm: 12 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '70%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        All set! You are now ready to embark on your journey. Press 'Start Journey' to begin.
                    </Typography>
                </Box>
            </Container>
        );
    }
}

export default JourneyRecommendations;