import ApiCalls from './util/ApiCalls';

class SpeechService {
  async generateSpeech(speechDto) {
    return ApiCalls.defaultPost("/speeches/create", {}, speechDto);
  }

  async getSpeech(speechId) {
    return ApiCalls.defaultGet("/speeches/" + speechId, {});
  }

  async getForBrand(queryParams) {
    return ApiCalls.defaultGet("/speeches", queryParams);
  }

  async delete(speechId) {
    return ApiCalls.defaultDelete("/speeches/" + speechId, {});
  }
}

export default new SpeechService();