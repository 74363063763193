import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/security/Login';
import Cookies from 'js-cookie';
import JwtService from './services/security/JwtService';
import './App.scss';
import Register from './components/security/Register';
import ForgotPassword from './components/security/ForgotPassword';
import ResetPassword from './components/security/ResetPassword';
import Subscriptions from './components/payment/Subscriptions';
import Sidebar from './components/shared/Sidebar';
import Account from './components/Account';
import Users from './components/lists/Users';
import Roles from './components/lists/RolesAndRights';
import Brands from './components/lists/Brands';
import CreateBrand from './components/create/brand/CreateBrand';
import Template from './components/view/Template';
import Brand from './components/create/brand/Brand';
import Campaigns from './components/lists/Campaigns';
import Campaign from './components/view/Campaign';
import ContentList from './components/lists/ContentList';
import Content from './components/view/Content';
import CalendarPlanner from './components/lists/CalendarPlanner';
import Images from './components/lists/Images';
import Image from './components/view/Image';
import Journeys from './components/lists/Journeys';
import CreateJourney from './components/create/journey/CreateJourney';
import Journey from './components/view/Journey';
import Reports from "./components/view/Reports";
import Contact from "./components/view/Contact";
import GuideComponent from "./components/guide/GuideComponent";
import Maintenance from "./components/Maintenance";
import Terms from "./components/guide/Terms";
import PrivacyPolicy from "./components/guide/PrivacyPolicy";
import Policies from "./components/guide/Policies";
import RefundPolicy from "./components/guide/RefundPolicy";
import CookiePolicy from "./components/guide/CookiePolicy";
import AcceptableUsePolicy from "./components/guide/AcceptableUsePolicy";
import SupportPolicy from "./components/guide/SupportPolicy";
import DataProcessingAgreement from "./components/guide/DataProcessingAgreement";
import Speeches from "./components/lists/Speeches";
import Speech from "./components/view/Speech";
import SwitchPlan from "./components/view/components/SwitchPlan";
import getTheme from "./components/getTheme";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme(getTheme())

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('token') || Cookies.get('refreshToken'),
    };
  }

  handleLogin = (newToken, newRefreshToken) => {
    const decodedToken = JwtService.decodeJwt(newToken);
    const decodedRefreshToken = JwtService.decodeJwt(newRefreshToken);
    localStorage.setItem("authorities", decodedToken.payload.authorities.map((authorityObject) => authorityObject.authority));
    localStorage.setItem("rights", JSON.stringify(decodedToken.payload.rights));
    localStorage.setItem("sub", decodedToken.payload.sub);

    const tokenExpiresAt = new Date(Date.now() + (decodedToken.payload.exp - decodedToken.payload.iat) * 1000);
    const refreshTokenExpiresAt = new Date(Date.now() + (decodedRefreshToken.payload.exp - decodedRefreshToken.payload.iat) * 1000);

    // Cookies.set('token', newToken, { secure: true, sameSite: 'None', expires: tokenExpiresAt });
    // Cookies.set('refreshToken', newRefreshToken, { secure: true, sameSite: 'None', expires: refreshTokenExpiresAt });

    // unsecure for local development todo: change to secure for prod
    Cookies.set('token', newToken, { expires: tokenExpiresAt });
    Cookies.set('refreshToken', newRefreshToken, { expires: refreshTokenExpiresAt });

    this.setState({ token: newToken });
  };

  PrivateRoute = ({ element }) => {
    return this.state.token ? (
      <ThemeProvider theme={theme}>
          <Sidebar content={element} />
      </ThemeProvider>
    ) : (
      <Navigate to="/login" />
    );
  };

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/login" element={<Login onLogin={this.handleLogin} />} />
          <Route path="/register" element={<Register onLogin={this.handleLogin} />} />
          <Route path="/app-guides" element={<GuideComponent />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/subscriptions/*" element={<Subscriptions />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/acceptable-use-policy" element={<AcceptableUsePolicy />} />
          <Route path="/support-policy" element={<SupportPolicy />} />
          <Route path="/data-processing" element={<DataProcessingAgreement />} />
          <Route path="/" element={<this.PrivateRoute element={<Dashboard />} />} />
          <Route path="/dashboard" element={<this.PrivateRoute element={<Dashboard />} />} />
          <Route path="/create-brand" element={<this.PrivateRoute element={<CreateBrand />} />} />
          <Route path="/account" element={<this.PrivateRoute element={<Account />} />} />
          <Route path="/users" element={<this.PrivateRoute element={<Users />} />} />
          <Route path="/roles" element={<this.PrivateRoute element={<Roles />} />} />
          <Route path="/brand/:id" element={<this.PrivateRoute element={<Brand />} />} />
          <Route path="/template/:id" element={<this.PrivateRoute element={<Template />} />} />
          <Route path="/brands" element={<this.PrivateRoute element={<Brands />} />} />
          <Route path="/campaigns" element={<this.PrivateRoute element={<Campaigns />} />} />
          <Route path="/campaigns/:id" element={<this.PrivateRoute element={<Campaign />} />} />
          <Route path="/content" element={<this.PrivateRoute element={<ContentList />} />} />
          <Route path="/content/:id" element={<this.PrivateRoute element={<Content />} />} />
          <Route path="/calendar" element={<this.PrivateRoute element={<CalendarPlanner />} />} />
          <Route path="/images" element={<this.PrivateRoute element={<Images />} />} />
          <Route path="/image" element={<this.PrivateRoute element={<Image />} />} />
          <Route path="/speeches" element={<this.PrivateRoute element={<Speeches />} />} />
          <Route path="/speech" element={<this.PrivateRoute element={<Speech />} />} />
          <Route path="/journeys" element={<this.PrivateRoute element={<Journeys />} />} />
          <Route path="/journeys/create" element={<this.PrivateRoute element={<CreateJourney />} />} />
          <Route path="/journeys/:id" element={<this.PrivateRoute element={<Journey />} />} />
          <Route path="/reports" element={<this.PrivateRoute element={<Reports />} />} />
          <Route path="/contact" element={<this.PrivateRoute element={<Contact />} />} />
          <Route path="/change-plan" element={<this.PrivateRoute element={<SwitchPlan />} />} />
          {/*todo: uncomment this when starting to use affiliates*/}
          {/*<Route path="/affiliate" element={<this.PrivateRoute element={<Affiliate />} />} />*/}

          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
