import React, { Component } from 'react';
import { Box, Typography, TextField, Tabs, Tab, Button, Container, Grid } from '@mui/material';
import UserService from '../services/UserService';
import MyPlan from './payment/MyPlan';
import Invoices from './payment/Invoices';
import CustomSnackbar from './shared/CustomSnackbar';
import Cookies from 'js-cookie';
import { withRouter } from '../services/security/withRouter';
import ConfirmDialog from './shared/ConfirmDialog';
import RightsService from "../services/security/RightsService";
import AccountCircle from '@mui/icons-material/AccountCircle';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNo: ''
            },
            activeTab: 0,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            snackbars: [],
            userErrors: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNo: ''
            },
            passwordErrors: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            initialEmail: '',
            confirmSaveOpen: false,
            confirmPasswordOpen: false
        };
    }

    componentDidMount() {
        this.getUserInfo();
        const activeTab = localStorage.getItem('activeTab');
        if (activeTab !== null) {
            this.setState({ activeTab: parseInt(activeTab, 10) });
        }
    }

    handleOpenConfirmSave = () => {
        this.setState({ confirmSaveOpen: true });
    };

    handleCloseConfirmSave = () => {
        this.setState({ confirmSaveOpen: false });
    };

    handleOpenConfirmPassword = () => {
        this.setState({ confirmPasswordOpen: true });
    };

    handleCloseConfirmPassword = () => {
        this.setState({ confirmPasswordOpen: false });
    };

    getUserInfo = async () => {
        try {
            const user = await UserService.getAuthenticatedUserInfo();
            this.setState({ user, initialEmail: user.email });
        } catch (error) {
            console.error('Failed to fetch user info:', error);
        }
    };

    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
        localStorage.setItem('activeTab', newValue);
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                [name]: value
            },
            userErrors: {
                ...prevState.userErrors,
                [name]: value ? '' : 'This field is mandatory.'
            }
        }));
    };

    handlePasswordChange = (event) => {
        const { name, value } = event.target;
        let error = '';

        if (!value) {
            error = 'This field is mandatory.';
        } else if (name === 'newPassword') {
            if (value.length < 8) {
                error = 'The password must be at least 8 characters long.';
            } else if (!/[A-Z]/.test(value)) {
                error = 'Password must contain at least one uppercase letter.';
            } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                error = 'Password must contain at least one special character.';
            }
        } else if (name === 'confirmPassword' && value !== this.state.newPassword) {
            error = 'The passwords do not match.';
        }

        this.setState((prevState) => ({
            [name]: value,
            passwordErrors: {
                ...prevState.passwordErrors,
                [name]: error
            }
        }));
    };

    handleSave = async () => {
        const { user } = this.state;
        const userErrors = {};

        Object.keys(user).forEach((key) => {
            if (!user[key]) {
                userErrors[key] = 'This field is mandatory.';
            }
        });

        const filteredErrors = Object.keys(userErrors).reduce((acc, key) => {
            if (userErrors[key]) {
                acc[key] = userErrors[key];
            }
            return acc;
        }, {});

        if (Object.keys(filteredErrors).length > 0) {
            this.setState({ userErrors: filteredErrors });
            return;
        }

        this.handleOpenConfirmSave();
    };

    confirmSave = async () => {
        const { user, initialEmail } = this.state;

        try {
            await UserService.updateSelf(user);
            this.addSnackbar('Your information has been updated successfully.', 'success');

            if (initialEmail !== user.email) {
                this.logout();
            }
        } catch (error) {
            this.addSnackbar(error.message ? error.message : 'There was an error updating your information.', 'error');
        }

        this.handleCloseConfirmSave();
    };

    handleChangePassword = async () => {
        const { currentPassword, newPassword, confirmPassword, passwordErrors } = this.state;

        if (!currentPassword) {
            passwordErrors.currentPassword = 'This field is mandatory.';
        }
        if (!newPassword) {
            passwordErrors.newPassword = 'This field is mandatory.';
        } else if (newPassword.length < 8) {
            passwordErrors.newPassword = 'Password must be at least 8 characters long';
        } else if (!/[A-Z]/.test(newPassword)) {
            passwordErrors.newPassword = 'Password must contain at least one uppercase letter';
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
            passwordErrors.newPassword = 'Password must contain at least one special character';
        }
        if (!confirmPassword) {
            passwordErrors.confirmPassword = 'This field is mandatory.';
        } else if (newPassword !== confirmPassword) {
            passwordErrors.confirmPassword = 'Passwords do not match';
        }

        const filteredErrors = Object.keys(passwordErrors).reduce((acc, key) => {
            if (passwordErrors[key]) {
                acc[key] = passwordErrors[key];
            }
            return acc;
        }, {});

        if (Object.keys(filteredErrors).length > 0) {
            this.setState({ passwordErrors: filteredErrors });
            return;
        }

        this.handleOpenConfirmPassword();
    };

    confirmChangePassword = async () => {
        const { currentPassword, newPassword, confirmPassword } = this.state;

        try {
            await UserService.changePassword({ currentPassword, newPassword, confirmPassword });
            this.addSnackbar('Your password has been changed successfully..', 'success');

            this.logout();
        } catch (error) {
            this.addSnackbar(error.message ? error.message : 'There was an error changing your password.', 'error');
        }

        this.handleCloseConfirmPassword();
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    logout() {
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        localStorage.clear();
        this.props.navigate('/login');
    }

    hasSubscriptionRight = () => {
        return RightsService.hasRight('SUBSCRIPTION');
    };

    render() {
        const { user, activeTab, currentPassword, newPassword, confirmPassword, userErrors, passwordErrors, confirmSaveOpen, confirmPasswordOpen } = this.state;

        return (
            <Box sx={{padding: 2}}>
                <Typography variant="h4" gutterBottom sx={{color: 'primary.dark', display: 'flex', alignItems: 'center'}}>
                    <AccountCircle sx={{ mr: 1, fontSize: 34 }} />
                    {user.firstName} {user.lastName}
                </Typography>
                    <Tabs value={activeTab} onChange={this.handleTabChange}>
                        <Tab label="Edit Info" />
                        <Tab label="Change Password" />
                        {this.hasSubscriptionRight() && <Tab label="Plan" />}
                        {this.hasSubscriptionRight() && <Tab label="Invoices" />}
                    </Tabs>
                    {activeTab === 0 && (
                        <Box sx={{ marginTop: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="First Name"
                                        name="firstName"
                                        value={user.firstName}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        error={!!userErrors.firstName}
                                        helperText={userErrors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Last Name"
                                        name="lastName"
                                        value={user.lastName}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        error={!!userErrors.lastName}
                                        helperText={userErrors.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        value={user.email}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        error={!!userErrors.email}
                                        helperText={userErrors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Phone Number"
                                        name="phoneNo"
                                        value={user.phoneNo}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        error={!!userErrors.phoneNo}
                                        helperText={userErrors.phoneNo}
                                    />
                                </Grid>
                            </Grid>
                            <Button variant="contained" color="primary" onClick={this.handleSave} sx={{ marginTop: 2 }}>
                                Save
                            </Button>
                        </Box>
                    )}
                    {activeTab === 2 && (
                        <Box sx={{ marginTop: 2 }}>
                            <MyPlan />
                        </Box>
                    )}
                    {activeTab === 3 && (
                        <Box sx={{ marginTop: 2 }}>
                            <Invoices />
                        </Box>
                    )}
                    {activeTab === 1 && (
                        <Box sx={{ marginTop: 2 }}>
                            <form autoComplete="off">
                                <TextField
                                    label="Current Password"
                                    name="currentPassword"
                                    type="password"
                                    value={currentPassword}
                                    onChange={this.handlePasswordChange}
                                    fullWidth
                                    margin="normal"
                                    autoComplete="off"
                                    error={!!passwordErrors.currentPassword}
                                    helperText={passwordErrors.currentPassword}
                                />
                                <TextField
                                    label="New Password"
                                    name="newPassword"
                                    type="password"
                                    value={newPassword}
                                    onChange={this.handlePasswordChange}
                                    fullWidth
                                    margin="normal"
                                    autoComplete="off"
                                    error={!!passwordErrors.newPassword}
                                    helperText={passwordErrors.newPassword}
                                />
                                <TextField
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={this.handlePasswordChange}
                                    fullWidth
                                    margin="normal"
                                    autoComplete="off"
                                    error={!!passwordErrors.confirmPassword}
                                    helperText={passwordErrors.confirmPassword}
                                />
                                <Button variant="contained" color="primary" onClick={this.handleChangePassword} sx={{ marginTop: 2 }}>
                                    Submit
                                </Button>
                            </form>
                        </Box>
                    )}
                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{ bottom: `${index * 60 + 20}px` }}
                    />
                ))}
                <ConfirmDialog
                    dialogOpen={confirmSaveOpen}
                    handleClose={this.handleCloseConfirmSave}
                    handleConfirm={this.confirmSave}
                    dialogTitle="Confirm Save"
                    dialogMessage="Are you sure you want to save the changes? Changing your email address will log you out."
                />
                <ConfirmDialog
                    dialogOpen={confirmPasswordOpen}
                    handleClose={this.handleCloseConfirmPassword}
                    handleConfirm={this.confirmChangePassword}
                    dialogTitle="Confirm Password Change"
                    dialogMessage="Are you sure you want to change your password?"
                />
            </Box>
        );
    }
}

export default withRouter(Account);