import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const VoiceSelector = ({ voices, selectedVoice, onVoiceChange }) => {
  useEffect(() => {
    if (voices.length >= 1 && selectedVoice !== voices[0].id) {
      onVoiceChange({ target: { value: voices[0].id } });
    }
  }, [voices, selectedVoice, onVoiceChange]);

  return (
    <FormControl fullWidth sx={{ marginBottom: 1 }}>
      <InputLabel>Voice</InputLabel>
      <Select
        value={selectedVoice}
        onChange={onVoiceChange}
        label="Voice"
        disabled={!voices.length}
        sx={{ height: 60 }}
      >
        {voices.map(voice => (
          <MenuItem key={voice.id} value={voice.id}>
            {`${voice.personality} - ${voice.toneOfVoice}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default VoiceSelector;