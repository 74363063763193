import React, { Component } from 'react';
import { Box, TextField, Button, CircularProgress, Typography, Grid } from '@mui/material';
import CampaignService from '../../services/CampaignService';
import CustomSnackbar from '../shared/CustomSnackbar'; // Import CustomSnackbar

class CampaignView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign: props.campaign,
            updating: false,
            error: null,
            snackbars: [],
            errors: {},
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.campaign !== this.props.campaign) {
            this.setState({ campaign: this.props.campaign });
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            campaign: {
                ...prevState.campaign,
                [name]: value,
            },
        }));
    };

    handleDateChange = (name, value) => {
        this.setState((prevState) => ({
            campaign: {
                ...prevState.campaign,
                [name]: value,
            },
        }));
    };

    validateForm = () => {
        const { campaign } = this.state;
        const errors = {};

        if (!campaign.name) errors.name = 'Name is mandatory.';
        if (!campaign.objective) errors.objective = 'Objective is mandatory.';
        if (!campaign.product) errors.product = 'Product is mandatory.';
        if (!campaign.startDate) errors.startDate = 'Start Date is mandatory.';
        if (!campaign.endDate) errors.endDate = 'End Date is mandatory.';

        this.setState({ errors });

        return Object.keys(errors).length === 0;
    };

    handleUpdateCampaign = async () => {
        if (!this.validateForm()) return;

        this.setState({ updating: true });
        const { campaign } = this.state;
        const campaignDto = {
            name: campaign.name,
            objective: campaign.objective,
            product: campaign.product,
            startDate: campaign.startDate,
            endDate: campaign.endDate,
        };

        try {
            await CampaignService.updateCampaign(campaign.id, campaignDto);
            this.setState({
                updating: false,
            });
            this.addSnackbar('The campaign has been updated successfully.', 'success');
        } catch (error) {
            this.setState({
                error: 'Unable to update campaign.',
                updating: false,
            });
            this.addSnackbar('There was an error updating the campaign. Please try again later.', 'error');
        }
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    render() {
        const { campaign, updating, error, errors } = this.state;

        if (!campaign) {
            return <CircularProgress />;
        }

        if (error) {
            return <Typography color="error">{error}</Typography>;
        }

        return (
            <Box sx={{ padding: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Edit Campaign
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Name"
                            name="name"
                            value={campaign.name}
                            onChange={this.handleInputChange}
                            fullWidth
                            margin="normal"
                            size="small"
                            error={!!errors.name}
                            helperText={errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Objective"
                            name="objective"
                            value={campaign.objective}
                            onChange={this.handleInputChange}
                            fullWidth
                            margin="normal"
                            size="small"
                            error={!!errors.objective}
                            helperText={errors.objective}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Product"
                            name="product"
                            value={campaign.product}
                            onChange={this.handleInputChange}
                            fullWidth
                            margin="normal"
                            size="small"
                            error={!!errors.product}
                            helperText={errors.product}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Start Date"
                            name="startDate"
                            type="datetime-local"
                            value={campaign.startDate}
                            onChange={(e) => this.handleDateChange('startDate', e.target.value)}
                            fullWidth
                            margin="normal"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors.startDate}
                            helperText={errors.startDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="End Date"
                            name="endDate"
                            type="datetime-local"
                            value={campaign.endDate}
                            onChange={(e) => this.handleDateChange('endDate', e.target.value)}
                            fullWidth
                            margin="normal"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors.endDate}
                            helperText={errors.endDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Content Type"
                            name="contentTypeName"
                            value={campaign.contentType.name}
                            fullWidth
                            margin="normal"
                            size="small"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Target Audience"
                            name="targetAudienceName"
                            value={campaign.audience.name}
                            fullWidth
                            margin="normal"
                            size="small"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleUpdateCampaign}
                    disabled={updating}
                    sx={{ marginTop: 2 }}
                >
                    {updating ? <CircularProgress size={24} color="inherit" /> : 'Update Campaign'}
                </Button>
                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{ bottom: `${index * 60 + 20}px` }}
                    />
                ))}
            </Box>
        );
    }
}

export default CampaignView;