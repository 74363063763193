import React, {Component} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Chip,
    Autocomplete,
    Button,
    FormHelperText,
    CircularProgress
} from '@mui/material';
import countries from '../../../../services/util/countries';
import ageRange from '../../../../services/util/ageRanges';
import AiService from '../../../../services/AiService';
import industries from '../../../../services/util/industries';

class TargetAudience extends Component {
    state = {
        loadingRecommendations: false,
        snackbarSeverity: '',
        snackbarMessage: '',
        snackbarOpen: false,
        errors: {},
        aiButtonDisabled: false,
        timer: 0,
        locationLimitError: '',
        interestsLimitError: '',
    };

    handleChange = (event) => {
        const {name, value} = event.target;
        this.props.onUpdate({
            targetAudience: {
                ...this.props.targetAudience,
                [name]: value,
            },
        });
    };

    handleAutocompleteChange = (name, value) => {
        if (name === 'location' && value.length > 5) {
            this.setState({locationLimitError: 'You can select up to 5 locations only.'});
            return;
        }
        if (name === 'interests' && value.length > 8) {
            this.setState({interestsLimitError: 'You can select up to 8 interests only.'});
            return;
        }
        this.setState({locationLimitError: '', interestsLimitError: ''});
        this.props.onUpdate({
            targetAudience: {
                ...this.props.targetAudience,
                [name]: value,
            },
        });
    };

    handleChangeSelect = (event, value) => {
        this.props.onUpdate({
            targetAudience: {
                ...this.props.targetAudience,
                industry: value,
                otherIndustry: value === 'Other' ? '' : undefined,
            },
        });
    };

    handleOtherIndustryChange = (event) => {
        const {value} = event.target;
        this.props.onUpdate({
            targetAudience: {
                ...this.props.targetAudience,
                otherIndustry: value,
            },
        });
    };

    validateLocationAndAudienceIndustry = () => {
        const errors = {};

        if (!this.props.targetAudience.location || this.props.targetAudience.location.length === 0) {
            errors.location = 'Location is required';
        }

        if (!this.props.targetAudience.industry) {
            errors.audienceIndustry = 'Industry is required';
        } else if (this.props.targetAudience.industry === 'Other' && !this.props.targetAudience.otherIndustry) {
            errors.audienceOtherIndustry = 'Other industry is required';
        }

        this.setState({errors});
        this.props.onUpdate({errors});

        return Object.keys(errors).length === 0;
    };

    handleAISuggestion = () => {
        if (!this.validateLocationAndAudienceIndustry()) {
            return;
        }

        this.setState({
            loadingRecommendations: true,
            aiButtonDisabled: true,
            timer: 15,
        });
        this.startTimer();

        const audienceIndustry = this.props.targetAudience.industry === 'Other' ? this.props.targetAudience.otherIndustry : this.props.targetAudience.industry;

        AiService.getInterests({
            locations: this.props.targetAudience.location,
            brandIndustry: this.props.industry,
            industry: audienceIndustry,
        }).then(response => {
            this.setState({loadingRecommendations: false});

            const updatedTargetAudience = {
                ...this.props.targetAudience,
                interests: response.interests,
                ageRange: response.ageRange,
            };

            this.props.onUpdate({targetAudience: updatedTargetAudience});
        }).catch(error => {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingRecommendations: false,
            });

            this.startTimer();
        });
    };

    startTimer = () => {
        const countdown = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.timer <= 1) {
                    clearInterval(countdown);
                    return {aiButtonDisabled: false, timer: 0};
                }
                return {timer: prevState.timer - 1};
            });
        }, 1000);
    };

    render() {
        const {errors, inSidebar} = this.props;
        const {aiButtonDisabled, timer, loadingRecommendations, locationLimitError, interestsLimitError} = this.state;

        return (
            <Container
                sx={{
                    pt: { xs: inSidebar ? 0 : 4, sm: inSidebar ? 0 : 12 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: {xs: 1, sm: 1},
                }}
            >
                <Box
                    sx={{
                        textAlign: {sm: 'left', md: 'center'},
                    }}
                >
                    {!inSidebar &&
                    <Typography component="h2" variant="h3" color="text.primary">
                        Your Audience
                    </Typography>
                    }
                    <br/>

                </Box>
                <Box component="form" noValidate sx={{width: '100%'}} className='businessInfoContainer'>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={inSidebar ? 12 : 6}>
                            <FormControl fullWidth error={!!errors.location || !!locationLimitError}>
                                <Autocomplete
                                    multiple
                                    options={countries}
                                    value={this.props.targetAudience.location}
                                    onChange={(event, value) => this.handleAutocompleteChange('location', value)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Main Locations"
                                            placeholder="Choose key regions"
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.location || locationLimitError}</FormHelperText>
                            </FormControl>
                            <FormHelperText>
                                Select the primary regions where your target audience resides.
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={inSidebar ? 12 : 6}>
                            <FormControl fullWidth error={!!errors.audienceIndustry}>
                                <Autocomplete
                                    options={industries}
                                    getOptionLabel={(option) => option}
                                    value={this.props.targetAudience.industry}
                                    onChange={this.handleChangeSelect}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select customer's industry"
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.audienceIndustry}</FormHelperText>
                            </FormControl>
                            <FormHelperText>
                                If targeting a broad market, select 'All Industries.'
                                If not listed, choose 'Other' and provide details.
                            </FormHelperText>
                        </Grid>
                        {this.props.targetAudience.industry === 'Other' && (
                            <Grid item xs={12} sm={inSidebar ? 12 : 6}>
                                <FormControl fullWidth error={!!errors.audienceOtherIndustry}>
                                    <TextField
                                        fullWidth
                                        label="Enter your industry"
                                        name="otherIndustry"
                                        value={this.props.targetAudience.otherIndustry || ''}
                                        onChange={this.handleOtherIndustryChange}
                                        error={!!errors.audienceOtherIndustry}
                                    />
                                    <FormHelperText>{errors.audienceOtherIndustry}</FormHelperText>
                                </FormControl>
                                <FormHelperText>
                                    Enter the specific industry your customers belong to.
                                </FormHelperText>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={inSidebar ? 12 : 6}>
                            <FormControl fullWidth error={!!errors.interests || !!interestsLimitError}>
                                <Autocomplete
                                    multiple
                                    options={[]}
                                    freeSolo
                                    value={this.props.targetAudience.interests}
                                    onChange={(event, value) => this.handleAutocompleteChange('interests', value)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Interests"
                                            placeholder="Add interests"
                                        />
                                    )}
                                />
                                <FormHelperText>{errors.interests || interestsLimitError}</FormHelperText>
                            </FormControl>
                            <FormHelperText>
                                Add customer interests (e.g., fashion, technology, sports). Press Enter after typing
                                each interest.
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={inSidebar ? 12 : 6}>
                            <FormControl fullWidth error={!!errors.ageRange}>
                                <InputLabel>Age Range</InputLabel>
                                <Select
                                    name="ageRange"
                                    value={this.props.targetAudience.ageRange}
                                    onChange={this.handleChange}
                                    label="Age Range"
                                >
                                    {ageRange.map((age) => (
                                        <MenuItem key={age} value={age}>
                                            {age}
                                        </MenuItem>
                                    ))}
                                    {!ageRange.includes(this.props.targetAudience.ageRange) && (
                                        <MenuItem key={this.props.targetAudience.ageRange}
                                                  value={this.props.targetAudience.ageRange}>
                                            {this.props.targetAudience.ageRange}
                                        </MenuItem>
                                    )}
                                </Select>
                                <FormHelperText>{errors.ageRange}</FormHelperText>
                            </FormControl>
                            <FormHelperText>
                                Don’t worry, we’ll include customers outside this range too.
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={inSidebar ? 12 : 6} sx={{mt: 2}}>
                            <Button variant="outlined" onClick={this.handleAISuggestion} disabled={aiButtonDisabled}>
                                {aiButtonDisabled ? `Need ideas? Let AI help! ${timer}s` : 'Need ideas? Let AI help!'}
                                {loadingRecommendations && <CircularProgress size={24} sx={{ml: 2}}/>}
                            </Button>
                            <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                                Recommendations are tailored using your brand industry, customer demographics, and
                                selected regions.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    }
}

export default TargetAudience;