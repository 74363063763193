import React, {Component} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Button, CircularProgress} from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Intro from './steps/Intro';
import BusinessInfo from './steps/BusinessInfo';
import BrandDetails from './steps/BrandDetails';
import TargetAudience from './steps/TargetAudience';
import CompanyService from '../../../services/CompanyService';
import {withRouter} from '../../../services/security/withRouter';
import CustomSnackbar from '../../shared/CustomSnackbar';
import industries from '../../../services/util/industries';
import RightsService from "../../../services/security/RightsService";

class CreateBrand extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const activeStep = parseInt(params.get('step')) || 0;

        this.state = {
            activeStep,
            skipped: new Set(),
            businessInfo: {
                companyId: '',
                companyName: '',
                brandName: '',
                industry: null,
                otherIndustry: '',
            },
            targetAudience: {
                location: [],
                ageRange: '',
                interests: [],
                industry: null,
                otherIndustry: '',
            },
            voice: {
                personality: '',
                toneOfVoice: '',
                story: '',
            },
            errors: {},
            errorResponse: {},
            loadingBrand: false,
            loadingAudience: false,
            loadingVoice: false,
            loadingNext: false,
            activeBrand: {},
            activeAudience: {},
            activeVoice: {},
            snackbarOpen: false,
            snackbarSeverity: 'success',
            snackbarMessage: '',
            editBrand: false,
        };
    }

    componentDidMount() {
        if (!RightsService.hasRight("BRANDS_EDIT")) {
            setTimeout(() => {
                this.props.navigate('/dashboard');
            }, 0);
            return;
        }

        const {location} = this.props;
        const params = new URLSearchParams(location.search);
        const brandId = params.get('brandId');

        const audienceId = params.get('audienceId');
        const voiceId = params.get('voiceId');
        const editBrand = params.get('editBrand') === 'true';

        if (brandId) {
            this.setState({loadingBrand: true});
            this.getBrand(brandId, audienceId, voiceId);
        }
        this.setState({editBrand});
    }

    updateQueryParams = (step) => {
        const {navigate, location} = this.props;
        const params = new URLSearchParams(location.search);
        params.set('step', step);
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    isStepOptional = (step) => {
        return false;
    };

    isStepSkipped = (step) => {
        return this.state.skipped.has(step);
    };

    handleNext = async () => {
        const {activeStep} = this.state;

        if (activeStep === 1) {
            if (!this.validateBusinessInfo()) {
                return;
            }

            if (!this.state.activeBrand
                || this.state.activeBrand.name !== this.state.businessInfo.brandName
                || (this.state.businessInfo.industry === 'Other'
                    ? this.state.activeBrand.industry !== this.state.businessInfo.otherIndustry
                    : this.state.activeBrand.industry !== this.state.businessInfo.industry)) {

                this.setState({loadingNext: true});
                await this.handleCompanyCreation();
            } else if (this.state.editBrand) {
                this.navigateToBrand();
            }
        }

        if (activeStep === 2) {
            if (!this.validateTargetAudience()) {
                return;
            }

            if (!this.state.activeAudience
                || this.state.activeAudience.location !== this.state.targetAudience.location
                || this.state.activeAudience.ageRange !== this.state.targetAudience.ageRange
                || this.state.activeAudience.interests !== this.state.targetAudience.interests
                || (this.state.targetAudience.industry === 'Other'
                    ? this.state.activeAudience.otherIndustry !== this.state.targetAudience.otherIndustry
                    : this.state.activeAudience.industry !== this.state.targetAudience.industry)) {

                this.setState({loadingNext: true});
                await this.handleTargetAudienceCreation();
            }
        }

        if (activeStep === 3) {
            if (!this.validateVoice()) {
                return;
            }

            if (!this.state.activeVoice
                || this.state.activeVoice.personality !== this.state.voice.personality
                || this.state.activeVoice.story !== this.state.voice.story
                || this.state.activeVoice.toneOfVoice !== this.state.voice.toneOfVoice) {

                this.setState({loadingNext: true});
                await this.handleVoiceCreation();
            }
        }

        let newSkipped = this.state.skipped;
        if (this.isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1,
            skipped: newSkipped,
        }), () => {
            this.updateQueryParams(this.state.activeStep);
        });
    };

    handleBack = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
        }), () => {
            this.updateQueryParams(this.state.activeStep);
        });
    };

    handleSkip = () => {
        if (!this.isStepOptional(this.state.activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        this.setState((prevState) => {
            const newSkipped = new Set(prevState.skipped.values());
            newSkipped.add(this.state.activeStep);
            return {
                activeStep: prevState.activeStep + 1,
                skipped: newSkipped,
            };
        }, () => {
            this.updateQueryParams(this.state.activeStep);
        });
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        }, () => {
            this.updateQueryParams(this.state.activeStep);
        });
    };

    handleUpdate = (updatedState) => {
        this.setState((prevState) => ({
            ...prevState,
            ...updatedState,
        }));
    };

    validateBusinessInfo = () => {
        let errors = {};

        if (!this.props.authenticatedUser.company && !this.state.businessInfo.companyName) {
            errors.companyName = 'Please provide your company name.';
        }

        if (!this.state.businessInfo.brandName) {
            errors.brandName = 'Please provide a brand name.';
        }

        if (!this.state.businessInfo.industry) {
            errors.industry = 'Please select an industry.';
        }

        this.setState({errors});

        return Object.keys(errors).length === 0;
    };


    validateVoice = () => {
        let errors = {};

        if (!this.state.voice.personality) {
            errors.personality = 'Please select a brand personality.';
        }

        if (!this.state.voice.toneOfVoice) {
            errors.toneOfVoice = 'Please provide a tone of voice for your brand.';
        }

        if (!this.state.voice.story) {
            errors.story = 'Please write a story for your brand.';
        }

        this.setState({errors});

        return Object.keys(errors).length === 0;
    };

    handleVoiceCreation = async () => {
        try {
            const response = await CompanyService.createVoice({
                personality: this.state.voice.personality,
                toneOfVoice: this.state.voice.toneOfVoice,
                story: this.state.voice.story,
                brandId: this.state.activeBrand ? this.state.activeBrand.id : null,
                targetAudienceId: this.state.activeAudience ? this.state.activeAudience.targetAudienceId : null,
                voiceId: this.state.activeVoice ? this.state.activeVoice.voiceId : null,
            });

            this.setState({
                activeVoice: response,
                loadingNext: false,
            });

            this.props.navigate('/dashboard');
            window.location.reload();
        } catch (error) {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingNext: false,
            });

            this.handleBack();
        }
    }

    validateTargetAudience = () => {
        let errors = {};

        if (!this.state.targetAudience.location || this.state.targetAudience.location.length === 0) {
            errors.location = 'Please specify at least one target location.';
        }

        if (!this.state.targetAudience.ageRange) {
            errors.ageRange = 'Please select an age range.';
        }

        if (!this.state.targetAudience.interests || this.state.targetAudience.interests.length === 0) {
            errors.interests = 'Please add at least one interest for your target audience.';
        }

        if (!this.state.targetAudience.industry) {
            errors.audienceIndustry = 'Please specify the industry of your target audience.';
        } else if (this.state.targetAudience.industry === 'Other' && !this.state.targetAudience.otherIndustry) {
            errors.audienceOtherIndustry = 'Please provide the "Other" industry details for your target audience.';
        }

        this.setState({errors});

        return Object.keys(errors).length === 0;
    };

    handleTargetAudienceCreation = async () => {
        try {
            const industryValue = this.state.targetAudience.industry === 'Other'
                ? this.state.targetAudience.otherIndustry
                : this.state.targetAudience.industry;

            const response = await CompanyService.createAudience({
                ageRange: this.state.targetAudience.ageRange,
                location: this.state.targetAudience.location,
                interests: this.state.targetAudience.interests,
                industry: industryValue,
                brandId: this.state.activeBrand ? this.state.activeBrand.id : null,
                targetAudienceId: this.state.activeAudience ? this.state.activeAudience.targetAudienceId : null,
            });

            this.setState({
                activeAudience: response,
                loadingNext: false,
            });
            const {navigate, location} = this.props;
            navigate({
                pathname: location.pathname,
                search: `?brandId=${this.state.activeBrand.id}&audienceId=${response.targetAudienceId}&step=${this.state.activeStep}`
            });

        } catch (error) {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingNext: false,
            });

            this.handleBack();
        }
    };

    handleCompanyCreation = async () => {
        try {
            const industryValue = this.state.businessInfo.industry === 'Other'
                ? this.state.businessInfo.otherIndustry
                : this.state.businessInfo.industry;

            const response = await CompanyService.createCompany({
                companyName: this.state.businessInfo.companyName,
                brandName: this.state.businessInfo.brandName,
                industry: industryValue,
                companyId: this.props.authenticatedUser.company ? this.props.authenticatedUser.company.id : null,
                brandId: this.state.activeBrand ? this.state.activeBrand.id : null,
                audienceId: this.state.activeAudience ? this.state.activeAudience.targetAudienceId : null,
                voiceId: this.state.activeVoice ? this.state.activeVoice.voiceId : null,
            });

            const updatedUser = {
                ...this.props.authenticatedUser,
                company: response
            };
            this.props.updateAuthenticatedUser(updatedUser);
            this.setState({
                activeBrand: response.createdBrand,
                loadingNext: false,
            });
            const {navigate, location} = this.props;
            if (this.state.editBrand) {
                this.navigateToBrand();
            } else {
                navigate({
                    pathname: location.pathname,
                    search: response.createdBrand.targetAudience ? (response.createdBrand.targetAudience.voice ? `?brandId=${response.createdBrand.id}&audienceId=${response.createdBrand.targetAudience.targetAudienceId}&voiceId=${response.createdBrand.targetAudience.voice.voiceId}&step=${this.state.activeStep}` : `?brandId=${response.createdBrand.id}&audienceId=${response.createdBrand.targetAudience.targetAudienceId}&step=${this.state.activeStep}`) : `?brandId=${response.createdBrand.id}&step=${this.state.activeStep}`
                });
            }
        } catch (error) {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                errors: error.code !== 1015 && {brandName: 'The brand name you provided is already in use. Please choose a different name.'},
                loadingNext: false,
            });

            this.handleBack();
        }
    };

    handleSnackbarClose = (event, reason) => {
        this.setState({snackbarOpen: false});
    };

    getBrand(brandId, audienceId, voiceId) {
        CompanyService.getBrand(brandId, audienceId, voiceId).then(brand => {
            const industry = industries.find(ind => ind === brand.industry);

            const audienceIndustry = brand.targetAudience ? industries.find(ind => ind === brand.targetAudience.industry) : '';

            this.setState({
                activeBrand: brand,
                businessInfo: {
                    ...this.state.businessInfo,
                    brandName: brand.name,
                    industry: industry || {label: 'Other', value: 'Other'},
                    otherIndustry: industry ? '' : brand.industry,
                },
                loadingBrand: false,
                activeAudience: brand.targetAudience ? {
                    ...brand.targetAudience,
                    industry: audienceIndustry || {label: 'Other', value: 'Other'},
                    otherIndustry: audienceIndustry ? '' : brand.targetAudience.industry,
                } : this.state.targetAudience,
                targetAudience: brand.targetAudience ? {
                    ...this.state.targetAudience,
                    ageRange: brand.targetAudience.ageRange,
                    location: brand.targetAudience.location,
                    interests: brand.targetAudience.interests,
                    industry: audienceIndustry || {label: 'Other', value: 'Other'},
                    otherIndustry: audienceIndustry ? '' : brand.targetAudience.industry,
                } : this.state.targetAudience,
                activeVoice: brand.targetAudience ? brand.targetAudience.voice : this.state.voice,
                voice: brand.targetAudience && brand.targetAudience.voice ? {
                    ...this.state.voice,
                    personality: brand.targetAudience.voice.personality,
                    toneOfVoice: brand.targetAudience.voice.toneOfVoice,
                    story: brand.targetAudience.voice.story,
                } : this.state.voice,
            });

        }).catch(error => {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingBrand: false,
            });
        });
    }


    navigateToBrand = () => {
        this.props.navigate(`/brand/${this.state.activeBrand.id}`);
    }

    render() {
        const {activeStep, businessInfo, errors, loadingNext} = this.state;
        const steps = ['Intro', 'Create Your Brand', 'Target Audience', 'Brand Voice'];

        return (
            <>
                    {!this.state.editBrand && <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (this.isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption">Optional</Typography>
                                );
                            }
                            if (this.isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>}
                    {activeStep === steps.length ? (
                        <Box/>
                    ) : (
                        <React.Fragment>
                            {activeStep === 0 && <Intro/>}
                            {activeStep === 1 && (
                                <BusinessInfo
                                    businessInfo={businessInfo}
                                    errors={errors}
                                    authenticatedUser={this.props.authenticatedUser}
                                    onUpdate={this.handleUpdate}
                                    loadingBrand={this.state.loadingBrand}
                                    activeBrand={this.state.activeBrand}
                                />
                            )}
                            {activeStep === 2 && (
                                <TargetAudience
                                    targetAudience={this.state.targetAudience}
                                    errors={errors}
                                    onUpdate={this.handleUpdate}
                                    industry={this.state.businessInfo.industry ? this.state.businessInfo.industry === "Other" ? this.state.businessInfo.otherIndustry : this.state.businessInfo.industry : ''}
                                />
                            )}
                            {activeStep === 3 && (
                                <BrandDetails
                                    voice={this.state.voice}
                                    errors={errors}
                                    onUpdate={this.handleUpdate}
                                    industry={this.state.businessInfo.industry ? this.state.businessInfo.industry === "Other" ? this.state.businessInfo.otherIndustry : this.state.businessInfo.industry : ''}
                                    audienceIndustry={this.state.targetAudience.industry ? this.state.targetAudience.industry === "Other" ? this.state.targetAudience.otherIndustry : this.state.targetAudience.industry : ''}
                                    ageRange={this.state.activeAudience.ageRange}
                                    location={this.state.activeAudience.location}
                                    brandName={this.state.activeBrand.name}
                                />
                            )}

                            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                {this.state.editBrand ?
                                    <Button
                                        color="inherit"
                                        onClick={this.navigateToBrand}
                                        sx={{fontSize: '1.25rem', padding: '12px 24px', mr: 1}}
                                    >
                                        Discard Changes
                                    </Button>
                                    :
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={this.handleBack}
                                        sx={{fontSize: '1.25rem', padding: '12px 24px', mr: 1}}
                                    >
                                        Previous
                                    </Button>
                                }
                                <Box sx={{flex: '1 1 auto'}}/>
                                {this.isStepOptional(activeStep) && (
                                    <Button color="inherit" onClick={this.handleSkip} sx={{mr: 1}}>
                                        Skip
                                    </Button>
                                )}
                                <Box sx={{position: 'relative'}}>
                                    <Button
                                        onClick={this.handleNext}
                                        sx={{fontSize: '1.25rem', padding: '12px 24px'}}
                                        disabled={loadingNext}
                                    >
                                        {activeStep === steps.length - 1 ? 'Complete Setup' : 'Continue'}
                                    </Button>
                                    {loadingNext && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'primary.main',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '24px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </React.Fragment>
                    )}
                    <CustomSnackbar
                        snackbarOpen={this.state.snackbarOpen}
                        handleSnackbarClose={this.handleSnackbarClose}
                        severity={this.state.snackbarSeverity}
                        snackbarMessage={this.state.snackbarMessage}/>
            </>
        );
    }
}

export default withRouter(CreateBrand);