import React, { useState } from 'react';
import { Box, TextField, Button, CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import CommentService from "../../../services/CommentService";

const CommentSection = ({
                            comments = [],
                            newComment,
                            authenticatedUser,
                            setComments,
                            setNewComment,
                            contentId,
                            brandJourneyId,
                        }) => {
    const [loadingComment, setLoadingCommentState] = useState(false);
    const [editingCommentId, setEditingCommentIdState] = useState(null);
    const [editingCommentText, setEditingCommentTextState] = useState('');

    const handleCommentChange = (event) => {
        setNewComment(event.target.value);
    };

    const handleCommentSubmit = async () => {
        if (newComment.trim()) {
            const commentDto = {
                comment: newComment,
                commenter: {
                    id: authenticatedUser.id,
                    firstName: authenticatedUser.firstName,
                    lastName: authenticatedUser.lastName,
                },
            };

            if (brandJourneyId) {
                commentDto.brandJourneyId = brandJourneyId;
            } else {
                commentDto.contentId = contentId;
            }

            setLoadingCommentState(true);

            try {
                const response = brandJourneyId
                    ? await CommentService.addCommentForBrandJourney(commentDto)
                    : await CommentService.addComment(commentDto);

                const newCommentObj = {
                    id: response.id,
                    comment: response.comment,
                    contentId: response.contentId,
                    brandJourneyId: response.brandJourneyId,
                    updatedAt: response.updatedAt,
                    commenter: response.commenter || { id: null, firstName: 'Unknown', lastName: 'User' },
                };
                setComments([...comments, newCommentObj]);
                setNewComment('');
                setLoadingCommentState(false);
            } catch (error) {
                console.error('Unable to add comment:', error);
                setLoadingCommentState(false);
            }
        }
    };

    const handleEditCommentChange = (event) => {
        setEditingCommentTextState(event.target.value);
    };

    const handleEditComment = (commentId, commentText) => {
        setEditingCommentIdState(commentId);
        setEditingCommentTextState(commentText);
    };

    const handleCancelEditComment = () => {
        setEditingCommentIdState(null);
        setEditingCommentTextState('');
    };

    const handleSaveEditComment = async () => {
        if (editingCommentText.trim()) {
            const commentDto = {
                id: editingCommentId,
                comment: editingCommentText,
                commenter: {
                    id: authenticatedUser.id,
                    firstName: authenticatedUser.firstName,
                    lastName: authenticatedUser.lastName,
                },
            };

            try {
                await CommentService.updateComment(commentDto);
                setComments(comments.map(comment =>
                    comment.id === editingCommentId ? { ...comment, comment: editingCommentText } : comment
                ));
                setEditingCommentIdState(null);
                setEditingCommentTextState('');
            } catch (error) {
                console.error('Failed to update comment:', error);
            }
        }
    };

    const handleCommentDelete = async (commentId) => {
        try {
            await CommentService.delete(commentId);
            setComments(comments.filter(comment => comment.id !== commentId));
        } catch (error) {
            console.error('Unable to delete comment:', error);
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', marginBottom: 2 }}>
                <Box sx={{ marginRight: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 45, height: 40, borderRadius: '50%', backgroundColor: 'primary.main', color: '#fff', fontSize: 16 }}>
                    {authenticatedUser.firstName.charAt(0)}
                </Box>
                <TextField
                    label="Write a comment"
                    value={newComment}
                    onChange={handleCommentChange}
                    fullWidth
                    variant="outlined"
                    size="small"
                />
                <Button onClick={handleCommentSubmit} variant="contained" color="primary" sx={{ marginLeft: 1 }} disabled={loadingComment}>
                    {loadingComment ? <CircularProgress size={24} color="inherit" /> : 'Send'}
                </Button>
            </Box>
            {comments.slice(0).reverse().map((comment) => {
                const commenter = comment.commenter || { firstName: 'Unknown', lastName: 'User' };
                return (
                    <Box key={comment.id} sx={{ marginBottom: 1, padding: 1, border: '1px solid #ddd', borderRadius: 1, display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ marginRight: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: '50%', backgroundColor: 'primary.main', color: '#fff', fontSize: 16 }}>
                            {commenter.firstName.charAt(0)}
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{commenter.firstName} {commenter.lastName}</Typography>
                            <Typography variant="body2" color="textSecondary">{moment(comment.updatedAt).format('MMMM Do YYYY, h:mm a')}</Typography>
                            {editingCommentId === comment.id ? (
                                <TextField
                                    value={editingCommentText}
                                    onChange={handleEditCommentChange}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginTop: 1 }}
                                />
                            ) : (
                                <Typography variant="body2">{comment.comment}</Typography>
                            )}
                        </Box>
                        {authenticatedUser.id === comment.commenter?.id && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {editingCommentId === comment.id ? (
                                    <>
                                        <Button size="small" sx={{ marginLeft: 1 }} onClick={handleSaveEditComment}>Save</Button>
                                        <Button size="small" sx={{ marginLeft: 1 }} onClick={handleCancelEditComment}>Cancel</Button>
                                    </>
                                ) : (
                                    <>
                                        <Button size="small" sx={{ marginLeft: 1 }} onClick={() => handleEditComment(comment.id, comment.comment)}>Edit</Button>
                                        <Button size="small" sx={{ marginLeft: 1 }} onClick={() => handleCommentDelete(comment.id)}>Delete</Button>
                                    </>
                                )}
                            </Box>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default CommentSection;