const personalityOptions = [
    { value: "Innovative", label: "Innovative" },
    { value: "Playful", label: "Playful" },
    { value: "Bold", label: "Bold" },
    { value: "Professional", label: "Professional" },
    { value: "Friendly", label: "Friendly" },
    { value: "Trustworthy", label: "Trustworthy" },
    { value: "Authoritative", label: "Authoritative" },
    { value: "Casual", label: "Casual" },
    { value: "Sophisticated", label: "Sophisticated" },
    { value: "Energetic", label: "Energetic" },
    { value: "Empathetic", label: "Empathetic" },
    { value: "Adventurous", label: "Adventurous" },
    { value: "Reliable", label: "Reliable" },
    { value: "Inspirational", label: "Inspirational" },
    { value: "Humorous", label: "Humorous" },
    { value: "Elegant", label: "Elegant" },
    { value: "Warm", label: "Warm" },
    { value: "Confident", label: "Confident" },
    { value: "Creative", label: "Creative" }
];

export default personalityOptions;