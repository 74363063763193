import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CompanyService from '../../services/CompanyService';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import { withRouter } from '../../services/security/withRouter';
import RightsService from "../../services/security/RightsService";

class Brands extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brands: [],
            brandsLoading: true,
            snackbarSeverity: '',
            snackbarMessage: '',
            snackbarOpen: false,
        };
    }

    componentDidMount() {
        CompanyService.getBrands().then(brands => {
            this.setState({
                brands: brands,
                brandsLoading: false
            });
        }).catch(error => {
            this.setState({
                brandsLoading: false,
                snackbarSeverity: 'error',
                snackbarMessage: 'Failed to retrieve the brands.',
                snackbarOpen: true
            });

            console.error(error);
        });
    }

    handleFabClick = () => {
        this.props.navigate('/create-brand');
    }

    handleChooseActiveClick = (brand) => {
        const selectedBrand = { id: brand.id, name: brand.name };
        localStorage.setItem('selectedBrand', JSON.stringify(selectedBrand));
        this.props.updateSelectedBrand(selectedBrand);
        this.props.navigate(`/brand/${brand.id}`);
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }

    hasBrandEditRight = () => {
        return RightsService.hasRight('BRANDS_EDIT');
    };

    render() {
        const { brands, brandsLoading, snackbarOpen, snackbarSeverity, snackbarMessage } = this.state;
        const disableAddButton = this.props.currentPlan?.maxNumberOfBrands <= brands.length;

        return (
            <Box sx={{ padding: 4, boxSizing: 'border-box', overflow: 'hidden' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
                    {this.hasBrandEditRight() && !this.props.isMobile && (
                        <Tooltip title={disableAddButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Create a Brand"} arrow>
                            <span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleFabClick}
                                disabled={disableAddButton}
                            >
                                Create a Brand
                            </Button>
                                </span>
                        </Tooltip>
                    )}
                </Box>
                {brandsLoading ? (
                    <Grid container spacing={3}>
                        {Array.from(new Array(3)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Skeleton variant="rectangular" height={200} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        {brands.map((brand) => (
                            <Grid item xs={12} sm={6} md={4} key={brand.id}>
                                <Card variant="outlined" sx={{ minWidth: 275, padding: 2 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                                            {brand.name}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            Industry: {brand.industry}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <Button size="small" variant="contained" color="primary" onClick={() => this.handleChooseActiveClick(brand)}>Set as Active</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
                {this.hasBrandEditRight() && this.props.isMobile && (
                    <Tooltip title={disableAddButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Create a Brand"} arrow>
                        <Fab
                            color="primary"
                            aria-label="add"
                            sx={{
                                position: 'fixed',
                                bottom: 16,
                                right: 16
                            }}
                            onClick={this.handleFabClick}
                            disabled={disableAddButton}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                )}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                >
                    <Alert onClose={this.handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        );
    }
}

export default withRouter(Brands);