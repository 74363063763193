import React, { Component } from "react";
import { Box, Typography, List, ListItem, Link } from "@mui/material";

class SupportPolicy extends Component {
    render() {
        return (
            <Box sx={{ padding: "2rem", maxWidth: "800px", margin: "0 auto" }}>
                <Typography variant="h4" gutterBottom>
                    Support Policy
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>Effective Date:</strong> 20 December 2024
                </Typography>

                <Typography variant="body1" paragraph>
                    At Brand Bounce, we are committed to providing reliable and efficient support to ensure the best possible experience for our users. This Support Policy outlines the scope, availability, and terms of support provided for our services.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Support Channels
                </Typography>
                <Typography variant="body1" paragraph>
                    You can reach out to us through the following support channels:
                </Typography>
                <List>
                    <ListItem>Email: <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link></ListItem>
                    <ListItem>Chat: AI chat everywhere in the application</ListItem>
                    <ListItem>Contact Form: Access the form at<Link href="/contact">Contact Form</Link></ListItem>
                    <ListItem>App Guider: Access our resources at <Link href="/app-guides">Application Guide</Link></ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    2. Support Availability
                </Typography>
                <Typography variant="body1" paragraph>
                    Our support team is available during the following hours:
                </Typography>
                <List>
                    <ListItem>Monday to Friday: 9:00 AM to 6:00 PM (EET)</ListItem>
                    <ListItem>Saturday, Sunday, and Public Holidays: Limited support via email</ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    Response times may vary based on the volume of support requests and the complexity of your inquiry.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    3. Scope of Support
                </Typography>
                <Typography variant="body1" paragraph>
                    Our support services cover the following areas:
                </Typography>
                <List>
                    <ListItem>Assistance with account setup and configuration</ListItem>
                    <ListItem>Guidance on using our features and tools</ListItem>
                    <ListItem>Troubleshooting technical issues within our platform</ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    The following are outside the scope of our support services:
                </Typography>
                <List>
                    <ListItem>Third-party integrations not directly provided by Brand Bounce</ListItem>
                    <ListItem>Customizations beyond the standard functionality of our platform</ListItem>
                    <ListItem>Issues caused by external factors, such as network outages</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    4. Response Times
                </Typography>
                <Typography variant="body1" paragraph>
                    We aim to respond to support requests within the following timeframes:
                </Typography>
                <List>
                    <ListItem>High Priority (e.g., service outages): Within 2 business hours</ListItem>
                    <ListItem>Medium Priority (e.g., functionality issues): Within 8 business hours</ListItem>
                    <ListItem>Low Priority (e.g., general inquiries): Within 24 business hours</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    5. Reporting an Issue
                </Typography>
                <Typography variant="body1" paragraph>
                    To help us resolve your issues efficiently, please provide the following information when contacting support:
                </Typography>
                <List>
                    <ListItem>Your account details (e.g., username or registered email)</ListItem>
                    <ListItem>A clear description of the issue</ListItem>
                    <ListItem>Steps to reproduce the issue</ListItem>
                    <ListItem>Screenshots or logs, if applicable</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    6. Updates and Maintenance
                </Typography>
                <Typography variant="body1" paragraph>
                    During platform updates or maintenance, there may be temporary disruptions to the Services. We will notify users in advance whenever possible. If you experience issues during these periods, please contact support for assistance.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Escalation Process
                </Typography>
                <Typography variant="body1" paragraph>
                    If your issue is not resolved satisfactorily, you may request an escalation by emailing <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link> with the subject line “Escalation Request.” Our escalation team will review and address your concerns promptly.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Changes to This Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce reserves the right to update this Support Policy. Significant changes will be communicated via email or through our platform. Continued use of our Services constitutes acceptance of the updated Policy.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    9. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    For additional assistance, please contact us at:
                </Typography>
                <List>
                    <ListItem>Email: <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link></ListItem>
                    <ListItem>Address: Șos. Pantelimon 311, Bucharest, Romania</ListItem>
                </List>
            </Box>
        );
    }
}

export default SupportPolicy;