import React, { Component } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";

class Terms extends Component {
    render() {
        return (
            <Box sx={{ padding: "2rem", maxWidth: "800px", margin: "0 auto" }}>
                <Typography variant="h4" gutterBottom>
                    Terms and Conditions
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>Effective Date:</strong> 20 December 2024
                </Typography>

                <Typography variant="body1" paragraph>
                    Welcome to Brand Bounce! By accessing or using our services, you agree
                    to these Terms and Conditions (“Terms”). These Terms constitute a
                    legally binding agreement between you (“you,” “your,” or “user”) and
                    Brand Bounce (“we,” “our,” or “us”). If you do not agree with any part
                    of these Terms, you must stop using the Services immediately.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Introduction
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce provides a software-as-a-service (SaaS) platform for
                    creating, managing, and optimizing marketing campaigns. Our Services
                    are available to users globally, and these Terms govern your access
                    to and use of the Brand Bounce website, applications, and
                    integrations (collectively, the “Services”).
                </Typography>
                <Typography variant="body1" paragraph>
                    For details on how we collect, use, and protect your personal data,
                    please refer to our{" "}
                    <Link
                        href="/privacy"
                        target="_blank"
                        rel="noopener"
                    >
                        Privacy Policy
                    </Link>
                    .
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Eligibility
                </Typography>
                <Typography variant="body1" paragraph>
                    To use our Services, you must:
                </Typography>
                <List>
                    <ListItem>
                        Be at least 18 years old or the legal age of majority in your
                        jurisdiction.
                    </ListItem>
                    <ListItem>
                        Have the authority to bind your organization if using the Services
                        on behalf of a company or entity.
                    </ListItem>
                    <ListItem>
                        Comply with all applicable laws and regulations, including those in
                        your country of residence.
                    </ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    3. Account Registration
                </Typography>
                <Typography variant="body1" paragraph>
                    By registering for an account, you agree to:
                </Typography>
                <List>
                    <ListItem>Provide accurate, current, and complete information.</ListItem>
                    <ListItem>Update your account details as necessary.</ListItem>
                    <ListItem>Keep your login credentials secure and confidential.</ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    You are solely responsible for all activities that occur under your
                    account. Notify us immediately at{" "}
                    <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link>{" "}
                    if you suspect unauthorized access or use of your account.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    4. Services Provided
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce offers tools and features to help businesses with marketing, including:
                </Typography>
                <List>
                    <ListItem>AI-driven content creation (powered by OpenAI).</ListItem>
                    <ListItem>Integration with third-party services, such as Stripe for payments and Google for Gmail APIs.</ListItem>
                    <ListItem>Templates for content creation.</ListItem>
                    <ListItem>Generated campaign posting plans.</ListItem>
                    <ListItem>Brand marketing journeys.</ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    We do not guarantee specific results from the use of our Services.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    5. Integrations with Third-Party Services
                </Typography>
                <Typography variant="h6" gutterBottom>
                    5.1 OpenAI Integration
                </Typography>
                <Typography variant="body1" paragraph>
                    Our platform uses OpenAI’s GPT models to provide AI-driven content creation. By using the Services, you acknowledge and agree that:
                </Typography>
                <List>
                    <ListItem>
                        The AI outputs are generated based on the inputs you provide and may not always be accurate, complete, or suitable for your purposes.
                    </ListItem>
                    <ListItem>
                        OpenAI’s Terms of Use apply to any AI-generated outputs. For more details, visit &nbsp;
                        <Link href="https://openai.com/terms" target="_blank" rel="noopener">
                            OpenAI’s Terms
                        </Link>
                        .
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    5.2 Stripe Integration
                </Typography>
                <Typography variant="body1" paragraph>
                    Payments are processed through Stripe. By subscribing to our Services, you also agree to Stripe’s{" "}
                    <Link href="https://stripe.com/legal" target="_blank" rel="noopener">
                        Terms of Service
                    </Link>{" "}
                    and Privacy Policy. Stripe may collect and process your payment information, and we are not responsible for any issues arising from their services.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    5.3 Google Integration
                </Typography>
                <Typography variant="body1" paragraph>
                    We use Google APIs, including Gmail, to enable features such as email notifications. By using these features, you agree to comply with Google’s{" "}
                    <Link href="https://policies.google.com/terms" target="_blank" rel="noopener">
                        Terms of Service
                    </Link>
                    .
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Subscriptions, Payments, and Billing
                </Typography>
                <Typography variant="h6" gutterBottom>
                    6.1 Subscription Plans
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce operates on a subscription model. Details of available plans and pricing are displayed on our website. By subscribing, you agree to:
                </Typography>
                <List>
                    <ListItem>Pay the subscription fees associated with your chosen plan.</ListItem>
                    <ListItem>Automatic renewal unless canceled before the next billing cycle.</ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    6.2 Payment Processing
                </Typography>
                <Typography variant="body1" paragraph>
                    Payments are handled through Stripe. Fees are charged in advance and billed on a recurring basis. You are responsible for ensuring your payment method is valid and up-to-date.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    6.3 Cancellations and Refunds
                </Typography>
                <Typography variant="body1" paragraph>
                    You may cancel your subscription anytime via your account dashboard. Refunds are not provided for unused portions of the subscription period.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    6.4 Modifications to Subscriptions
                </Typography>
                <Typography variant="body1" paragraph>
                    Subscription fees will be adjusted based on changes to the number of users within your organization. Specifically:
                </Typography>
                <List>
                    <ListItem>
                        Adding users will result in a new item being added to the subscription, which will increase the associated fees.
                    </ListItem>
                    <ListItem>
                        Removing users will remove the item from the subscription, and subsequent billing cycles will reflect the adjusted amount.
                    </ListItem>
                    <ListItem>
                        Upgrading the subscription will automatically increase your subscription fees.
                    </ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    It is your responsibility to manage your user base and review changes to your subscription fees within your account dashboard.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    6.5 Currency and Taxes
                </Typography>
                <Typography variant="body1" paragraph>
                    All prices are listed in USD. You are responsible for any applicable taxes, duties, or fees in your jurisdiction.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Acceptable Use
                </Typography>
                <Typography variant="body1" paragraph>
                    You agree to use the Services only for lawful purposes. Prohibited activities include but are not limited to:
                </Typography>
                <List>
                    <ListItem>Uploading or distributing harmful, illegal, or infringing content.</ListItem>
                    <ListItem>Misusing AI tools to create harmful or offensive material.</ListItem>
                    <ListItem>Attempting to reverse engineer, decompile, or hack the Services.</ListItem>
                    <ListItem>Violating third-party intellectual property or privacy rights.</ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    We reserve the right to suspend or terminate accounts for violations of this section.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Intellectual Property
                </Typography>
                <Typography variant="h6" gutterBottom>
                    8.1 Ownership
                </Typography>
                <Typography variant="body1" paragraph>
                    All content, features, and functionality provided by Brand Bounce—including the AI tools, templates, and software—are our property or licensed to us. However, any content generated by users through the AI, or uploaded to the platform by users, is owned by the user who created or provided it.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    8.2 License to Use
                </Typography>
                <Typography variant="body1" paragraph>
                    You grant us a non-exclusive, worldwide, royalty-free license to use, store, and process the content you generate or upload to the platform solely for the purpose of providing and improving the Services. This license is limited to the duration of your use of the Services.                </Typography>

                <Typography variant="h5" gutterBottom>
                    9. Data Protection and Privacy
                </Typography>
                <Typography variant="body1" paragraph>
                    Your data is handled in accordance with our{" "}
                    <Link href="/privacy" target="_blank" rel="noopener">
                        Privacy Policy
                    </Link>
                    . By using our Services, you agree to:
                </Typography>
                <List>
                    <ListItem>Allow us to process your data to provide the Services.</ListItem>
                    <ListItem>Grant us a license to use your data as necessary to deliver the platform’s functionality.</ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    For users in the European Union, we comply with GDPR regulations. For California residents, we comply with CCPA regulations.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    10. Disclaimer of Warranties
                </Typography>
                <Typography variant="body1" paragraph>
                    The Services are provided “as is” without any warranties, express or implied. We disclaim all warranties, including but not limited to:
                </Typography>
                <List>
                    <ListItem>Merchantability, fitness for a particular purpose, and non-infringement.</ListItem>
                    <ListItem>Availability, reliability, and error-free operation.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    11. Limitation of Liability
                </Typography>
                <Typography variant="body1" paragraph>
                    To the fullest extent permitted by law:
                </Typography>
                <List>
                    <ListItem>
                        Brand Bounce and its affiliates are not liable for any indirect, incidental, or consequential damages, including loss of data or profits.
                    </ListItem>
                    <ListItem>
                        Our liability is limited to the amount you paid for the Services in the 12 months preceding the event giving rise to the claim.
                    </ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    12. Termination
                </Typography>
                <Typography variant="h6" gutterBottom>
                    12.1 Termination by You
                </Typography>
                <Typography variant="body1" paragraph>
                    You may terminate your account at any time. Upon termination, you will lose access to the Services, and your data may be deleted after a retention period.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    12.2 Termination by Us
                </Typography>
                <Typography variant="body1" paragraph>
                    We may suspend or terminate your account if you breach these Terms, fail to pay fees, or engage in prohibited activities.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    13. International Use
                </Typography>
                <Typography variant="body1" paragraph>
                    Our Services are available worldwide. However:
                </Typography>
                <List>
                    <ListItem>
                        You are responsible for ensuring compliance with local laws in your jurisdiction.
                    </ListItem>
                    <ListItem>
                        Some features or integrations may not be available in certain countries due to regulatory restrictions.
                    </ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    14. Force Majeure
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce is not liable for any delay or failure to perform its obligations under these Terms due to events beyond its reasonable control. Such events include but are not limited to acts of God, natural disasters, government actions, pandemics, labor strikes, internet or telecommunication failures, and other unforeseeable circumstances.
                </Typography>
                <Typography variant="body1" paragraph>
                    In such cases, our obligations will be suspended for the duration of the event, and we will make reasonable efforts to minimize its impact on our Services.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    15. Modifications to the Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to update these Terms. If changes are significant, we will notify you via email or through the platform. Continued use of the Services after changes take effect constitutes acceptance of the updated Terms.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    16. Dispute Resolution
                </Typography>
                <Typography variant="h6" gutterBottom>
                    16.1 Governing Law
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce, operated by Ardona Software SRL, is governed by the laws of Romania.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    16.2 Arbitration
                </Typography>
                <Typography variant="body1" paragraph>
                    All disputes arising under these Terms shall be resolved through binding arbitration in Romania. Each party will bear its own costs.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    17. Contact Information
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have questions or concerns, contact us at:
                </Typography>
                <List>
                    <ListItem>Email: office@brand-bounce.com</ListItem>
                    <ListItem>Address: Șos. Pantelimon 311, Bucharest, Romania</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    18. Acknowledgment
                </Typography>
                <Typography variant="body1" paragraph>
                    By using Brand Bounce, you acknowledge that you have read,
                    understood, and agreed to these Terms.
                </Typography>
            </Box>
        );
    }
}

export default Terms;