import React, { Component } from 'react';
import Box from '@mui/material/Box';
import { withRouter } from '../../../services/security/withRouter';
import { Card, CardContent, Grid, Typography, Stepper, Step, StepLabel, Button, CircularProgress } from '@mui/material';
import JourneyGoal from './JourneyGoal';
import JourneyInputs from './JourneyInputs';
import JourneyRecommendations from './JourneyRecommendations';
import JourneyService from '../../../services/journey/JourneyService';
import CustomSnackbar from "../../shared/CustomSnackbar";

const steps = ['Journey Goal', 'Input Brand Information', 'Start Your Journey'];

class CreateJourney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadCreate: false,
            errors: {},
            journey: {},
            selectedGoal: '',
            responses: {},
            questions: [],
            activeStep: 0,
            submitting: false,
            snackbars: [],
        };
        this.journeyInputsRef = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    handleNext = () => {
        if (this.state.activeStep === 1) {
            if (!this.journeyInputsRef.current.validateMandatoryGroup()) {
                return;
            }
        }
        this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1,
        }));
    };

    handleSubmit = async () => {
        const { selectedBrand } = this.props;
        const { selectedGoal, responses } = this.state;

        const journeyData = {
            brandId: selectedBrand.id,
            journeyGoalId: selectedGoal,
            questionAnswers: responses,
        };

        this.setState({ submitting: true });

        try {
            const journey = await JourneyService.create(journeyData);
            this.setState({ submitting: false });

            this.props.navigate(`/journeys/${journey.id}`);
        } catch (error) {
            this.setState({
                submitting: false
            });
            this.addSnackbar(error.message, 'error');

        }
    };

    handleBack = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
        }));
    };

    handleGoalChange = (goal) => {
        this.setState({ selectedGoal: goal });
    };

    handleResponsesChange = (responses) => {
        this.setState({ responses });
    };

    handleQuestionsChange = (questions) => {
        const initialResponses = {};
        questions.forEach((question) => {
            if (question.inputType === 'multiselect') {
                initialResponses[question.id] = [];
            } else if (question.inputType === 'checkbox') {
                initialResponses[question.id] = true;
            } else {
                initialResponses[question.id] = '';
            }
        });
        this.setState({ questions, responses: initialResponses });
    };

    isNextDisabled = () => {
        const { selectedGoal, responses, questions, activeStep } = this.state;
        if (!selectedGoal && activeStep === 0) return true;

        if (activeStep === 1) {
            for (const question of questions) {
                const response = responses[question.id];
                if (question.inputType === 'checkbox') {
                    continue;
                }
                if (response === '' || response === undefined || (Array.isArray(response) && response.length === 0)) {
                    return true;
                }
            }
        }

        return false;
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    render() {
        const { selectedBrand } = this.props;
        const { activeStep, selectedGoal, errors, responses, submitting } = this.state;

        if (!selectedBrand || selectedBrand.id === 0) {
            return (
                <Box sx={{ padding: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Journeys
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Select a brand to view and create journeys.
                    </Typography>
                </Box>
            );
        }

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography variant="h4" gutterBottom>
                                            Start a New Journey
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <React.Fragment>
                                    {activeStep === 0 && (
                                        <JourneyGoal
                                            selectedGoal={selectedGoal}
                                            errors={errors}
                                            onGoalChange={this.handleGoalChange}
                                        />
                                    )}
                                    {activeStep === 1 && (
                                        <JourneyInputs
                                            ref={this.journeyInputsRef}
                                            selectedGoal={selectedGoal}
                                            onResponsesChange={this.handleResponsesChange}
                                            onQuestionsChange={this.handleQuestionsChange}
                                        />
                                    )}
                                    {activeStep === 2 && (
                                        <JourneyRecommendations responses={responses} />
                                    )}
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={this.handleBack}
                                            sx={{ mr: 1, padding: '12px 24px', fontSize: '16px' }}
                                        >
                                            Previous Step
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {activeStep !== steps.length - 1 ? (
                                            <Button
                                                onClick={this.handleNext}
                                                disabled={this.isNextDisabled()}
                                                sx={{ padding: '12px 24px', fontSize: '16px' }}
                                            >
                                                Continue
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={this.handleSubmit}
                                                disabled={submitting}
                                                sx={{ padding: '12px 24px', fontSize: '16px' }}
                                            >
                                                {submitting ? <CircularProgress size={24} /> : 'Start Journey'}
                                            </Button>
                                        )}
                                    </Box>
                                </React.Fragment>
                            </CardContent>
                    </Grid>
                </Grid>
                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{ bottom: `${index * 60 + 20}px` }}
                    />
                ))}
            </>
        );
    }
}

export default withRouter(CreateJourney);