import React from 'react';
import { Button } from '@mui/material';
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

const StatusButton = ({ statusButton, handleStatusUpdate, deleting }) => {
    if (!statusButton) return null;

    return (
        <Button
            variant="outlined"
            color="primary"
            startIcon={<AccessTimeOutlinedIcon />}
            onClick={() => handleStatusUpdate(statusButton.newStatus)}
            disabled={deleting}
            sx={{ marginRight: 1 }}
        >
            {statusButton.label}
        </Button>
    );
};

export default StatusButton;