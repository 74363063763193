import React, { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

class CustomSnackbar extends Component {
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.handleSnackbarClose();
    };

    render() {
        return (
            <Snackbar
                open={this.props.snackbarOpen}
                autoHideDuration={4000}
                onClose={this.handleClose}
                style={this.props.style}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={this.handleClose}
                    severity={this.props.severity}
                    variant="filled"
                    sx={{ width: '100%', color: 'white' }}
                >
                    {this.props.snackbarMessage}
                </Alert>
            </Snackbar>
        );
    }
}

export default CustomSnackbar;