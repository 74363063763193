import React, {Component} from 'react';
import {Box, Menu, MenuItem, Skeleton, Tooltip} from '@mui/material';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import ContentService from '../services/ContentService';
import CustomSnackbar from './shared/CustomSnackbar';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import {renderIcon} from "../services/util/iconHelper";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

class CalendarComponent extends Component {
    constructor(props) {
        super(props);
        const defaultView = localStorage.getItem('calendarView') || Views.WEEK;
        const savedDate = localStorage.getItem('calendarDate') || moment().toISOString();
        this.state = {
            events: [],
            loading: true,
            snackbars: [],
            date: moment(savedDate).toDate(),
            view: defaultView,
            contextMenu: {
                visible: false,
                x: 0,
                y: 0,
                eventId: null,
            },
            contextMenuAnchorEl: null,
        };
    }

    componentDidMount() {
        this.fetchContentsForCalendar();

        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('contextmenu', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('contextmenu', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        const {selectedBrand, postingPlan} = this.props;
        const {date} = this.state;

        if (selectedBrand && selectedBrand.id !== prevProps.selectedBrand.id) {
            this.fetchContentsForCalendar();
        }

        if (date !== prevState.date) {
            this.fetchContentsForCalendar();
        }

        if (postingPlan !== prevProps.postingPlan) {
            this.addPostingPlanEvents(postingPlan);
        }
    }

    handleNavigate = (date) => {
        this.setState({date}, () => {
            localStorage.setItem('calendarDate', date.toISOString());
        });
    };

    handleViewChange = (view) => {
        this.setState({view}, () => {
            localStorage.setItem('calendarView', view);
            this.fetchContentsForCalendar();
        });
    };

    fetchContentsForCalendar = async () => {
        const {selectedBrand, campaign} = this.props;
        const {date, view} = this.state;

        if (!selectedBrand || !selectedBrand.id) return;

        const currentRange = this.getViewCurrentRange(view, date);
        const queryParams = {
            startDate: moment(currentRange.start).format('YYYY-MM-DD'),
            endDate: moment(currentRange.end).format('YYYY-MM-DD'),
            campaignId: campaign ? campaign.id : '',
        };

        try {
            const response = await ContentService.getForCalendar(selectedBrand.id, queryParams);

            const events = response.content.map(content => ({
                id: content.id,
                title: (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {renderIcon(content.platformName, { fontSize: 20, color: 'white', marginRight: 1 })}
                        {content.templateName}
                    </Box>
                ),
                start: moment(content.postDate).toDate(),
                end: moment(content.postDate).add(1, 'hour').toDate(),
                type: 'content',
            }));

            this.setState({events, loading: false}, () => {
                this.addPostingPlanEvents(this.props.postingPlan);
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            this.addSnackbar(error.message, 'error');
        }
    };

    getViewCurrentRange = (view, date = new Date()) => {
        const now = moment(date);
        switch (view) {
            case Views.MONTH:
                return {
                    start: now.startOf('month').startOf('month').toDate(),
                    end: now.endOf('month').endOf('month').toDate(),
                };
            case Views.DAY:
                return {
                    start: now.startOf('day').toDate(),
                    end: now.endOf('day').toDate(),
                };
            case Views.WEEK:
            default:
                return {
                    start: now.startOf('week').toDate(),
                    end: now.endOf('week').toDate(),
                };
        }
    };

    addPostingPlanEvents = (postingPlan) => {
        if (!postingPlan || Object.keys(postingPlan).length === 0) {
            return;
        }

        const postingPlanEvents = [];

        Object.keys(postingPlan).forEach((platform) => {
            Object.keys(postingPlan[platform]).forEach((template) => {
                postingPlan[platform][template].forEach((date) => {
                    postingPlanEvents.push({
                        id: `postingPlan-${platform}-${template}-${date}`,
                        title: (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {renderIcon(platform, { fontSize: 20, color: 'white', marginRight: 1 })}
                                {template}
                            </Box>
                        ),
                        start: moment(date).toDate(),
                        end: moment(date).add(1, 'hour').toDate(),
                        allDay: false,
                        custom: true,
                        type: 'template',
                        platform: platform,
                        template: template,
                    });
                });
            });
        });

        this.setState((prevState) => ({
            events: [...prevState.events, ...postingPlanEvents],
        }), () => {
        });
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    eventRenderer = ({event}) => {
        return (
            <Tooltip title={event.title}>
                <div
                    onContextMenu={(e) => this.handleRightClick(e, event.id)}
                    style={{
                        color: 'white',
                        margin: '2px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {event.type === 'template' && <EditOffOutlinedIcon style={{marginRight: 4}}/>}
                    <strong>{event.title}</strong>
                </div>
            </Tooltip>
        );
    };

    handleRightClick = (e, eventId) => {
        e.preventDefault();
        this.setState({
            contextMenu: {
                visible: true,
                x: e.clientX,
                y: e.clientY,
                eventId,
            },
            contextMenuAnchorEl: e.currentTarget,
        });
    };

    handleContextMenuClose = () => {
        this.setState({
            contextMenu: {
                ...this.state.contextMenu,
                visible: false,
            },
            contextMenuAnchorEl: null,
        });
    };

    handleClickOutside = (e) => {
        if (this.state.contextMenu.visible && !this.state.contextMenuAnchorEl.contains(e.target)) {
            this.handleContextMenuClose();
        }
    };

    handleViewOptionClick = () => {
        const {eventId} = this.state.contextMenu;
        this.props.navigate(`/content/${eventId}`);
    };

    onEventDrop = ({event, start, end}) => {
        if (event.type === 'template') {
            return;
        }

        const {events} = this.state;
        const updatedEvents = events.map(existingEvent => {
            return existingEvent.id === event.id
                ? {...existingEvent, start, end}
                : existingEvent;
        });
        this.setState({events: updatedEvents});
        this.updatePostDate(event.id, start);
    };

    updatePostDate = async (id, postDate) => {
        try {
            await ContentService.updatePostDate(id, moment(postDate).format('YYYY-MM-DDTHH:mm:ss'));
            this.addSnackbar('Content updated successfully', 'success');
        } catch (error) {
            this.addSnackbar('Failed to update content', 'error');
        }
    }

    eventPropGetter = (event) => {
        let newStyle = {
            backgroundColor: '#595ce2',
            color: 'white',
            borderRadius: '8px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '4px 8px',
            margin: '2px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
        };

        if (event.type === 'template') {
            newStyle.backgroundColor = '#ffc899';
            newStyle.color = 'white';
        }

        return {
            className: "",
            style: newStyle
        };
    };

    handleContextMenuOptionClick = () => {
        const {eventId} = this.state.contextMenu;
        const event = this.state.events.find(event => event.id === eventId);

        if (event.type === 'content') {
            this.handleViewOptionClick();
        } else if (event.type === 'template') {
            const platform = this.props.campaign.contentType.platforms.find(p => p.name === event.platform);
            if (!platform) return null;

            const template = platform.templates.find(t => t.name === event.template);
            const templateId = template ? template.id : null;

            this.props.navigate(`/template/${templateId}?campaignId=${this.props.campaign.id}&postDate=${moment(event.start).format('YYYY-MM-DDTHH:mm:ss')}`);
        }
    }

    render() {
        const {events, loading, view, contextMenu, date} = this.state;

        if (loading) {
            return (
                <Box sx={{padding: 4}}>
                    <Skeleton variant="text" width="60%" height={40}/>
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{marginTop: 2}}/>
                </Box>
            );
        }

        return (
            <Box>
                <DragAndDropCalendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: 700}}
                    view={view}
                    date={date}
                    onView={this.handleViewChange}
                    onNavigate={this.handleNavigate}
                    views={['month', 'week', 'day']}
                    components={{
                        event: this.eventRenderer,
                    }}
                    onEventDrop={this.onEventDrop}
                    resizable={false}
                    eventPropGetter={this.eventPropGetter}
                    formats={{
                        timeGutterFormat: 'HH:mm',
                        eventTimeRangeFormat: ({start, end}, culture, localizer) =>
                            `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
                        agendaTimeRangeFormat: ({start, end}, culture, localizer) =>
                            `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
                    }}
                    scrollToTime={new Date(1970, 1, 1, 6, 0, 0)} // Scroll to 06:00 on page load
                />
                {contextMenu.visible && (
                    <Menu
                        anchorEl={this.state.contextMenuAnchorEl}
                        open={contextMenu.visible}
                        onClose={this.handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={{top: contextMenu.y, left: contextMenu.x}}
                    >
                        {this.state.events.find(event => event.id === contextMenu.eventId)?.type === 'content' ? (
                            <MenuItem onClick={this.handleContextMenuOptionClick}>
                                <VisibilityIcon style={{ marginRight: 8 }} />
                                View
                            </MenuItem>
                        ) : (
                            <MenuItem onClick={this.handleContextMenuOptionClick}>
                                <AddIcon style={{ marginRight: 8 }} />
                                Create
                            </MenuItem>
                        )}
                    </Menu>
                )}
                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{bottom: `${index * 60 + 20}px`}}
                    />
                ))}
            </Box>
        );
    }
}

export default CalendarComponent;