import config from '../../config';

class PasswordResetService {

  async forgotPassword(email) {
    try {
      const response = await fetch(`${config.apiUrl}/forgot-password?${new URLSearchParams({email: email})}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const error = await response.json();
        return error;
      }

      return response;
    } catch (error) {
      console.error('Forgot password error:', error);
      throw error;
    }
  }

  async resetPassword(token, newPassword) {
    try {
      const response = await fetch(`${config.apiUrl}/reset-password?${new URLSearchParams({token: token, newPassword: newPassword})}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const error = await response.json();
        return error;
      }

      return response;
    } catch (error) {
      console.error('Forgot password error:', error);
      throw error;
    }
  }
}
  
export default new PasswordResetService();
