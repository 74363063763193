import React from 'react';
import { useMediaQuery } from '@mui/material';

const withMediaQuery = (WrappedComponent) => {
    return (props) => {
        const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
        return <WrappedComponent {...props} isMobile={isMobile} />;
    };
};

export default withMediaQuery;