import ApiCalls from './util/ApiCalls';

class CampaignService {
  async addCampaign(campaignDto) {
    return ApiCalls.defaultPost("/campaigns/create", {}, campaignDto);
  }

  async updateCampaign(campaignId, campaignDto) {
    return ApiCalls.defaultPost("/campaigns/" + campaignId + "/update", {}, campaignDto);
  }

  async updateTutorialShown(campaignId) {
    return ApiCalls.defaultPost("/campaigns/" + campaignId + "/update-tutorial-shown", {});
  }

  async deleteCampaigns(campaignIds) {
    return ApiCalls.defaultPost("/campaigns/delete", {}, campaignIds);
}

  async getCampaign(campaignId) {
    return ApiCalls.defaultGet("/campaigns/" + campaignId, {});
  }

  async getCampaignPlan(campaignId) {
    return ApiCalls.defaultGet("/campaigns/" + campaignId + "/plan", {});
  }

  async getForBrand(queryParams) {
    return ApiCalls.defaultGet("/campaigns", queryParams);
  }

  async getForDashboard(queryParams) {
    return ApiCalls.defaultGet("/campaigns/dashboard", queryParams);
  }

  async delete(campaignId) {
    return ApiCalls.defaultDelete("/campaigns/" + campaignId, {});
  }

  async generatePostingPlan(campaignId) {
    return ApiCalls.defaultPost("/campaigns/" + campaignId + "/generate-posting-plan", {});
  }

  async getPostingPlan(campaignId) {
    return ApiCalls.defaultGet("/campaigns/" + campaignId + "/posting-plan", {});
  }
}

export default new CampaignService();