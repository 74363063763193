import config from '../../config';

class JwtService {
    decodeJwt(jwt) {
        const [header, payload, signature] = jwt.split('.');

        const decodedPayload = atob(payload);
        const payloadObject = JSON.parse(decodedPayload);
    
        return {
            payload: payloadObject,
        };
    }
}
  
export default new JwtService();