import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';
import RefundPolicy from './RefundPolicy';
import CookiePolicy from './CookiePolicy';
import AcceptableUsePolicy from './AcceptableUsePolicy';
import SupportPolicy from './SupportPolicy';
import DataProcessingAgreement from './DataProcessingAgreement';
import { Box, List, ListItemButton, ListItemText, Divider, Button } from '@mui/material';
import getTheme from '../getTheme';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme(getTheme());

const Policies = () => {
    const navigate = useNavigate();
    const [selectedPolicy, setSelectedPolicy] = useState('terms');

    const renderPolicy = () => {
        switch (selectedPolicy) {
            case 'terms':
                return <Terms />;
            case 'privacy':
                return <PrivacyPolicy />;
            case 'refund':
                return <RefundPolicy />;
            case 'cookie':
                return <CookiePolicy />;
            case 'acceptableUse':
                return <AcceptableUsePolicy />;
            case 'support':
                return <SupportPolicy />;
            case 'dataProcessing':
                return <DataProcessingAgreement />;
            default:
                return <Terms />;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box display="flex">
                <Box width="20%" bgcolor="primary.light" p={2} sx={{ padding: 0, color: 'white', position: 'sticky', top: 0, height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto' }}>
                    <List component="nav">
                        <ListItemButton selected={selectedPolicy === 'terms'} onClick={() => setSelectedPolicy('terms')} sx={{ '&:hover': { bgcolor: 'primary.dark' } }}>
                            <ListItemText primary="Terms and Conditions" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton selected={selectedPolicy === 'privacy'} onClick={() => setSelectedPolicy('privacy')} sx={{ '&:hover': { bgcolor: 'primary.dark' } }}>
                            <ListItemText primary="Privacy Policy" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton selected={selectedPolicy === 'refund'} onClick={() => setSelectedPolicy('refund')} sx={{ '&:hover': { bgcolor: 'primary.dark' } }}>
                            <ListItemText primary="Refund Policy" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton selected={selectedPolicy === 'cookie'} onClick={() => setSelectedPolicy('cookie')} sx={{ '&:hover': { bgcolor: 'primary.dark' } }}>
                            <ListItemText primary="Cookie Policy" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton selected={selectedPolicy === 'acceptableUse'} onClick={() => setSelectedPolicy('acceptableUse')} sx={{ '&:hover': { bgcolor: 'primary.dark' } }}>
                            <ListItemText primary="Acceptable Use Policy" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton selected={selectedPolicy === 'support'} onClick={() => setSelectedPolicy('support')} sx={{ '&:hover': { bgcolor: 'primary.dark' } }}>
                            <ListItemText primary="Support Policy" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton selected={selectedPolicy === 'dataProcessing'} onClick={() => setSelectedPolicy('dataProcessing')} sx={{ '&:hover': { bgcolor: 'primary.dark' } }}>
                            <ListItemText primary="Data Processing Agreement" />
                        </ListItemButton>
                    </List>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashboard')}>
                        Back to the app
                    </Button>
                </Box>
                <Box width="80%" p={2}>
                    {renderPolicy()}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Policies;