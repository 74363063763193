import ApiCalls from './util/ApiCalls';

class CompanyService {
    async getAll() {
      return ApiCalls.defaultGet("/users/get-all-for-company", {});
    }

    async getAuthenticatedUserInfo() {
        return ApiCalls.defaultGet("/users/get-authenticated", {});
      }  

    async createUser(simpleUserDto) {
        return ApiCalls.defaultPost("/users/create", {}, simpleUserDto);
    }

    async getAllRoles() {
        return ApiCalls.defaultGet("/users/get-all-roles", {});
    }

    async getAvailableSeats() {
        return ApiCalls.defaultGet("/users/get-available-seats", {});
    }

    async updateUserRole(userRoleDto) {
        return ApiCalls.defaultPost("/users/update-role", {}, userRoleDto);
    }

    async deleteUsers(userIds) {
        return ApiCalls.defaultPost("/users/delete", {}, userIds);
    }

    async changePassword(changePasswordDto) {
        return ApiCalls.defaultPost("/change-password", {}, changePasswordDto);
    }

    async updateSelf(userDto) {
        return ApiCalls.defaultPost("/users/update-self", {}, userDto);
    }
}
  
export default new CompanyService();