import React, { Component } from 'react';
import { Box, Card, CardContent, Typography, Skeleton, Grid } from '@mui/material';
import { withRouter } from '../../services/security/withRouter';
import ContentService from '../../services/ContentService';

class ContentGeneratedSpeeches extends Component {
    state = {
        speeches: [],
        loading: true,
        error: null,
    };

    async componentDidMount() {
        const { contentId } = this.props;
        try {
            const speeches = await ContentService.getSpeeches(contentId);
            this.setState({ speeches, loading: false });
        } catch (error) {
            this.setState({ error: error.message, loading: false });
        }
    }

    handleCardClick = (speechId) => {
        const { contentId } = this.props;
        this.props.navigate(`/speech?id=${speechId}&contentId=${contentId}`);
    };

    render() {
        const { speeches, loading, error } = this.state;

        if (loading) {
            return (
                <Grid container spacing={2}>
                    {[1, 2, 3].map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card>
                                <CardContent>
                                    <Skeleton variant="rectangular" width="100%" height={118} />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            );
        }

        if (error) {
            return <Typography color="error">{error}</Typography>;
        }

        if (speeches.length === 0) {
            return <Typography variant="body1" color="textSecondary" sx={{ padding: 4 }}>No speeches generated yet.</Typography>;
        }

        return (
            <Box sx={{ padding: 4 }}>
                <Grid container spacing={2}>
                    {speeches.map((entry) => (
                        <Grid item xs={12} sm={6} md={4} key={entry.id}>
                            <Card onClick={() => this.handleCardClick(entry.id)} sx={{ cursor: 'pointer' }}>
                                <CardContent>
                                    <Typography variant="body1" sx={{ padding: 4 }}>{entry.prompt}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }
}

export default withRouter(ContentGeneratedSpeeches);