import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {

        return (
            <Container
                id="create-brand"
                sx={{
                    pt: { xs: 4, sm: 12 },
                    pb: { xs: 8, sm: 16 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >

                <Box
                    sx={{
                        width: { sm: '100%', md: '70%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h2" color="text.primary">
                        Build Your Ideal Brand with Brand Bounce!
                    </Typography> < br />
                    <Typography component="h2" variant="h4" color="text.primary">
                        Provide some details, and let AI craft the perfect brand identity tailored to your business.
                    </Typography>
                </Box>
            </Container>
        );
    }
}

export default Intro;