import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, Typography } from '@mui/material';

const AudienceSelector = ({ audiences, selectedAudience, onAudienceChange, disabled }) => {
  useEffect(() => {
    if (audiences.length >= 1 && selectedAudience !== audiences[0].id) {
      onAudienceChange({ target: { value: audiences[0].id } });
    }
  }, [audiences, selectedAudience, onAudienceChange]);

  return (
    <FormControl fullWidth sx={{ marginBottom: 1 }} size="small">
      <InputLabel>Select Audience</InputLabel>
      <Select value={selectedAudience} onChange={onAudienceChange} label="Audience" disabled={!!disabled}>
        {audiences.map(audience => (
          <MenuItem key={audience.id} value={audience.id}>
            <Box>
              {`${audience.location.join(', ')} - ${audience.ageRange}`}
              <Typography variant="body2" color="textSecondary">
                {audience.interests.join(', ')}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AudienceSelector;