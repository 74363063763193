import React from 'react';
import { Box, Typography, TextField } from '@mui/material';

const AssigneeSelect = ({ users, assignee, handleAssigneeChange }) => {
    if (users.length <= 1) return null;

    const selectedAssigneeId = assignee ? assignee.id : '';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ marginRight: 2 }}>
                Select Assignee:
            </Typography>
            <TextField
                select
                value={selectedAssigneeId}
                onChange={handleAssigneeChange}
                size="small"
                sx={{ minWidth: 200, '& .MuiOutlinedInput-root': { borderRadius: 2, backgroundColor: '#fff' } }}
                SelectProps={{
                    native: true,
                    sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #ccc',
                        },
                    },
                }}
            >
                <option value="" disabled>
                    Select an assignee
                </option>
                {users.map((user) => (
                    <option key={user.id} value={user.id}>
                        {user.firstName} {user.lastName}
                    </option>
                ))}
            </TextField>
        </Box>
    );
};

export default AssigneeSelect;