import React, { Component } from 'react';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Chip from '@mui/material/Chip';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { withRouter } from '../../services/security/withRouter';
import TagIcon from '@mui/icons-material/Tag';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import CampaignIcon from '@mui/icons-material/Campaign';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import RightsService from "../../services/security/RightsService";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined'
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& .MuiListItemIcon-root': {
            color: theme.palette.common.white,
        },
        '& .MuiListItemText-primary': {
            color: theme.palette.common.white,
        },
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        '& .MuiListItemIcon-root': {
            color: theme.palette.common.white,
        },
        '& .MuiListItemText-primary': {
            color: theme.palette.common.white,
        },
    },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    '& .MuiChip-root': {
        padding: theme.spacing(0.5, 2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

class MenuItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: this.getSelectedItemFromPath(this.props.location.pathname),
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ selectedItem: this.getSelectedItemFromPath(this.props.location.pathname) });
        }
    }

    getSelectedItemFromPath(pathname) {
        if (pathname === '/dashboard') {
            return 'Dashboard';
        } else if (pathname.startsWith('/brand') || pathname === '/create-brand') {
            return 'Brand';
        } else if (pathname.startsWith('/campaigns')) {
            return 'Campaigns';
        } else if (pathname.startsWith('/journeys')) {
            return 'Journeys';
        } else if (pathname === '/brands') {
            return 'Brand';
        } else if (pathname === '/reports') {
            return 'Reports';
        } else if (pathname === '/settings') {
            return 'Settings';
        } else if (pathname === '/users') {
            return 'Users';
        } else if (pathname === '/calendar') {
            return 'CalendarPlanner';
        } else if (pathname === '/roles') {
            return 'Roles';
        } else if (pathname === '/contact') {
            return 'Contact';
        } else if (pathname.startsWith('/content') || pathname.startsWith('/template/')) {
            return 'Content';
        } else if (pathname.startsWith('/image')) {
            return 'Images';
        } else if (pathname.startsWith('/speech')) {
            return 'Speeches';
        } else if (pathname.startsWith('/affiliate')) {
            return 'Affiliate';
        } else {
            return 'Dashboard';
        }
    }

    handleNavigation = (path, item) => {
        this.setState({ selectedItem: item }, () => {
            this.props.navigate(path);
            localStorage.setItem('selectedItem', item);
        });
    };

    hasUsersEditRight = () => {
        return RightsService.hasRight('USERS_EDIT');
    };

    render() {
        return (
            <Box sx={{ overflow: this.props.open ? 'auto' : 'hidden' }}>
                <List>

                    <ListItem key={'Dashboard'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/dashboard', 'Dashboard')}
                            selected={this.state.selectedItem === 'Dashboard'}
                        >
                            <Tooltip title="Dashboard" arrow>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Dashboard'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>

                    <ListItem key={'Journeys'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/journeys', 'Journeys')}
                            selected={this.state.selectedItem === 'Journeys'}
                        >
                            <Tooltip title="Journeys" arrow>
                                <ListItemIcon>
                                    <TrendingUpOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Journeys'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>

                    <ListItem key={'Campaigns'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/campaigns', 'Campaigns')}
                            selected={this.state.selectedItem === 'Campaigns'}
                        >
                            <Tooltip title="Campaigns" arrow>
                                <ListItemIcon>
                                    <CampaignIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Campaigns'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>


                    <ListItem key={'Content'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/content', 'Content')}
                            selected={this.state.selectedItem === 'Content'}
                        >
                            <Tooltip title="Content" arrow>
                                <ListItemIcon>
                                    <FolderCopyOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Content'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>

                    <ListItem key={'CalendarPlanner'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/calendar', 'CalendarPlanner')}
                            selected={this.state.selectedItem === 'CalendarPlanner'}
                        >
                            <Tooltip title="Calendar Planner" arrow>
                                <ListItemIcon>
                                    <CalendarMonthOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Calendar Planner'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>

                    <ListItem key={'Images'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/images', 'Images')}
                            selected={this.state.selectedItem === 'Images'}
                        >
                            <Tooltip title="Images" arrow>
                                <ListItemIcon>
                                    <PhotoOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Images'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>

                    <ListItem key={'Speeches'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/speeches', 'Speeches')}
                            selected={this.state.selectedItem === 'Speeches'}
                        >
                            <Tooltip title="Speeches" arrow>
                                <ListItemIcon>
                                    <MicNoneOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Speeches'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>

                    <ListItem key={'Reports'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/reports', 'Reports')}
                            selected={this.state.selectedItem === 'Reports'}
                        >
                            <Tooltip title="Reports" arrow>
                                <ListItemIcon>
                                    <AssessmentOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Reports'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>

                </List>
                {this.props.open && (
                    <StyledDivider>
                        <Chip label="Settings" size="small" />
                    </StyledDivider>
                )}
                <List>
                    <ListItem key={'Brand'} disablePadding>
                        <StyledListItemButton
                            onClick={() => {
                                const targetUrl = this.props.selectedBrand.id !== 0 ? `/brand/${this.props.selectedBrand.id}` : '/brands';
                                this.handleNavigation(targetUrl, 'Brand');
                            }}
                            selected={this.state.selectedItem === 'Brand'}
                        >
                            <Tooltip title="Brand" arrow>
                                <ListItemIcon>
                                    <TagIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Brand'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>
                    {this.hasUsersEditRight() && (
                        <>
                            <ListItem key={'Users'} disablePadding>
                                <StyledListItemButton
                                    onClick={() => this.handleNavigation('/users', 'Users')}
                                    selected={this.state.selectedItem === 'Users'}
                                >
                                    <Tooltip title="Users" arrow>
                                        <ListItemIcon>
                                            <GroupOutlinedIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    {this.props.open && (
                                        <ListItemText primary={'Users'} />
                                    )}
                                </StyledListItemButton>
                            </ListItem>

                            {/*<ListItem key={'Roles'} disablePadding>*/}
                            {/*    <StyledListItemButton*/}
                            {/*        onClick={() => this.handleNavigation('/roles', 'Roles')}*/}
                            {/*        selected={this.state.selectedItem === 'Roles'}*/}
                            {/*    >*/}
                            {/*        <Tooltip title="Roles and Rights" arrow>*/}
                            {/*            <ListItemIcon>*/}
                            {/*                <AdminPanelSettingsIcon />*/}
                            {/*            </ListItemIcon>*/}
                            {/*        </Tooltip>*/}
                            {/*        {this.props.open && (*/}
                            {/*            <ListItemText primary={'Roles and Rights'} />*/}
                            {/*        )}*/}
                            {/*    </StyledListItemButton>*/}
                            {/*</ListItem>*/}
                        </>
                    )}
                    <ListItem key={'Contact'} disablePadding>
                        <StyledListItemButton
                            onClick={() => this.handleNavigation('/contact', 'Contact')}
                            selected={this.state.selectedItem === 'Contact'}
                        >
                            <Tooltip title="Contact" arrow>
                                <ListItemIcon>
                                    <ContactPageOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Contact'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>

                     {/*todo: uncomment this when starting to use affiliates*/}
                    {/*{this.props.authenticatedUser.primary && <ListItem key={'Affiliate'} disablePadding>*/}
                    {/*    <StyledListItemButton*/}
                    {/*        onClick={() => this.handleNavigation('/affiliate', 'Affiliate')}*/}
                    {/*        selected={this.state.selectedItem === 'Affiliate'}*/}
                    {/*    >*/}
                    {/*        <Tooltip title="Affiliate" arrow>*/}
                    {/*            <ListItemIcon>*/}
                    {/*                <LoyaltyOutlinedIcon />*/}
                    {/*            </ListItemIcon>*/}
                    {/*        </Tooltip>*/}
                    {/*        {this.props.open && (*/}
                    {/*            <ListItemText primary={'Affiliate'} />*/}
                    {/*        )}*/}
                    {/*    </StyledListItemButton>*/}
                    {/*</ListItem>}*/}
                    <ListItem key={'AppGuides'} disablePadding>
                        <StyledListItemButton
                            onClick={() => window.open('/app-guides', '_blank')}
                            selected={this.state.selectedItem === 'AppGuides'}
                        >
                            <Tooltip title="App guides" arrow>
                                <ListItemIcon>
                                    <LibraryBooksOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'AppGuides'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>
                    <ListItem key={'Policies'} disablePadding>
                        <StyledListItemButton
                            onClick={() => window.open('/policies', '_blank')}
                            selected={this.state.selectedItem === 'Policies'}
                        >
                            <Tooltip title="Policies" arrow>
                                <ListItemIcon>
                                    <PolicyOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            {this.props.open && (
                                <ListItemText primary={'Policies'} />
                            )}
                        </StyledListItemButton>
                    </ListItem>
                </List>
            </Box>
        );
    }
}

export default withRouter(MenuItems);