import React, {Component} from 'react';
import {Typography} from '@mui/material';
import {withRouter} from '../../services/security/withRouter';
import CalendarComponent from '../CalendarComponent';

class CalendarPlanner extends Component {
    render() {
        const {selectedBrand, navigate} = this.props;

        return (
            <>
                <Typography variant="h4" sx={{padding: 2, color: '#000'}}>
                    Calendar Planner
                </Typography>
                {selectedBrand && selectedBrand.id ? (
                    <CalendarComponent selectedBrand={selectedBrand} navigate={navigate}/>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        To continue, please select a brand from the navigation bar.
                    </Typography>
                )}
            </>
        );
    }
}

export default withRouter(CalendarPlanner);