import React, {Component} from 'react';
import {Box, Button, Card, CardContent, Fab, Grid, Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {withRouter} from '../../services/security/withRouter';
import CustomSnackbar from '../shared/CustomSnackbar';
import ImageService from '../../services/ImageService';
import moment from 'moment';
import PlusIcon from "@mui/icons-material/Add";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

class Images extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            imagesGenerated: 0,
            page: 0,
            pageSize: 10,
            sort: 'createdAt',
            order: 'desc',
            totalNumberOfItems: 0,
            snackbarOpen: false,
            snackbarSeverity: 'success',
            snackbarMessage: '',
        };
    }

    componentDidMount() {
        this.fetchImages();
    }

    componentDidUpdate(prevProps, prevState) {
        const {selectedBrand} = this.props;

        if (selectedBrand && selectedBrand.id !== prevProps.selectedBrand.id) {
            this.fetchImages();
        }
    }

    fetchImages = async () => {
        const {page, pageSize, sort, order} = this.state;
        const {selectedBrand} = this.props;

        if (!selectedBrand || selectedBrand.id === 0) return;

        const queryParams = {
            page,
            size: pageSize,
            sort: sort,
            order: order,
            brandId: selectedBrand.id,
        };

        try {
            const response = await ImageService.getForBrand(queryParams);
            this.setState({
                images: response.content,
                totalNumberOfItems: response.totalNumberOfItems,
            });
        } catch (error) {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
            });
        }
    };

    handlePaginationModelChange = (paginationModel) => {
        const {page, pageSize} = paginationModel;
        this.setState({page, pageSize}, this.fetchImages);
    };

    handleSortModelChange = (sortModel) => {
        if (sortModel.length > 0) {
            const {field, sort} = sortModel[0];
            this.setState({sort: field, order: sort}, this.fetchImages);
        }
    };

    handleViewClick = (id) => {
        const image = this.state.images.find(image => image.id === id);
        const contentId = image ? image.contentId : null;
        this.props.navigate(`/image?id=${id}${contentId ? `&contentId=${contentId}` : ''}`);
    };

    handleCreateClick = () => {
        this.props.navigate('/image');
    };

    handleSnackbarClose = () => {
        this.setState({snackbarOpen: false});
    };

    render() {
        const {
            images,
            page,
            pageSize,
            totalNumberOfItems,
            snackbarOpen,
            snackbarSeverity,
            snackbarMessage
        } = this.state;
        const {selectedBrand, isMobile, currentPlan} = this.props;

        if (!selectedBrand || selectedBrand.id === 0) {
            return (
                <Box sx={{ padding: 4}}>
                    <Typography variant="h4" gutterBottom>
                        Images
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        To continue, please select a brand from the navigation bar.
                    </Typography>
                </Box>
            );
        }

        const disableCreateButton = currentPlan.alreadyGeneratedImages >= currentPlan.maxImagesGenerated;

        const columns = [
            {
                field: 'prompt',
                headerName: 'Prompt',
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
            },
            {
                field: 'createdAt',
                headerName: 'Date Created',
                flex: 0.5,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                valueGetter: (params) => {
                    return moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 0.3,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: (params) => (
                    <Tooltip title="View">
                        <IconButton
                            color="primary"
                            onClick={() => this.handleViewClick(params.id)}
                        >
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                ),
            },
        ];

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography variant="h4" gutterBottom>
                                            Images
                                        </Typography>
                                    </Grid>
                                    {!isMobile && (
                                        <Grid item xs={3} sx={{textAlign: 'right'}}>
                                            <Tooltip title={disableCreateButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Add New Image"} arrow>
                                                <span>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleCreateClick}
                                                        disabled={disableCreateButton}
                                                    >
                                                        Add New Image
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                                {images.length > 0 ? (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            overflowX: 'auto',
                                            '@media (max-width: 600px)': {
                                                padding: 1,
                                            }
                                        }}
                                    >
                                        <DataGrid
                                            key={this.props.sidebarOpen}
                                            rows={images}
                                            columns={columns}
                                            pagination
                                            paginationMode="server"
                                            sortingMode="server"
                                            filterMode="server"
                                            page={page}
                                            pageSize={pageSize}
                                            rowCount={totalNumberOfItems}
                                            onPaginationModelChange={this.handlePaginationModelChange}
                                            onSortModelChange={this.handleSortModelChange}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiDataGrid-root': {
                                                    overflowX: 'auto',
                                                },
                                                minWidth: 600,
                                                marginTop: '10px',
                                            }}
                                            pageSizeOptions={[10, 5, 3]}
                                            disableRowSelectionOnClick
                                        />
                                    </Box>
                                ) : (
                                    <Box sx={{}}>
                                        <Typography color="textPrimary">
                                            There are currently no images available for <strong>{selectedBrand.name}</strong>.
                                        </Typography>
                                    </Box>
                                )}
                            </CardContent>
                    </Grid>
                </Grid>

                <CustomSnackbar
                    snackbarOpen={snackbarOpen}
                    handleSnackbarClose={this.handleSnackbarClose}
                    severity={snackbarSeverity}
                    snackbarMessage={snackbarMessage}
                />
                {isMobile && (
                    <Tooltip title={disableCreateButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Add New Image"} arrow>
                        <span>
                            <Fab
                                color="primary"
                                aria-label="add"
                                onClick={this.handleCreateClick}
                                disabled={disableCreateButton}
                                sx={{
                                    position: 'fixed',
                                    bottom: 16,
                                    right: 16,
                                }}
                            >
                                <PlusIcon/>
                            </Fab>
                        </span>
                    </Tooltip>
                )}
            </>
        );
    }
}

export default withRouter(Images);