import React, { Component } from 'react';
import {
    Box,
    Grid,
    LinearProgress,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Avatar,
    Tabs,
    Tab,
    Skeleton
} from '@mui/material';
import { withRouter } from '../../services/security/withRouter';
import JourneyService from "../../services/journey/JourneyService";
import JourneyActionItems from "./JourneyActionItems";
import ArchivedJourneyActionItems from "./ArchivedJourneyActionItems";
import JourneyActions from "./JourneyActions";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { styled } from '@mui/system';

const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 10,
    backgroundColor: theme.palette.grey[300],
    '& .MuiLinearProgress-bar': {
        borderRadius: 10,
        background: 'linear-gradient(to right, #secondary.light, secondary.main)',
    },
}));

class Journey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            journey: null,
            loading: false,
            error: null,
            tabIndex: 0,
        };
    }

    componentDidMount() {
        const { id } = this.props.params;
        if (id) {
            this.fetchJourney(id);
        }
    }

    updateJourneyProgress = (numberOfCompletedActionItems) => {
        const progress = ((numberOfCompletedActionItems / this.state.journey.numberOfActionItems) * 100).toFixed(2);
        this.setState((prevState) => ({
            journey: {
                ...prevState.journey,
                numberOfCompletedActionItems,
                progress,
            },
        }));
    };

    fetchJourney = async (id) => {
        this.setState({ loading: true });
        try {
            const response = await JourneyService.getOne(id);
            this.setState({ journey: response, loading: false });
        } catch (error) {
            console.error('Failed to fetch journey:', error);
            this.setState({ error: 'Unable to retrieve journey information.', loading: false });
        }
    };

    handleTabChange = (event, newValue) => {
        this.setState({ tabIndex: newValue });
    };

    render() {
        const { journey, loading, error, tabIndex } = this.state;

        if (loading) {
            return (
                <Box sx={{ padding: 4, display: 'flex', justifyContent: 'center' }}>
                    <Card sx={{ width: '100%' }}>
                        <CardHeader
                            avatar={
                                <Skeleton variant="circular" width={40} height={40} />
                            }
                            title={<Skeleton variant="text" width="60%" />}
                        />
                        <CardContent>
                            <Skeleton variant="rectangular" height={10} sx={{ marginBottom: 2 }} />
                            <Skeleton variant="rectangular" height={10} sx={{ marginBottom: 2 }} />
                            <Skeleton variant="rectangular" height={10} sx={{ marginBottom: 2 }} />
                        </CardContent>
                    </Card>
                </Box>
            );
        }

        if (error) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Typography variant="h6" color="error">{error}</Typography>
                </Box>
            );
        }

        return (
            <>
                {journey ?
                    <Card sx={{ width: '100%', boxShadow: 'none'}}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: 'primary.main', boxShadow: 'none' }}>
                                    <EmojiEventsIcon />
                                </Avatar>
                            }
                            title={<Typography variant="h4">{journey.name}</Typography>}
                        />
                        <CardContent>
                            <Box sx={{ marginBottom: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="h6" sx={{ color: 'primary.main' }}>
                                            Journey Progress
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                        <GradientLinearProgress variant="determinate" value={journey.progress} />
                                        <Typography variant="body2" sx={{ color: 'secondary.main' }}>{journey.progress}%
                                            Completed</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Tabs value={tabIndex} onChange={this.handleTabChange} indicatorColor="primary"
                                  textColor="primary">
                                <Tab label="Journey" />
                                <Tab label="History" />
                                <Tab label="Actions" />
                            </Tabs>
                            {tabIndex === 0 &&
                                <JourneyActionItems journey={journey}
                                                    fetchJourney={this.fetchJourney}
                                                    authenticatedUser={this.props.authenticatedUser}
                                                    updateJourneyProgress={this.updateJourneyProgress}
                                />
                            }
                            {tabIndex === 1 && <ArchivedJourneyActionItems journeyId={journey.id} />}
                            {tabIndex === 2 && <JourneyActions journeyId={journey.id} navigate={this.props.navigate} />}
                        </CardContent>
                    </Card> : <Typography variant="h6">No data available for this journey.</Typography>
                }
            </>
        );
    }
}

export default withRouter(Journey);