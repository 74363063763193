import ApiCalls from './util/ApiCalls';

class CommentService {
  async addComment(commentDto) {
    return ApiCalls.defaultPost("/comment/create", {}, commentDto);
  }

  async addCommentForBrandJourney(commentDto) {
    return ApiCalls.defaultPost("/comment/create/brand-journey", {}, commentDto);
  }

  async updateComment(commentDto) {
    return ApiCalls.defaultPost("/comment/update", {}, commentDto);
  }

  async delete(commentId) {
    return ApiCalls.defaultDelete("/comment/" + commentId, {});
  }
}

export default new CommentService();