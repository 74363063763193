import {
    Tag as TagIcon,
    CreditCardOutlined as CreditCardOutlinedIcon,
    AlternateEmailOutlined as AlternateEmailOutlinedIcon,
    StoreOutlined as StoreOutlinedIcon,
    WysiwygOutlined as WysiwygOutlinedIcon,
    WebOutlined as WebOutlinedIcon,
    Facebook as FacebookIcon,
    Instagram as InstagramIcon,
    LinkedIn as LinkedInIcon,
    YouTube as YouTubeIcon,
    Google as GoogleIcon,
    Pinterest as PinterestIcon,
    OndemandVideo as OndemandVideoIcon,
    FileCopyOutlined as FileCopyOutlinedIcon,
    X as XIcon
} from '@mui/icons-material';
import { SiTiktok } from 'react-icons/si';

export const renderIcon = (name, sx) => {
    const mapping = {
        'social media': TagIcon,
        advertising: CreditCardOutlinedIcon,
        'e-mail': AlternateEmailOutlinedIcon,
        'e-commerce': StoreOutlinedIcon,
        blog: WysiwygOutlinedIcon,
        website: WebOutlinedIcon,
        facebook: FacebookIcon,
        instagram: InstagramIcon,
        linkedin: LinkedInIcon,
        youtube: YouTubeIcon,
        google: GoogleIcon,
        pinterest: PinterestIcon,
        email: AlternateEmailOutlinedIcon,
        video: OndemandVideoIcon,
        x: XIcon,
        tiktok: SiTiktok,
        default: FileCopyOutlinedIcon
    };

    const IconComponent = Object.entries(mapping).find(([key]) =>
        name.toLowerCase().includes(key)
    )?.[1] || mapping.default;

    const iconProps = IconComponent === SiTiktok ? { style: { color: '#595ce2', ...sx } } : { sx: { color: 'primary.main', ...sx } };

    return <IconComponent {...iconProps} />;
};