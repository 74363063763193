import React, {Component} from 'react';
import {Box, Typography, Skeleton, Grid, Button, CircularProgress, Tab, Tabs} from '@mui/material';
import {withRouter} from '../../services/security/withRouter';
import {EditorState, convertFromRaw, convertToRaw, ContentState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import ContentService from '../../services/ContentService';
import TextEditor from './components/TextEditor';
import AiService from '../../services/AiService';
import {convertFromHTML} from 'draft-js';
import CustomSnackbar from '../shared/CustomSnackbar';
import moment from 'moment';
import ConfirmDialog from '../shared/ConfirmDialog';
import UserService from '../../services/UserService';
import AssigneeSelect from './components/AssigneeSelect';
import CommentSection from './components/CommentSection';
import InputsGrid from './components/InputsGrid';
import StatusButton from './components/StatusButton';
import {getStatusButtonLabelAndNewStatus} from './components/utils';
import ContentGeneratedSpeeches from "../lists/ContentGeneratedSpeeches";
import ContentGeneratedImages from "../lists/ContentGeneratedImages";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import {renderIcon} from "../../services/util/iconHelper";
import MicExternalOnOutlinedIcon from '@mui/icons-material/MicExternalOnOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            loading: true,
            values: {},
            template: {},
            loadingGenerate: false,
            loadingSave: false,
            deleting: false,
            deleteDialogOpen: false,
            selectedTrend: '',
            postDate: '',
            useBrandName: false,
            loadingRephrase: false,
            tabValue: 0,
            comments: [],
            newComment: '',
            loadingComment: false,
            editingCommentId: null,
            editingCommentText: '',
            users: [],
            snackbars: [],
        };
    }

    componentDidMount() {
        const {id} = this.props.params;
        this.fetchContent(id);
        this.fetchUsers();
    }

    fetchUsers = async () => {
        try {
            const users = await UserService.getAll();
            this.setState({users});
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    }

    handleAssigneeChange = async (event) => {
        const {content} = this.state;
        const newAssigneeId = event.target.value;

        try {
            await ContentService.updateAssignee(content.id, newAssigneeId);
            const newAssignee = this.state.users.find(user => user.id === newAssigneeId);
            this.setState((prevState) => ({
                content: {
                    ...prevState.content,
                    assignee: newAssignee,
                },
            }));
            this.addSnackbar('Assignee updated successfully', 'success');
        } catch (error) {
            this.addSnackbar('Failed to update assignee. Please try again later.', 'error');
        }
    };

    componentDidUpdate(prevProps) {
        const {id} = this.props.params;
        if (id !== prevProps.params.id) {
            this.fetchContent(id);
        }
    }

    handleStatusUpdate = async (newStatus) => {
        const {content} = this.state;

        const contentDto = {
            id: content.id,
            status: newStatus,
        };

        try {
            await ContentService.updateContent(contentDto);
            this.setState((prevState) => ({
                content: {
                    ...prevState.content,
                    status: newStatus,
                },
            }));
            this.addSnackbar('Content status updated successfully', 'success');
        } catch (error) {
            this.addSnackbar('Failed to update content status. Please try again later.', 'error');
        }
    };

    handleTabChange = (event, newValue) => {
        this.setState({tabValue: newValue});
    };

    fetchContent = async (id) => {
        this.setState({loading: true});
        try {
            const content = await ContentService.getContent(id);
            const actualContent = JSON.parse(content.actualContent);
            const editorContent = EditorState.createWithContent(convertFromRaw(actualContent));

            const values = JSON.parse(content.params);
            const template = content.template;
            const selectedTrend = content.selectedTrend || '';
            const useBrandName = content.useBrandName || false;

            this.setState({
                content,
                editorContent,
                template,
                values,
                selectedTrend,
                loading: false,
                postDate: content.postDate ? new Date(content.postDate) : new Date(),
                useBrandName,
                comments: content.comments || [],
            });
        } catch (error) {
            this.addSnackbar('There was an error fetching the content. Please try again later.', 'error');

            this.setState({loading: false});
        }
    };

    handleEditorChange = (editorContent) => {
        this.setState({editorContent});
    };

    handleChange = (param) => (event) => {
        this.setState((prevState) => ({
            values: {
                ...prevState.values,
                [param]: event.target.value
            }
        }));
    };

    handleAutocompleteChange = (param, value) => {
        this.setState((prevState) => ({
            values: {
                ...prevState.values,
                [param]: value
            }
        }));
    };

    handleGenerate = async () => {
        const {values, content} = this.state;

        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        const journeyActionItemId = content.brandJourney?.journeyActionItemResponseDtoList[0]?.id

        const requestData = {
            brandId: content.brandId,
            voiceId: content.voiceId,
            audienceId: content.audienceId,
            parameters: JSON.stringify(filteredValues),
            templateId: content.template.id,
            selectedTrend: this.state.selectedTrend,
            useBrandName: this.state.useBrandName,
            language: content.language,
            journeyActionItemId: journeyActionItemId ? journeyActionItemId : null,
        };

        this.setState({loadingGenerate: true});

        try {
            const response = await AiService.generateContent(requestData);
            const htmlResponse = response.response;

            const blocksFromHTML = convertFromHTML(htmlResponse);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );

            const newEditorState = EditorState.createWithContent(contentState);
            this.setState({editorContent: newEditorState});
        } catch (error) {
            console.error('Failed to generate content:', error);
        } finally {
            this.setState({loadingGenerate: false});
        }
    };

    handleRephrase = async () => {
        const {editorContent} = this.state;

        const contentState = editorContent.getCurrentContent();
        const htmlContent = stateToHTML(contentState); // Convert content state to HTML

        this.setState({loadingRephrase: true});

        try {
            const response = await AiService.rephraseContent({content: htmlContent});
            const htmlResponse = response.response;

            const blocksFromHTML = convertFromHTML(htmlResponse);
            const newContentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );

            const newEditorState = EditorState.createWithContent(newContentState);
            this.setState({editorContent: newEditorState});
        } catch (error) {
            console.error('Failed to rephrase content:', error);
        } finally {
            this.setState({loadingRephrase: false});
        }
    };

    handleUseBrandNameChange = (event) => {
        this.setState({useBrandName: event.target.checked});
    };

    saveContent = async () => {
        const {editorContent, values, content, postDate} = this.state;

        this.setState({loadingSave: true});

        const isEmpty = !editorContent;

        if (isEmpty) {
            this.addSnackbar('Content must not be empty.', 'error');
            return;
        }

        const contentDto = {
            id: content.id,
            actualContent: JSON.stringify(convertToRaw(editorContent.getCurrentContent())),
            params: JSON.stringify(values),
            postDate: postDate ? moment(postDate).format('YYYY-MM-DDTHH:mm:ss') : null,
            selectedTrend: this.state.selectedTrend,
            useBrandName: this.state.useBrandName,
        };

        try {
            await ContentService.updateContent(contentDto);
            this.addSnackbar('The content was updated successfully.', 'success');
            this.setState({loadingSave: false});
        } catch (error) {
            this.addSnackbar('Unable to update content.', 'error');
            this.setState({loadingSave: false});
        }
    };

    handleDeleteDialogOpen = () => {
        this.setState({deleteDialogOpen: true});
    };

    handleDeleteDialogClose = () => {
        this.setState({deleteDialogOpen: false});
    };

    handleDeleteDialogConfirm = async () => {
        await this.handleDeleteContent();
    };

    handleDeleteContent = async () => {
        const {content} = this.state;
        this.setState({deleting: true});

        try {
            await ContentService.delete(content.id);
            this.props.navigate('/content');
        } catch (error) {
            this.addSnackbar('Failed to delete content.  Please try again later.', 'error');
            this.setState({deleting: false});
        }
    };

    handleTrendChange = (event) => {
        this.setState({selectedTrend: event.target.value});
    };

    handlePostDateChange = (event) => {
        this.setState({postDate: new Date(event.target.value)});
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    handleGenerateSpeech = () => {
        const {content} = this.state;
        this.props.navigate(`/speech?contentId=${content.id}`);
    };

    handleGenerateImage = () => {
        const {content} = this.state;
        this.props.navigate(`/image?contentId=${content.id}`);
    };

    render() {
        const {
            content,
            loading,
            editorContent,
            values,
            template,
            loadingGenerate,
            loadingSave,
            deleting,
            tabValue,
        } = this.state;

        if (loading) {
            return (
                <Box sx={{padding: 4}}>
                    <Skeleton variant="text" width="60%" height={40}/>
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{marginTop: 2}}/>
                </Box>
            );
        }

        const statusButton = getStatusButtonLabelAndNewStatus(content);

        return (
            <Box sx={{padding: 2}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
                    <Typography variant="h4" gutterBottom>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {content.campaign ? (
                                <span
                                    onClick={() => this.props.navigate(`/campaigns/${content.campaign.id}`)}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#595ce2',
                                    }}
                                    onMouseOver={(e) => (e.target.style.color = 'primary.light')}
                                    onMouseOut={(e) => (e.target.style.color = 'primary.dark')}
                                >
                {content.campaign.name}
            </span>
                            ) : content.brandJourney ? (
                                <span
                                    onClick={() => this.props.navigate(`/journeys/${content.brandJourney.id}`)}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#595ce2',
                                    }}
                                    onMouseOver={(e) => (e.target.style.color = 'primary.light')}
                                    onMouseOut={(e) => (e.target.style.color = 'primary.dark')}
                                >
                {content.brandJourney.name}
            </span>
                            ) : null}

                            {(content.campaign || content.brandJourney) && (
                                <span style={{margin: '0 8px', color: '#ccc'}}>|</span>
                            )}

                            {renderIcon(template.platformName, {fontSize: 30})}
                            &nbsp;

                            {template.name}
                        </Box>
                    </Typography>

                    <Box>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<MicExternalOnOutlinedIcon/>}
                            onClick={this.handleGenerateSpeech} // Add the appropriate handler
                            disabled={deleting}
                            sx={{marginRight: 1}}
                        >
                            Generate Speech
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<ImageOutlinedIcon/>}
                            onClick={this.handleGenerateImage} // Add the appropriate handler
                            disabled={deleting}
                            sx={{marginRight: 1}}
                        >
                            Generate Image
                        </Button>
                        {statusButton && (
                            <StatusButton
                                statusButton={statusButton}
                                handleStatusUpdate={() => this.handleStatusUpdate(statusButton.newStatus)}
                                disabled={deleting}
                            />
                        )}
                        <Button
                            variant="contained"
                            color="error"
                            onClick={this.handleDeleteDialogOpen}
                            disabled={deleting}
                            startIcon={<RemoveOutlinedIcon/>}
                        >
                            {deleting ? <CircularProgress size={24} color="danger" /> : 'Delete Content'}
                        </Button>
                    </Box>
                </Box>
                {content.postDate && (
                    <Typography variant="body1" color="textSecondary">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <EventIcon sx={{fontSize: 20, marginRight: 1, color: 'primary.light'}}/>
                            Post Date:<strong> {moment(content.postDate).format('MMMM Do YYYY, h:mm a')} </strong>
                        </Box>
                    </Typography>
                )}
                {content.status && (
                    <Typography variant="body1" color="textSecondary">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <InfoIcon sx={{fontSize: 20, marginRight: 1, color: 'primary.light'}}/>
                            Status: <strong> {content.status}</strong>
                        </Box>
                    </Typography>
                )}
                {this.state.users.length > 1 && (
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <PersonIcon sx={{fontSize: 20, marginRight: 1, color: 'primary.light'}}/>
                        <AssigneeSelect
                            users={this.state.users}
                            assignee={content.assignee}
                            handleAssigneeChange={this.handleAssigneeChange}
                        />
                    </Box>
                )}

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box sx={{position: 'relative'}}>
                            <TextEditor
                                initialContent={editorContent}
                                onChange={this.handleEditorChange}
                                inEdit={true}
                                loading={loadingGenerate}
                                handleGenerate={this.handleGenerate}
                                saveContent={this.saveContent}
                                loadingSave={loadingSave}
                                handleRephrase={this.handleRephrase}
                                loadingRephrase={this.state.loadingRephrase}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs value={tabValue} onChange={this.handleTabChange} aria-label="content tabs">
                            <Tab label="Comments"/>
                            <Tab label="Inputs"/>
                            <Tab label="Generated Images"/>
                            <Tab label="Generated Speeches"/>
                        </Tabs>
                        {tabValue === 0 && (
                            <CommentSection
                                comments={this.state.comments}
                                newComment={this.state.newComment}
                                authenticatedUser={this.props.authenticatedUser}
                                setComments={(comments) => this.setState({comments})}
                                setNewComment={(newComment) => this.setState({newComment})}
                                setLoadingComment={(loadingComment) => this.setState({loadingComment})}
                                setEditingCommentId={(editingCommentId) => this.setState({editingCommentId})}
                                setEditingCommentText={(editingCommentText) => this.setState({editingCommentText})}
                                contentId={this.state.content.id}
                            />
                        )}
                        {tabValue === 1 && (
                            <InputsGrid
                                template={template}
                                values={values}
                                handleChange={this.handleChange}
                                handleAutocompleteChange={this.handleAutocompleteChange}
                                postDate={this.state.postDate}
                                handlePostDateChange={this.handlePostDateChange}
                                selectedTrend={this.state.selectedTrend}
                                handleTrendChange={this.handleTrendChange}
                                useBrandName={this.state.useBrandName}
                                handleUseBrandNameChange={this.handleUseBrandNameChange}
                                content={content}
                            />
                        )}
                        {tabValue === 2 && (
                            <ContentGeneratedImages contentId={this.state.content.id}/>
                        )}
                        {tabValue === 3 && (
                            <ContentGeneratedSpeeches contentId={this.state.content.id}/>
                        )}
                    </Grid>
                </Grid>
                <ConfirmDialog
                    dialogOpen={this.state.deleteDialogOpen}
                    handleClose={this.handleDeleteDialogClose}
                    handleConfirm={this.handleDeleteDialogConfirm}
                    dialogTitle={"Delete content"}
                    dialogMessage={"Are you sure you want to delete this content?"}
                />

                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{bottom: `${index * 60 + 20}px`}}
                    />
                ))}
            </Box>
        );
    }
}

export default withRouter(Content);