import React, {Component} from 'react';
import Box from '@mui/material/Box';
import {withRouter} from '../../services/security/withRouter';
import {Card, CardContent, Grid, Skeleton, Typography, Link, Fab, Tooltip} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import PlusIcon from '@mui/icons-material/Add';
import JourneyService from '../../services/journey/JourneyService';
import {Button} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

class Journeys extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadFetch: false,
            errors: {},
            journeys: [],
            page: 0,
            pageSize: 10,
            sort: 'createdAt',
            order: 'desc',
            totalNumberOfItems: 0,
            activeJourneys: 0,
        };
    }

    componentDidMount() {
        this.fetchJourneys();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBrand.id !== prevProps.selectedBrand.id) {
            this.fetchJourneys();
        }
    }

    fetchJourneys = async () => {
        const {page, pageSize, sort, order} = this.state;
        const {selectedBrand} = this.props;

        if (!selectedBrand || selectedBrand.id === 0) return;

        this.setState({loadFetch: true});

        const queryParams = {
            page,
            size: pageSize,
            sort: sort,
            order: order,
            brandId: selectedBrand.id,
        };

        try {
            const response = await JourneyService.getForBrand(queryParams);
            this.setState({
                journeys: response.content,
                totalNumberOfItems: response.totalNumberOfItems,
                activeJourneys: response.activeJourneys,
                loadFetch: false,
            });
        } catch (error) {
            this.setState({
                loadFetch: false,
                errors: {fetch: 'Unable to load journeys. Please try again later.'},
            });
        }
    };

    handlePaginationModelChange = (paginationModel) => {
        const {page, pageSize} = paginationModel;
        this.setState({page, pageSize}, this.fetchJourneys);
    };

    handleSortModelChange = (sortModel) => {
        if (sortModel.length > 0) {
            const {field, sort} = sortModel[0];
            this.setState({sort: field, order: sort}, this.fetchJourneys);
        }
    };

    handleClickOpen = () => {
        this.props.navigate('/journeys/create');
    };

    handleViewClick = (id) => {
        this.props.navigate(`/journeys/${id}`);
    };

    render() {
        const {loadFetch, journeys, page, pageSize, totalNumberOfItems} = this.state;
        const {selectedBrand, isMobile, currentPlan} = this.props;
        const disableCreate = currentPlan?.maxJourneysPerBrand <= this.state.activeJourneys;

        if (!selectedBrand || selectedBrand.id === 0) {
            return (
                <Box sx={{padding: 4}}>
                    <Typography variant="h4" gutterBottom>
                        Marketing Journeys
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        To continue, please select a brand from the navigation bar.
                    </Typography>
                </Box>
            );
        }

        const columns = [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
            },
            {
                field: 'progress',
                headerName: 'Journey Progress',
                flex: 0.5,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => `${params.value}%`,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 0.5,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: (params) => (
                    <Tooltip title="View">
                        <IconButton
                            color="primary"
                            onClick={() => this.handleViewClick(params.id)}
                        >
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                ),
            },
        ];

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography variant="h4" gutterBottom>
                                            Journeys
                                        </Typography>
                                    </Grid>
                                    {!isMobile && (
                                        <Grid item xs={3} sx={{textAlign: 'right'}}>
                                            <Tooltip title={disableCreate ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : ""}>
                                                <span>
                                                    <LoadingButton
                                                        loadingPosition="start"
                                                        startIcon={<PlusIcon />}
                                                        onClick={this.handleClickOpen}
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ marginRight: 2 }}
                                                        disabled={disableCreate}
                                                    >
                                                        Create
                                                    </LoadingButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                                {loadFetch ? (
                                    <Skeleton variant="rectangular" width="100%" height={400}/>
                                ) : (
                                    journeys.length > 0 ? (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                overflowX: 'auto',
                                                '@media (max-width: 600px)': {
                                                    padding: 1,
                                                }
                                            }}
                                        >
                                            <DataGrid
                                                key={this.props.sidebarOpen}
                                                rows={journeys}
                                                columns={columns}
                                                pagination
                                                paginationMode="server"
                                                sortingMode="server"
                                                filterMode="server"
                                                page={page}
                                                pageSize={pageSize}
                                                rowCount={totalNumberOfItems}
                                                onPaginationModelChange={this.handlePaginationModelChange}
                                                onSortModelChange={this.handleSortModelChange}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 10,
                                                        },
                                                    },
                                                }}
                                                sx={{
                                                    '& .MuiDataGrid-root': {
                                                        overflowX: 'auto',
                                                    },
                                                    minWidth: 600,
                                                    marginTop: '10px',
                                                }}
                                                pageSizeOptions={[10]}
                                                disableRowSelectionOnClick
                                            />
                                        </Box>
                                    ) : (
                                        <Typography variant="body1" color="textSecondary">
                                            There are no journeys available at the moment.
                                        </Typography>
                                    )
                                )}
                            </CardContent>
                    </Grid>
                </Grid>
                {isMobile && (
                    <Tooltip title={disableCreate ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : ""}>
                        <span>
                            <Fab
                                color="primary"
                                aria-label="add"
                                onClick={this.handleClickOpen}
                                sx={{
                                    position: 'fixed',
                                    bottom: 16,
                                    right: 16,
                                }}
                                disabled={disableCreate}
                            >
                                <PlusIcon/>
                            </Fab>
                        </span>
                    </Tooltip>
                )}
            </>
        );
    }
}

export default withRouter(Journeys);