import ApiCalls from './util/ApiCalls';

class ContentService {
  async addContent(contentDto) {
    return ApiCalls.defaultPost("/content/create", {}, contentDto);
  }

  async updateContent(contentDto) {
    return ApiCalls.defaultPost("/content/update", {}, contentDto);
  }

  async updateAssignee(contentId, assigneeId) {
    return ApiCalls.defaultPost("/content/update-assignee", {contentId, assigneeId});
  }

  async updatePostDate(id, postDate) {
    return ApiCalls.defaultPost("/content/update/" + id + "/post-date", {postDate});
  }

  async getContent(contentId) {
    return ApiCalls.defaultGet("/content/" + contentId, {});
  }

  async getForCampaign(campaignId, queryParams) {
    return ApiCalls.defaultGet("/content/campaign/" + campaignId, queryParams);
  }

  async getForDashboard(queryParams) {
    return ApiCalls.defaultGet("/content/dashboard", queryParams);
  }

  async getForBrand(brandId, queryParams) {
    return ApiCalls.defaultGet("/content/brand/" + brandId, queryParams);
  }

  async getForCalendar(brandId, queryParams) {
    return ApiCalls.defaultGet("/content/brand/" + brandId + "/calendar", queryParams);
  }

  async delete(contentId) {
    return ApiCalls.defaultDelete("/content/" + contentId, {});
  }

  async getImages(contentId) {
    return ApiCalls.defaultGet("/content/" + contentId + "/images", {});
  }

  async getSpeeches(contentId) {
    return ApiCalls.defaultGet("/content/" + contentId + "/speeches", {});
  }

  async generatePromptForImage(contentId) {
    return ApiCalls.defaultPost("/content/" + contentId + "/image", {});
  }

    async generatePromptForSpeech(contentId) {
        return ApiCalls.defaultPost("/content/" + contentId + "/speech", {});
    }
}

export default new ContentService();