import React, {Component} from 'react';
import {Box, Tabs, Tab, Typography, CircularProgress, Button, Modal, Backdrop, Fade} from '@mui/material';
import {withRouter} from '../../services/security/withRouter';
import CalendarComponent from '../CalendarComponent';
import CampaignView from './CampaignView';
import CampaignService from '../../services/CampaignService';
import ChooseContentModal from '../create/template/ChooseContentModal';
import CampaignPlan from './CampaignPlan';
import ConfirmDialog from '../shared/ConfirmDialog';
import CustomSnackbar from '../shared/CustomSnackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

class Campaign extends Component {
    constructor(props) {
        super(props);
        const savedTab = localStorage.getItem('selectedTab');
        this.state = {
            selectedTab: savedTab ? parseInt(savedTab, 10) : 0,
            campaign: null,
            loading: true,
            error: null,
            isModalOpen: false,
            loadingGeneratePostingPlan: false,
            loadingPostingPlan: true,
            postingPlan: null,
            snackbars: [],
            tutorialOpen: false,
            tutorialStep: 0,
        };
    }

    componentDidMount() {
        const {id} = this.props.params;
        this.fetchCampaign(id);
        this.fetchPostingPlan(id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedTab !== this.state.selectedTab) {
            localStorage.setItem('selectedTab', this.state.selectedTab);
        }
    }

    fetchPostingPlan = async (id) => {
        try {
            const postingPlan = await CampaignService.getPostingPlan(id);
            this.setState({postingPlan, loadingPostingPlan: false});
        } catch (error) {
            this.setState({loadingPostingPlan: false});
        }
    };

    handleGeneratePostingPlan = () => {
        this.setState({loadingGeneratePostingPlan: true});
        CampaignService.generatePostingPlan(this.state.campaign.id).then(() => {
            this.fetchPostingPlan(this.state.campaign.id);
            this.setState(prevState => ({
                loadingGeneratePostingPlan: false,
            }));
            this.addSnackbar('Posting plan generated successfully.', 'success');
        }).catch(error => {
            this.setState({
                loadingGeneratePostingPlan: false,
            });
            this.addSnackbar('An error occurred while generating the posting plan. Please try again later.', 'error');

        });
    };

    handleCloseModal = () => {
        this.setState({isModalOpen: false});
    };

    fetchCampaign = async (id) => {
        try {
            const campaign = await CampaignService.getCampaign(id);
            this.setState({
                campaign,
                loading: false,
                tutorialOpen: !campaign.tutorialShown
            });
        } catch (error) {
            this.setState({error: 'Failed to fetch campaign', loading: false});
        }
    };

    handleTabChange = (event, newValue) => {
        this.setState({selectedTab: newValue});
    };

    handleCreateContent = () => {
        this.setState({isModalOpen: true});
    };

    handleDeleteDialogOpen = () => {
        this.setState({deleteDialogOpen: true});
    };

    handleDeleteDialogClose = () => {
        this.setState({deleteDialogOpen: false});
    };

    handleDeleteDialogConfirm = () => {
        const removeId = this.state.campaign.id;
        CampaignService.delete(removeId).then(() => {
            this.addSnackbar('The campaign was deleted successfully.', 'success');

            this.props.navigate('/campaigns');
        }).catch(error => {
            this.addSnackbar('There was an error deleting the campaign. Please try again later.', 'error');
        });

        this.handleDeleteDialogClose();
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    handleCloseTutorial = () => {
        this.setState({tutorialOpen: false});
        CampaignService.updateTutorialShown(this.state.campaign.id);
    };

    handleNextTutorialStep = () => {
        this.setState((prevState) => {
            if (prevState.tutorialStep === 4) {
                this.handleCloseTutorial();
                return;
            }
            return {tutorialStep: prevState.tutorialStep + 1};
        });
    };

    renderTutorialContent = () => {
        const steps = [
            "Welcome to the Campaign Page. Here you can manage your campaigns, view insights, and create content.",
            "Use the tabs to switch between different views.",
            "Click 'Generate Posting Plan' to create a posting plan for your campaign.",
            "Click 'Create Campaign Content' to add new content to your campaign.",
            "Use the 'Delete Campaign' button to remove a campaign."
        ];

        return (
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 4
            }}>
                <Typography variant="h6" component="h2">
                    Tutorial
                </Typography>
                <Typography sx={{mt: 2}}>
                    {steps[this.state.tutorialStep]}
                </Typography>
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={this.handleCloseTutorial} variant="contained" color="danger">
                        Skip
                    </Button>
                    <Button onClick={this.handleNextTutorialStep} variant="contained" color="primary">
                        {this.state.tutorialStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </Box>
        );
    };

    renderTabContent = () => {
        const {selectedTab, campaign, postingPlan} = this.state;
        const {navigate, selectedBrand} = this.props;

        if (this.state.loading) {
            return <CircularProgress/>;
        }

        if (this.state.error) {
            return <Typography color="error">{this.state.error}</Typography>;
        }

        switch (selectedTab) {
            case 0:
                return <CalendarComponent
                    selectedBrand={{id: selectedBrand.id}}
                    navigate={navigate}
                    campaign={campaign}
                    postingPlan={postingPlan}
                />;
            case 1:
                return <CampaignPlan campaign={campaign}/>;
            case 2:
                return <CampaignView campaign={campaign}/>;
            default:
                return null;
        }
    };

    render() {
        const {
            selectedTab,
            campaign,
            isModalOpen,
            loadingGeneratePostingPlan,
            postingPlan,
            loadingPostingPlan,
            tutorialOpen,
            tutorialStep
        } = this.state;
        const {isMobile} = this.props;
        const disableGenerateButton = loadingGeneratePostingPlan || loadingPostingPlan || (postingPlan && Object.keys(postingPlan).length > 0);

        return (
            <Box sx={{width: '100%', typography: 'body1', padding: 2}}>
                {campaign && (
                    <Box sx={{
                        padding: 2,
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', marginBottom: isMobile ? 2 : 0}}>
                            <Button
                                startIcon={<ArrowBackIcon/>}
                                onClick={() => this.props.navigate('/campaigns')}
                                sx={{marginRight: 2}}
                            >
                            </Button>
                            <Box>
                                <Typography variant="h4">{campaign.name}</Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {campaign.objective}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center'}}>
                            <Button
                                variant="contained"
                                color="danger"
                                onClick={this.handleDeleteDialogOpen}
                                sx={{
                                    marginRight: isMobile ? 0 : 2,
                                    marginBottom: isMobile ? 2 : 0,
                                    width: isMobile ? '100%' : 'auto'
                                }}
                                size={isMobile ? 'small' : 'medium'}
                            >
                                Delete Campaign
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleGeneratePostingPlan}
                                sx={{
                                    marginRight: isMobile ? 0 : 2,
                                    marginBottom: isMobile ? 2 : 0,
                                    width: isMobile ? '100%' : 'auto'
                                }}
                                size={isMobile ? 'small' : 'medium'}
                                disabled={disableGenerateButton}
                            >
                                {loadingGeneratePostingPlan ?
                                    <CircularProgress size={24} color="inherit"/> : 'Generate Posting Plan'}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleCreateContent}
                                sx={{width: isMobile ? '100%' : 'auto'}}
                                size={isMobile ? 'small' : 'medium'}
                            >
                                Create Campaign Content
                            </Button>
                        </Box>
                    </Box>
                )}
                <Tabs value={selectedTab} onChange={this.handleTabChange} aria-label="campaign tabs">
                    <Tab label="Calendar"/>
                    <Tab label="Insights"/>
                    <Tab label="Edit"/>
                </Tabs>
                <Box sx={{padding: 2}}>
                    {this.renderTabContent()}
                </Box>
                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{bottom: `${index * 60 + 20}px`}}
                    />
                ))}
                <ChooseContentModal
                    open={isModalOpen}
                    handleClose={this.handleCloseModal}
                    contentType={campaign ? campaign.contentType : null}
                    campaignId={campaign ? campaign.id : null}
                />
                <ConfirmDialog
                    dialogOpen={this.state.deleteDialogOpen}
                    handleClose={this.handleDeleteDialogClose}
                    handleConfirm={this.handleDeleteDialogConfirm}
                    dialogTitle={"Delete campaign"}
                    dialogMessage={"Are you sure you want to delete this campaign? This action will also delete any content created for it."}
                />
                <Modal
                    open={tutorialOpen}
                    onClose={this.handleCloseTutorial}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={tutorialOpen}>
                        {this.renderTutorialContent()}
                    </Fade>
                </Modal>
            </Box>
        );
    }
}

export default withRouter(Campaign);