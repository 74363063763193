import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AudienceSelector from '../view/components/AudienceSelector';
import { CircularProgress } from '@mui/material';

const CampaignDrawer = ({drawerOpen, handleCloseDrawer, newCampaign, contentTypes, handleInputChange, onSubmitCampaign, errors, handleAudienceChange, audiences, selectedAudience, loadingCreate,}) => {
    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={handleCloseDrawer}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            BackdropProps={{
                onClick: (event) => event.stopPropagation(),
            }}
            disableEscapeKeyDown
        >
            <Box
                sx={{
                    width: { xs: '325px', md: '600px' },
                    padding: 3,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="h6">Start a New Campaign</Typography>
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <form onSubmit={onSubmitCampaign}>
                    <TextField
                        label="Enter Campaign Name"
                        name="name"
                        value={newCampaign.name}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                    <TextField
                        label="Enter Product Name"
                        name="product"
                        value={newCampaign.product}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.product}
                        helperText={errors.product}
                    />
                    <TextField
                        label="Set Campaign Start Date"
                        name="startDate"
                        type="datetime-local"
                        value={newCampaign.startDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors.startDate}
                        helperText={errors.startDate}
                    />
                    <TextField
                        label="Set Campaign End Date"
                        name="endDate"
                        type="datetime-local"
                        value={newCampaign.endDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors.endDate}
                        helperText={errors.endDate}
                    />
                    <TextField
                        label="Define Campaign Objective"
                        name="objective"
                        value={newCampaign.objective}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.objective}
                        helperText={errors.objective}
                    />
                    <AudienceSelector
                        audiences={audiences}
                        selectedAudience={selectedAudience}
                        onAudienceChange={handleAudienceChange}
                        disabled={false}
                    />
                    <FormControl fullWidth margin="normal" error={!!errors.contentType}>
                        <InputLabel id="content-label">Choose a Content Type</InputLabel>
                        <Select
                            labelId="content-label"
                            id="chooseContentSelect"
                            name="contentType"
                            value={newCampaign.contentType}
                            onChange={handleInputChange}
                            label="Choose a Content Type"
                        >
                            {contentTypes.map((type) => (
                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                            ))}
                        </Select>
                        {errors.contentType && <Typography color="error">{errors.contentType}</Typography>}
                    </FormControl>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                        <Button onClick={handleCloseDrawer} color="danger" sx={{ marginRight: 1 }}>
                            Cancel Creation
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            {loadingCreate ? <CircularProgress size={24} color="inherit" /> : 'Create Campaign'}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Drawer>
    );
};

export default CampaignDrawer;