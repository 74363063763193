import React, { Component } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";

class AcceptableUsePolicy extends Component {
    render() {
        return (
            <Box sx={{ padding: "2rem", maxWidth: "800px", margin: "0 auto" }}>
                <Typography variant="h4" gutterBottom>
                    Acceptable Use Policy
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>Effective Date:</strong> 20 December 2024
                </Typography>

                <Typography variant="body1" paragraph>
                    This Acceptable Use Policy (“Policy”) outlines the acceptable and prohibited uses of Brand Bounce’s services (“Services”). By accessing or using our Services, you agree to comply with this Policy. Failure to comply may result in the suspension or termination of your account.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Purpose of the Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce provides tools and services to help businesses create, manage, and optimize their marketing campaigns. This Policy ensures that all users use our Services responsibly and in compliance with applicable laws and regulations.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Prohibited Activities
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2.1 Illegal Activities
                </Typography>
                <List>
                    <ListItem>Using the Services for any activity that violates local, national, or international laws or regulations.</ListItem>
                    <ListItem>Promoting or engaging in fraudulent, deceptive, or misleading practices.</ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    2.2 Harmful Activities
                </Typography>
                <List>
                    <ListItem>Uploading, transmitting, or distributing harmful content, such as viruses, malware, or spyware.</ListItem>
                    <ListItem>Attempting to disrupt or compromise the security, integrity, or availability of our Services.</ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    2.3 Misuse of AI Tools
                </Typography>
                <List>
                    <ListItem>Generating harmful, offensive, or misleading content using our AI tools.</ListItem>
                    <ListItem>Using AI-generated content to violate third-party intellectual property or privacy rights.</ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    2.4 Unauthorized Access
                </Typography>
                <List>
                    <ListItem>Attempting to access or use another user’s account without permission.</ListItem>
                    <ListItem>Attempting to bypass or disable security measures implemented by Brand Bounce.</ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    2.5 Spamming and Exploitation
                </Typography>
                <List>
                    <ListItem>Using the Services to send spam or other unsolicited messages.</ListItem>
                    <ListItem>Exploiting the Services for activities such as phishing, fraud, or identity theft.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    3. User Responsibilities
                </Typography>
                <Typography variant="body1" paragraph>
                    As a user, you agree to:
                </Typography>
                <List>
                    <ListItem>Provide accurate and truthful information when using the Services.</ListItem>
                    <ListItem>Maintain the security of your account by safeguarding your login credentials.</ListItem>
                    <ListItem>Report any vulnerabilities, security issues, or policy violations to &nbsp; <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link>.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    4. Consequences of Policy Violations
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce reserves the right to take action if you violate this Policy, including but not limited to:
                </Typography>
                <List>
                    <ListItem>Suspending or terminating your account.</ListItem>
                    <ListItem>Reporting violations to appropriate authorities.</ListItem>
                    <ListItem>Pursuing legal remedies for damages caused by violations.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    5. Reporting Violations
                </Typography>
                <Typography variant="body1" paragraph>
                    If you become aware of any violations of this Policy, please report them to:
                </Typography>
                <List>
                    <ListItem>Email: <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link></ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    Provide as much detail as possible, including the nature of the violation and any supporting evidence.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Changes to This Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce may update this Policy from time to time. Significant changes will be communicated via email or through a notification on our platform. Your continued use of the Services after such updates constitutes acceptance of the revised Policy.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    For questions or concerns regarding this Policy, contact us at:
                </Typography>
                <List>
                    <ListItem>Email: <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link></ListItem>
                    <ListItem>Address: Șos. Pantelimon 311, Bucharest, Romania</ListItem>
                </List>
            </Box>
        );
    }
}

export default AcceptableUsePolicy;