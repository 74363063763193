const styleMap = {
    'FONTSIZE_12': { fontSize: '12px' },
    'FONTSIZE_14': { fontSize: '14px' },
    'FONTSIZE_16': { fontSize: '16px' },
    'FONTSIZE_18': { fontSize: '18px' },
    'FONTSIZE_20': { fontSize: '20px' },
    'FONTSIZE_24': { fontSize: '24px' },
    'FONTSIZE_28': { fontSize: '28px' },
    'FONTSIZE_32': { fontSize: '32px' },
    'COLOR_#000000': { color: '#000000' },
    'COLOR_#FF0000': { color: '#FF0000' },
    'COLOR_#00FF00': { color: '#00FF00' },
    'COLOR_#0000FF': { color: '#0000FF' },
    'COLOR_#FFA500': { color: '#FFA500' },
};

export default styleMap;