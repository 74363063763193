import React, { Component } from 'react';
import { Box, Checkbox, Container, Typography, Link, Stepper, Step, StepLabel, CircularProgress, StepConnector } from '@mui/material';
import { CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';
import ConfirmDialog from "../shared/ConfirmDialog";
import JourneyService from "../../services/journey/JourneyService";
import {styled} from '@mui/system';
import CommentSection from "./components/CommentSection";
import CustomSnackbar from "../shared/CustomSnackbar";

const CustomStepConnector = styled(StepConnector)(({}) => ({
    '& .MuiStepConnector-line': {
        borderLeft: `2px solid`,
        borderImage: 'linear-gradient(to bottom, #ffae6f, #ff7f33) 1 100%',
        marginLeft: '8px',
        transition: 'border-image 0.5s ease-in-out',
    },
}));

class JourneyActionItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actionItems: props.journey.journeyActionItemResponseDtoList,
            confirmDialogOpen: false,
            selectedActionItem: null,
            loading: false,
            comments: props.journey.comments ? props.journey.comments : [],
            newComment: '',
            loadingComment: false,
            editingCommentId: null,
            editingCommentText: '',
            snackbars: [],
        };
    }

    handleCheckboxChange = (actionItem) => {
        this.setState({
            selectedActionItem: actionItem,
            confirmDialogOpen: true,
        });
    };

    handleConfirm = async () => {
        const {selectedActionItem} = this.state;
        const {journey, updateJourneyProgress} = this.props;
        if (selectedActionItem) {
            this.setState({loading: true});
            try {
                const response = await JourneyService.completeJourneyActionItem(selectedActionItem.id);
                const updatedActionItems = this.state.actionItems.map((item) => {
                    if (item.id === selectedActionItem.id) {
                        item.completion = true;
                    }
                    return item;
                });

                const numberOfCompletedActionItems = journey.numberOfCompletedActionItems + 1;
                updateJourneyProgress(numberOfCompletedActionItems);

                if (response && response.id) {
                    updatedActionItems.push(response);
                }

                this.setState({
                    actionItems: updatedActionItems,
                    confirmDialogOpen: false,
                    selectedActionItem: null,
                    loading: false,
                });
            } catch (error) {
                this.addSnackbar(error.message, 'error');

                this.setState({
                    loading: false,
                    confirmDialogOpen: false,
                    selectedActionItem: null,
                });
            }
        }
    };

    handleClose = () => {
        this.setState({
            confirmDialogOpen: false,
            selectedActionItem: null,
        });
    };

    generateLink = (uri, id) => {
        return `${uri}?journeyActionItemId=${id}`;
    };

    getStepLabelStyle = (disabled) => {
        return disabled ? {opacity: 0.5} : {};
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    render() {
        const {actionItems, confirmDialogOpen, loading, comments, newComment} = this.state;
        const {authenticatedUser, journey} = this.props;

        const nonCompletedItems = actionItems.filter(item => !item.completion);
        const latestCompletedItem = actionItems.filter(item => item.completion).sort((a, b) => new Date(b.startAt) - new Date(a.startAt))[0];

        return (
            <Container>
                <Box sx={{marginTop: 2}}>
                    <Box sx={{maxHeight: 400, overflow: 'auto', marginBottom: 10}}>
                        <Stepper orientation="vertical" connector={<CustomStepConnector/>}>
                            {latestCompletedItem && (
                                <Step key={latestCompletedItem.id} completed className="fade-enter fade-enter-active">
                                    <StepLabel
                                        StepIconComponent={() => (
                                            <Checkbox
                                                icon={<RadioButtonUnchecked/>}
                                                checkedIcon={<CheckCircleOutline/>}
                                                checked
                                                disabled
                                            />
                                        )}
                                        style={this.getStepLabelStyle(true)}
                                    >
                                        <Typography variant="h6">
                                            {latestCompletedItem.uri ? (
                                                <Link
                                                    href={this.generateLink(latestCompletedItem.uri, latestCompletedItem.id)}
                                                    sx={{
                                                        textDecoration: 'none',
                                                        color: 'primary.main',
                                                        '&:hover': {textDecoration: 'underline'}
                                                    }}>
                                                    {latestCompletedItem.title}
                                                </Link>
                                            ) : (
                                                latestCompletedItem.title
                                            )}
                                        </Typography>
                                        <Typography variant="body2">{latestCompletedItem.description}</Typography>
                                    </StepLabel>
                                </Step>
                            )}
                            {nonCompletedItems.slice(0, 8).map((item, index) => (
                                <Step key={item.id} completed={item.completion}
                                      className="fade-enter fade-enter-active">
                                    <StepLabel
                                        StepIconComponent={() => (
                                            <Checkbox
                                                icon={<RadioButtonUnchecked/>}
                                                checkedIcon={<CheckCircleOutline/>}
                                                checked={item.completion}
                                                onChange={() => this.handleCheckboxChange(item)}
                                                disabled={item.completion || index > 0}
                                                sx={{color: 'secondary.main'}}
                                            />
                                        )}
                                        style={this.getStepLabelStyle(item.completion || index > 0)}
                                    >
                                        <Typography variant="h6">
                                            {item.uri ? (
                                                <Link href={this.generateLink(item.uri, item.id)} sx={{
                                                    textDecoration: 'none',
                                                    color: 'primary.main',
                                                    '&:hover': {textDecoration: 'underline'}
                                                }}>
                                                    {item.title}
                                                </Link>
                                            ) : (
                                                item.title
                                            )}
                                        </Typography>
                                        <Typography variant="body2">{item.description}</Typography>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <CommentSection
                        comments={comments}
                        newComment={newComment}
                        authenticatedUser={authenticatedUser}
                        setComments={(comments) => this.setState({comments})}
                        setNewComment={(newComment) => this.setState({newComment})}
                        setLoadingComment={(loadingComment) => this.setState({loadingComment})}
                        setEditingCommentId={(editingCommentId) => this.setState({editingCommentId})}
                        setEditingCommentText={(editingCommentText) => this.setState({editingCommentText})}
                        brandJourneyId={journey.id}
                    />
                </Box>
                {loading && (
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 2}}>
                        <CircularProgress/>
                    </Box>
                )}
                <ConfirmDialog
                    dialogOpen={confirmDialogOpen}
                    handleClose={this.handleClose}
                    handleConfirm={this.handleConfirm}
                    dialogTitle="Confirm Completion"
                    dialogMessage="Are you sure you want to mark this action item as completed?"
                />
                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{ bottom: `${index * 60 + 20}px` }}
                    />
                ))}
            </Container>
        );
    }
}

export default JourneyActionItems;