import React, {Component} from 'react';
import {withRouter} from '../../services/security/withRouter';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {FormControlLabel, Switch} from '@mui/material';
import FullLoading from '../shared/FullLoading';
import StripeSubscriptionService from '../../services/payment/StripeSubscriptionService';
import getTheme from "../getTheme";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme(getTheme());

class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripeUrl: '',
            open: false,
            products: [],
            loadingProducts: true,
            tiers: [],
            switchChecked: false,
        };
    }

    componentDidMount() {
        StripeSubscriptionService.getProducts().then(products => {
            this.setState({
                loadingProducts: false,
                products: products,
                tiers: this.mapProductsToTiers(products, 'month')
            });

            const queryParams = new URLSearchParams(this.props.location.search);
            const planName = queryParams.get('planName');
            const period = queryParams.get('period');

            if (planName && period && planName !== 'null' && period !== 'null') {
                this.getUrl(planName, period);
            }
        }).catch(error => {
            this.setState({open: true, loadingProducts: false});
            console.error(error);
        });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false});
    };

    handleSwitchChange = (event) => {
        const period = event.target.checked ? 'year' : 'month';
        this.setState({
            tiers: this.mapProductsToTiers(this.state.products, period),
            switchChecked: event.target.checked
        });
    };

    getUrl = (productTitle, period) => {
        this.setState({loadingProducts: true});
        StripeSubscriptionService.getUrl(productTitle, period).then(res => {
            this.setState({loadingProducts: false});
            window.location.href = res.url;
        }).catch(error => {
            this.setState({open: true});
        });
    };

    mapProductsToTiers = (products, period) => {
        products.sort((a, b) => a[`${period}Price`] - b[`${period}Price`]);
        return products.map(product => {
            const monthlyPrice = parseInt(product['monthPrice']) / 100;
            const discountedPrice = period === 'year' ? (monthlyPrice * 0.85) : monthlyPrice;
            const seatMonthlyPrice = parseInt(product['seatPriceMonth']) / 100;
            const seatDiscountedPrice = period === 'year' ? (seatMonthlyPrice * 0.85) : seatMonthlyPrice;
            return {
                title: product.title,
                price: discountedPrice.toFixed(2),
                description: product.description,
                buttonText: 'Start Trial Now',
                buttonVariant: 'outlined',
                period: period,
                fullPrice: period === 'year' ? monthlyPrice.toFixed(2) : null,
                seatPrice: seatDiscountedPrice.toFixed(2),
                seatFullPrice: period === 'year' ? seatMonthlyPrice.toFixed(2) : null,
            };
        });
    };

    render() {
        const {loadingProducts, tiers, switchChecked, open} = this.state;
        const action = (
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </React.Fragment>
        );

        return (
            <ThemeProvider theme={theme}>

                <Container
                    id="pricing"
                    sx={{
                        pt: {xs: 4, sm: 12},
                        pb: {xs: 8, sm: 16},
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: {xs: 3, sm: 6},
                    }}
                >
                    <FullLoading loading={loadingProducts}/>

                    <Box sx={{width: {sm: '100%', md: '60%'}, textAlign: {sm: 'left', md: 'center'}}}>
                        <Typography component="h2" variant="h2" color="text.primary">
                            {switchChecked ? 'Yearly Pricing' : 'Monthly Pricing'}
                        </Typography>
                        <FormControlLabel
                            control={<Switch checked={switchChecked} onChange={this.handleSwitchChange}
                                             inputProps={{'aria-label': 'controlled'}}/>}
                            label="Yearly Pricing (Save 15%)"
                            style={{color: 'green'}}
                        />
                        <Typography variant="h4" color="text.secondary">
                            Select the best subscription plan for your needs.
                        </Typography>
                    </Box>
                    <Grid container spacing={3} alignItems="center" justifyContent="center">
                        {tiers.map((tier, index) => (
                            <TierCard key={tier.title} tier={tier} index={index} getUrl={this.getUrl}/>
                        ))}
                    </Grid>
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        message="Unable to load subscription details. Please try again later or contact support."
                        action={action}
                    />
                </Container>
            </ThemeProvider>
        );
    }
}

const TierCard = ({tier, index, getUrl}) => (
    <Grid item xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
        <Card
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: index === 1 ? '1px solid' : undefined,
                borderColor: index === 1 ? 'primary.main' : undefined,
            }}
        >
            <CardContent>
                <Box sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography component="h2" variant="h3" color="primary">
                        {tier.title}
                    </Typography>
                    {index === 1 && (
                        <Chip
                            icon={<AutoAwesomeIcon/>}
                            label={'Recommended'}
                            size="small"
                            sx={{
                                background: (theme) => theme.palette.mode === 'light' ? '' : 'none',
                                backgroundColor: 'primary.contrastText',
                                '& .MuiChip-label': {color: 'primary.dark'},
                                '& .MuiChip-icon': {color: 'primary.dark'},
                            }}
                        />
                    )}
                </Box>
                <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                    <Typography component="h3" variant="h2">
                        ${tier.price}
                    </Typography>
                    {tier.fullPrice && (
                        <Typography component="h4" variant="subtitle1"
                                    style={{color: 'red', textDecoration: 'line-through', marginLeft: '8px'}}>
                            ${tier.fullPrice}
                        </Typography>
                    )}
                </Box>
                <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                    <Typography component="h3" variant="subtitle2">
                        <b>1 seat included. </b>Extra Seat: ${tier.seatPrice}
                    </Typography>
                    {tier.seatFullPrice && (
                        <Typography component="h4" variant="subtitle1"
                                    style={{color: 'red', textDecoration: 'line-through', marginLeft: '8px'}}>
                            ${tier.seatFullPrice}
                        </Typography>
                    )}
                </Box>

                <Typography variant="body2" color="text.secondary" sx={{mt: 2}}>
                    <b>3 days free trial</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {tier.period === 'year' ? 'Billed Annually' : 'Billed Monthly'}
                </Typography>

                <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} component="a" target="_blank"
                            onClick={() => getUrl(tier.title, tier.period)}>
                        {tier.buttonText}
                    </Button>
                </CardActions>
                <Divider sx={{my: 2, opacity: 0.2, borderColor: 'grey.500'}}/>
                {tier.description.map((line, idx) => (
                    <Box key={line} sx={{py: 1, display: 'flex', gap: 1.5, alignItems: 'center'}}>
                        <CheckCircleRoundedIcon
                            sx={{width: 20, color: 'primary.main'}}/>
                        <Typography component="text" variant="subtitle2" sx={idx < 7 ? {fontWeight: 'bold'} : {}}>
                            {line}
                        </Typography>
                    </Box>
                ))}
            </CardContent>
        </Card>
    </Grid>
);

export default withRouter(Subscriptions);