const industries = [
    'Tech',
    'E-commerce',
    'Fashion',
    'Health',
    'Food & Beverage',
    'Finance',
    'Education',
    'Automotive',
    'Real Estate',
    'Travel & Tourism',
    'Entertainment',
    'Sports',
    'Retail',
    'Manufacturing',
    'Construction',
    'Agriculture',
    'Energy',
    'Telecommunications',
    'Logistics',
    'Media',
    'Non-Profit',
    'Government',
    'Consulting',
    'Legal',
    'Marketing & Advertising',
    'Pharmaceuticals',
    'Biotechnology',
    'Hospitality',
    'Insurance',
    'Aerospace',
    'Defense',
    'Mining',
    'Chemicals',
    'Utilities',
    'Transportation',
    'Architecture',
    'Design',
    'Publishing',
    'Recruitment',
    'Security',
    'Art & Culture',
    'Event Planning',
    'Fitness & Wellness',
    'Cleaning Services',
    'Pet Services',
    'Home Services',
    'HR & Talent Management',
    'Gaming',
    'Luxury Goods',
    'Waste Management',
    'Shipping & Freight',
    'Fitness Equipment',
    'Legal Tech',
    'EdTech',
    'Health Tech',
    'FinTech',
    'Maritime',
    'Textiles',
    'Leather Goods',
    'Artisan Goods',
    'Virtual Reality',
    'Augmented Reality',
    'Smart Home',
    'Space Exploration',
    'Crowdfunding',
    'Blockchain',
    'Cryptocurrency',
    'Eco-Friendly Products',
    'Antiques & Collectibles',
    'Horticulture',
    'Religious Services',
    'Public Relations',
    'Market Research',
    'Data Analysis',
    'Translation Services',
    'Customer Support',
    'Venture Capital',
    'Business Services',
    'Robotics',
    'Industrial Design',
    'Interior Design',
    'Garden & Landscaping',
    'Marine Services',
    'Animal Health',
    'Beauty & Personal Care',
    'Hardware',
    'Drone Technology',
    'Wearable Tech',
    'Social Enterprise',
    'Private Equity',
    'Life Sciences',
    'Precision Engineering',
    'R&D (Research & Development)',
    'Fire & Safety',
    'Green Energy',
    'Renewables',
    'Talent Agency',
    'Optical Services',
    'Tutoring & Coaching',
    'Wedding Services',
    'Freight Forwarding',
    '3D Printing',
    'Hydropower',
    'Electric Vehicles',
    'Bicycle Manufacturing',
    'Freelance Services',
    'Dentistry',
    'Rental Services',
    'Mobile Apps',
    'All Industries',
    'Other',
];

export default industries;