import React, {Component} from 'react';
import {
    Box,
    Typography,
    Grid,
    IconButton,
    TextField,
    FormControlLabel,
    Checkbox,
    CircularProgress
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import {EditorState, convertFromHTML, ContentState, convertToRaw} from 'draft-js';
import {withRouter} from '../../services/security/withRouter';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AudienceSelector from './components/AudienceSelector';
import VoiceSelector from './components/VoiceSelector';
import ParameterInputField from './components/ParameterInputField';
import TemplateService from '../../services/TemplateService';
import AiService from '../../services/AiService';
import CompanyService from '../../services/CompanyService';
import CustomSnackbar from '../shared/CustomSnackbar';
import ContentService from '../../services/ContentService';
import TextEditor from './components/TextEditor';
import moment from 'moment';
import CampaignService from '../../services/CampaignService';
import languages from '../../services/util/languages';
import {Card} from "@mui/material";
import {renderIcon} from "../../services/util/iconHelper";

class Template extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template: null,
            values: {},
            brand: null,
            selectedAudience: '',
            selectedVoice: '',
            loading: false,
            loadingIdeas: false,
            snackbars: [],
            postDate: new Date(new Date().setDate(new Date().getDate() + 1)).setHours(12, 0, 0, 0),
            campaign: null,
            postDateDisabled: false,
            useBrandName: true,
            topTrends: [],
            selectedTrend: '',
            useLatestTrends: false,
            loadingTrends: false,
            selectedLanguage: 'English',
        };
    }

    componentDidMount() {
        const {id} = this.props.params;
        this.getTemplate(id);
        if (this.props.selectedBrand.id !== 0) {
            this.getBrand(this.props.selectedBrand.id);
        }

        const queryParams = new URLSearchParams(this.props.location.search);
        const campaignId = queryParams.get('campaignId');
        const postDate = queryParams.get('postDate');
        if (campaignId) {
            this.fetchCampaign(campaignId);
        }
        if (postDate) {
            this.setState({postDate: new Date(postDate), postDateDisabled: true});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {id} = this.props.params;
        const {selectedBrand} = this.props;

        if (selectedBrand !== prevProps.selectedBrand && selectedBrand && !this.state.campaign) {
            this.setState({selectedAudience: '', selectedVoice: ''});
            this.getBrand(selectedBrand.id);
        }

        if (id !== prevProps.params.id) {
            this.getTemplate(id);
            const editorState = EditorState.createEmpty();
            this.setState({editorContent: editorState});
        }
    }

    handleUseLatestTrendsChange = async (event) => {
        const useLatestTrends = event.target.checked;
        this.setState({useLatestTrends});

        if (useLatestTrends && this.state.topTrends.length === 0) {
            this.setState({loadingTrends: true});
            try {
                const topTrends = await AiService.topTrends({brandId: this.props.selectedBrand.id});
                this.setState({topTrends, loadingTrends: false});
            } catch (error) {
                console.error('Failed to fetch top trends:', error);
                this.setState({loadingTrends: false});
            }
        }
    };

    handleTrendChange = (event) => {
        this.setState({selectedTrend: event.target.value});
    };

    handleUseBrandNameChange = (event) => {
        this.setState({useBrandName: event.target.checked});
    };

    fetchCampaign = async (id) => {
        try {
            const campaign = await CampaignService.getCampaign(id);
            this.setState({campaign, selectedAudience: campaign.audience.targetAudienceId, loading: false});
        } catch (error) {
            this.setState({error: 'Failed to fetch campaign', loading: false});
        }
    };

    handlePostDateChange = (event) => {
        this.setState({postDate: new Date(event.target.value)});
    };

    saveContent = async () => {
        const {editorContent, selectedAudience, selectedVoice, postDate, values, postDateDisabled} = this.state;
        const {id} = this.props.params;
        const {selectedBrand, navigate} = this.props;

        const queryParams = new URLSearchParams(this.props.location.search);
        const campaignId = queryParams.get('campaignId');
        const journeyActionItemId = queryParams.get('journeyActionItemId');

        const isEmpty = !editorContent;

        if (isEmpty) {
            this.addSnackbar('The content cannot be empty.', 'error');
            return;
        }
        const contentDto = {
            actualContent: JSON.stringify(convertToRaw(editorContent.getCurrentContent())),
            params: JSON.stringify(values),
            templateId: id,
            brandId: this.state.campaign ? this.state.campaign.audience.brandId : selectedBrand.id,
            audienceId: selectedAudience,
            voiceId: selectedVoice,
            postDate: postDate ? moment(postDate).format('YYYY-MM-DDTHH:mm:ss') : null,
            campaignId: campaignId ? campaignId : null,
            journeyActionItemId: journeyActionItemId ? journeyActionItemId : null,
            fromSuggestion: postDateDisabled,
            useBrandName: this.state.useBrandName,
            selectedTrend: this.state.selectedTrend,
            topTrends: this.state.topTrends,
            language: this.state.selectedLanguage,
        };

        try {
            const response = await ContentService.addContent(contentDto);
            this.addSnackbar('Your content has been saved successfully.', 'success');

            navigate(`/content/${response.id}`);

        } catch (error) {
            this.addSnackbar('There was an error saving your content. Please try again later.', 'error');
        }
    };

    getTemplate = async (id) => {
        try {
            const template = await TemplateService.getTemplate(id);
            const values = Object.keys(template.parameters).reduce((acc, param) => {
                acc[param] = '';
                return acc;
            }, {});
            this.setState({template, values});
        } catch (error) {
            this.addSnackbar('There was an error fetching the template. Please try again later.', 'error');
        }
    };

    getBrand = async (brandId) => {
        try {
            const brand = await CompanyService.getModelBrand(brandId);
            this.setState({brand, loadingBrand: false});
        } catch (error) {
            this.addSnackbar(error.message, 'error');
            this.setState({
                loadingBrand: false,
            });
        }
    };

    handleChange = (param) => (event) => {
        this.setState((prevState) => ({
            values: {
                ...prevState.values,
                [param]: event.target.value
            }
        }));
    };

    handleAutocompleteChange = (param, value) => {
        this.setState((prevState) => ({
            values: {
                ...prevState.values,
                [param]: value
            }
        }));
    };

    handleGenerate = async () => {
        const {selectedAudience, selectedVoice, values, template} = this.state;
        const {selectedBrand} = this.props;

        if (this.props.selectedBrand.id === 0) {
            this.addSnackbar('Please select a brand in the navbar.', 'info');

            return;
        }

        if (!selectedAudience || !selectedVoice) {
            this.addSnackbar('Select both the audience and voice to proceed.', 'info');

            return;
        }

        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        const queryParams = new URLSearchParams(this.props.location.search);
        const journeyActionItemId = queryParams.get('journeyActionItemId');

        const requestData = {
            brandId: this.state.campaign ? this.state.campaign.audience.brandId : selectedBrand.id,
            voiceId: selectedVoice,
            audienceId: selectedAudience,
            parameters: JSON.stringify(filteredValues),
            templateId: template.id,
            selectedTrend: this.state.selectedTrend,
            useBrandName: this.state.useBrandName,
            language: this.state.selectedLanguage,
            journeyActionItemId: journeyActionItemId ? journeyActionItemId : null,
        };

        this.setState({loading: true});

        try {
            const response = await AiService.generateContent(requestData);
            const htmlResponse = response.response;

            const blocksFromHTML = convertFromHTML(htmlResponse);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );

            const newEditorState = EditorState.createWithContent(contentState);
            this.setState({editorContent: newEditorState});
        } catch (error) {
            console.error('Failed to generate content:', error);
        } finally {
            this.setState({loading: false});
        }
    };

    handleGenerateIdeas = async () => {
        const {selectedAudience, selectedVoice, template} = this.state;
        const {selectedBrand} = this.props;

        if (this.props.selectedBrand.id === 0) {
            this.addSnackbar('Please select a brand in the navbar.', 'info');

            return;
        }

        if (!selectedAudience || !selectedVoice) {
            this.addSnackbar('Select both the audience and voice to proceed.', 'info');

            return;
        }
        const queryParams = new URLSearchParams(this.props.location.search);
        const journeyActionItemId = queryParams.get('journeyActionItemId');

        const requestData = {
            brandId: selectedBrand.id,
            audienceId: selectedAudience,
            voiceId: selectedVoice,
            templateId: template.id,
            language: this.state.selectedLanguage,
            journeyActionItemId: journeyActionItemId ? journeyActionItemId : null,
        };

        this.setState({loadingIdeas: true});

        try {
            const response = await AiService.generateIdeas(requestData);
            const htmlResponse = response.response;

            const blocksFromHTML = convertFromHTML(htmlResponse);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );

            const newEditorState = EditorState.createWithContent(contentState);
            this.setState({editorContent: newEditorState});
        } catch (error) {
            console.error('Failed to generate ideas:', error);
        } finally {
            this.setState({loadingIdeas: false});
        }
    };

    handleEditorChange = (editorContent) => {
        this.setState({editorContent});
    };

    handleAudienceChange = (event) => {
        this.setState({selectedAudience: event.target.value, selectedVoice: ''});
    };

    handleVoiceChange = (event) => {
        this.setState({selectedVoice: event.target.value});
    };

    toggleFavorite = async () => {
        const {template} = this.state;
        try {
            if (template.favorite) {
                await TemplateService.removeFavoriteTemplate(template.id);
            } else {
                await TemplateService.addFavoriteTemplate(template.id);
            }
            this.setState((prevState) => ({
                template: {
                    ...prevState.template,
                    favorite: !prevState.template.favorite,
                },
            }));
        } catch (error) {
            this.addSnackbar('Failed to add template to favourites. Please try again later.', 'success');
        }
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    render() {
        const {
            template,
            values,
            brand,
            selectedAudience,
            selectedVoice,
            loading,
            loadingIdeas,
            postDate,
            campaign
        } = this.state;

        if (!template) {
            return (
                <Box sx={{padding: 4}}>
                    <Skeleton variant="text" width="60%" height={40}/>
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{marginTop: 2}}/>
                </Box>
            );
        }

        const audiences = brand ? brand.audiences : [];
        const foundAudience = audiences.find(a => a.id === selectedAudience);
        const voices = (selectedAudience && foundAudience) ? audiences.find(a => a.id === selectedAudience).voices : [];

        const inputsGrid = (
            <Grid item xs={12}>
                {this.props.selectedBrand.id === 0 && (
                    <Typography variant="body1" color="textSecondary">
                        To continue, please select a brand from the navigation bar.
                    </Typography>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AudienceSelector
                            audiences={audiences}
                            selectedAudience={selectedAudience}
                            onAudienceChange={this.handleAudienceChange}
                            disabled={this.props.selectedBrand.id === 0 || campaign}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <VoiceSelector
                            voices={voices}
                            selectedVoice={selectedVoice}
                            onVoiceChange={this.handleVoiceChange}
                        />
                    </Grid>
                    {Object.entries(template.parameters).map(([param, config]) => (
                        <Grid item xs={12} key={param}>
                            <ParameterInputField
                                param={param}
                                config={config}
                                values={values}
                                handleChange={this.handleChange}
                                handleAutocompleteChange={this.handleAutocompleteChange}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <TextField
                            label="Select the post date"
                            type="datetime-local"
                            value={moment(postDate).format('YYYY-MM-DDTHH:mm')}
                            onChange={this.handlePostDateChange}
                            fullWidth
                            margin="dense"
                            size="small"
                            sx={{marginTop: 2}}
                            disabled={this.state.postDateDisabled}
                            helperText="Select the date and time to post."
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Choose a language"
                            value={this.state.selectedLanguage}
                            onChange={(event) => this.setState({selectedLanguage: event.target.value})}
                            fullWidth
                            margin="dense"
                            size="small"
                            sx={{marginTop: 2}}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {languages.map((language, index) => (
                                <option key={index} value={language}>
                                    {language}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    {this.state.useLatestTrends && (
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Choose a trend"
                                value={this.state.selectedTrend}
                                onChange={this.handleTrendChange}
                                fullWidth
                                margin="dense"
                                size="small"
                                sx={{marginTop: 2}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value=""></option>
                                {this.state.topTrends.map((trend, index) => (
                                    <option key={index} value={trend}>
                                        {trend}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.useBrandName}
                                        onChange={this.handleUseBrandNameChange}
                                        color="primary"
                                    />
                                }
                                label="Include the brand name in the content"
                                sx={{marginRight: 2}}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.useLatestTrends}
                                        onChange={this.handleUseLatestTrendsChange}
                                        color="primary"
                                    />
                                }
                                label="Include the latest trends in the content"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        );

        const editorGrid = (
            <Grid item xs={12}>
                <Box sx={{position: 'relative'}} sx={{padding: 3}}>
                    <TextEditor
                        initialContent={this.state.editorContent}
                        onChange={this.handleEditorChange}
                        loading={loading}
                        loadingIdeas={loadingIdeas}
                        handleGenerate={this.handleGenerate}
                        handleGenerateIdeas={this.handleGenerateIdeas}
                        saveContent={this.saveContent}
                    />
                </Box>
            </Grid>
        );

        return (
            <>
                {this.state.loadingTrends && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            zIndex: 9999,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress/>
                    </Box>
                )}
                <Box sx={{backgroundColor: '#fff', paddingRight: 3, paddingLeft: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Box sx={{paddingRight: 2, borderRight: '1px solid #ccc', paddingTop: 3, paddingBottom: 3}}>
                                <Typography variant="h6" sx={{marginBottom: 2}}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <IconButton onClick={this.toggleFavorite} aria-label="favorite">
                                            {template.favorite ? (
                                                <StarIcon sx={{color: 'gold'}}/>
                                            ) : (
                                                <StarBorderIcon sx={{color: 'gold'}}/>
                                            )}
                                        </IconButton>
                                        {campaign && (
                                            <span
                                                onClick={() => this.props.navigate(`/campaigns/${campaign.id}`)}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#595ce2',
                                                }}
                                                onMouseOver={(e) => (e.target.style.color = 'primary.light')}
                                                onMouseOut={(e) => (e.target.style.color = 'primary.dark')}
                                            >
                                                {campaign.name}
                                            </span>
                                        )}
                                        {campaign && <span style={{margin: '0 8px', color: '#ccc'}}>|</span>}
                                        {renderIcon(template.platformName, {fontSize: 30})} &nbsp;
                                        {template.name}
                                    </Box>
                                </Typography>
                                {inputsGrid}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8} sx={{padding: 2}}>
                            {editorGrid}
                        </Grid>
                    </Grid>
                </Box>
                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{bottom: `${index * 60 + 20}px`}}
                    />
                ))}
            </>
        );
    }
}

export default withRouter(Template);