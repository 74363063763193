import React, { Component } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Grid,
    Card,
    CardContent,
    Avatar,
    Divider,
    } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import PlatformIcon from '@mui/icons-material/Devices';
import ContentIcon from '@mui/icons-material/ContentPaste';
import FrequencyIcon from '@mui/icons-material/Schedule';
import TimeIcon from '@mui/icons-material/AccessTime';
import EngagementIcon from '@mui/icons-material/ThumbUp';
import DateIcon from '@mui/icons-material/Event';
import CampaignService from '../../services/CampaignService';

class CampaignPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignPlan: null,
            loading: true,
            error: null,
            activeTab: 0,
        };
    }

    componentDidMount() {
        const { campaign } = this.props;
        if (campaign && campaign.id) {
            this.fetchCampaignPlan(campaign.id);
        }
    }

    fetchCampaignPlan = async (campaignId) => {
        try {
            const response = await CampaignService.getCampaignPlan(campaignId);
            this.setState({ campaignPlan: response, loading: false });
        } catch (error) {
            console.error('Failed to fetch campaign plan:', error);
            this.setState({ error: 'Failed to retrieve campaign plan', loading: false });
        }
    };

    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
    };

    renderSection = (title, content, IconComponent) => (
        <Card sx={{ marginBottom: 3 }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Avatar sx={{ bgcolor: 'primary.main', marginRight: 2 }}>
                        <IconComponent />
                    </Avatar>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                </Box>
                <Divider sx={{ marginBottom: 2 }} />
                {Array.isArray(content) ? (
                    content.map((item, index) => (
                        <Typography variant="body1" key={index} sx={{ marginBottom: 1 }}>
                            - {item}
                        </Typography>
                    ))
                ) : (
                    <Typography variant="body1">{content}</Typography>
                )}
            </CardContent>
        </Card>
    );

    render() {
        const { campaignPlan, loading, error } = this.state;
        const { campaign } = this.props;

        if (loading) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            );
        }

        if (error) {
            return (
                <Box sx={{ padding: 4, textAlign: 'center' }}>
                    <Typography color="error" variant="h6">
                        No insights available for this campaign.
                    </Typography>
                </Box>
            );
        }

        return (
            <Box sx={{ padding: 4 }}>
                <Grid container spacing={4}>
                    {(campaign.contentType.name === 'Paid Advertising Content') && (
                        <>
                            <Grid item xs={12} md={6}>
                                {this.renderSection('Insights', campaignPlan.insights, InsightsIcon)}
                                {this.renderSection('Suggested Platforms', campaignPlan.suggestedPlatforms, PlatformIcon)}
                                {this.renderSection('Ad Frequency', campaignPlan.adFrequency, FrequencyIcon)}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {this.renderSection('Ad Creative Ideas', campaignPlan.adCreativeIdeas, ContentIcon)}
                                {this.renderSection('Best Ad Schedules', campaignPlan.bestAdSchedules, TimeIcon)}
                                {this.renderSection('Targeting Suggestions', campaignPlan.targetingSuggestions, EngagementIcon)}
                            </Grid>
                        </>
                    )}
                    {(campaign.contentType.name === 'Social Media Content') && (
                        <>
                            <Grid item xs={12} md={6}>
                                {this.renderSection('Insights', campaignPlan.insights, InsightsIcon)}
                                {this.renderSection('Suggested Platforms', campaignPlan.suggestedPlatforms, PlatformIcon)}
                                {this.renderSection('Suggested Content Ideas', campaignPlan.suggestedContentIdeas, ContentIcon)}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {this.renderSection('Posting Frequency', campaignPlan.postingFrequency, FrequencyIcon)}
                                {this.renderSection('Best Posting Times', campaignPlan.bestPostingTimes, TimeIcon)}
                                {this.renderSection('Engagement Suggestions', campaignPlan.engagementSuggestions, EngagementIcon)}
                            </Grid>
                        </>
                    )}
                    {(campaign.contentType.name === 'E-mail Content') && (
                        <>
                            <Grid item xs={12} md={6}>
                                {this.renderSection('Insights', campaignPlan.insights, InsightsIcon)}
                                {this.renderSection('Suggested Email Templates', campaignPlan.suggestedEmailTemplates, ContentIcon)}
                                {this.renderSection('Subject Line Suggestions', campaignPlan.subjectLineSuggestions, EngagementIcon)}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {this.renderSection('Best Sending Times', campaignPlan.bestSendingTimes, TimeIcon)}
                                {this.renderSection('Engagement Tactics', campaignPlan.engagementTactics, EngagementIcon)}
                                {this.renderSection('Special Dates', campaignPlan.specialDates, DateIcon)}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        );
    }
}

export default CampaignPlan;
