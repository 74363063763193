import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import JourneyQuestionService from '../../../services/journey/JourneyQuestionService';

class JourneyInputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            loading: true,
            responses: {},
            errors: {},
        };
    }

    async componentDidMount() {
        try {
            const response = await JourneyQuestionService.getByGoal(this.props.selectedGoal);
            this.setState({ questions: response, loading: false });
            this.props.onQuestionsChange(response);
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    handleChange = (id, value) => {
        this.setState((prevState) => {
            const newResponses = {
                ...prevState.responses,
                [id]: value,
            };
            this.props.onResponsesChange(newResponses);
            return { responses: newResponses };
        });
    };

    validateMandatoryGroup = () => {
        const { questions, responses } = this.state;
        const errors = {};

        const mandatoryGroupQuestions = questions.filter(q => q.inputType === 'checkbox' && q.mandatoryGroup);
        const isAnyMandatoryChecked = mandatoryGroupQuestions.some(q => responses[q.id]);

        if (!isAnyMandatoryChecked) {
            errors.mandatoryGroup = 'At least one of the mandatory checkboxes must be checked';
        }

        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };

    renderInput = (question) => {
        const { id, label, inputType, options, helperText, mandatoryGroup } = question;
        const { responses, errors } = this.state;

        switch (inputType) {
            case 'select':
                return (
                    <FormControl fullWidth key={id} sx={{ mb: 2 }} variant="outlined">
                        <InputLabel id={`label-${id}`} shrink={responses[id] !== undefined}>{label}</InputLabel>
                        <Select
                            labelId={`label-${id}`}
                            value={responses[id] || ''}
                            onChange={(e) => this.handleChange(id, e.target.value)}
                            label={label}
                        >
                            {options.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography variant="caption" color="textSecondary">
                            {helperText}
                        </Typography>
                    </FormControl>
                );
            case 'multiselect':
                return (
                    <FormControl fullWidth key={id} sx={{ mb: 2 }} variant="outlined">
                        <InputLabel id={`label-${id}`} shrink={responses[id] && responses[id].length > 0}>{label}</InputLabel>
                        <Select
                            labelId={`label-${id}`}
                            multiple
                            value={responses[id] || []}
                            onChange={(e) => this.handleChange(id, e.target.value)}
                            label={label}
                        >
                            {options.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography variant="caption" color="textSecondary">
                            {helperText}
                        </Typography>
                    </FormControl>
                );
            case 'checkbox':
                return (
                    <FormControlLabel
                        key={id}
                        control={
                            <Checkbox
                                checked={responses[id] || false}
                                onChange={(e) => this.handleChange(id, e.target.checked)}
                                sx={{ color: mandatoryGroup && errors.mandatoryGroup ? 'red' : 'inherit' }}
                            />
                        }
                        label={label}
                        sx={{ mb: 2 }}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        const { questions, loading, errors } = this.state;

        // Separate checkboxes and other inputs
        const checkboxQuestions = questions.filter(q => q.inputType === 'checkbox');
        const otherQuestions = questions.filter(q => q.inputType !== 'checkbox');

        return (
            <Container
                sx={{
                    pt: { xs: 4, sm: 12 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '70%' },
                        textAlign: { sm: 'left' },
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Please provide the necessary details to get your journey started.
                    </Typography>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={56} />
                    ) : (
                        <>
                            {otherQuestions.map((question) => this.renderInput(question))}
                            {checkboxQuestions.map((question) => this.renderInput(question))}
                            <br />
                            {errors.mandatoryGroup && (
                                <Typography variant="h6" color="error">
                                    {errors.mandatoryGroup}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            </Container>
        );
    }
}

export default JourneyInputs;