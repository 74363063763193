import React, { Component } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";

class CookiePolicy extends Component {
    render() {
        return (
            <Box sx={{ padding: "2rem", maxWidth: "800px", margin: "0 auto" }}>
                <Typography variant="h4" gutterBottom>
                    Cookie Policy
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>Effective Date:</strong> 20 December 2024
                </Typography>

                <Typography variant="body1" paragraph>
                    At Brand Bounce, we are committed to protecting your privacy and ensuring
                    transparency regarding how we use cookies on our platform. This Cookie
                    Policy explains what cookies are, how we use them, and your options
                    regarding their usage.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. What Are Cookies?
                </Typography>
                <Typography variant="body1" paragraph>
                    Cookies are small text files placed on your device by websites you visit.
                    They are widely used to make websites function efficiently and to provide
                    information to the website owners. Cookies can enhance user experience by
                    remembering preferences and providing tailored functionality.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Types of Cookies We Use
                </Typography>
                <Typography variant="body1" paragraph>
                    Currently, Brand Bounce only uses cookies that are&nbsp;
                    <strong>mandatory for the functionality and security of our platform.</strong>
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2.1 Mandatory Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                    These cookies are essential for the operation of our platform and cannot
                    be disabled. They ensure the security, stability, and functionality of the
                    Services. Without these cookies, you may not be able to access the full
                    functionality of the platform.
                </Typography>
                <Typography variant="body1" paragraph>
                    Examples include:
                </Typography>
                <List>
                    <ListItem>Authentication cookies to maintain secure sessions.</ListItem>
                    <ListItem>Security cookies to protect against fraud and unauthorized access.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    3. What Cookies Do Not Do
                </Typography>
                <Typography variant="body1" paragraph>
                    The cookies we use <strong>do not:</strong>
                </Typography>
                <List>
                    <ListItem>Collect personal or sensitive information.</ListItem>
                    <ListItem>Track your browsing behavior across other websites.</ListItem>
                    <ListItem>Display advertisements or marketing content.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    4. Managing Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                    As we only use mandatory cookies, they cannot be disabled without
                    impacting the functionality of the platform. By continuing to use Brand
                    Bounce, you acknowledge and accept the use of these necessary cookies.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    5. Changes to This Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this Cookie Policy from time to time to reflect changes in
                    technology, laws, or our business operations. Any updates will be posted
                    on this page, and significant changes will be communicated via email or a
                    notice on the platform.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have questions about our use of cookies, feel free to contact us:
                </Typography>
                <List>
                    <ListItem>
                        <strong>Email:</strong>{" "}
                        <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link>
                    </ListItem>
                    <ListItem>
                        <strong>Address:</strong> Șos. Pantelimon 311, Bucharest, Romania
                    </ListItem>
                </List>
            </Box>
        );
    }
}

export default CookiePolicy;