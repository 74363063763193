import config from '../../config';

class AuthService {
  async login(email, password, rememberMe) {
    try {
      const response = await fetch(`${config.apiUrl}/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email, password, rememberMe }),
      });

      if (!response.ok) {
        const error = await response.json();
        return error;
      }

      const data = await response.json();
      return {token: data.token, refreshToken: data.refreshToken};
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }
}
  
  export default new AuthService();