import ApiCalls from './util/ApiCalls';

class ImageService {
  async generateImage(imageDto) {
    return ApiCalls.defaultPost("/images/create", {}, imageDto);
  }

  async updateImage(dto) {
    return ApiCalls.defaultPost("/images/update/" + dto.imageId, {}, dto.data);
  }

  async getImage(imageId) {
    return ApiCalls.defaultGet("/images/" + imageId, {});
  }

  async getForBrand(queryParams) {
    return ApiCalls.defaultGet("/images", queryParams);
  }

  async delete(imageId) {
    return ApiCalls.defaultDelete("/images/" + imageId, {});
  }
}

export default new ImageService();