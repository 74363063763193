import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { withRouter } from '../../services/security/withRouter';
import Cookies from 'js-cookie';
import ChooseContentModal from '../create/template/ChooseContentModal';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import withMediaQuery from "../../services/withMediaQuery";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorElNav: null,
            anchorElUser: null,
            isModalOpen: false,
        };
    }

    handleOpenNavMenu = (event) => {
        this.setState({ anchorElNav: event.currentTarget });
    };

    handleOpenUserMenu = (event) => {
        this.setState({ anchorElUser: event.currentTarget });
    };

    handleCloseUserMenu = () => {
        this.setState({ anchorElUser: null });
    };

    handleLogout = () => {
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        localStorage.clear();
        this.props.navigate('/login');
    };

    handleOpenModal = () => {
        this.setState({ isModalOpen: true });
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
    };

    handleBrandChange = (event) => {
        const filteredBrand = this.props.authenticatedUser.company.brands.find(brand => brand.id === event.target.value);
        if (filteredBrand) {
            const selectedBrand = { id: filteredBrand.id, name: filteredBrand.name };
            localStorage.setItem('selectedBrand', JSON.stringify(selectedBrand));
            this.props.updateSelectedBrand(selectedBrand);
        }
    };

    render() {
        const { anchorElUser, isModalOpen } = this.state;
        const { authenticatedUser, selectedBrand, isMobile } = this.props;
        const imgAlt = authenticatedUser ? authenticatedUser.firstName : 'User';
        const companyName = authenticatedUser && authenticatedUser.company ? authenticatedUser.company.name : '';

        const queryParams = new URLSearchParams(this.props.location.search);
        const campaignId = queryParams.get('campaignId');
        const campaignIdExists = campaignId !== null && campaignId !== '';

        const brandSelect = authenticatedUser && authenticatedUser.company && authenticatedUser.company.brands && (
            <FormControl sx={{ minWidth: 100, my: 2 }}>
                <Select
                    labelId="brand-select-label"
                    value={selectedBrand.id === 0 ? '' : selectedBrand.id}
                    onChange={this.handleBrandChange}
                    displayEmpty
                    disabled={campaignIdExists}
                    sx={{
                        color: 'white',
                        borderRadius: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'white',
                        },
                        '& .MuiSelect-select': {
                            padding: '10px 20px',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        },
                    }}
                >
                    <MenuItem value="" disabled>
                        Select a brand
                    </MenuItem>
                    {authenticatedUser.company.brands.map((brand) => (
                        <MenuItem key={brand.id} value={brand.id}>
                            {brand.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );

        return (
            <div>
                <AppBar
                    position="relative"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        background: 'linear-gradient(90deg, #3f51b5, #1a237e)'
                    }}
                >
                    <Container maxWidth="xxl">
                        <Toolbar sx={{ justifyContent: 'space-between' }}>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                                {brandSelect}
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {!isMobile && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleOpenModal}
                                        sx={{
                                            fontWeight: 600,
                                            padding: '8px 16px',
                                            borderRadius: '20px',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': {
                                                backgroundColor: 'primary.dark',
                                                boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
                                            },
                                        }}
                                    >
                                        Create Content
                                    </Button>
                                )}

                                <Tooltip title="Open settings">
                                    <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar
                                            alt={imgAlt}
                                            src="/static/images/avatar/2.jpg"
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                border: '2px solid white',
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                                marginRight: isMobile ? '45px' : 0,
                                                backgroundColor: 'white',
                                                color: 'primary.dark'
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{
                                        mt: '45px',
                                        '& .MuiPaper-root': {
                                            backgroundColor: '#ffffff',
                                            borderRadius: '12px',
                                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                            minWidth: 200,
                                        },
                                    }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={this.handleCloseUserMenu}
                                >
                                    <MenuItem
                                        key="Account"
                                        onClick={() => {
                                            this.props.navigate('/account');
                                            this.handleCloseUserMenu();
                                        }}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            padding: '10px 20px',
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0',
                                            }
                                        }}
                                    >
                                        <AccountCircleIcon />
                                        <Typography fontWeight="500">Account</Typography>
                                    </MenuItem>

                                    <MenuItem
                                        key="Logout"
                                        onClick={this.handleLogout}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            padding: '10px 20px',
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0',
                                            },
                                        }}
                                    >
                                        <ExitToAppIcon />
                                        <Typography fontWeight="500">Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>

                <ChooseContentModal open={isModalOpen} handleClose={this.handleCloseModal} />
            </div>
        );
    }
}

export default withRouter(withMediaQuery(Navbar));