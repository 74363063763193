import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import industries from '../../../../services/util/industries';

class BusinessInfo extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() { }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.props.onUpdate({
            businessInfo: {
                ...this.props.businessInfo,
                [name]: value,
            },
        });
    };

    handleChangeSelect = (event, value) => {
        this.props.onUpdate({
            businessInfo: {
                ...this.props.businessInfo,
                industry: value,
                otherIndustry: value === 'Other' ? '' : undefined,
            },
        });
    };

    handleOtherIndustryChange = (event) => {
        const { value } = event.target;
        this.props.onUpdate({
            businessInfo: {
                ...this.props.businessInfo,
                otherIndustry: value,
            },
        });
    };

    render() {
        const { errors, businessInfo, loadingBrand } = this.props;

        return (
            <Container
                id="create-brand"
                sx={{
                    pt: { xs: 4, sm: 12 },
                    pb: { xs: 8, sm: 16 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 1, sm: 1 },
                }}
            >
                <Box
                    sx={{
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    {
                        this.props.activeBrand ?
                            <Typography component="h2" variant="h3" color="text.primary">
                                Your Brand
                            </Typography> :
                            <Typography component="h2" variant="h3" color="text.primary">
                                Let’s Get Started with the Basics
                            </Typography>
                    }
                    <br />
                </Box>
                <Box component="form" noValidate sx={{ width: '100%' }} onSubmit={this.handleSubmit} className='businessInfoContainer'>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Business Name"
                                name="companyName"
                                value={this.props.authenticatedUser.company ? this.props.authenticatedUser.company.name : (businessInfo.companyName || '')}
                                onChange={this.handleChange}
                                error={!!errors.companyName}
                                helperText={errors.companyName}
                                disabled={!!this.props.authenticatedUser.company}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ position: 'relative' }}>
                                <TextField
                                    fullWidth
                                    label="Brand Name"
                                    name="brandName"
                                    value={businessInfo.brandName}
                                    onChange={this.handleChange}
                                    error={!!errors.brandName}
                                    helperText={errors.brandName}
                                    disabled={loadingBrand}
                                />
                                {loadingBrand && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            marginTop: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                options={industries}
                                getOptionLabel={(option) => option}
                                value={businessInfo.industry}
                                onChange={this.handleChangeSelect}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Industry"
                                        variant="outlined"
                                        error={!!errors.industry}
                                    />
                                )}
                            />
                        </Grid>
                        {businessInfo.industry && businessInfo.industry === 'Other' && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Specify Your Industry"
                                    name="otherIndustry"
                                    value={businessInfo.otherIndustry || ''}
                                    onChange={this.handleOtherIndustryChange}
                                    error={!!errors.industry}
                                    helperText={errors.industry}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                        Choose the industry your brand operates in, not your customers' industry. If your industry isn’t listed, select "Other" and provide details manually.
                    </Typography>
                </Box>
            </Container>
        );
    }
}

export default BusinessInfo;