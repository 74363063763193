import React from 'react';
import { ButtonGroup, IconButton, Select, MenuItem, Tooltip } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'; // Unordered List
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'; // Ordered List

const Toolbar = ({ toggleInlineStyle, toggleBlockType, setAlignment, setFontSize, setColor }) => {
    const fontSizes = [12, 14, 16, 18, 20, 24, 28, 32];
    const colors = ['#000000', '#FF0000', '#00FF00', '#0000FF', '#FFA500'];

    return (
        <ButtonGroup variant="outlined" aria-label="text formatting" sx={{ marginBottom: 0 }}>
            <Tooltip title="Bold">
                <IconButton onMouseDown={(e) => toggleInlineStyle(e, 'BOLD')} aria-label="bold">
                    <FormatBoldIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Italic">
                <IconButton onMouseDown={(e) => toggleInlineStyle(e, 'ITALIC')} aria-label="italic">
                    <FormatItalicIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Underline">
                <IconButton onMouseDown={(e) => toggleInlineStyle(e, 'UNDERLINE')} aria-label="underline">
                    <FormatUnderlinedIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Font Size">
                <Select
                    value=""
                    displayEmpty
                    onChange={(e) => setFontSize(e.target.value)}
                    variant="standard"
                    sx={{ marginLeft: 2 }}
                >
                    <MenuItem value="" disabled>
                        <TextFieldsIcon />
                    </MenuItem>
                    {fontSizes.map((size) => (
                        <MenuItem key={size} value={size}>
                            {size}px
                        </MenuItem>
                    ))}
                </Select>
            </Tooltip>

            <Tooltip title="Text Color">
                <Select
                    value=""
                    displayEmpty
                    onChange={(e) => setColor(e.target.value)}
                    variant="standard"
                    sx={{ marginLeft: 2 }}
                >
                    <MenuItem value="" disabled>
                        <ColorLensIcon />
                    </MenuItem>
                    {colors.map((color) => (
                        <MenuItem key={color} value={color}>
                            <span
                                style={{
                                    backgroundColor: color,
                                    display: 'inline-block',
                                    width: 20,
                                    height: 20,
                                    borderRadius: '50%',
                                }}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </Tooltip>

            <Tooltip title="Unordered List">
                <IconButton onMouseDown={(e) => toggleBlockType(e, 'unordered-list-item')} aria-label="unordered list">
                    <FormatListBulletedIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Ordered List">
                <IconButton onMouseDown={(e) => toggleBlockType(e, 'ordered-list-item')} aria-label="ordered list">
                    <FormatListNumberedIcon />
                </IconButton>
            </Tooltip>
        </ButtonGroup>
    );
};

export default Toolbar;