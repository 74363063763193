import config from '../../config';
import Cookies from 'js-cookie';
import JwtService from "../security/JwtService";

class ApiCalls {
  constructor() {
    this.refreshTokenPromise = null;
  }

  async request(url, method, queryParams = {}, body = null, requiresAuth = true, secondCall = false) {
    try {
      const headers = { 'Content-Type': 'application/json' };
      if (requiresAuth) {
        const token = Cookies.get('token');
        headers['Authorization'] = `Bearer ${token}`;
      }

      const response = await fetch(`${config.apiUrl + url}?${new URLSearchParams(queryParams)}`, {
        method,
        headers,
        body: body ? JSON.stringify(body) : null,
      });

      if (!response.ok) {
        return this.handleErrorResponse(response, url, method, queryParams, body, requiresAuth, secondCall);
      }

      if (response.status === 204) {
        return null;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      if (error.message.includes('Failed to fetch') || error.message.includes('ERR_CONNECTION_REFUSED')) {
        console.error('Network error:', error);
        window.location.href = '/maintenance';
      }
      console.error('error:', error);
      throw error;
    }
  }

  async handleErrorResponse(response, url, method, queryParams, body, requiresAuth, secondCall) {
    if (response.status === 401) {
      if (!this.refreshTokenPromise) {
        this.refreshTokenPromise = this.refreshToken();
      }
      const stillActive = await this.refreshTokenPromise;
      this.refreshTokenPromise = null;

      if (stillActive && !secondCall) {
        return this.request(url, method, queryParams, body, requiresAuth, true);
      } else {
        this.removeCookies();
        window.location.href = '/login';
      }
    } else if (response.status === 402) {
      window.location.href = '/subscriptions';
    } else if (response.status === 307) {
      // in case we create for users that have no brand
    } else {
      const error = await response.json();
      throw error;
    }
  }

  async defaultGet(url, queryParams, secondCall = false) {
    return this.request(url, 'GET', queryParams, null, true, secondCall);
  }

  async defaultDelete(url, queryParams, secondCall = false) {
    return this.request(url, 'DELETE', queryParams, null, true, secondCall);
  }

  async defaultPost(url, queryParams, body, secondCall = false) {
    return this.request(url, 'POST', queryParams, body, true, secondCall);
  }

  async defaultGetNoToken(url, queryParams) {
    return this.request(url, 'GET', queryParams, null, false);
  }

  async defaultPostNoToken(url, queryParams, body) {
    return this.request(url, 'POST', queryParams, body, false);
  }

  async refreshToken() {
    try {
      const refreshToken = Cookies.get('refreshToken');
      const response = await fetch(`${config.apiUrl}/refresh`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken, username: localStorage.getItem('sub') }),
      });

      const resultJson = await response.json();
      if (resultJson && resultJson.code === 1004) {
        return false;
      } else {
        const decodedToken = JwtService.decodeJwt(resultJson.token);
        const tokenExpiresAt = new Date(Date.now() + (decodedToken.payload.exp - decodedToken.payload.iat) * 1000);

        // unsecure for local development todo: change to secure for prod
        // Cookies.set('token', resultJson.token, { secure: true, sameSite: 'None', expires: tokenExpiresAt });
        Cookies.set('token', resultJson.token, { expires: tokenExpiresAt });

        return true;
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  removeCookies() {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    localStorage.clear();
  }
}

export default new ApiCalls();