import React, { Component } from "react";
import { Box, Typography, List, ListItem, Link } from "@mui/material";

class DataProcessingAgreement extends Component {
    render() {
        return (
            <Box sx={{ padding: "2rem", maxWidth: "800px", margin: "0 auto" }}>
                <Typography variant="h4" gutterBottom>
                    Data Processing Agreement
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>Effective Date:</strong> 20 December 2024
                </Typography>

                <Typography variant="body1" paragraph>
                    This Data Processing Agreement (“DPA”) is entered into by and between Brand Bounce (“Processor”) and the user of Brand Bounce services (“Controller”) to ensure compliance with applicable data protection laws and regulations.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Definitions
                </Typography>
                <Typography variant="body1" paragraph>
                    For the purposes of this DPA:
                </Typography>
                <List>
                    <ListItem>
                        <strong>“Controller”</strong> means the natural or legal person who determines the purposes and means of the processing of personal data.
                    </ListItem>
                    <ListItem>
                        <strong>“Processor”</strong> means the entity which processes personal data on behalf of the Controller.
                    </ListItem>
                    <ListItem>
                        <strong>“Personal Data”</strong> means any information relating to an identified or identifiable natural person.
                    </ListItem>
                    <ListItem>
                        <strong>“Processing”</strong> means any operation performed on personal data, such as collection, storage, use, or sharing.
                    </ListItem>
                    <ListItem>
                        <strong>“Applicable Data Protection Laws”</strong> includes the General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and other relevant regulations.
                    </ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    2. Subject Matter and Scope
                </Typography>
                <Typography variant="body1" paragraph>
                    This DPA applies to the processing of personal data that the Controller provides to the Processor in connection with the use of Brand Bounce services. The Processor agrees to process personal data solely for the purposes outlined in this DPA or as otherwise instructed by the Controller.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    3. Obligations of the Processor
                </Typography>
                <Typography variant="body1" paragraph>
                    The Processor agrees to:
                </Typography>
                <List>
                    <ListItem>
                        Process personal data only in accordance with the Controller’s documented instructions.
                    </ListItem>
                    <ListItem>
                        Implement appropriate technical and organizational measures to ensure the security of personal data.
                    </ListItem>
                    <ListItem>
                        Ensure that personnel authorized to process personal data are subject to confidentiality obligations.
                    </ListItem>
                    <ListItem>
                        Assist the Controller in ensuring compliance with applicable data protection laws, including responding to data subject requests and performing data protection impact assessments.
                    </ListItem>
                    <ListItem>
                        Notify the Controller without undue delay in the event of a data breach affecting personal data.
                    </ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    4. Obligations of the Controller
                </Typography>
                <Typography variant="body1" paragraph>
                    The Controller agrees to:
                </Typography>
                <List>
                    <ListItem>
                        Ensure that personal data is collected and provided to the Processor in compliance with applicable data protection laws.
                    </ListItem>
                    <ListItem>
                        Provide lawful instructions for the processing of personal data.
                    </ListItem>
                    <ListItem>
                        Maintain accurate and up-to-date records of processing activities.
                    </ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    5. Sub-Processors
                </Typography>
                <Typography variant="body1" paragraph>
                    The Processor may engage sub-processors to process personal data. The Processor shall:
                </Typography>
                <List>
                    <ListItem>
                        Obtain the Controller’s prior written authorization before engaging any sub-processor.
                    </ListItem>
                    <ListItem>
                        Ensure that sub-processors are bound by contractual obligations consistent with this DPA.
                    </ListItem>
                    <ListItem>
                        Remain liable for the actions of sub-processors.
                    </ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    6. Data Transfers
                </Typography>
                <Typography variant="body1" paragraph>
                    If the processing of personal data involves transferring data outside the European Economic Area (EEA) or other applicable jurisdictions, the Processor agrees to ensure that appropriate safeguards are in place, such as:
                </Typography>
                <List>
                    <ListItem>Standard Contractual Clauses (SCCs) approved by the European Commission.</ListItem>
                    <ListItem>Binding Corporate Rules (BCRs).</ListItem>
                    <ListItem>Other lawful mechanisms permitted under applicable data protection laws.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    7. Data Retention and Deletion
                </Typography>
                <Typography variant="body1" paragraph>
                    Upon termination of the agreement or at the Controller’s request, the Processor shall:
                </Typography>
                <List>
                    <ListItem>Delete or return all personal data to the Controller, unless required by law to retain the data.</ListItem>
                    <ListItem>Ensure that no copies of personal data remain in the Processor’s systems, except as required for legal or regulatory compliance.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    8. Liability and Indemnification
                </Typography>
                <Typography variant="body1" paragraph>
                    Each party shall be liable for damages caused by the processing of personal data only to the extent of its responsibility under applicable data protection laws.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    9. Amendments
                </Typography>
                <Typography variant="body1" paragraph>
                    This DPA may be amended by mutual written agreement of the parties to reflect changes in applicable data protection laws or processing activities.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    10. Contact Information
                </Typography>
                <Typography variant="body1" paragraph>
                    For questions or concerns regarding this DPA, please contact us at:
                </Typography>
                <List>
                    <ListItem>Email: <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link></ListItem>
                    <ListItem>Address: Șos. Pantelimon 311, Bucharest, Romania</ListItem>
                </List>
            </Box>
        );
    }
}

export default DataProcessingAgreement;