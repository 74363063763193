import React, { Component } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";

class RefundPolicy extends Component {
    render() {
        return (
            <Box sx={{ padding: "2rem", maxWidth: "800px", margin: "0 auto" }}>
                <Typography variant="h4" gutterBottom>
                    Refund and Cancellation Policy
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Effective Date:</strong> 20 December 2024
                </Typography>

                <Typography variant="body1" paragraph>
                    At Brand Bounce, we value transparency and strive to ensure your satisfaction with our services.
                    This Refund and Cancellation Policy outlines the terms and conditions related to the cancellation
                    of subscriptions and refunds for services provided through our platform.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Subscription Plans
                </Typography>
                <Typography variant="body1" paragraph>
                    Brand Bounce operates on a subscription-based model with recurring billing cycles. You can view
                    the details of available plans, features, and pricing on our website.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Cancellation Policy
                </Typography>
                <Typography variant="h6" gutterBottom>
                    2.1 How to Cancel
                </Typography>
                <Typography variant="body1" paragraph>
                    You can cancel your subscription at any time through your account dashboard:
                </Typography>
                <List>
                    <ListItem>Go to your Account Settings page.</ListItem>
                    <ListItem>Select the Plan tab from the menu.</ListItem>
                    <ListItem>Click on Manage Your Subscription to proceed.</ListItem>
                    <ListItem>You’ll be redirected to Stripe, where you can cancel your subscription.</ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    Alternatively, you may contact our support team at&nbsp;
                    <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link>&nbsp;
                    for assistance with cancellations.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2.2 When Cancellation Takes Effect
                </Typography>
                <Typography variant="body1" paragraph>
                    - <strong>Monthly Subscriptions:</strong> Cancellation will take effect at the end of the current billing cycle. You will retain access to the Services until the billing period ends.
                </Typography>
                <Typography variant="body1" paragraph>
                    - <strong>Annual Subscriptions:</strong> Cancellation will take effect at the end of the annual subscription term. No refunds will be issued for the unused portion of the subscription.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    3. Refund Policy
                </Typography>
                <Typography variant="h6" gutterBottom>
                    3.1 General Refund Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Refunds are not provided for:
                </Typography>
                <List>
                    <ListItem>Partially used subscriptions.</ListItem>
                    <ListItem>Unused portions of a billing cycle.</ListItem>
                    <ListItem>Services or features already delivered or utilized.</ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    3.2 Exceptional Refunds
                </Typography>
                <Typography variant="body1" paragraph>
                    Refunds may be granted at our sole discretion in the following cases:
                </Typography>
                <List>
                    <ListItem>Billing Errors: If a billing error occurs due to a technical issue or system malfunction.</ListItem>
                    <ListItem>Unauthorized Charges: If unauthorized charges are identified and reported within 14 days of the transaction.</ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    To request a refund, please contact our support team at&nbsp;
                    <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link> and provide the following details:
                </Typography>
                <List>
                    <ListItem>Your account email address.</ListItem>
                    <ListItem>The transaction ID or receipt of the payment.</ListItem>
                    <ListItem>A brief explanation of the issue.</ListItem>
                </List>

                <Typography variant="h5" gutterBottom>
                    4. Downgrades or Modifications to Subscription
                </Typography>
                <Typography variant="body1" paragraph>
                    - If you choose to downgrade your subscription plan, the change will take effect at the start of the next billing cycle.
                </Typography>
                <Typography variant="body1" paragraph>
                    - Subscription modifications (e.g., adding or removing users) will adjust the billing amount accordingly for the next billing period. Refunds will not be issued for unused features in the current billing cycle.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    5. Free Trials
                </Typography>
                <Typography variant="body1" paragraph>
                    - If you sign up for a free trial and do not cancel before the trial ends, your account will automatically transition to a paid subscription, and you will be charged according to the selected plan.
                </Typography>
                <Typography variant="body1" paragraph>
                    - No refunds are provided for charges incurred due to the non-cancellation of a free trial.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Payment Disputes
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have a payment-related issue, please contact us at&nbsp;
                    <Link href="mailto:office@brand-bounce.com">office@brand-bounce.com</Link>&nbsp;
                    within 14 days of the transaction. We will work with you to resolve the matter promptly.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Termination by Brand Bounce
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to terminate your subscription under our&nbsp;
                    <Link href="/terms" target="_blank" rel="noopener">
                        Terms and Conditions
                    </Link>
                    , including cases of breach or prohibited activities. In such instances, refunds will not be provided.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Changes to the Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this Refund and Cancellation Policy from time to time. If changes are significant, we will notify you via email or through the platform. Continued use of our Services constitutes acceptance of the updated policy.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    9. Contact Information
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about this policy, please reach out to us:
                </Typography>
                <List>
                    <ListItem>Email: office@brand-bounce.com</ListItem>
                    <ListItem>Address: Șos. Pantelimon 311, Bucharest, Romania</ListItem>
                </List>
            </Box>
        );
    }
}

export default RefundPolicy;