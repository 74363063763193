// src/components/shared/Sidebar.js
import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Navbar from './Navbar';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuItems from './MenuItems';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import getTheme from '../getTheme';
import UserService from '../../services/UserService';
import CircularProgress from '@mui/material/CircularProgress';
import ChatWidget from '../chat/ChatWidget';
import { Card } from '@mui/material';
import withMediaQuery from '../../services/withMediaQuery';

const theme = createTheme(getTheme());

const drawerWidth = '250px';
const miniDrawerWidth = '56px';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: localStorage.getItem('sidebarOpen') === 'true',
            authenticatedUser: {},
            selectedBrand: localStorage.getItem('selectedBrand') ? JSON.parse(localStorage.getItem('selectedBrand')) : {
                id: 0,
                name: ''
            },
            appLoading: true,
        };
    }

    componentDidMount() {
        UserService.getAuthenticatedUserInfo().then(authenticatedUser => {
            if (authenticatedUser && authenticatedUser.company && authenticatedUser.company.brands.length === 1) {
                localStorage.setItem('selectedBrand', JSON.stringify({
                    id: authenticatedUser.company.brands[0].id,
                    name: authenticatedUser.company.brands[0].name
                }));
                this.setState({
                    selectedBrand: {
                        id: authenticatedUser.company.brands[0].id,
                        name: authenticatedUser.company.brands[0].name
                    },
                });
            } else if (authenticatedUser && !authenticatedUser.company || !authenticatedUser.company.brands || authenticatedUser.company.brands.length === 0) {
                const currentUrl = window.location.href;
                const currentPath = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

                if (!currentPath.includes('create-brand')) {
                    let newUrl = '/create-brand';
                    window.location.href = newUrl;
                }
            }

            this.setState({
                authenticatedUser,
                currentPlan: authenticatedUser.currentPlan,
                appLoading: false,
            });
        }).catch(error => {
            console.error(error);
        });
    }

    updateAuthenticatedUser = (updatedUser) => {
        this.setState({ authenticatedUser: updatedUser });
    };

    updateSelectedBrand = (selectedBrand) => {
        this.setState({ selectedBrand });
    };

    handleDrawerToggle = () => {
        this.setState((prevState) => ({
            open: !prevState.open,
        }));
        localStorage.setItem('sidebarOpen', !this.state.open);
    };

    render() {
        const { open, authenticatedUser, selectedBrand, appLoading, currentPlan } = this.state;
        const { isMobile } = this.props;

        const hasBrands = authenticatedUser && authenticatedUser.company && authenticatedUser.company.brands && authenticatedUser.company.brands.length > 0;

        if (appLoading) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            );
        }

        return (
                <Box sx={{ display: 'flex', width: '100%', height: '100%', overflow: 'hidden' }}>
                    <CssBaseline />
                    <Drawer
                        variant="permanent"
                        sx={{
                            width: miniDrawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: {
                                width: miniDrawerWidth,
                                boxSizing: 'border-box',
                                backgroundColor: '#fff',
                            },
                        }}>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            <IconButton onClick={this.handleDrawerToggle}>
                                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </Toolbar>
                        {
                            hasBrands && <MenuItems selectedBrand={selectedBrand} authenticatedUser={authenticatedUser}
                                                    open={false} />
                        }
                    </Drawer>
                    <Drawer
                        variant={open ? "persistent" : "temporary"}
                        open={open}
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                                backgroundColor: '#fff',
                            },
                        }}>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <img
                                src="/logo.png"
                                alt="logo"
                                style={{ display: { xs: 'none', md: 'flex' }, marginRight: '8px', width: '40px' }}
                            />
                            <IconButton onClick={this.handleDrawerToggle}>
                                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </Toolbar>
                        {
                            hasBrands &&
                            <MenuItems selectedBrand={selectedBrand} authenticatedUser={authenticatedUser} open={true} />
                        }
                    </Drawer>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginLeft: open ? '-' + miniDrawerWidth : 0
                    }}>
                        <Box sx={{ backgroundColor: '#fff', width: '100%' }}>
                            <Navbar
                                selectedBrand={selectedBrand}
                                authenticatedUser={authenticatedUser}
                                updateSelectedBrand={this.updateSelectedBrand}
                            />
                        </Box>
                        <Box sx={{ backgroundColor: '#fff', flexGrow: 1, overflow: 'auto' }}>
                            <Box sx={{ backgroundColor: '#edeefd', padding: isMobile ? 0 : 4 }}>
                                <Card sx={{
                                    padding: 2,
                                    minHeight: '330px',
                                }}>
                                    {React.cloneElement(this.props.content, {
                                        authenticatedUser: authenticatedUser,
                                        updateAuthenticatedUser: this.updateAuthenticatedUser,
                                        updateSelectedBrand: this.updateSelectedBrand,
                                        selectedBrand: selectedBrand,
                                        currentPlan: currentPlan,
                                        isMobile: isMobile,
                                        sidebarOpen: open
                                    })}
                                </Card>
                            </Box>
                            <ChatWidget
                                selectedBrand={selectedBrand}
                            />
                        </Box>
                    </Box>
                </Box>
        );
    }
}

export default withMediaQuery(Sidebar);