import React, { Component } from 'react';
import Box from '@mui/material/Box';
import { Fade, CircularProgress } from '@mui/material';

class FullLoading extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: this.props.loading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    zIndex: 9999,
                    pointerEvents: 'auto',
                }}
            >
                <Fade
                    in={this.props.loading}
                    style={{
                        transitionDelay: this.props.loading ? '800ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <CircularProgress />
                </Fade>
            </Box>

        );
    }

}

export default FullLoading;