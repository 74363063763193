import React, { Component } from 'react';
import { withRouter } from '../../services/security/withRouter';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PasswordResetService from '../../services/security/PasswordResetService';
import getTheme from "../getTheme";

const theme = createTheme(getTheme());

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: {},
            errorResponse: {},
            successMessage: '',
            isButtonDisabled: false, // New state variable
        };
    }

    validateForm = () => {
        const { email } = this.state;
        let errors = {};

        if (!email) {
            errors.email = 'Please enter your email address.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Please enter a valid email address.';
        }

        this.setState({ errors });

        return Object.keys(errors).length === 0;
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validateForm()) {
            this.setState({ errorResponse: '', successMessage: '', isButtonDisabled: true }); // Disable the button

            this.handleForgotPassword();

            // Re-enable the button after 60 seconds
            setTimeout(() => {
                this.setState({ isButtonDisabled: false });
            }, 60000);
        }
    };

    handleForgotPassword = async () => {
        const response = await PasswordResetService.forgotPassword(this.state.email);
        if (response.error) {
            this.setState({ errorResponse: response });
        } else {
            this.setState({ successMessage: 'We’ve sent a password reset link to your email. Please check your inbox and follow the instructions.' });
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    render() {
        const { errors, isButtonDisabled } = this.state;

        return (
            <div>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Forgot Password
                            </Typography>
                            <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                    </Grid>
                                </Grid>
                                <FormHelperText>We’ve sent a password reset link to your email. Please check your inbox and follow the instructions.</FormHelperText>

                                {this.state.errorResponse && <FormHelperText error>{this.state.errorResponse.message}</FormHelperText>}
                                {this.state.successMessage && <FormHelperText style={{ color: 'green' }}>{this.state.successMessage}</FormHelperText>}

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={isButtonDisabled}
                                >
                                    Send Reset Link
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            Back to Sign In
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </div>
        );
    }
}

export default withRouter(ForgotPassword);