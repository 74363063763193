import React, {Component} from 'react';
import {
    Box,
    Grid,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Tooltip,
    Skeleton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button,
    CircularProgress,
    Backdrop
} from '@mui/material';
import {DataGrid, GridDeleteIcon} from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import PlusIcon from '@mui/icons-material/Add';
import ConfirmDialog from '../shared/ConfirmDialog';
import CreateUser from '../create/CreateUser';
import UserService from '../../services/UserService';
import StripeSubscriptionService from '../../services/payment/StripeSubscriptionService';
import {withRouter} from '../../services/security/withRouter';
import RightsService from '../../services/security/RightsService';
import CustomSnackbar from "../shared/CustomSnackbar";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadDelete: false,
            loadEdit: false,
            loadCreate: false,
            errors: {},
            errorResponse: {},
            editDialogOpen: false,
            deleteDialogOpen: false,
            snackbars: [],
            users: [],
            roles: [],
            selectedUserIds: [],
            loading: true,
            isDeleting: false,
            availableSeats: null,
            seatPrice: 0,
            seatInterval: '',
            buySeatsDialogOpen: false,
            removeSeatsDialogOpen: false,
            quantity: 1,
            fullScreenLoading: false,
        };
    }

    componentDidMount() {
        if (!RightsService.hasRight('USERS_EDIT')) {
            setTimeout(() => {
                this.props.navigate('/dashboard');
            }, 0);
            return;
        }

        UserService.getAll()
            .then((users) => {
                this.setState({users, loading: false}); // Set loading to false after data is fetched
            })
            .catch((error) => {
                this.addSnackbar(error.message, 'error');
                this.setState({loading: false}); // Set loading to false in case of error
            });

        UserService.getAllRoles()
            .then((roles) => {
                this.setState({roles});
            })
            .catch((error) => {
                // this.addSnackbar('Unable to fetch roles. Please refresh the page or try again later.', 'error');
            });

        UserService.getAvailableSeats()
            .then((response) => {
                this.setState({
                    availableSeats: response.quantity,
                    seatPrice: response.seatPrice,
                    seatInterval: response.seatInterval
                });
            })
            .catch((error) => {
                this.addSnackbar('Unable to fetch available seats. Please try again later.', 'error');
            });
    }

    handleRoleChange = (event, id, firstName) => {
        const usersCopy = [...this.state.users];
        const updatedUsers = this.state.users.map((user) =>
            user.id === id ? {...user, roleId: event.target.value} : user
        );

        this.setState({users: updatedUsers});

        UserService.updateUserRole({userId: id, roleId: event.target.value})
            .then(() => {
                this.addSnackbar(`User role updated successfully. Please ask ${firstName} to login again.`, 'success');
            })
            .catch((error) => {
                this.setState({
                    users: usersCopy,
                });
                this.addSnackbar(error.message, 'error');
            });
    };

    handleClickOpen = () => {
        this.setState({editDialogOpen: true});
    };

    handleClose = () => {
        this.setState({editDialogOpen: false});
    };

    handleDeleteDialogOpen = () => {
        this.setState({deleteDialogOpen: true});
    };

    handleDeleteDialogClose = () => {
        this.setState({deleteDialogOpen: false});
    };

    handleDeleteDialogConfirm = () => {
        this.setState({isDeleting: true}); // Set isDeleting to true

        UserService.deleteUsers(this.state.selectedUserIds)
            .then(() => {
                this.setState({
                    users: this.state.users.filter((user) => !this.state.selectedUserIds.includes(user.id)),
                    isDeleting: false, // Set isDeleting to false
                });
                this.addSnackbar('Selected user(s) have been deleted successfully.', 'success');
            })
            .catch((error) => {
                this.setState({isDeleting: false}); // Set isDeleting to false
                this.addSnackbar('There was an error deleting the users.', 'error');
            });
        this.setState({deleteDialogOpen: false});
    };

    onSubmitUser = (formData, callback) => {
        this.setState({loadCreate: true});

        const formJson = Object.fromEntries(formData.entries());

        UserService.createUser({
            firstName: formJson.firstName,
            lastName: formJson.lastName,
            email: formJson.email,
            phoneNo: formJson.phoneNo,
            roleId: formJson.role,
        })
            .then((users) => {
                this.setState({
                    users,
                    loadCreate: false,
                });
                this.addSnackbar('User created successfully. They will receive an email with instructions to set up their account.', 'success');
                callback();
            })
            .catch((error) => {
                this.setState({loadCreate: false});
                this.addSnackbar(error.message, 'error');
            });

        this.handleClose();
    };

    handleSnackbarClose = (key) => {
        this.removeSnackbar(key);
    }

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {
                message,
                severity,
                key: new Date().getTime(),
                index: prevState.snackbars.length
            }],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    handleBuySeatsClick = () => {
        this.setState({buySeatsDialogOpen: true});
    };

    handleBuySeatsClose = () => {
        this.setState({buySeatsDialogOpen: false});
    };

    handleBuySeatsConfirm = () => {
        this.setState({fullScreenLoading: true}); // Set full-screen loading state to true
        const {quantity} = this.state;
        StripeSubscriptionService.getSeatsUrl(quantity)
            .then((response) => {
                window.location.href = response.url;
            })
            .catch((error) => {
                this.addSnackbar('Unable to get the URL. Please try again later.', 'error');
            })
            .finally(() => {
                this.setState({fullScreenLoading: false, buySeatsDialogOpen: false}); // Set full-screen loading state to false
            });
    };

    handleRemoveSeatsClick = () => {
        this.setState({removeSeatsDialogOpen: true});
    };

    handleRemoveSeatsClose = () => {
        this.setState({removeSeatsDialogOpen: false});
    };

    handleRemoveSeatsConfirm = () => {
        this.setState({fullScreenLoading: true}); // Set full-screen loading state to true
        const {quantity} = this.state;
        StripeSubscriptionService.removeSeats(quantity)
            .then(() => {
                this.addSnackbar('Seats removed successfully.', 'success');
                UserService.getAvailableSeats()
                    .then((response) => {
                        this.setState({availableSeats: this.state.availableSeats - quantity});
                    })
                    .catch((error) => {
                        this.addSnackbar('Unable to fetch available seats. Please try again later.', 'error');
                    });
            })
            .catch((error) => {
                this.addSnackbar(error.message, 'error');
            })
            .finally(() => {
                this.setState({fullScreenLoading: false, removeSeatsDialogOpen: false}); // Set full-screen loading state to false
            });
    };

    handleQuantityChange = (event) => {
        const {value} = event.target;
        const {availableSeats, users} = this.state;
        const maxQuantity = availableSeats - users.length + 1;

        if (value === '' || (value >= 1 && value <= maxQuantity)) {
            this.setState({quantity: value});
        }
    };

    handleQuantityChangeBuySeats = (event) => {
        const {value} = event.target;
        const maxQuantity = 50;

        if (value === '' || (value >= 1 && value <= maxQuantity)) {
            this.setState({quantity: value});
        }
    };

    render() {
        const {isMobile} = this.props;
        const {
            loading,
            users,
            loadCreate,
            isDeleting,
            availableSeats,
            seatPrice,
            seatInterval,
            buySeatsDialogOpen,
            removeSeatsDialogOpen,
            quantity,
            fullScreenLoading
        } = this.state;

        const columns = [
            {field: 'firstName', headerName: 'First Name', flex: 1},
            {field: 'lastName', headerName: 'Last Name', flex: 1},
            {field: 'email', headerName: 'Email', flex: 1.5},
            {field: 'phoneNo', headerName: 'Phone Number', flex: 1},
            {
                field: 'role',
                headerName: 'Role',
                flex: 1.2,
                renderCell: (params) => (
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            value={params.row.roleId || ''}
                            onChange={(event) => this.handleRoleChange(event, params.row.id, params.row.firstName)}
                            disabled={params.row.primary || this.props.authenticatedUser.id === params.row.id}
                        >
                            {this.state.roles.map((role) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ),
            }
        ];

        const seatPriceUSD = (seatPrice / 100).toFixed(2);

        return (
            <>
                    <Typography variant="h4" gutterBottom>
                        Manage Users
                    </Typography>
                {availableSeats !== null ? (
                    <Box sx={{marginBottom: 2}}>
                        <Typography variant="body1" color="textSecondary">
                            <b>Users / Seats: </b> {users.length} / {availableSeats}
                        </Typography>
                        {users.length === availableSeats && (
                            <Typography variant="body2" color="error">
                                You have reached the maximum number of users for the available seats. To add more users, please click on the "Buy Seats" button.
                            </Typography>
                        )}
                    </Box>
                ) : (
                        <Skeleton variant="text" width={210} height={30}/>
                    )}
                    <Grid container justifyContent="space-between" alignItems="center" sx={{marginBottom: 3}}>
                        {!isMobile && (
                            <Grid item>
                                <Typography variant="body1" color="textSecondary">
                                    View, edit, and manage user roles within your organization.
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            {availableSeats !== null &&
                                <>
                                    <Tooltip title="Add a new user">
                                        <LoadingButton
                                            onClick={this.handleClickOpen}
                                            startIcon={<PlusIcon/>}
                                            variant="contained"
                                            color="primary"
                                            loading={loadCreate}
                                            sx={{marginRight: 2}}
                                            disabled={availableSeats === (users.length)}
                                        >
                                            Create User
                                        </LoadingButton>
                                    </Tooltip>

                                    <Tooltip title="Delete selected users">
                                        <LoadingButton
                                            onClick={this.handleDeleteDialogOpen}
                                            startIcon={<GridDeleteIcon/>}
                                            variant="contained"
                                            color="error"
                                            loading={isDeleting} // Use isDeleting state
                                            sx={{marginRight: 2}}
                                        >
                                            Delete
                                        </LoadingButton>
                                    </Tooltip>

                                    <Tooltip title="Buy more seats">
                                        <LoadingButton
                                            onClick={this.handleBuySeatsClick}
                                            startIcon={<PlusIcon/>}
                                            variant="contained"
                                            color="primary"
                                            sx={{marginRight: 2}}
                                        >
                                            Buy Seats
                                        </LoadingButton>
                                    </Tooltip>

                                    <Tooltip title="Remove seats">
                                        <LoadingButton
                                            onClick={this.handleRemoveSeatsClick}
                                            startIcon={<RemoveOutlinedIcon/>}
                                            variant="contained"
                                            color="error"
                                            disabled={availableSeats === (users.length)}
                                            sx={{marginRight: 2}}
                                        >
                                            Remove Seats
                                        </LoadingButton>
                                    </Tooltip>
                                </>
                            }
                        </Grid>
                    </Grid>
                    {loading ? (
                        <Grid container spacing={2}>
                            {Array.from(new Array(2)).map((_, index) => (
                                <Grid item xs={12} key={index}>
                                    <Skeleton variant="rectangular" height={50}/>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        users.length > 0 ? (
                            <DataGrid
                                key={this.props.sidebarOpen}
                                rows={users}
                                columns={columns}
                                pageSizeOptions={[10, 25, 50, 100]}
                                checkboxSelection
                                disableRowSelectionOnClick
                                isRowSelectable={(params) => !params.row.primary}
                                onRowSelectionModelChange={(ids) => this.setState({selectedUserIds: ids})}
                            />
                        ) : (
                            <Typography variant="h6" align="center">
                                No users available. Start by creating one!
                            </Typography>
                        )
                    )}

                <CreateUser
                    editDialogOpen={this.state.editDialogOpen}
                    handleClose={this.handleClose}
                    onSubmitUser={this.onSubmitUser}
                    roles={this.state.roles}
                />

                <ConfirmDialog
                    dialogOpen={this.state.deleteDialogOpen}
                    handleClose={this.handleDeleteDialogClose}
                    handleConfirm={this.handleDeleteDialogConfirm}
                    dialogTitle="Delete Users"
                    dialogMessage="Are you sure you want to delete the selected user(s)?"
                />

                <Dialog open={buySeatsDialogOpen} onClose={this.handleBuySeatsClose}>
                    <DialogTitle>Buy Seats</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the number of seats you want to buy. You will be charged for the seats for the
                            remaining billing period.
                            This will increase your subscription by
                            ${(quantity * seatPriceUSD).toFixed(2)} per {seatInterval}.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Quantity"
                            type="number"
                            fullWidth
                            value={quantity}
                            onChange={this.handleQuantityChangeBuySeats}
                            inputProps={{min: 1, max: 50}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleBuySeatsClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleBuySeatsConfirm} color="primary" disabled={!quantity}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={removeSeatsDialogOpen} onClose={this.handleRemoveSeatsClose}>
                    <DialogTitle>Remove Seats</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the number of seats you want to remove.
                            This will decrease your subscription by
                            ${(quantity * seatPriceUSD).toFixed(2)} per {seatInterval}.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Quantity"
                            type="number"
                            fullWidth
                            value={quantity}
                            onChange={this.handleQuantityChange}
                            inputProps={{min: 1, max: availableSeats - users.length + 1}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveSeatsClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleRemoveSeatsConfirm} color="primary" disabled={!quantity}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.snackbars.map((snackbar, index) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.handleSnackbarClose(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                        style={{bottom: `${index * 60 + 20}px`}}
                    />
                ))}

                <Backdrop open={fullScreenLoading} sx={{color: '#fff', zIndex: 10000}}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }
}

export default withRouter(Users);