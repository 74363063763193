import React, { Component } from 'react';
import { Box, Typography, Card, CardContent, IconButton, Divider, Skeleton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import StripeSubscriptionService from '../../services/payment/StripeSubscriptionService';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

class Invoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            loading: true,
            open: false,
        };
    }

    componentDidMount() {
        StripeSubscriptionService.getInvoices().then(invoices => {
            this.setState({ invoices, loading: false });
        }).catch(error => {
            this.setState({ open: true, loading: false });
            console.error(error);
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { invoices, loading, open } = this.state;

        const action = (
            <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        );

        return (
            <Box sx={{ padding: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Billing History
                </Typography>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={400} />
                ) : (
                    <Box>
                        {invoices.length > 0 ? (
                            invoices.map((invoice, index) => (
                                <Card key={index} sx={{ mb: 1, p: 1 }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box>
                                                <Typography variant="body2">
                                                    Date: {new Date(invoice.date).toLocaleDateString()}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Amount: ${invoice.amount / 100}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Status: {invoice.status}
                                                </Typography>
                                            </Box>
                                            <IconButton href={invoice.link} target="_blank">
                                                <DownloadIcon />
                                            </IconButton>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                No invoices available.
                            </Typography>
                        )}
                    </Box>
                )}
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    message="Unable to load the invoices. Please try again later or contact support."
                    action={action}
                />
            </Box>
        );
    }
}

export default Invoices;