import React from 'react';
import { Box, Grid, TextField, FormControlLabel, Checkbox } from '@mui/material';
import ParameterInputField from './ParameterInputField';
import moment from 'moment';

const InputsGrid = ({
    template,
    values,
    handleChange,
    handleAutocompleteChange,
    postDate,
    handlePostDateChange,
    selectedTrend,
    handleTrendChange,
    useBrandName,
    handleUseBrandNameChange,
}) => (
    <Grid item xs={12} md={12}>
        <Box sx={{ padding: 2, borderRadius: 1 }}>
            <Grid container spacing={2}>
                {Object.entries(template.parameters).map(([param, config]) => (
                    <Grid item xs={12} md={6} key={param}>
                        <ParameterInputField
                            param={param}
                            config={config}
                            values={values}
                            handleChange={handleChange}
                            handleAutocompleteChange={handleAutocompleteChange}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Schedule Post Date"
                        type="datetime-local"
                        value={moment(postDate).format('YYYY-MM-DDTHH:mm')}
                        onChange={handlePostDateChange}
                        fullWidth
                        margin="dense"
                        size="small"
                        sx={{ marginTop: 2 }}
                        helperText="Choose the date and time for the post.	"
                    />
                </Grid>
                {template.topTrends && template.topTrends.length > 0 && (
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            label="Choose a Trend"
                            value={selectedTrend}
                            onChange={handleTrendChange}
                            fullWidth
                            margin="dense"
                            size="small"
                            sx={{ marginTop: 2 }}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {template.topTrends.map((trend, index) => (
                                <option key={index} value={trend}>
                                    {trend}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                )}
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={useBrandName}
                                onChange={handleUseBrandNameChange}
                                color="primary"
                            />
                        }
                        label="Include Brand Name"
                    />
                </Grid>
            </Grid>
        </Box>
    </Grid>
);

export default InputsGrid;