import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ContactFormService from "../../services/contact/ContactFormService";
import CustomSnackbar from "../shared/CustomSnackbar";
import { withRouter } from "../../services/security/withRouter";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            message: '',
            submitting: false,
            snackbarOpen: false,
            snackbarMessage: '',
            snackbarSeverity: 'success',
        };
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { subject, message } = this.state;

        if (!subject || !message) {
            this.setState({
                snackbarOpen: true,
                snackbarMessage: 'Please fill in all fields.',
                snackbarSeverity: 'error',
            });
            return;
        }

        this.setState({ submitting: true });

        try {
            await ContactFormService.create({ subject, message });
            this.setState({
                snackbarOpen: true,
                snackbarMessage: 'Message sent successfully!',
                snackbarSeverity: 'success',
                subject: '',
                message: '',
            });
        } catch (error) {
            this.setState({
                snackbarOpen: true,
                snackbarMessage: error.message,
                snackbarSeverity: 'error',
            });
        } finally {
            this.setState({ submitting: false });
        }
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    render() {
        const { subject, message, submitting, snackbarOpen, snackbarMessage, snackbarSeverity } = this.state;
        const { isMobile } = this.props;
        const subjects = [
            'General Inquiry',
            'Billing/Payment',
            'Technical Support',
            'Bug Report',
            'Feature Request',
            'General Feedback',
            'Other',
        ];

        return (
            <>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            Contact Us
                        </Typography>
                        <form onSubmit={this.handleSubmit}>
                            <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                                <InputLabel id="subject-label">Subject</InputLabel>
                                <Select
                                    labelId="subject-label"
                                    name="subject"
                                    value={subject}
                                    onChange={this.handleChange}
                                    label="Subject"
                                >
                                    {subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>
                                            {subject}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Message"
                                name="message"
                                value={message}
                                onChange={this.handleChange}
                                multiline
                                rows={4}
                                sx={{ mb: 2 }}
                            />
                            <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={submitting}
                                    sx={{ padding: '12px 24px', fontSize: '16px' }}
                                >
                                    {submitting ? <CircularProgress size={24} /> : 'Send Message'}
                                </Button>
                            </Box>
                        </form>
                    </CardContent>
                <CustomSnackbar
                    snackbarOpen={snackbarOpen}
                    handleSnackbarClose={this.handleSnackbarClose}
                    severity={snackbarSeverity}
                    snackbarMessage={snackbarMessage}
                />
            </>
        );
    }
}

export default withRouter(Contact);