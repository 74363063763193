import React, { Component } from 'react';
import { Box, Link } from '@mui/material';
import ConfirmDialog from '../shared/ConfirmDialog';
import JourneyService from '../../services/journey/JourneyService';
import getTheme from "../getTheme";

class JourneyActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteDialogOpen: false,
        };
    }

    handleDeleteDialogOpen = () => {
        this.setState({ deleteDialogOpen: true });
    };

    handleDeleteDialogClose = () => {
        this.setState({ deleteDialogOpen: false });
    };

    handleDeleteDialogConfirm = async () => {
        const { journeyId, navigate } = this.props;
        try {
            await JourneyService.delete(journeyId);
            navigate('/journeys');
        } catch (error) {
            console.error('Unable to delete the journey.', error);
        }
    };

    render() {
        const { deleteDialogOpen } = this.state;
        const { theme } = this.props;

        return (
            <Box sx={{ padding: 2 }}>
                <Link
                    component="button"
                    variant="body1"
                    underline="none"
                    onClick={this.handleDeleteDialogOpen}
                    sx={{ cursor: 'pointer', color: '#e64a45' }}
                >
                    Delete This Journey
                </Link>
                <ConfirmDialog
                    dialogOpen={deleteDialogOpen}
                    handleClose={this.handleDeleteDialogClose}
                    handleConfirm={this.handleDeleteDialogConfirm}
                    dialogTitle="Delete Journey"
                    dialogMessage="Are you sure you want to delete this journey? This action cannot be undone."
                />
            </Box>
        );
    }
}

export default JourneyActions;