import ApiCalls from './util/ApiCalls';

class TemplateService {

  async getContentTypes() {
    return ApiCalls.defaultGet("/template/content-types", {});
  }

  async getContentTypesForCampaign() {
    return ApiCalls.defaultGet("/template/content-types-campaign", {});
  }

  async getPlatformsForContentType(contentTypeId) {
    return ApiCalls.defaultGet("/template/platforms/" + contentTypeId, {});
  }

  async getTemplatesForPlatform(platformId) {
    return ApiCalls.defaultGet("/template/templates/" + platformId, {});
  }

  async getTemplate(id) {
    return ApiCalls.defaultGet("/template/" + id, {});
  }

  async addFavoriteTemplate(templateId) {
    return ApiCalls.defaultPost("/template/add-to-favorites/" + templateId, {});
  }

  async removeFavoriteTemplate(templateId) {
    return ApiCalls.defaultDelete("/template/remove-from-favorites/" + templateId, {});
  }

  async getFavoriteTemplates() {
    return ApiCalls.defaultGet("/template/favorites", {});
  }
}

export default new TemplateService();