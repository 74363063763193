import React, { Component } from "react";
import {
    Typography,
    CssBaseline,
    Box,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    TextField,
    Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TemplateService from "../services/TemplateService";
import ContentService from "../services/ContentService";
import CampaignService from "../services/CampaignService";
import ChooseContentModal from "./create/template/ChooseContentModal";
import {renderIcon} from "../services/util/iconHelper";

const Main = styled("main")(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
}));

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favoriteTemplates: [],
            isModalOpen: false,
            recentContents: [],
            recentCampaigns: [],
            searchQuery: "",
        };
    }

    async componentDidMount() {
        this.fetchFavouriteTemplates();
        if (this.props.selectedBrand && this.props.selectedBrand.id !== 0) {
            this.fetchData();
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.selectedBrand.id !== this.props.selectedBrand.id) {
            this.fetchFavouriteTemplates();
            this.fetchData();
        }
    }

    async fetchData() {
        const { selectedBrand } = this.props;
        const queryParams = { brandId: selectedBrand.id };

        try {
            const recentContentsResponse = await ContentService.getForDashboard(queryParams);
            const recentCampaignsResponse = await CampaignService.getForDashboard(queryParams);
            this.setState({
                recentContents: recentContentsResponse.content,
                recentCampaigns: recentCampaignsResponse.content,
            });
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    }

    async fetchFavouriteTemplates() {
        try {
            const favoriteTemplates = await TemplateService.getFavoriteTemplates();
            this.setState({ favoriteTemplates });
        } catch (error) {
            console.error("Failed to fetch favorite templates:", error);
        }
    }

    handleOpenModal = () => {
        this.setState({ isModalOpen: true });
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
    };

    getGreeting() {
        const hour = new Date().getHours();
        if (hour < 12) {
            return "Good Morning";
        } else if (hour < 18) {
            return "Good Afternoon";
        } else {
            return "Good Evening";
        }
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };

    renderSectionTitle(title) {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 3,
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
                    {title}
                </Typography>
            </Box>
        );
    }

    render() {
        const { favoriteTemplates, isModalOpen, recentContents, recentCampaigns, searchQuery } = this.state;
        const { authenticatedUser } = this.props;
        const greeting = this.getGreeting();

        const filteredTemplates = favoriteTemplates.filter((template) =>
            template.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        return (
            <>
                <Main sx={{backgroundColor: "white"}}>
                    {/* Greeting */}
                    <Typography variant="h3" gutterBottom>
                        {greeting},{" "}
                        <Box component="span" sx={{ color: "primary.main" }}>
                            {authenticatedUser ? authenticatedUser.firstName : "Friend"}
                        </Box>
                        !
                    </Typography>

                    {/* Search Bar */}
                    <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                        <TextField
                            variant="outlined"
                            placeholder="Search within your favorite templates..."
                            value={searchQuery}
                            onChange={this.handleSearchChange}
                            InputProps={{
                                startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                            }}
                            fullWidth
                        />
                    </Box>

                    <Grid container spacing={4}>
                        {/* Favorite Templates */}
                        <Grid item xs={12}>
                            {this.renderSectionTitle("What would you like to create today?")}
                            <Grid container spacing={3}>
                                {/* Create Content Button */}
                                <Grid item xs={12} sm={6} md={3}>
                                    <Card
                                        sx={{
                                            height: 150,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "primary.main",
                                            color: "white",
                                            transition: "transform 0.3s ease",
                                            "&:hover": {
                                                transform: "scale(1.05)",
                                            },
                                        }}
                                    >
                                        <CardActionArea
                                            onClick={this.handleOpenModal}
                                            sx={{
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="h6">Create Content</Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>

                                {/* Render Favorite Templates */}
                                {filteredTemplates.map((template) => (
                                    <Grid item xs={12} sm={6} md={3} key={template.id}>
                                        <Card
                                            sx={{
                                                height: 150,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                transition: "transform 0.3s ease",
                                                "&:hover": {
                                                    transform: "scale(1.05)",
                                                },
                                            }}
                                        >
                                            <CardActionArea
                                                component={Link}
                                                to={`/template/${template.id}`}
                                                sx={{
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <CardContent>
                                                    <Typography variant="h6" sx={{ fontWeight: "bold", display: 'flex', justifyContent: 'center' }}>
                                                        {template.name}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            {renderIcon(template.platformName, { fontSize: 40 })}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        {/* Recently Created Content */}
                        <Grid item xs={12}>
                            {this.renderSectionTitle("Recently Created Content")}
                            <Grid container spacing={3}>
                                {recentContents.length > 0 ? (
                                    recentContents.map((content) => (
                                        <Grid item xs={12} sm={6} md={3} key={content.id}>
                                            <Card
                                                sx={{
                                                    height: 150,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    transition: "transform 0.3s ease",
                                                    "&:hover": {
                                                        transform: "scale(1.05)",
                                                    },
                                                }}
                                            >
                                                <CardActionArea
                                                    component={Link}
                                                    to={`/content/${content.id}`}
                                                    sx={{
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Typography variant="h6" sx={{ fontWeight: "bold", display: 'flex', justifyContent: 'center' }}>
                                                            {content.templateName}
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                            >
                                                                {renderIcon(content.platformName, { fontSize: 40 })}
                                                            </Typography>
                                                        </Box>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography variant="body1" sx={{paddingLeft: 3}}>No content created recently.</Typography>
                                )}
                            </Grid>
                        </Grid>

                        {/* Recently Created Campaigns */}
                        <Grid item xs={12}>
                            {this.renderSectionTitle("Recently Created Campaigns")}
                            <Grid container spacing={3}>
                                {recentCampaigns.length > 0 ? (
                                    recentCampaigns.map((campaign) => (
                                        <Grid item xs={12} sm={6} md={3} key={campaign.id}>
                                            <Card
                                                sx={{
                                                    height: 150,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    transition: "transform 0.3s ease",
                                                    "&:hover": {
                                                        transform: "scale(1.05)",
                                                    },
                                                }}
                                            >
                                                <CardActionArea
                                                    component={Link}
                                                    to={`/campaigns/${campaign.id}`}
                                                    sx={{
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                                            {campaign.name}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography variant="body1" sx={{paddingLeft: 3}}>No campaigns created recently.</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Main>
                <ChooseContentModal open={isModalOpen} handleClose={this.handleCloseModal} />
            </>
        );
    }
}

export default Dashboard;