import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class ConfirmDialog extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Dialog
                open={this.props.dialogOpen || false}
                onClose={this.props.handleClose}
            >
                <DialogTitle>{this.props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose}  sx={{ color: 'error.main'}}>Cancel</Button>
                    <Button onClick={this.props.handleConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default ConfirmDialog;