// utils.js
export const getStatusButtonLabelAndNewStatus = (content) => {
    const { status, template } = content;

    if (status === 'Draft') {
        return { label: 'Schedule', newStatus: 'Scheduled' };
    } else if (status === 'Scheduled') {
        if (template.contentTypeName === 'Paid Advertising Content') {
            return { label: 'Run', newStatus: 'Running' };
        } else if (template.contentTypeName === 'E-mail Content') {
            return { label: 'Send', newStatus: 'Posted' };
        } else {
            return { label: 'Post', newStatus: 'Posted' };
        }
    } else if (status === 'Running' || status === 'Posted') {
        return { label: 'Archive', newStatus: 'Archived' };
    }
    return null;
};