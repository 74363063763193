import React, {Component} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
    MenuItem,
    Tooltip,
    Select,
    FormControl,
    InputLabel,
    Alert,
} from '@mui/material';
import {withRouter} from '../../services/security/withRouter';
import SpeechService from '../../services/SpeechService';
import ContentService from '../../services/ContentService';
import CustomSnackbar from '../shared/CustomSnackbar';
import ConfirmDialog from '../shared/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import ShortTextOutlinedIcon from '@mui/icons-material/ShortTextOutlined';

const voiceOptions = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];

class Speech extends Component {
    state = {
        prompt: '',
        speechUrl: '',
        voice: voiceOptions[0],
        loading: false,
        fetching: false,
        deleting: false,
        deleteDialogOpen: false,
        snackbars: [],
        contextMenu: null,
        generatingPrompt: false, // New state variable for loading button
        charCount: 0, // New state variable for character count
        createdAt: null, // New state variable for createdAt
    };

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const speechId = queryParams.get('id');
        if (speechId) this.fetchSpeech(speechId);
    }

    fetchSpeech = async (id) => {
        this.setState({fetching: true});
        try {
            const response = await SpeechService.getSpeech(id);
            this.setState({
                prompt: response.prompt,
                voice: response.voice,
                speechUrl: response.url,
                fetching: false,
                charCount: response.prompt.length,
                createdAt: response.createdAt,
            });
        } catch (error) {
            this.setState({fetching: false});
            this.addSnackbar('Unable to retrieve speech. Please try again later.', 'error');
        }
    };

    handleGenerateSpeech = async () => {
        const {prompt, voice} = this.state;
        const queryParams = new URLSearchParams(this.props.location.search);
        const contentId = queryParams.get('contentId');

        if (!prompt.trim()) {
            this.addSnackbar('The prompt field is required.', 'error');
            return;
        }

        if (prompt.length > 4000) {
            this.addSnackbar('The prompt cannot exceed 4000 characters.', 'error');
            return;
        }

        if (!voice) {
            this.addSnackbar('Please select a voice.', 'error');
            return;
        }

        this.setState({loading: true});

        const updatedPrompt = prompt.replace(/\n/g, ' ');
        try {
            const requestBody = {prompt: updatedPrompt.trim(), voice, brandId: this.props.selectedBrand.id, contentId};
            const response = await SpeechService.generateSpeech(requestBody);
            this.setState({speechUrl: response.url, voice: response.voice, loading: false});
            this.addSnackbar('Speech generated successfully.', 'success');
            this.props.navigate(`/speech?id=${response.id}${response.contentId ? `&contentId=${response.contentId}` : ''}`);
        } catch (error) {
            this.setState({loading: false});
            this.addSnackbar(error.message, 'error');
        }
    };

    handleDeleteSpeech = async () => {
        const queryParams = new URLSearchParams(this.props.location.search);
        const speechId = queryParams.get('id');
        this.setState({deleting: true});

        try {
            await SpeechService.delete(speechId);
            this.setState({deleting: false, deleteDialogOpen: false});
            this.addSnackbar('Speech deleted successfully.', 'success');
            this.props.navigate('/speeches');
        } catch (error) {
            this.setState({deleting: false, deleteDialogOpen: false});
            this.addSnackbar('Failed to delete the speech. Please try again later.', 'error');
        }
    };

    handleGeneratePrompt = async () => {
        const queryParams = new URLSearchParams(this.props.location.search);
        const contentId = queryParams.get('contentId');
        if (!contentId) {
            this.addSnackbar('Content ID is required to generate a prompt.', 'error');
            return;
        }

        this.setState({ generatingPrompt: true });

        try {
            const response = await ContentService.generatePromptForSpeech(contentId);
            this.setState({ prompt: response.prompt, generatingPrompt: false, charCount: response.prompt.length });
            this.addSnackbar('Prompt generated successfully.', 'success');
        } catch (error) {
            this.setState({ generatingPrompt: false });
            this.addSnackbar('Failed to generate prompt. Please try again later.', 'error');
        }
    };

    handlePromptChange = (e) => {
        const prompt = e.target.value;
        if (prompt.length <= 4000) {
            this.setState({ prompt, charCount: prompt.length });
        }
    };

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {message, severity, key: new Date().getTime()}],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    render() {
        const {prompt, speechUrl, voice, loading, fetching, deleting, deleteDialogOpen, generatingPrompt, charCount, createdAt} = this.state;
        const queryParams = new URLSearchParams(this.props.location.search);
        const speechId = queryParams.get('id');
        const contentId = queryParams.get('contentId');
        const {currentPlan} = this.props;
        const disableCreateButton = currentPlan.alreadyGeneratedSpeeches && currentPlan.alreadyGeneratedSpeeches >= currentPlan.maxSpeechesGenerated;

        const daysRemaining = createdAt ? 120 - moment().diff(moment(createdAt), 'days') : null;

        return (
            <Box sx={{padding: 2}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
                    <Box>
                        <Button
                            startIcon={<ArrowBackIcon/>}
                            onClick={() => this.props.navigate('/speeches')}
                            sx={{textTransform: 'none', fontWeight: 600}}
                        >
                            Back to Speeches
                        </Button>
                        {contentId && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => this.props.navigate(`/content/${contentId}`)}
                                sx={{
                                    ml: 2,
                                    px: 3,
                                    py: 1,
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    boxShadow: 3,
                                    borderRadius: 2,
                                }}
                            >
                                Go to Content
                            </Button>
                        )}
                    </Box>
                    <Typography variant="h4" fontWeight="bold">
                        {speechId ? 'View Speech' : 'Generate Speech'}
                    </Typography>
                </Box>

                {/* Info Message */}
                {speechId && daysRemaining !== null && (
                    <Alert severity="info" sx={{marginBottom: 3}}>
                        {daysRemaining > 0 ? `This speech will be deleted after ${daysRemaining} days.` : 'This speech was deleted.'}
                    </Alert>
                )}

                {/* Main Content */}
                <Grid container spacing={4}>
                    {/* Speech Preview */}
                    <Grid item xs={12} md={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden',
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >
                            {fetching ? (
                                <CircularProgress/>
                            ) : speechUrl ? (
                                <audio controls style={{width: '100%'}}>
                                    <source src={speechUrl} type="audio/mpeg"/>
                                    Your browser does not support the audio element.
                                </audio>
                            ) : (
                                <Typography variant="body1" color="textSecondary">
                                    No speech has been generated yet.
                                </Typography>
                            )}
                        </Box>
                    </Grid>

                    {/* Speech Controls */}
                    <Grid item xs={12} md={12}>
                        <TextField
                            label="Prompt"
                            value={prompt}
                            onChange={this.handlePromptChange}
                            multiline
                            rows={8}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            disabled={!!speechId}
                            helperText={`${charCount}/4000 characters`}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Voice</InputLabel>
                            <Select
                                value={voice}
                                onChange={(e) => this.setState({voice: e.target.value})}
                                disabled={!!speechId}
                            >
                                {voiceOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 3}}>
                            {contentId && !speechId && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleGeneratePrompt}
                                    disabled={generatingPrompt}
                                    startIcon={generatingPrompt ? <CircularProgress size={20} /> : <ShortTextOutlinedIcon />}
                                >
                                    Generate Prompt
                                </Button>
                            )}
                            {!speechId && (
                                <Tooltip
                                    title={disableCreateButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Generate Speech"}
                                    arrow>
                                    <span>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleGenerateSpeech}
                                            disabled={loading || disableCreateButton}
                                            startIcon={loading ? <CircularProgress size={20}/> : <AddIcon/>}
                                        >
                                            Generate Speech
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                            {speechId && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => this.setState({deleteDialogOpen: true})}
                                    disabled={deleting}
                                    startIcon={deleting ? <CircularProgress size={20}/> : <DeleteIcon/>}
                                >
                                    Delete Speech
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                {/* Snackbar Notifications */}
                {this.state.snackbars.map((snackbar) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.removeSnackbar(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                    />
                ))}

                {/* Confirm Dialog */}
                <ConfirmDialog
                    dialogOpen={deleteDialogOpen}
                    handleClose={() => this.setState({deleteDialogOpen: false})}
                    handleConfirm={this.handleDeleteSpeech}
                    dialogTitle="Delete Speech"
                    dialogMessage="Are you sure you want to delete this speech?"
                />
            </Box>
        );
    }
}

export default withRouter(Speech);