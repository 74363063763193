import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import JourneyGoalService from '../../../services/journey/JourneyGoalService';

class JourneyGoal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            journeyGoals: [],
            loading: true,
        };
    }

    async componentDidMount() {
        try {
            const response = await JourneyGoalService.getAll();
            this.setState({ journeyGoals: response, loading: false });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    handleChange = (event) => {
        const selectedGoalId = event.target.value;
        this.props.onGoalChange(selectedGoalId);
    };

    render() {
        const { selectedGoal, errors } = this.props;
        const { journeyGoals, loading } = this.state;

        return (
            <Container
                sx={{
                    pt: { xs: 4, sm: 12 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '70%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        Let’s start by understanding your primary goal.
                    </Typography>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={56} />
                    ) : (
                        <FormControl fullWidth error={!!errors.selectedGoal}>
                            <InputLabel id="journey-goal-label">Choose Your Goal</InputLabel>
                            <Select
                                labelId="journey-goal-label"
                                value={selectedGoal}
                                label="Select Your Goal"
                                onChange={this.handleChange}
                            >
                                {journeyGoals.map((goal) => (
                                    <MenuItem key={goal.id} value={goal.id}>
                                        {goal.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.selectedGoal && (
                                <Typography variant="caption" color="error">
                                    {errors.selectedGoal}
                                </Typography>
                            )}
                        </FormControl>
                    )}
                </Box>
            </Container>
        );
    }
}

export default JourneyGoal;