const languages = [
    'Arabic',           // United Arab Emirates
    'Czech',            // Czech Republic
    'Danish',           // Denmark
    'Dutch',            // Netherlands, Belgium
    'English',          // United States, United Kingdom, Canada, Australia, New Zealand, Ireland, Singapore
    'Estonian',         // Estonia
    'Finnish',          // Finland
    'French',           // France, Canada, Belgium, Switzerland, Luxembourg
    'German',           // Germany, Austria, Switzerland, Luxembourg
    'Greek',            // Greece
    'Hungarian',        // Hungary
    'Icelandic',        // Iceland
    'Italian',          // Italy, Switzerland
    'Japanese',         // Japan
    'Korean',           // South Korea
    'Latvian',          // Latvia
    'Lithuanian',       // Lithuania
    'Luxembourgish',    // Luxembourg
    'Maltese',          // Malta
    'Mandarin Chinese', // Singapore
    'Norwegian',        // Norway
    'Polish',           // Poland
    'Portuguese',       // Portugal
    'Romanian',         // Romania
    'Slovak',           // Slovakia
    'Slovene',          // Slovenia
    'Spanish',          // Spain
    'Swedish',          // Sweden
    'Turkish'           // Turkey
];
export default languages;