import React, { Component } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Box,
    Typography,
} from '@mui/material';

class CreateUser extends Component {
    constructor(props) {
        super(props);
        const defaultRole = props.roles.find(role => role.name.toLowerCase() === 'employee');
        this.state = {
            role: defaultRole ? defaultRole.id : '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNo: '',
            errors: {},
        };
    }

    componentDidMount() {
        const defaultRole = this.props.roles.find(role => role.name.toLowerCase() === 'employee');
        if (defaultRole) {
            this.setState({ role: defaultRole.id });
        }
    }

    handleRoleChange = (event) => {
        this.setState({ role: event.target.value });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    validate = () => {
        const errors = {};
        if (!this.state.firstName) errors.firstName = 'First Name is required';
        if (!this.state.lastName) errors.lastName = 'Last Name is required';
        if (!this.state.email) errors.email = 'Email is required';
        if (!/\S+@\S+\.\S+/.test(this.state.email)) errors.email = 'Email is invalid';
        if (this.state.phoneNo && !/^\d{10}$/.test(this.state.phoneNo)) errors.phoneNo = 'Phone Number is invalid';
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        if (Object.keys(errors).length === 0) {
            const form = e.target; // Get the form element from the event
            const formData = new FormData(form); // Create FormData with the form element
            this.props.onSubmitUser(formData, this.refreshData); // Pass the FormData object
        } else {
            this.setState({ errors });
        }
    }

    refreshData = () => {
        const defaultRole = this.props.roles.find(role => role.name.toLowerCase() === 'employee');
        this.setState({
            role: defaultRole ? defaultRole.id : '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNo: '',
            errors: {},
        });
    }

    render() {
        const { errors } = this.state;

        return (
            <Dialog
                open={this.props.editDialogOpen}
                onClose={this.props.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: this.handleSubmit,
                    sx: { borderRadius: 3, padding: 2 },
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h5" fontWeight="bold">
                        Add a New User
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component="div">
                        <Typography variant="body1" sx={{ color: 'text.secondary', marginBottom: 2 }}>
                            Add a new user to your company's system. The user will receive an email with setup instructions.
                        </Typography>
                    </DialogContentText>
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    value={this.state.firstName}
                                    onChange={this.handleChange}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    variant="outlined"
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    type="email"
                                    variant="outlined"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="phoneNo"
                                    name="phoneNo"
                                    label="Phone Number"
                                    type="tel"
                                    variant="outlined"
                                    value={this.state.phoneNo}
                                    onChange={this.handleChange}
                                    error={!!errors.phoneNo}
                                    helperText={errors.phoneNo}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="role-label" >Role</InputLabel>
                                    <Select
                                        labelId="role-label"
                                        id="role"
                                        name="role"
                                        value={this.state.role}
                                        onChange={this.handleRoleChange}
                                        label="Role"
                                    >
                                        {this.props.roles.map((role, index) => (
                                            <MenuItem key={index} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ paddingX: 3, paddingBottom: 3 }}>
                    <Button
                        onClick={this.props.handleClose}
                        variant="outlined"
                        color="danger"
                        sx={{ textTransform: 'none', fontWeight: 'bold' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ textTransform: 'none', fontWeight: 'bold' }}
                    >
                        Create User
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default CreateUser;