import React from 'react';
import { TextField, Autocomplete, Chip, InputAdornment } from '@mui/material';

const ParameterInputField = ({ param, config, values, handleChange, handleAutocompleteChange }) => {
  const capitalizedLabel = param.charAt(0).toUpperCase() + param.slice(1);

  switch (config.type) {
    case 'text':
      const handleTextChange = (event) => {
        const value = event.target.value;
        if (value.length <= 200) {
          handleChange(param)(event);
        }
      };

      return (
        <TextField
          type="text"
          label={capitalizedLabel}
          value={values[param]}
          onChange={handleTextChange}
          fullWidth
          margin="dense"
          size="small"
          helperText={`Enter ${capitalizedLabel} (${values[param]?.length || 0}/200)`}
        />
      );
    case 'number':
      const handleNumberChange = (event) => {
        const value = event.target.value;
        if (value >= 0) {
          const syntheticEvent = {
            target: {
              value: param === 'Video Length' ? `${value} seconds` : value
            }
          };
          handleChange(param)(syntheticEvent);
        }
      };

      return (
        <TextField
          type="number"
          label={capitalizedLabel}
          value={values[param] ? values[param].replace(' seconds', '') : ''} // Remove ' seconds' for display
          onChange={handleNumberChange}
          fullWidth
          margin="dense"
          size="small"
          InputProps={{
            endAdornment: param === 'Video Length' ? <InputAdornment position="end">seconds</InputAdornment> : null,
          }}
          helperText={`Enter ${capitalizedLabel}${param === 'Video Length' ? ' (in seconds)' : ''}`}
        />
      );
    case 'textarea':
      const handleTextareaChange = (event) => {
        const value = event.target.value;
        if (value.length <= 800) {
          handleChange(param)(event);
        }
      };

      return (
        <TextField
          type="text"
          label={capitalizedLabel}
          value={values[param]}
          onChange={handleTextareaChange}
          fullWidth
          margin="dense"
          size="small"
          multiline
          rows={3}
          helperText={`Enter ${capitalizedLabel} (${values[param]?.length || 0}/800)`}
        />
      );
    case 'autocomplete':
      const handleAutocompleteChangeInternal = (event, value) => {
        if (value.length <= 10) {
          handleAutocompleteChange(param, value);
        }
      };

      return (
        <Autocomplete
          multiple
          options={[]}
          freeSolo
          value={Array.isArray(values[param]) ? values[param] : []}
          onChange={handleAutocompleteChangeInternal}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={capitalizedLabel}
              placeholder={`Add ${capitalizedLabel}`}
              fullWidth
              margin="dense"
              size="small"
              helperText={`Press enter after each item (${values[param]?.length || 0}/10 items)`}
            />
          )}
        />
      );
    default:
      return null;
  }
};

export default ParameterInputField;