import React, {Component} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Menu,
    MenuItem,
    Tooltip,
    Alert,
} from '@mui/material';
import {withRouter} from '../../services/security/withRouter';
import ImageService from '../../services/ImageService';
import ContentService from '../../services/ContentService';
import CustomSnackbar from '../shared/CustomSnackbar';
import ConfirmDialog from '../shared/ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import {Card} from "@mui/material";
import ShortTextOutlinedIcon from "@mui/icons-material/ShortTextOutlined";

class Image extends Component {
    state = {
        prompt: '',
        imageUrl: '',
        loading: false,
        fetching: false,
        updating: false,
        deleting: false,
        deleteDialogOpen: false,
        snackbars: [],
        doNotPutText: true,
        contextMenu: null,
        generatingPrompt: false,
        charCount: 0, // New state variable for character count
        createdAt: null, // New state variable for createdAt
    };

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const imageId = queryParams.get('id');
        if (imageId) this.fetchImage(imageId);
    }

    fetchImage = async (id) => {
        this.setState({fetching: true});
        try {
            const response = await ImageService.getImage(id);
            this.setState({
                prompt: response.prompt,
                imageUrl: response.url,
                fetching: false,
                charCount: response.prompt.length,
                createdAt: response.createdAt
            });
        } catch (error) {
            this.setState({fetching: false});
            this.addSnackbar('Unable to retrieve image. Please try again later.', 'error');
            this.props.navigate('/image', {replace: true});
        }
    };

    handleGenerateOrUpdateImage = async (isUpdate) => {
        const {prompt, doNotPutText} = this.state;
        const queryParams = new URLSearchParams(this.props.location.search);
        const imageId = queryParams.get('id');
        const contentId = queryParams.get('contentId');

        if (!prompt.trim()) {
            this.addSnackbar('The prompt field is required.', 'error');
            return;
        }

        if (prompt.length > 1500) {
            this.addSnackbar('The prompt field must not exceed 1500 characters.', 'error');
            return;
        }

        const action = isUpdate ? 'updateImage' : 'generateImage';
        this.setState({[isUpdate ? 'updating' : 'loading']: true});

        try {
            const requestBody = isUpdate ? {prompt, doNotPutText} : {
                prompt,
                brandId: this.props.selectedBrand.id,
                doNotPutText,
                contentId
            };
            const response = await ImageService[action](isUpdate ? {imageId: imageId, data: requestBody} : requestBody);
            this.setState({imageUrl: response.url, [isUpdate ? 'updating' : 'loading']: false});
            this.addSnackbar(`Image ${isUpdate ? 'updated' : 'generated'} successfully.`, 'success');
            this.props.navigate(`/image?id=${response.id}${response.contentId ? `&contentId=${response.contentId}` : ''}`);
        } catch (error) {
            this.setState({[isUpdate ? 'updating' : 'loading']: false});
            this.addSnackbar(error.message, 'error');
        }
    };

    handleDeleteImage = async () => {
        const queryParams = new URLSearchParams(this.props.location.search);
        const imageId = queryParams.get('id');
        this.setState({deleting: true});

        try {
            await ImageService.delete(imageId);
            this.setState({deleting: false, deleteDialogOpen: false});
            this.addSnackbar('Image deleted successfully.', 'success');
            this.props.navigate('/images');
        } catch (error) {
            this.setState({deleting: false, deleteDialogOpen: false});
            this.addSnackbar('Failed to delete the image. Please try again later.', 'error');
        }
    };

    handleGeneratePrompt = async () => {
        const queryParams = new URLSearchParams(this.props.location.search);
        const contentId = queryParams.get('contentId');
        if (!contentId) {
            this.addSnackbar('Content ID is required to generate a prompt.', 'error');
            return;
        }

        this.setState({generatingPrompt: true});

        try {
            const response = await ContentService.generatePromptForImage(contentId);
            this.setState({prompt: response.prompt, generatingPrompt: false, charCount: response.prompt.length});
            this.addSnackbar('Prompt generated successfully.', 'success');
        } catch (error) {
            this.setState({generatingPrompt: false});
            this.addSnackbar('Failed to generate prompt. Please try again later.', 'error');
        }
    };

    handlePromptChange = (e) => {
        const prompt = e.target.value;
        if (prompt.length <= 1500) {
            this.setState({prompt, charCount: prompt.length});
        }
    };

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {message, severity, key: new Date().getTime()}],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    handleContextMenu = (event) => {
        event.preventDefault();
        if (this.state.imageUrl) {
            this.setState({contextMenu: {mouseX: event.clientX, mouseY: event.clientY}});
        }
    };

    render() {
        const {
            prompt,
            imageUrl,
            loading,
            fetching,
            updating,
            deleting,
            deleteDialogOpen,
            doNotPutText,
            contextMenu,
            generatingPrompt,
            charCount,
            createdAt
        } = this.state;
        const queryParams = new URLSearchParams(this.props.location.search);
        const imageId = queryParams.get('id');
        const contentId = queryParams.get('contentId');
        const {currentPlan} = this.props;
        const disableCreateButton = currentPlan.alreadyGeneratedImages >= currentPlan.maxImagesGenerated;

        const daysRemaining = createdAt ? 120 - moment().diff(moment(createdAt), 'days') : null;

        return (
            <Box sx={{padding: 2}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
                    <Box>
                        <Button
                            startIcon={<ArrowBackIcon/>}
                            onClick={() => this.props.navigate('/images')}
                            sx={{textTransform: 'none', fontWeight: 600}}
                        >
                            Back to Images
                        </Button>
                        {contentId && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => this.props.navigate(`/content/${contentId}`)}
                                sx={{
                                    ml: 2,
                                    px: 3,
                                    py: 1,
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    boxShadow: 3,
                                    borderRadius: 2,
                                }}
                            >
                                Go to Content
                            </Button>
                        )}
                    </Box>
                    <Typography variant="h4" fontWeight="bold">
                        {imageId ? 'Update Image' : 'Generate Image'}
                    </Typography>
                </Box>

                {/* Info Message */}
                {imageId && daysRemaining !== null && (
                    <Alert severity="info" sx={{marginBottom: 3}}>
                        {daysRemaining > 0 ? `This image will be deleted after ${daysRemaining} days.` : 'This image was deleted.'}
                    </Alert>
                )}

                {/* Main Content */}
                <Grid container spacing={4}>
                    {/* Image Preview */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                borderRadius: 2,
                                backgroundColor: '#fff',
                                boxShadow: 1,
                                padding: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 550,
                                width: '90%',
                                overflow: 'hidden',
                                textAlign: 'center',
                                position: 'relative',
                            }}
                            onContextMenu={this.handleContextMenu}
                        >
                            {fetching ? (
                                <CircularProgress/>
                            ) : imageUrl ? (
                                <img src={imageUrl} alt="Generated" style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                    borderRadius: 8
                                }}/>
                            ) : (
                                <Typography variant="body1" color="textSecondary">
                                    No image has been generated yet.
                                </Typography>
                            )}
                        </Box>
                    </Grid>

                    {/* Image Controls */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Prompt"
                            value={prompt}
                            onChange={this.handlePromptChange}
                            multiline
                            rows={8}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            helperText={`${charCount}/1500 characters`}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={doNotPutText}
                                               onChange={(e) => this.setState({doNotPutText: e.target.checked})}/>}
                            label="Do not include text in the image."
                        />
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 3}}>
                            {contentId && !imageId && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleGeneratePrompt}
                                    disabled={generatingPrompt}
                                    startIcon={generatingPrompt ? <CircularProgress size={20}/> :
                                        <ShortTextOutlinedIcon/>}
                                >
                                    Generate Prompt
                                </Button>
                            )}
                            {imageId ? (
                                <>
                                    <Tooltip
                                        title={disableCreateButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Update Image"}
                                        arrow>
                                        <span>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.handleGenerateOrUpdateImage(true)}
                                                disabled={updating || disableCreateButton}
                                                startIcon={updating ? <CircularProgress size={20}/> : <RefreshIcon/>}
                                            >
                                                Update Image
                                            </Button>
                                        </span>
                                    </Tooltip>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => this.setState({deleteDialogOpen: true})}
                                        disabled={deleting}
                                        startIcon={deleting ? <CircularProgress size={20}/> : <DeleteIcon/>}
                                    >
                                        Delete Image
                                    </Button>
                                </>
                            ) : (
                                <Tooltip
                                    title={disableCreateButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Generate Image"}
                                    arrow>
                                    <span>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.handleGenerateOrUpdateImage(false)}
                                            disabled={loading || disableCreateButton}
                                            startIcon={loading ? <CircularProgress size={20}/> :
                                                <AddPhotoAlternateIcon/>}
                                        >
                                            Generate Image
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                {/* Context Menu */}
                <Menu
                    open={!!contextMenu}
                    onClose={() => this.setState({contextMenu: null})}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu ? {top: contextMenu.mouseY, left: contextMenu.mouseX} : undefined
                    }
                >
                    <MenuItem onClick={() => window.open(imageUrl, '_blank')}>
                        View Full Screen
                    </MenuItem>
                </Menu>

                {/* Snackbar Notifications */}
                {this.state.snackbars.map((snackbar) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.removeSnackbar(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                    />
                ))}

                {/* Confirm Dialog */}
                <ConfirmDialog
                    dialogOpen={deleteDialogOpen}
                    handleClose={() => this.setState({deleteDialogOpen: false})}
                    handleConfirm={this.handleDeleteImage}
                    dialogTitle="Delete Image"
                    dialogMessage="Are you sure you want to delete this image?"
                />
            </Box>
        );
    }
}

export default withRouter(Image);