import ApiCalls from './util/ApiCalls';

class CompanyService {
  async createCompany(companyAndBrandDto) {
    return ApiCalls.defaultPost("/company/create-brand", {}, companyAndBrandDto);
  }

  async createAudience(audienceDto) {
    return ApiCalls.defaultPost("/company/create-audience", {}, audienceDto);
  }

  async createAudienceFully(brandId, audienceDto) {
    return ApiCalls.defaultPost("/company/" + brandId + "/create-audience-fully", {}, audienceDto);
  }

  async deleteAudience(audienceId) {
    return ApiCalls.defaultDelete("/company/delete-audience/" + audienceId, {});
  }

  async createVoice(voiceDto) {
    return ApiCalls.defaultPost("/company/create-voice", {}, voiceDto);
  }

  async getBrand(id, audienceId, voiceId) {
    return ApiCalls.defaultGet("/company/get-simple-brand/" + id, { audienceId, voiceId });
  }

  async getModelBrand(id) {
    return ApiCalls.defaultGet("/company/get-brand/" + id, {});
  }

  async getBrands() {
    return ApiCalls.defaultGet("/company/get-brands", {});
  }

  async deleteBrand(id) {
    return ApiCalls.defaultDelete("/company/delete-brand/" + id, {});
  }
}

export default new CompanyService();