import React, {Component} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import PlusIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CustomSnackbar from '../shared/CustomSnackbar';
import {withRouter} from '../../services/security/withRouter';
import CampaignDrawer from '../create/CampaignDrawer';
import TemplateService from '../../services/TemplateService';
import CampaignService from '../../services/CampaignService';
import CompanyService from '../../services/CompanyService';
import {Fab, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

class Campaigns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadEdit: false,
            loadCreate: false,
            errors: {},
            errorResponse: {},
            editDialogOpen: false,
            snackbarOpen: false,
            snackbarSeverity: 'success',
            snackbarMessage: '',
            campaigns: [],
            contentTypes: [],
            selectedCampaignIds: [],
            drawerOpen: false,
            newCampaign: {
                name: '',
                product: '',
                startDate: '',
                endDate: '',
                objective: '',
                contentType: ''
            },
            loadingContentTypes: true,
            loadingCampaigns: true,
            page: 0,
            pageSize: 10,
            sort: 'createdAt',
            order: 'desc',
            filters: {},
            totalNumberOfItems: 0,
            brand: {},
            selectedAudience: '',
            loadingCreate: false,
            activeMarketingCampaigns: 0,
            activeContentCampaigns: 0,
            activeEmailCampaigns: 0,
        };
    }

    componentDidMount() {
        if (this.props.selectedBrand.id !== 0) {
            this.getBrand(this.props.selectedBrand.id);
        }
        this.fetchCampaignsForBrand();
    }

    componentDidUpdate(prevProps, prevState) {
        const {selectedBrand} = this.props;

        if (selectedBrand && selectedBrand.id !== prevProps.selectedBrand.id) {
            this.setState({selectedAudience: ''});
            this.getBrand(this.props.selectedBrand.id);
            this.fetchCampaignsForBrand();
        }
    }

    getBrand = async (brandId) => {
        try {
            const brand = await CompanyService.getModelBrand(brandId);
            this.setState({brand});
        } catch (error) {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingBrand: false,
            });
        }
    };

    fetchContentTypes = async () => {
        try {
            const contentTypes = await TemplateService.getContentTypesForCampaign();
            const { activeMarketingCampaigns, activeContentCampaigns, activeEmailCampaigns } = this.state;
            const { currentPlan } = this.props;

            const filteredContentTypes = contentTypes.filter(type => {
                switch (type.name) {
                    case "Social Media Content":
                        return activeContentCampaigns < currentPlan.maxContentCampaignsPerBrand;
                    case "Paid Advertising Content":
                        return activeMarketingCampaigns < currentPlan.maxMarketingCampaignsPerBrand;
                    case "E-mail Content":
                        return activeEmailCampaigns < currentPlan.maxEmailCampaignsPerBrand;
                    default:
                        return true;
                }
            });

            this.setState({ contentTypes: filteredContentTypes, loadingContentTypes: false });
        } catch (error) {
            this.setState({ loadingContentTypes: false });
        }
    };

    fetchCampaignsForBrand = async () => {
        const {selectedBrand} = this.props;
        const {page, pageSize, sort, order, filters} = this.state;
        if (!selectedBrand || !selectedBrand.id) return;

        const queryParams = {
            brandId: selectedBrand.id,
            page,
            size: pageSize,
            sort,
            order,
            ...filters
        };

        try {
            const response = await CampaignService.getForBrand(queryParams);
            this.setState({
                campaigns: response.content,
                totalNumberOfItems: response.totalNumberOfItems,
                loadingCampaigns: false,
                activeMarketingCampaigns: response.activeMarketingCampaigns,
                activeContentCampaigns: response.activeContentCampaigns,
                activeEmailCampaigns: response.activeEmailCampaigns,
            });
            this.fetchContentTypes();
        } catch (error) {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingCampaigns: false
            });
        }
    };

    handleClickOpen = () => {
        this.setState({drawerOpen: true});
    };

    handleCloseDrawer = () => {
        this.setState({drawerOpen: false, loadCreate: false});
    };

    validateForm = () => {
        const { newCampaign } = this.state;
        const errors = {};

        if (!newCampaign.name) errors.name = 'Campaign name is required';
        if (!newCampaign.product) errors.product = 'Product name is required';
        if (!newCampaign.startDate) errors.startDate = 'Start date is required';
        if (!newCampaign.endDate) errors.endDate = 'End date is required';
        if (!newCampaign.objective) errors.objective = 'Campaign objective is required';
        if (!newCampaign.contentType) errors.contentType = 'Content type is required';

        if (newCampaign.startDate && newCampaign.endDate) {
            const startDate = new Date(newCampaign.startDate);
            const endDate = new Date(newCampaign.endDate);

            if (endDate < startDate) {
                errors.endDate = 'End date cannot be before start date';
            }

            const threeMonthsInMs = 3 * 30 * 24 * 60 * 60 * 1000;
            if (endDate - startDate > threeMonthsInMs) {
                errors.endDate = 'The date range cannot be longer than 3 months';
            }
        }

        this.setState({ errors });

        return Object.keys(errors).length === 0;
    };

    onSubmitCampaign = async (event) => {
        event.preventDefault();
        if (!this.validateForm()) return;

        const {newCampaign, selectedAudience} = this.state;
        const {selectedBrand} = this.props;

        const campaignDto = {
            name: newCampaign.name,
            objective: newCampaign.objective,
            product: newCampaign.product,
            startDate: newCampaign.startDate,
            endDate: newCampaign.endDate,
            brandId: selectedBrand.id,
            contentTypeId: newCampaign.contentType,
            audienceId: selectedAudience,
        };

        this.setState({loadingCreate: true});

        try {
            const c = await CampaignService.addCampaign(campaignDto);
            this.setState({
                snackbarSeverity: 'success',
                snackbarMessage: 'Your campaign was successfully created!',
                snackbarOpen: true,
                drawerOpen: false,
                loadingCreate: false,
            });

            this.props.navigate(`/campaigns/${c.id}`);
        } catch (error) {
            this.setState({
                snackbarSeverity: 'error',
                snackbarMessage: error.message,
                snackbarOpen: true,
                loadingCreate: false,
            });
        }
    };

    handleSnackbarClose = (event, reason) => {
        this.setState({snackbarOpen: false});
    };

    handleInputChange = (event) => {
        const {name, value} = event.target;
        this.setState(prevState => ({
            newCampaign: {
                ...prevState.newCampaign,
                [name]: value
            }
        }));
    };

    handlePaginationModelChange = (paginationModel) => {
        const {page, pageSize} = paginationModel;
        this.setState({page, pageSize}, this.fetchCampaignsForBrand);
    };

    handleSortModelChange = (sortModel) => {
        if (sortModel.length > 0) {
            const {field, sort} = sortModel[0];
            this.setState({sort: field, order: sort}, this.fetchCampaignsForBrand);
        }
    };

    handleFilterChange = (filterModel) => {
        const filters = filterModel.items.reduce((acc, filter) => {
            if (filter.value) {
                acc[filter.field] = filter.value;
            }
            return acc;
        }, {});

        this.setState({filters}, this.fetchCampaignsForBrand);
    };

    handleViewClick = (id) => {
        this.props.navigate(`/campaigns/${id}`);
    };

    handleAudienceChange = (event) => {
        this.setState({selectedAudience: event.target.value});
    };

    render() {
        const {drawerOpen, newCampaign, errors, contentTypes, campaigns, loadingCampaigns, page, pageSize, brand, activeMarketingCampaigns, activeContentCampaigns, activeEmailCampaigns} = this.state;
        const {selectedBrand, isMobile, currentPlan} = this.props;

        const audiences = brand ? brand.audiences : [];

        if (!selectedBrand || selectedBrand.id === 0) {
            return (
                <Box sx={{ padding: 4}}>
                    <Typography variant="body1" color="textSecondary">
                        To proceed, select a brand from the navigation bar.
                    </Typography>
                </Box>
            );
        }

        const disableCreateButton = activeMarketingCampaigns >= currentPlan.maxMarketingCampaignsPerBrand &&
            activeContentCampaigns >= currentPlan.maxContentCampaignsPerBrand &&
            activeEmailCampaigns >= currentPlan.maxEmailCampaignsPerBrand;

        let columns;
        if (campaigns) {
            columns = [
                {
                    field: 'name',
                    headerName: 'Name',
                    flex: 1,
                    editable: false,
                },
                {
                    field: 'contentTypeName',
                    headerName: 'Content Type',
                    flex: 2,
                    renderCell: (params) => {
                        return params?.row?.contentType?.name || 'Type not specified';
                    },
                },
                {
                    field: 'product',
                    headerName: 'Product',
                    flex: 1.5,
                    editable: false,
                },
                {
                    field: 'startDate',
                    headerName: 'Start Date',
                    flex: 2,
                    editable: false,
                    filterable: false,
                    valueGetter: (param) => {
                        if (!param) return '';
                        const date = new Date(param);
                        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                    },
                },
                {
                    field: 'endDate',
                    headerName: 'End Date',
                    flex: 2,
                    editable: false,
                    filterable: false,
                    valueGetter: (param) => {
                        if (!param) return '';
                        const date = new Date(param);
                        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                    },
                },
                {
                    field: 'actions',
                    headerName: 'Actions',
                    flex: 1.5,
                    editable: false,
                    filterable: false,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => (
                        <Tooltip title="View">
                            <IconButton
                                color="primary"
                                onClick={() => this.handleViewClick(params.id)}
                            >
                                <VisibilityOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ),
                },
            ];
        }

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography variant="h4" gutterBottom>
                                            Campaigns
                                        </Typography>
                                    </Grid>
                                    {!isMobile && (
                                        <Grid item xs={3} sx={{textAlign: 'right'}}>
                                            <Tooltip title={disableCreateButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Create a Campaign"} arrow>
                                                <span>
                                                    <LoadingButton
                                                        loading={this.state.loadCreate}
                                                        loadingPosition="start"
                                                        startIcon={<PlusIcon/>}
                                                        onClick={this.handleClickOpen}
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{marginRight: 2}}
                                                        disabled={disableCreateButton}
                                                    >
                                                        Create
                                                    </LoadingButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                                {loadingCampaigns ? (
                                    <Skeleton variant="rectangular" width="100%" height={400}/>
                                ) : (
                                    campaigns.length > 0 ? (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                overflowX: 'auto',
                                                '@media (max-width: 600px)': {
                                                    padding: 1,
                                                }
                                            }}
                                        >
                                            <DataGrid
                                                key={this.props.sidebarOpen}
                                                rows={campaigns}
                                                columns={columns}
                                                pagination
                                                paginationMode="server"
                                                sortingMode="server"
                                                filterMode="server"
                                                page={page}
                                                pageSize={pageSize}
                                                rowCount={this.state.totalNumberOfItems}
                                                onPaginationModelChange={this.handlePaginationModelChange}
                                                onSortModelChange={this.handleSortModelChange}
                                                onFilterModelChange={this.handleFilterChange}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 10,
                                                        },
                                                    },
                                                }}
                                                sx={{
                                                    '& .MuiDataGrid-root': {
                                                        overflowX: 'auto',
                                                    },
                                                    minWidth: 600,
                                                    marginTop: '10px',
                                                }}
                                                pageSizeOptions={[10, 5, 3]}
                                                disableRowSelectionOnClick
                                            />
                                        </Box>
                                    ) : (
                                        <Typography variant="body1" color="textSecondary">
                                            There are no campaigns available at the moment.
                                        </Typography>
                                    )
                                )}
                            </CardContent>
                    </Grid>
                </Grid>

                <CampaignDrawer
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={this.handleCloseDrawer}
                    newCampaign={newCampaign}
                    contentTypes={contentTypes}
                    handleInputChange={this.handleInputChange}
                    onSubmitCampaign={this.onSubmitCampaign}
                    audiences={audiences}
                    selectedAudience={this.state.selectedAudience}
                    handleAudienceChange={this.handleAudienceChange}
                    errors={errors}
                    loadingCreate={this.state.loadingCreate}
                />

                <CustomSnackbar
                    snackbarOpen={this.state.snackbarOpen}
                    handleSnackbarClose={this.handleSnackbarClose}
                    severity={this.state.snackbarSeverity}
                    snackbarMessage={this.state.snackbarMessage}
                />
                {isMobile && (
                    <Tooltip title={disableCreateButton ? "You’ve reached the limit for this feature. Upgrade your subscription to access more!" : "Create a Campaign"} arrow>
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={this.handleClickOpen}
                            sx={{
                                position: 'fixed',
                                bottom: 16,
                                right: 16,
                            }}
                            disabled={disableCreateButton}
                        >
                            <PlusIcon/>
                        </Fab>
                    </Tooltip>
                )}
            </>
        );
    }
}

export default withRouter(Campaigns);