import ApiCalls from './util/ApiCalls';

class AiService {
    async getInterests(audienceAiDto) {
        return ApiCalls.defaultPost("/ai/get-interests", {}, audienceAiDto);
    }

    async getVoice(voiceAiDto) {
        return ApiCalls.defaultPost("/ai/get-voice", {}, voiceAiDto);
    }

    async generateContent(createContentDto) {
        return ApiCalls.defaultPost("/ai/content", {}, createContentDto);
    }

    async rephraseContent(rephraseContentDto) {
        return ApiCalls.defaultPost("/ai/rephrase-content", {}, rephraseContentDto);
    }

    async generateIdeas(createContentDto) {
        return ApiCalls.defaultPost("/ai/ideas", {}, createContentDto);
    }

    async topTrends(topTrendsDto) {
        return ApiCalls.defaultPost("/ai/top-trends", topTrendsDto);
    }
}

export default new AiService();