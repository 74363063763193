import React, {Component} from 'react';
import {withRouter} from '../../services/security/withRouter';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import {FormHelperText} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import RegisterService from '../../services/security/RegisterService';
import getTheme from "../getTheme";

const theme = createTheme(getTheme());

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            matchPassword: '',
            termsAndConditions: false,
            errors: {},
            errorResponse: {},
        };
    }

    validateForm = () => {
        const {firstName, lastName, email, password, matchPassword, termsAndConditions} = this.state;
        let errors = {};

        if (!firstName) {
            errors.firstName = 'Please provide your first name.';
        }

        if (!lastName) {
            errors.lastName = 'Please provide your last name.';
        }

        if (!email) {
            errors.email = 'Please enter your email address.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Please enter a valid email address.';
        }

        if (!password) {
            errors.password = 'Please enter a password.';
        } else if (password.length < 8) {
            errors.password = 'Password must be at least 8 characters long.';
        } else if (!/[A-Z]/.test(password)) {
            errors.password = 'Password must contain at least one uppercase letter.';
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.password = 'Password must contain at least one special character.';
        }

        if (!matchPassword) {
            errors.matchPassword = 'Please re-enter your password to confirm.';
        } else if (password !== matchPassword) {
            errors.matchPassword = 'The passwords you entered do not match. Please try again.';
        }

        if (!termsAndConditions) {
            errors.termsAndConditions = 'Please agree to the terms and conditions';
        }

        this.setState({errors});

        return Object.keys(errors).length === 0;
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validateForm()) {
            this.handleRegister();
        }
    };

    handleRegister = async () => {
        const queryParams = new URLSearchParams(this.props.location.search);
        const ref = queryParams.get('ref');

        const response = await RegisterService.register({
            email: this.state.email,
            password: this.state.password,
            matchPassword: this.state.matchPassword,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            termsAndConditions: this.state.termsAndConditions,
            ref: ref ? ref : null,
        });
        if (response.error) {
            this.setState({errorResponse: response});

        } else {
            this.props.onLogin(response.token, response.refreshToken);
            // redirect to subscription page
            const planName = queryParams.get('planName');
            const period = queryParams.get('period');
            let url = '/subscriptions';

            if (planName && planName !== 'null') {
                url += `?planName=${planName}`;
                if (period && period !== 'null') {
                    url += `&period=${period}`;
                }
            } else if (period && period !== 'null') {
                url += `?period=${period}`;
            }

            this.props.navigate(url);
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        let error = '';

        if (name === 'password') {
            if (!value) {
                error = 'Please enter a password.';
            } else if (value.length < 8) {
                error = 'Password must be at least 8 characters long.';
            } else if (!/[A-Z]/.test(value)) {
                error = 'Password must contain at least one uppercase letter.';
            } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                error = 'Password must contain at least one special character.';
            }
        } else if (name === 'matchPassword' && value !== this.state.password) {
            error = 'The passwords you entered do not match. Please try again.';
        }

        this.setState((prevState) => ({
            [name]: value,
            errors: {
                ...prevState.errors,
                [name]: error
            }
        }));
    };

    handleChangeCheckbox = (e) => {
        this.setState({[e.target.name]: e.target.checked});
    };


    render() {
        const {errors} = this.state;

        return (
            <div>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline/>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
                                <LockOutlinedIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign up
                            </Typography>
                            <Box component="form" noValidate sx={{mt: 3}} onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            value={this.state.firstName}
                                            onChange={this.handleChange}
                                            error={!!errors.firstName}
                                            helperText={errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="family-name"
                                            value={this.state.lastName}
                                            onChange={this.handleChange}
                                            error={!!errors.lastName}
                                            helperText={errors.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            error={!!errors.password}
                                            helperText={errors.password}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="matchPassword"
                                            label="Confirm Your Password"
                                            type="password"
                                            id="matchPassword"
                                            autoComplete="new-password"
                                            value={this.state.matchPassword}
                                            onChange={this.handleChange}
                                            error={!!errors.matchPassword}
                                            helperText={errors.matchPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            required
                                            control={<Checkbox name="termsAndConditions" color="primary" onChange={this.handleChangeCheckbox} checked={this.state.termsAndConditions} />}
                                            label={
                                                <span>
                I have read and agree to the{' '}
                                                    <Link href="/policies" target="_blank" rel="noopener">
                    terms and conditions
                </Link>{' '}
                                                    and the{' '}
                                                    <Link href="/policies" target="_blank" rel="noopener">
                    privacy policy
                </Link>.
            </span>
                                            }
                                        />
                                        {errors.termsAndConditions && <FormHelperText error>{errors.termsAndConditions}</FormHelperText>}
                                    </Grid>
                                </Grid>
                                {this.state.errorResponse &&
                                    <FormHelperText error>{this.state.errorResponse.message}</FormHelperText>}

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    Sign Up
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            Already have an account? Log in.
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </div>
        );
    }
}

export default withRouter(Register);
