import React, {Component} from 'react';
import {withRouter} from '../../../services/security/withRouter';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FullLoading from '../../shared/FullLoading';
import StripeSubscriptionService from '../../../services/payment/StripeSubscriptionService';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomSnackbar from "../../shared/CustomSnackbar";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CircularProgress from '@mui/material/CircularProgress';
import RightsService from "../../../services/security/RightsService";

class SwitchPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            loading: true,
            open: false,
            snackbarMessage: '',
            currentUsage: null,
            numberOfBrands: 0,
            dialogOpen: false,
            selectedPlanTitle: '',
            selectedPlanPeriod: '',
            updating: false,
            snackbars: [],
            upgradeEstimates: null,
            loadingEstimate: null, // New state for loading estimate
        };
    }

    componentDidMount() {
        if (!RightsService.hasRight('SUBSCRIPTION')) {
            setTimeout(() => {
                this.props.navigate('/dashboard');
            }, 0);
            return;
        }
        this.fetchPlans();
        this.fetchCurrentUsage();
    }

    fetchPlans = () => {
        StripeSubscriptionService.getProducts().then(plans => {
            this.setState({
                loading: false,
                plans: this.mapProductsToPlans(plans).sort((a, b) => a.monthlyPrice - b.monthlyPrice)
            });
        }).catch(error => {
            this.setState({open: true, loading: false});
            console.error(error);
        });
    };

    fetchCurrentUsage = () => {
        StripeSubscriptionService.getCurrentUsage().then(currentUsage => {
            const numberOfBrands = Object.keys(currentUsage).length;
            this.setState({currentUsage, numberOfBrands});
        }).catch(error => {
            console.error('Error fetching current usage:', error);
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false});
    };

    mapProductsToPlans = (products) => {
        return products.map(product => ({
            title: product.title,
            monthlyPrice: (parseInt(product['monthPrice']) / 100).toFixed(2),
            yearlyPrice: ((parseInt(product['monthPrice']) * 12 * 0.85) / 100).toFixed(2),
            seatMonthlyPrice: (parseInt(product['seatPriceMonth']) / 100).toFixed(2),
            seatYearlyPrice: ((parseInt(product['seatPriceMonth']) * 12 * 0.85) / 100).toFixed(2),
            description: product.description.join(', '),
        }));
    };

    handlePlanChange = async (plan, period) => {
        const planTitle = plan.title;
        if (this.props.authenticatedUser.inTrial) {
            this.setState({
                dialogOpen: true,
                selectedPlanTitle: planTitle,
                selectedPlanPeriod: period,
                upgradeEstimates: null,
            });
        } else {
            this.setState({loadingEstimate: `${planTitle}-${period}`});
            try {
                const upgradeEstimates = await StripeSubscriptionService.getUpgradeEstimates(planTitle, period);
                this.setState({
                    dialogOpen: true,
                    selectedPlanTitle: planTitle,
                    selectedPlanPeriod: period,
                    upgradeEstimates,
                });
            } catch (error) {
                console.error('Error fetching upgrade estimates:', error);
                this.addSnackbar('Failed to fetch upgrade estimates. Please try again later.', 'error');
            } finally {
                this.setState({loadingEstimate: null});
            }
        }
    };

    handleConfirmUpgrade = async () => {
        const {selectedPlanTitle, selectedPlanPeriod} = this.state;
        this.setState({updating: true});
        try {
            const response = await StripeSubscriptionService.upgradeSubscription(selectedPlanTitle, selectedPlanPeriod);
            if (response.url) {
                window.location.href = response.url;
            } else {
                this.addSnackbar('Subscription upgraded successfully!', 'success');
            }
        } catch (error) {
            console.error('Error upgrading subscription:', error);
            this.addSnackbar(error.message, 'error');
        } finally {
            this.setState({dialogOpen: false, updating: false});
        }
    };

    handleCloseDialog = () => {
        this.setState({dialogOpen: false});
    };

    handleCloseSnackbar = () => {
        this.setState({open: false});
    };

    addSnackbar = (message, severity) => {
        this.setState((prevState) => ({
            snackbars: [...prevState.snackbars, {message, severity, key: new Date().getTime()}],
        }));
    };

    removeSnackbar = (key) => {
        this.setState((prevState) => ({
            snackbars: prevState.snackbars.filter((snackbar) => snackbar.key !== key),
        }));
    };

    render() {
        const {
            plans,
            loading,
            open,
            snackbarMessage,
            numberOfBrands,
            dialogOpen,
            updating,
            snackbars,
            upgradeEstimates,
            loadingEstimate,
            selectedPlanTitle,
            selectedPlanPeriod
        } = this.state;
        const {authenticatedUser} = this.props;
        const currentPlanName = authenticatedUser.currentPlan.name.toLowerCase();
        const currentPlanPeriod = authenticatedUser.currentPlan.period.toLowerCase();

        return (
            <Box sx={{padding: 2}}>
                <FullLoading loading={loading || updating}/>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4}}>
                    <IconButton color="primary" onClick={() => this.props.navigate('/account')}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography component="h2" variant="h4" color="text.primary">
                        Switch Your Plan
                    </Typography>
                </Box>
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    {plans.map((plan, index) => {
                        const disableStarter = plan.title === 'Starter' && numberOfBrands > 1;
                        const disableGrowth = plan.title === 'Growth' && numberOfBrands > 5;
                        const disableButtons = disableStarter || disableGrowth;
                        const disableMessage = disableStarter
                            ? `Can't switch to this plan because you have ${numberOfBrands} brands created.`
                            : disableGrowth
                                ? `Can't switch to this plan because you have ${numberOfBrands} brands created.`
                                : '';

                        const isCurrentPlan = plan.title.toLowerCase() === currentPlanName;
                        const isLoadingEstimate = loadingEstimate === `${plan.title}-month` || loadingEstimate === `${plan.title}-year`;

                        return (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    border: isCurrentPlan ? '2px solid' : 'none',
                                    borderColor: isCurrentPlan ? 'primary.main' : 'none'
                                }}>
                                    <CardContent>
                                        {isCurrentPlan && (
                                            <Typography variant="h6" color="primary" align="center" sx={{mt: 1}}>
                                                This is your current plan.
                                            </Typography>
                                        )}
                                        <Typography component="h3" variant="h5" color="primary">
                                            {plan.title}
                                        </Typography>
                                        <Typography component="h4" variant="body1">
                                            <strong>Monthly plan:</strong> ${plan.monthlyPrice} / month
                                        </Typography>
                                        <Typography component="h4" variant="body1">
                                            <strong>Yearly plan:</strong> ${plan.yearlyPrice} / year
                                        </Typography>
                                        <Typography component="h4" variant="body1">
                                            <strong>Monthly Plan Seat:</strong> ${plan.seatMonthlyPrice} / month
                                        </Typography>
                                        <Typography component="h4" variant="body1">
                                            <strong>Yearly Plan Seat:</strong> ${plan.seatYearlyPrice} / year
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{mt: 2}}>
                                            {plan.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{flexDirection: 'column', alignItems: 'flex-start'}}>
                                        <Typography component="h4" variant="h6">
                                            Switch to {plan.title}:
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: 1}}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.handlePlanChange(plan, 'month')}
                                                disabled={disableButtons || (isCurrentPlan && currentPlanPeriod === 'month') || isLoadingEstimate}
                                            >
                                                {loadingEstimate === `${plan.title}-month` ?
                                                    <CircularProgress size={24}/> : 'Monthly'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.handlePlanChange(plan, 'year')}
                                                disabled={disableButtons || (isCurrentPlan && currentPlanPeriod === 'year') || isLoadingEstimate}
                                            >
                                                {loadingEstimate === `${plan.title}-year` ?
                                                    <CircularProgress size={24}/> : 'Yearly'}
                                            </Button>
                                        </Box>
                                        {disableMessage && (
                                            <Typography variant="body2" color="error" sx={{mt: 1}}>
                                                {disableMessage}
                                            </Typography>
                                        )}
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
                {snackbars.map((snackbar) => (
                    <CustomSnackbar
                        key={snackbar.key}
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.removeSnackbar(snackbar.key)}
                        severity={snackbar.severity}
                        snackbarMessage={snackbar.message}
                    />
                ))}
                <Dialog
                    open={dialogOpen}
                    onClose={this.handleCloseDialog}
                >
                    <DialogTitle>Confirm Subscription Upgrade</DialogTitle>
                    <DialogContent>
                        {authenticatedUser.inTrial ? (
                            <DialogContentText>
                                {(() => {
                                    const selectedPlan = plans.find(plan => plan.title === selectedPlanTitle);
                                    if (selectedPlan) {
                                        return (
                                            <>
                                                You are currently in a trial period. By switching to
                                                the {selectedPlan.title} plan, you will be switching trials. <strong>Plan
                                                Price after trial
                                                end: ${selectedPlanPeriod === 'month' ? selectedPlan.monthlyPrice : selectedPlan.yearlyPrice} per {selectedPlanPeriod} </strong> (seats
                                                not included in this calculation).
                                            </>
                                        );
                                    } else {
                                        return 'Loading plan details...';
                                    }
                                })()}
                            </DialogContentText>
                        ) : (
                            <>
                                <DialogContentText>
                                    You will be charged immediately for any outstanding balance, as the amounts are
                                    prorated.
                                </DialogContentText>
                                {upgradeEstimates && (
                                    <Box sx={{mt: 2}}>
                                        <Typography variant="h6">Upgrade Estimates:</Typography>
                                        {upgradeEstimates.lines.map((line, index) => (
                                            <Typography key={index} variant="body2">
                                                {line.description}: ${line.amount.toFixed(2)}
                                            </Typography>
                                        ))}
                                        {upgradeEstimates.total < 0 ? (
                                            <>
                                                <Typography variant="h6" sx={{mt: 2}}>
                                                    Total now: $0.00
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    Total Credit Added:
                                                    ${Math.abs(upgradeEstimates.total).toFixed(2)}
                                                </Typography>
                                            </>
                                        ) : (
                                            <Typography variant="h6" sx={{mt: 2}}>
                                                Total now:
                                                ${upgradeEstimates.total.toFixed(2)}
                                            </Typography>
                                        )}
                                        {upgradeEstimates.credit > 0 &&
                                            <Typography variant="body2" sx={{mt: 2}}>
                                                Your credit balance is ${Math.abs(upgradeEstimates.credit).toFixed(2)}.
                                                This amount will be deducted from the total in the actual payment.
                                            </Typography>
                                        }

                                    </Box>
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleConfirmUpgrade} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
}

export default withRouter(SwitchPlan);