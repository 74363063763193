import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import { withRouter } from '../../services/security/withRouter';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import StripeSubscriptionService from '../../services/payment/StripeSubscriptionService';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import RightsService from "../../services/security/RightsService";
import moment from "moment";

class MyPlan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tier: { description: [] },
            loading: true,
            product: {},
            open: false,
            usage: {},
        };
    }

    componentDidMount() {
        if (!RightsService.hasRight('SUBSCRIPTION')) {
            setTimeout(() => {
                this.props.navigate('/dashboard');
            }, 0);
            return;
        }

        StripeSubscriptionService.getSubscription().then(product => {
            this.setState({
                loading: false,
                product: product,
                tier: this.mapProductToTiers(product),
            });
        }).catch(error => {
            this.setState({ open: true, loading: false });
            console.error(error);
        });

        StripeSubscriptionService.getCurrentUsage().then(usage => {
            this.setState({ usage });
        }).catch(error => {
            console.error(error);
        });
    }

    mapProductToTiers = (product) => {
        return {
            title: product.title,
            price: parseInt(product.price) / 100,
            fullPrice: parseInt(product.fullPrice) / 100,
            quantity: product.quantity,
            description: product.description,
            period: product.period,
            currentPeriodEnd: moment(product.currentPeriodEnd).format('MMMM Do YYYY'),
            seatProductName: product.seatProductName,
            seatPlanQuantity: product.seatPlanQuantity,
            seatPlanPrice: product.seatPlanPrice ? parseInt(product.seatPlanPrice) / 100 : null,
        };
    };

    getStripeSubscriptionUrl = () => {
        this.setState({ loadingProducts: true });

        StripeSubscriptionService.getUrl(null).then(res => {
            this.setState({ loadingProducts: false });
            window.location.href = res.url;
        }).catch(error => {
            this.setState({ open: true });
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChangePlan = () => {
        this.props.navigate('/change-plan');
    };

    render() {
        const { tier, loading, open, product, usage } = this.state;

        const action = (
            <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        );

        return (
            <Container
                id="pricing"
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                    py: 4,
                }}
            >
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={400} />
                ) : (
                    <>
                        <Grid container spacing={4} alignItems="flex-start" justifyContent="center">
                            <Grid item xs={12} md={6}>
                                <Card
                                    sx={{
                                        p: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 4,
                                        boxShadow: 4,
                                        borderRadius: 2,
                                    }}
                                >
                                    <CardActions>
                                        <Button fullWidth variant="contained" color="primary" onClick={this.getStripeSubscriptionUrl}>
                                            Manage Your Subscription
                                        </Button>
                                        <Button fullWidth variant="contained" color="primary" onClick={this.handleChangePlan}>
                                            Change Plan
                                        </Button>
                                    </CardActions>
                                    <CardContent>
                                        <Box
                                            sx={{
                                                mb: 3,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography component="h2" variant="h2" sx={{ fontWeight: 700 }}>
                                                {tier.title}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            component="h3"
                                            variant="h3"
                                            sx={{ color: 'primary.main', fontWeight: 600 }}
                                        >
                                            ${tier.fullPrice}
                                            <Typography component="span" variant="body2" color="textSecondary">
                                                &nbsp; per {tier.period}
                                            </Typography>
                                        </Typography>
                                        <Typography variant="body1" sx={{ mt: 1 }}>
                                            <strong>Status:</strong> {product.status}
                                        </Typography>
                                        {product.cancelAt && <Typography variant="body1" sx={{ mt: 1 }}>
                                            <strong>Cancel at:</strong> {moment(product.cancelAt).format('MMMM Do YYYY')}
                                        </Typography>}
                                        {product.cancelledAt && <Typography variant="body1" sx={{ mt: 1 }}>
                                            <strong>Cancelled at:</strong> {moment(product.cancelledAt).format('MMMM Do YYYY')}
                                        </Typography>}
                                        <Typography variant="body1" sx={{ mt: 1 }}>
                                            <strong>Current Period End:</strong> {tier.currentPeriodEnd}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mt: 1 }}>
                                            <strong>Subscription price:</strong> ${tier.price}
                                        </Typography>
                                        {tier.seatPlanQuantity &&
                                            <Typography variant="body1" sx={{ mt: 1 }}>
                                            <strong>Number of seats:</strong> {tier.seatPlanQuantity} {tier.seatProductName}
                                        </Typography>
                                        }
                                        {tier.seatPlanPrice &&
                                        <Typography variant="body1" sx={{ mt: 1 }}>
                                            <strong>Seat Price:</strong> ${tier.seatPlanPrice}
                                        </Typography>
                                        }
                                        <Typography variant="body1" sx={{ mt: 1 }}>
                                            <strong>Total Price:</strong> ${tier.fullPrice} / {tier.period}
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                        {tier.description.map((line, index) => (
                                                <Typography variant="body2" color="textSecondary">
                                                    {line}
                                                </Typography>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        p: 3,
                                        border: '1px solid #e0e0e0',
                                        borderRadius: 2,
                                        boxShadow: 2,
                                        backgroundColor: '#f9f9f9',
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                                        Usage for Current Period
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        <strong>Brands Created:</strong> {Object.keys(usage).length} / {product.currentPlan.maxNumberOfBrands}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        <strong>Generated Images:</strong> {product.currentPlan.alreadyGeneratedImages} / {product.currentPlan.maxImagesGenerated}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        <strong>Generated speeches:</strong> {product.currentPlan.alreadyGeneratedSpeeches} / {product.currentPlan.maxSpeechesGenerated}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 2 }}>
                                         The generated images and speeches are reset at the beginning of each month.
                                    </Typography>
                                    <Divider sx={{ my: 2 }} />
                                    {Object.entries(usage).map(([brand, data], index) => (
                                        <Box key={index} sx={{ mb: 3 }}>
                                            <Typography variant="body1" sx={{ fontWeight: 600, color: 'primary.main' }}>
                                                Brand: {brand}
                                            </Typography>
                                            <Typography variant="body2">
                                                Active Marketing Campaigns: {data.activeMarketingCampaigns} / {product.currentPlan.maxMarketingCampaignsPerBrand}
                                            </Typography>
                                            <Typography variant="body2">
                                                Active Email Campaigns: {data.activeEmailCampaigns} / {product.currentPlan.maxEmailCampaignsPerBrand}
                                            </Typography>
                                            <Typography variant="body2">
                                                Active Content Campaigns: {data.activeContentCampaigns} / {product.currentPlan.maxContentCampaignsPerBrand}
                                            </Typography>
                                            <Typography variant="body2">
                                                Active Journeys: {data.activeJourneys} / {product.currentPlan.maxJourneysPerBrand}
                                            </Typography>
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}

                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    message="Unable to load the subscription details. Please try again later or contact support."
                    action={action}
                />
            </Container>
        );
    }
}

export default withRouter(MyPlan);