import React, { Component } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Skeleton, Button } from '@mui/material';
import ArchivedJourneyActionItemsService from "../../services/ArchivedJourneyActionItemsService";
import { withRouter } from "../../services/security/withRouter";

class ArchivedJourneyActionItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true,
            page: 0,
            pageSize: 10,
            totalCount: 0,
        };
    }

    componentDidMount() {
        this.fetchArchivedItems();
    }

    fetchArchivedItems = async () => {
        const { journeyId } = this.props;
        const { page, pageSize } = this.state;
        try {
            const response = await ArchivedJourneyActionItemsService.getForBrandJourney(journeyId, { page, size: pageSize });
            this.setState({
                items: response.content,
                totalCount: response.totalNumberOfItems,
                loading: false,
            });
        } catch (error) {
            console.error('Failed to fetch archived journey action items:', error);
            this.setState({ loading: false });
        }
    };

    handlePaginationModelChange = (paginationModel) => {
        const { page, pageSize } = paginationModel;
        this.setState({ page, pageSize }, this.fetchArchivedItems);
    };

    handleButtonClick = (uri) => {
        this.props.navigate(uri);
    };

    render() {
        const { items, loading, page, pageSize, totalCount } = this.state;

        const columns = [
            { field: 'title', headerName: 'Title', flex: 2, editable: false, filterable: false, sortable: false },
            { field: 'description', headerName: 'Description', flex: 1.5, editable: false, filterable: false, sortable: false },
            {
                field: 'uri',
                headerName: 'Action',
                flex: 1,
                renderCell: (params) => (
                    !params.value.includes('template') && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleButtonClick(params.value)}
                        >
                            View
                        </Button>
                    )
                ),
            },
        ];

        return (
            <Box sx={{ width: '100%' }}>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={500} />
                ) : (
                    <DataGrid
                        key={this.props.sidebarOpen}
                        rows={items}
                        columns={columns}
                        pagination
                        paginationMode="server"
                        page={page}
                        pageSize={pageSize}
                        rowCount={totalCount}
                        onPaginationModelChange={this.handlePaginationModelChange}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        style={{ marginTop: '10px' }}
                        pageSizeOptions={[10, 5, 3]}
                        disableRowSelectionOnClick
                    />
                )}
            </Box>
        );
    }
}

export default withRouter(ArchivedJourneyActionItems);